import Cookies from "js-cookie";
import React, { useState } from "react";

import { Add } from "@mui/icons-material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import MainButton from "../../components/buttons/MainButton";
import HomeTable from "../../components/table/HomeTable";
import { useUserContext } from "../../context/UserContext";
import {
  assessmentCompanyList,
  assessmentDelete,
} from "../../services/assessment.service";
import { getAccountInfo } from "../../services/user.service";

function Home() {
  const { user, setUser } = useUserContext(); // Use the hook directly within the component
  const [createdAssessments, setCreatedAssessments] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchAssessments();

    const accessToken = Cookies.get("access_token");

    if (accessToken === null || accessToken === undefined) return;

    getAccountInfo().then((val) => {
      const { data } = val;
      setUser(data);
    });
  }, []);

  const handleButtonClick = () => {
    navigate("/customer/assessments/new");
  };

  const fetchAssessments = () => {
    assessmentCompanyList().then((res) => {
      const { data } = res;

      setCreatedAssessments(data);
    });
  };

  const deleteAssess = async (id) => {
    await assessmentDelete(id);

    await assessmentCompanyList().then((res) => {
      const { data } = res;

      setCreatedAssessments(data);
    });
  };

  const changeActiveStatus = (status) => {
    setIsActive(status);
  };

  return (
    <div>
      <div className="m-auto w-11/12 pt-11 ">
        <div className="grid grid-flow-col sm:auto-cols-max justify-between sm:justify-between gap-2 pb-4">
          <div className="font-bold text-[24px]">{t("Home.jobs")}</div>
          <MainButton
            label={t("Home.addNewJob")}
            isSelected={true}
            rIcon={<Add className="ml-1" />}
            onClick={() => handleButtonClick()}
          />
        </div>
        <div className="grid grid-flow-col sm:auto-cols-max justify-between sm:justify-between gap-2 pb-4">
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-8 pb-4">
            <button
              onClick={() => {
                changeActiveStatus(true);
              }}
              className={`px-4 py-2 ${isActive ? "border-b-2 border-[#FD6B3A] text-[#FD6B3A]" : ""
                }  `}
            >
              <span className="hidden xs:block">{t("Home.active")}</span>
            </button>
            <button
              onClick={() => {
                changeActiveStatus(false);
              }}
              className={`px-4 py-2 ${isActive ? "" : "border-b-2 border-[#FD6B3A] text-[#FD6B3A]"
                }  `}
            >
              <span className="hidden xs:block">{t("Home.inactive")}</span>
            </button>
          </div>
        </div>
        <HomeTable
          createdAssessments={createdAssessments}
          deleteAss={deleteAssess}
          isActive={isActive}
        />
      </div>
    </div>
  );
}

export default Home;
