import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    // Placeholder for login function
    const authLogin = (username, roles) => {
        // Implement your login logic here, and set the user state accordingly
        setUser({ username, roles: roles }); // Example user object
    };

    // Placeholder for logout function
    const logout = () => {
        // Implement your logout logic here
        setUser(null);
    };

    // Placeholder for checking user role
    const hasRole = (role) => user?.roles?.includes(role);

    const value = { user, authLogin, logout, hasRole };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
