import {
  Box,
  Divider,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createCompanyUser,
  getCompanyUsers,
} from "../../services/user.service";
import Paper from "@mui/material/Paper";
import { completedPlayerCount } from "../../services/assessment.service";
import { Trans, useTranslation } from "react-i18next";

const modalStyle = {
  borderRadius: "15px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 12,
  p: 4,
};

const MySettings = () => {
  const [viewModal, setViewModal] = useState(false);
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [users, setUsers] = useState([]);
  const [credit, setCredit] = useState(null);
  const { t, i18n } = useTranslation();

  // const { register, handleSubmit } = useForm();

  useEffect(() => {
    getCompanyUsers().then((res) => {
      console.log("res = ", res);
      setUsers(res.data);
    });

    completedPlayerCount().then((res) => {
      console.log("COUNT = ", res);
      setCredit(res.data);
    });
  }, []);

  const submitNewUser = () => {
    console.log("Submitted = ", email);
    if (email !== "" && email.length < 4) {
      console.log("Email");
      toast.error(t("toast.emailMustBe"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    let regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(email)) {
      toast.error(t("toast.writeCorrectEmail"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    if (password !== "" && password.length < 3) {
      toast.error(t("toast.usernameMustBe4"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    createCompanyUser({
      email,
      password,
    })
      .then((res) => {
        toast.success(t("toast.newUserAddedSuccessfully"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setEmail("");
        setPassword("");
        setUsers(res.data);
      })
      .catch((e) => {
        console.log("error");
      });
  };

  return (
    <div className="m-11">
      <ToastContainer />
      <Modal
        open={viewModal}
        onClose={() => {
          setViewModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography sx={{ fontSize: 18 }} gutterBottom>
            Хэрэглэгч нэмэх
          </Typography>
          {/* <TextField
            // value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            className="mb-30"
            margin="normal"
            required
            fullWidth
            id="playerName"
            label="Хэрэглэгчийн нэр"
            name="playerName"
            autoFocus
          />
          <Divider variant="middle" /> */}
          <TextField
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="mb-30"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Хэрэглэгчийн нэвтрэх email хаяг"
            name="email"
            autoFocus
          />
          <Divider variant="middle" />
          <TextField
            type={"password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="mt-30"
            margin="normal"
            required
            fullWidth
            id="playerName"
            label="Нууц үг"
            name="playerName"
            autoFocus
          />
          <div className="grid grid-cols-4 gap-4"></div>
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 pr-4 pt-4 pb-4">
            <button
              onClick={() => {
                // setViewModal(false);
                submitNewUser();
              }}
              className="btn bg-[#23293D] hover:bg-slate-900 text-white"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.875 8.91667H7.125V6.58334H9.625V5.41667H7.125V3.08334H5.875V5.41667H3.375V6.58334H5.875V8.91667ZM6.5 11.8333C5.63542 11.8333 4.82292 11.6801 4.0625 11.3737C3.30208 11.0676 2.64062 10.6521 2.07812 10.1271C1.51562 9.60209 1.07042 8.98473 0.7425 8.275C0.414167 7.56528 0.25 6.80695 0.25 6C0.25 5.19306 0.414167 4.43473 0.7425 3.72501C1.07042 3.01528 1.51562 2.39792 2.07812 1.87292C2.64062 1.34792 3.30208 0.932199 4.0625 0.625755C4.82292 0.319699 5.63542 0.166672 6.5 0.166672C7.36458 0.166672 8.17708 0.319699 8.9375 0.625755C9.69792 0.932199 10.3594 1.34792 10.9219 1.87292C11.4844 2.39792 11.9296 3.01528 12.2575 3.72501C12.5858 4.43473 12.75 5.19306 12.75 6C12.75 6.80695 12.5858 7.56528 12.2575 8.275C11.9296 8.98473 11.4844 9.60209 10.9219 10.1271C10.3594 10.6521 9.69792 11.0676 8.9375 11.3737C8.17708 11.6801 7.36458 11.8333 6.5 11.8333Z"
                  fill="white"
                />
              </svg>
              <span className="hidden xs:block ml-2">Нэмэх</span>
            </button>
          </div>
        </Box>
      </Modal>
      <div className="mb-4 bg-white">
        <div className="p-5">
          {t("Settings.allCredits")}:{" "}
          {credit != null && credit.count ? credit.count : 0}/
          {credit != null && credit.credit ? credit.credit : 0}
        </div>
      </div>
      <div className="bg-white mb-4">
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 pr-4 pt-4 pb-4">
          <button
            onClick={() => {
              setViewModal(true);
            }}
            className="btn bg-[#23293D] hover:bg-slate-900 text-white"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.875 8.91667H7.125V6.58334H9.625V5.41667H7.125V3.08334H5.875V5.41667H3.375V6.58334H5.875V8.91667ZM6.5 11.8333C5.63542 11.8333 4.82292 11.6801 4.0625 11.3737C3.30208 11.0676 2.64062 10.6521 2.07812 10.1271C1.51562 9.60209 1.07042 8.98473 0.7425 8.275C0.414167 7.56528 0.25 6.80695 0.25 6C0.25 5.19306 0.414167 4.43473 0.7425 3.72501C1.07042 3.01528 1.51562 2.39792 2.07812 1.87292C2.64062 1.34792 3.30208 0.932199 4.0625 0.625755C4.82292 0.319699 5.63542 0.166672 6.5 0.166672C7.36458 0.166672 8.17708 0.319699 8.9375 0.625755C9.69792 0.932199 10.3594 1.34792 10.9219 1.87292C11.4844 2.39792 11.9296 3.01528 12.2575 3.72501C12.5858 4.43473 12.75 5.19306 12.75 6C12.75 6.80695 12.5858 7.56528 12.2575 8.275C11.9296 8.98473 11.4844 9.60209 10.9219 10.1271C10.3594 10.6521 9.69792 11.0676 8.9375 11.3737C8.17708 11.6801 7.36458 11.8333 6.5 11.8333Z"
                fill="white"
              />
            </svg>
            <span className="hidden xs:block ml-2">
              {t("Settings.addNewUser")}
            </span>
          </button>
        </div>
      </div>
      <div className="bg-white">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Number</TableCell>
                <TableCell align="left">Email/username</TableCell>
                <TableCell align="left">Role</TableCell>
                {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.role}</TableCell>
                  {/* <TableCell align="right">{row.carbs}</TableCell>
                  <TableCell align="right">{row.protein}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default MySettings;
