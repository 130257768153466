import { useEffect } from "react";

const ChangeUrl = () => {
  useEffect(() => {
    window.location.href = "https://4amuslhdq4s.typeform.com/to/BbxmMR7Y";
  }, []);
  return (
    <>
      <div>https://4amuslhdq4s.typeform.com/to/BbxmMR7Y</div>
    </>
  );
};

export default ChangeUrl;
