import { useTranslation } from "react-i18next";


const Congratz = () => {
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   i18n.changeLanguage("ru");
  // }, []);

  return (
    <div>
      {
        i18n.language === "ru" ? (
          <div className={`min-h-screen w-full  bg-[url('/public/assets/ruCongratz.png')] bg-cover`}>
          </div>
        ) : (<div className={`min-h-screen w-full  bg-[url('/public/assets/congratz1.png')] bg-cover`}>
        </div>)
      }

    </div>
  );
};

export default Congratz;
