import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";
import { FiEdit2, FiEye, FiEyeOff } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainInput from "../../components/input/MainInput";
import MainTable from "../../components/table/MainTable";
import { useUserContext } from "../../context/UserContext";
import { completedPlayerCount } from "../../services/assessment.service";
import {
  changeCompanyPhoto,
  createCompanyUser,
  getCompanyUsers,
  removeCompanyUser,
} from "../../services/user.service";
import AgCellMenu from "../../components/menu/AgCellMenu";
import Loader from "../../components/uncommon/loader";

const UserSettings = () => {
  const { user, setUser } = useUserContext();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [credit, setCredit] = useState(null);
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const modalRef = useRef(null);
  const [companyImg, setCompanyImg] = useState(null);
  const [selecetedCompanyImg, setSelectedCompanyImg] = useState(null);
  const companyImginputRef = useRef(null);
  const [userRole, setUserRole] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [showModal, setShowModal] = useState(false);
  //modal
  const modalButton = () => {
    setIsOpen(!isOpen);
  };

  const handleModalClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      modalButton();
    }
  };

  //users
  useEffect(() => {
    getCompanyUsers().then((res) => {
      setUsers(res.data);
    });

    completedPlayerCount().then((res) => {
      setCredit(res.data);
    });
    setCompanyImg(user?.companyPhotoUrl);
    setCompanyName(user?.companyName);
    setUserRole(user?.userRole);
  }, []);

  const handleCompanyImageClick = () => {
    companyImginputRef.current.click();
  };

  const handleCompanyFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > 25 * 1024 * 1024) {
        alert("Зургийн хэмжээ 25MB буюу түүнээс бага байх ёстой.");
        return;
      }
      const imageUrl = URL.createObjectURL(file);

      setSelectedCompanyImg(imageUrl);
      setCompanyImg(null);
      const formData = new FormData();

      formData.append("file", companyImginputRef.current.files[0]);

      changeCompanyPhoto(formData)
        .then((response) => {
          toast.success();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteOrActive1 = async (params, e) => {
    setShowModal(true);
    await removeCompanyUser({ userId: params.id }).then((val) => {
      setUsers(val.data);
    });
    setShowModal(false);
  };

  //column
  const columnDefs = [
    {
      headerName: "",
      valueGetter: "node.rowIndex + 1",
      cellRenderer: (params) => params.value,
      flex: 1,
    },
    {
      headerName: "Username",
      field: "username",
      flex: 3,
    },
    {
      headerName: t("Profile.Settings.tableEmail"),
      field: "email",
      flex: 3,
    },
    {
      headerName: t("Profile.Settings.tableAccCreateDate"),
      flex: 3,
      field: "createdDate",
    },
    {
      headerName: "",
      field: "action",
      sortable: false,
      cellRenderer: AgCellMenu,
      flex: 1,
      flex: 1,
      cellRendererParams: (params) => {
        let menuItems = [];
        menuItems.push({ label: "Delete user", action: "deleteOrActive" });
        return {
          menuItems: menuItems,
          menuActions: {
            deleteOrActive: deleteOrActive1,
          },
        };
      },
    },
  ];
  const cellRenderer = (params) => {
    return <div className="w-full">{params.value}</div>;
  };

  // submit
  const submitNewUser = () => {
    if (email !== "" && email.length < 4) {
      toast.error(t("Profile.Settings.toastEmailLengthError"));
      return;
    }

    let regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(email)) {
      toast.error(t("Profile.Settings.toastWrongEmailError"));
      return;
    }

    if (password !== "" && password.length < 8) {
      toast.error(t("Profile.Settings.toastPasswordLengthError"));
      return;
    }
    createCompanyUser({
      email,
      password,
    })
      .then((res) => {
        toast.success(t("Profile.Settings.toastAddSuccess"));
        setEmail("");
        setPassword("");
        setUsers(res.data);
        modalButton();
      })
      .catch((e) => {
        toast.error(t("toast.Error"));
      });
  };
  return (
    <>
      <div>
        <Loader showModal={showModal} />
        <div className="my-10 flex items-center">
          <div>
            <input
              type="file"
              accept="image/*"
              onChange={handleCompanyFileChange}
              style={{ display: "none" }}
              ref={companyImginputRef}
            />
            {companyImg ? (
              <div className="relative">
                <img
                  src={companyImg}
                  alt="Selected"
                  style={{ width: "100px", borderRadius: "30%" }}
                  onClick={
                    userRole === "MANAGER" || userRole === "ADMIN"
                      ? handleCompanyImageClick
                      : ""
                  }
                  // onClick={handleCompanyImageClick}
                />
                {userRole === "MANAGER" || userRole === "ADMIN" ? (
                  <div
                    className="absolute right-[-13px] bottom-[-10px] p-1 border rounded-full bg-[#324D72] border-[#324D72] "
                    onClick={
                      userRole === "MANAGER" || userRole === "ADMIN"
                        ? handleCompanyImageClick
                        : ""
                    }
                  >
                    <FiEdit2 className="cursor-pointer w-4 h-4 text-[#fff]" />
                  </div>
                ) : null}
              </div>
            ) : (
              <div
                className={`border relative rounded-full w-max ${
                  selecetedCompanyImg ? "p-0" : "p-2"
                }`}
                onClick={
                  userRole === "MANAGER" || userRole === "ADMIN"
                    ? handleCompanyImageClick
                    : ""
                }
              >
                {selecetedCompanyImg ? (
                  <img
                    src={selecetedCompanyImg}
                    style={{ width: "100px", borderRadius: "30%" }}
                    alt="selected user img"
                    className={`m-0 w-[120px] h-[120px] rounded-full text-[#666874]`}
                  />
                ) : (
                  <BiSolidUser className="m-0 cursor-pointer w-[110px] h-[112px] text-[#666874]" />
                )}
                <div className="absolute right-[-3px] bottom-[0px] p-1 border rounded-full bg-[#324D72] border-[#324D72]">
                  <FiEdit2 className="cursor-pointer w-5 h-5 m-[6px] text-[#fff]" />
                </div>
              </div>
            )}
          </div>
          <div>
            <p className="text-2xl ms-10 text-bold">{companyName}</p>
          </div>
        </div>
        <div className="flex items-center justify-between my-10">
          <div>
            <p className="text-2xl font-bold">
              {t("Profile.Settings.createAccountNumbers")} - 5
            </p>
          </div>
          <div>
            <button
              onClick={modalButton}
              className="flex items-center justify-center gap-2 px-4 py-2 bg-[#324D72] text-lg text-white rounded-lg"
            >
              <AiOutlinePlus /> {t("Profile.Settings.createAccountButton")}
            </button>
          </div>
        </div>
        <div>
          <MainTable
            columnDefs={columnDefs}
            rowData={users.map((a) => {
              return {
                ...a,
                createdDate:
                  a.createdDate != null ? a.createdDate.substring(0, 10) : "",
              };
            })}
            frameworkComponents={{ cellRenderer }}
          />
        </div>
      </div>
      {isOpen && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          <div className="fixed inset-0 bg-black opacity-50 transition-opacity duration-300"></div>
          <div
            className="bg-white w-1/2 rounded-lg p-4 relative transform transition-transform duration-300"
            ref={modalRef}
          >
            <div className="modal-content p-10">
              <p className="text-4xl font-bold mb-5">
                {t("Profile.Settings.modalTitle")}
              </p>
              <div className="mb-4">
                <MainInput
                  placeholder={t("Profile.Settings.modalInputEmail")}
                  className="border mb-5"
                  type="email"
                  required={true}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <MainInput
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder={t("Profile.Settings.modalInputPassword")}
                  className="border "
                  required={true}
                  type={show ? "text" : "password"}
                  buttonLabel={!show ? <FiEyeOff /> : <FiEye />}
                  x
                  showButton={true}
                  onClickButton={() => {
                    setShow(!show);
                  }}
                />
              </div>
              <div className="mt-10 flex justify-end gap-5">
                <button
                  type="submit"
                  onClick={() => {
                    // setViewModal(false);
                    submitNewUser();
                  }}
                  className="py-3 px-6 bg-[#324d76] hover:bg-[#324d90] font-bold  text-white rounded-lg "
                >
                  {t("Profile.Settings.modalPlusButton")}
                </button>
                <button
                  className="py-3 px-6 rounded-lg border font-bold hover:bg-[#f0f0f0]"
                  onClick={modalButton}
                >
                  {t("Profile.Settings.modalCloseButton")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};
export default UserSettings;
