import api from "../helper/request";

const completePleyer = (id) => api.get(`/assessmentPlayer/${id}`);
const deletePlayerApi = (obj) => api.post(`/assessmentPlayer/delete`, obj);
const companyAssessmentPlayer = () => api.get(`/assessmentPlayer/company`);
const getPlayer = (id) => api.get(`/assPlayer/${id}`);
const declinePlayer = (id) => api.get(`/assPlayer/declined/${id}`);
const inviteInterviewPlayer = (id) => api.get(`/assPlayer/interview/${id}`);
const waitListPlayer = (id) => api.get(`/assPlayer/waitlist/${id}`);
const totalRequest = () => api.get(`/assPlayer/company/all`);
export {
  completePleyer,
  deletePlayerApi,
  companyAssessmentPlayer,
  getPlayer,
  declinePlayer,
  inviteInterviewPlayer,
  waitListPlayer,
  totalRequest,
};
