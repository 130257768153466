import React from "react";

const MainInput = ({
  onClick,
  onChange,
  type,
  color,
  placeholder,
  className,
  value,
  required,
  id,
  icon,
  showButton,
  buttonLabel,
  onClickButton,
  name,
}) => {
  const baseStyles = "w-full px-2 text-left py-3 rounded-[10px] flex";
  const bgColor = color ? "" : color;
  const requireds = required ? required : false;
  const combinedClassName = `${baseStyles} ${bgColor} ${className || ""}`;

  return (
    <div className="relative flex items-center">
      {icon && (
        <div className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400">
          {icon}
        </div>
      )}
      <input
        onClick={onClick}
        id={id}
        className={combinedClassName}
        placeholder={placeholder}
        type={type}
        required={requireds}
        value={value}
        name={name}
        onChange={onChange}
        style={icon ? { paddingLeft: "2rem" } : { paddingLeft: "1rem" }}
      />
      {showButton && (
        <button
          type="button"
          className="absolute right-4 top-1/2 transform -translate-y-2/4 text-gray-400"
          onClick={onClickButton}
        >
          {buttonLabel}
        </button>
      )}
    </div>
  );
};

export default MainInput;
