import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import NewAssessmentGeneral from "../../pages/assessment/NewAssessmentGeneral";
import NewAssessmentRequirement from "../../pages/assessment/NewAssessmentRequirement";
import NewAssRequirementDemo from "../../pages/assessment/NewAssRequirementDemo";
import {
  createAssessmentApi,
  findAllAssessmentReq,
  findAssessmentWithSkillScore,
} from "../../services/assessment.service";

function NewAssessment() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [assessmentName, setAssessmentName] = useState("");
  const [description, setDescription] = useState("");
  const [validateAssess, setValidateAssess] = useState(false);
  const [validateJob, setValidateJob] = useState(false);
  const [skillScoreInputs, setSkillScoreInputs] = useState({
    inductive: 3,
    deductive: 3,
    attention: 3,
    processingSpeed: 3,
    quantitativeReasoing: 3,
    workingMemory: 3,
    adaptability: 3,
    curiosity: 3,
    riskTaking: 3,
    assertiveness: 3,
    cooperative: 3,
    trusting: 3,
    criticalThinking: 3,
    decisionMaking: 3,
    learningRate: 3,
    patternRecognition: 3,
    planning: 3,
    consistence: 3,
    goalSetting: 3,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [savedSkillScore, setSavedSkillScore] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [value, setValue] = useState("");
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState("general");
  const [jobName, setJobName] = useState("");
  const [jobType, setJobType] = useState("");
  const [jobDepartment, setJobDepartment] = useState("");
  const [salary, setSalary] = useState("");
  const [reqText, setReqText] = useState("");
  const [introText, setIntroText] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState("fullTime");
  const [jobTypes, setJobTypes] = useState([]);
  const [gameLanguage, setGameLanguage] = useState(0);

  useEffect(() => {
    if (id) {
      setIsEdit(true);

      findAssessmentWithSkillScore(id).then((res) => {
        setSavedSkillScore(res?.data?.assessmentSkillscore);
        setAssessment(res?.data?.assessment);
      });
    }

    findAllAssessmentReq().then((res) => {
      setJobTypes(res?.data);
    });
  }, []);

  const changeSkillScoreInput = (reqId) => {
    let newSkillScore = jobTypes.find((item) => item.id === reqId);
    setJobType(reqId);

    setSkillScoreInputs({
      inductive: newSkillScore?.inductive,
      deductive: newSkillScore?.deductive,
      attention: newSkillScore?.attention,
      processingSpeed: newSkillScore?.processingSpeed,
      quantitativeReasoing: newSkillScore?.quantitativeReasoing,
      workingMemory: newSkillScore?.workingMemory,
      adaptability: newSkillScore?.adaptability,
      curiosity: newSkillScore?.curiosity,
      riskTaking: newSkillScore?.riskTaking,
      assertiveness: newSkillScore?.assertiveness,
      cooperative: newSkillScore?.cooperative,
      trusting: newSkillScore?.trusting,
      criticalThinking: newSkillScore?.criticalThinking,
      decisionMaking: newSkillScore?.decisionMaking,
      learningRate: newSkillScore?.learningRate,
      patternRecognition: newSkillScore?.patternRecognition,
      planning: newSkillScore?.planning,
      consistence: newSkillScore?.consistence,
      goalSetting: newSkillScore?.goalSetting,
    });
  };

  const createAssessment = () => {
    let completed = true;
    const missingFields = [];

    if (jobName === "") {
      missingFields.push(t("NewAssessmentGeneral.jobName"));
      completed = false;
    }
    if (jobDepartment === "") {
      missingFields.push(t("NewAssessmentGeneral.department"));
      completed = false;
    }
    if (jobType === "") {
      missingFields.push(t("NewAssessmentGeneral.jobType"));
      completed = false;
    }
    if (salary === "") {
      missingFields.push(t("NewAssessmentGeneral.salary"));
      completed = false;
    }
    if (reqText === "") {
      missingFields.push(t("NewAssessmentGeneral.introText"));
      completed = false;
    }
    if (introText === "") {
      missingFields.push(t("NewAssessmentGeneral.reqText"));
      completed = false;
    }

    if (!completed) {
      const missingFieldsString = missingFields.join(", ");
      toast.error(`${t("NewAssessment.errToast")} ${missingFieldsString}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    // If all fields are filled, proceed to create the assessment
    createAssessmentApi({
      name: jobName,
      description: description,
      job: jobName,
      skillScore: skillScoreInputs,
      departmentName: jobDepartment,
      introductionText: introText,
      requirementText: reqText,
      salary: salary,
      assessmentReqId: jobType,
      employmentStatus: employmentStatus,
      gameLanguage: gameLanguage,
    }).then((res) => {
      const { data } = res;
      navigate(`/customer/assessments/${data.id}`, { replace: true });
    });
  };

  const changePage = (pa) => {
    if (page === "general") {
      if (
        jobName === "" ||
        jobDepartment === "" ||
        jobType === "" ||
        salary === "" ||
        reqText === "" ||
        introText === ""
      ) {
        toast.error(t("NewAssessmentGeneral.pleaseFill"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setPage("req");
      }
    } else {
      setPage("general");
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <ToastContainer />
      <div>
        <div className="flex justify-center pt-[50px]">
          <div className="flex font-bold">
            <button
              className="text-start"
              onClick={() => {
                setPage("general");
              }}
            >
              <div
                className={`${
                  page === "general" ? "bg-main-peach" : "bg-[#ABADB5]"
                } w-[450px] h-[3px]`}
              ></div>
              <p className={`${page === "general" ? "" : "text-[#ABADB5]"}`}>
                1. {t("NewAssessmentGeneral.createNewJob")}
              </p>
            </button>
            <button
              className="pl-[18px] text-start"
              onClick={() => {
                setPage("req");
              }}
            >
              <div
                className={`${
                  page !== "general" ? "bg-main-peach" : "bg-[#ABADB5]"
                } w-[450px] h-[3px]`}
              ></div>
              <p className={`${page !== "general" ? "" : "text-[#ABADB5]"}`}>
                2. {t("NewAssessmentGeneral.fillRequirement")}
              </p>
            </button>
          </div>
        </div>
      </div>
      {page === "general" ? (
        <NewAssessmentGeneral
          changePage={changePage}
          jobName={jobName}
          setJobName={setJobName}
          jobType={jobType}
          setJobType={changeSkillScoreInput}
          jobDepartment={jobDepartment}
          setJobDepartment={setJobDepartment}
          salary={salary}
          setSalary={setSalary}
          reqText={reqText}
          setReqText={setReqText}
          introText={introText}
          setIntroText={setIntroText}
          employmentStatus={employmentStatus}
          setEmploymentStatus={setEmploymentStatus}
          jobTypes={jobTypes}
        />
      ) : (
        // <NewAssessmentRequirement
        //   changePage={changePage}
        //   skillScoreInputs={skillScoreInputs}
        //   setSkillScoreInputs={setSkillScoreInputs}
        //   createAssessment={createAssessment}
        //   gameLanguage={gameLanguage}
        //   setGameLanguage={setGameLanguage}
        // />
        <NewAssRequirementDemo
          jobName={jobName}
          changePage={changePage}
          skillScoreInputs={skillScoreInputs}
          setSkillScoreInputs={setSkillScoreInputs}
          createAssessment={createAssessment}
          gameLanguage={gameLanguage}
          setGameLanguage={setGameLanguage}
        />
      )}

      {/* <div className="grid grid-cols-12 gap-4 mt-[200px]">
        <Button
          onClick={() => navigate(-1)}
          style={{
            fontSize: "18px",
            backgroundColor: "#ededed",
            color: "black",
            width: "10px",
          }}
          variant="contained"
        >
          {"<"}
        </Button>
        <div className="col-start-2 col-span-4 ...">
          <div
            className="text-xl font-semibold"
          >
            {t('NewAssessment.createNewJob')}
          </div>
          <div
            style={{
              color: "#276678",
            }}
            className="text-base "
          >
            <span className="mr-7 ">
              {
                assessmentName === "" ? t('NewAssessment.nonameJon') : `${assessmentName} ${t('NewAssessment.jobwithName')}`
              }
            </span>
          </div>
        </div>
        {
          isEdit === false ? (
            <button
              onClick={() => createAssessment()}
              className="btn bg-slate-700 hover:bg-slate-800 text-white w-40 h-14 col-start-11 col-end-12 "
            >

              <span className="hidden xs:block">{t('NewAssessment.create')}</span>
            </button>
          ) : null
        }

      </div> */}
    </div>
  );
}

export default NewAssessment;
