const UsePC = () => {
    return (
      <div className="flex h-screen">
        <div className="m-auto center text-center">
          <img src="/assets/pc.jpeg" alt="rec" style={{ maxWidth: "100%", maxHeight: "50%" }} />
          <h3 className="pt-[40px]">Уучлаарай, Та компьютэрээс орно уу</h3>
        </div>
      </div>
    );
  };
  
  export default UsePC;
  