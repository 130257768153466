import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const Permission = () => {
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation();
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("http://worldtimeapi.org/api/timezone/Asia/Ulaanbaatar");
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const month = data.datetime.substring(5, 7);
        const day = data.datetime.substring(8, 10);

        setMonth(month);
        setDay(day);
      } catch (error) {
        console.error('Error fetching data:', error); 
      }
    };

    fetchData();

    // Clean-up function if needed
    return () => {
      // Any cleanup code goes here
    };
  }, []);

  return (
    <div className="sm:w-10/12 sm:m-auto">
      <div className="sm:px-20 px-10 py-10  bg-[#ccc] bg-opacity-[26%] sm:mt-20">
        <p className="text-lg sm:text-2xl font-bold text-center">
          ТАНИУЛСАН ЗӨВШӨӨРЛИЙН ХУУДАС
        </p>
        <p className="font-bold pt-3">Танд энэ өдрийн мэндийг хүргэе!</p>
        <p className="font-bold pt-3">Танилцуулга:</p>
        <p className="pt-1.5">АШУҮИС-ийн Био-Анагаахын сургуулийн физиологийн тэнхим <b>"Метаког" психометрикийн тоглоомын үнэн бодит байдлыг үнэлэх</b> сэдвээр судалгааны ажил хийж байна. </p>
        <p className="font-bold pt-3">Судалгааны үндэслэл, зорилго:</p>
        <p className="sm:text-lg text-sm  sm:mt-0 mt-5 pt-1.5">
          Ажилтнуудын ажлаас халшрах хам шинжийг физиологи болон сэтгэл зүйн ядралтын хэмжээг "Метаког" психометрикийн тоглоом ашиглан хэмжин Маслах (Maslach)-н ажлаас халшрах хам шинжийн индекс (MBI) үр дүнтэй харьцуулан үнэлэх.
        </p>
        <p className="font-bold pt-3">
          Судалгааны талаар ерөнхий танилцуулга:
        </p>
        <p className="pt-1.5 pl-3">
          <p>
            - Судалгааны үргэлжлэх хугацаа: 2024.05.01-2024.06.01
          </p>
          <p>
            - Бид судалгаандаа АШУҮИС-ийн багш, ажилчид, оюутан залуусыг хамруулна.
          </p>
        </p>
        <p className="pt-1.5 font-bold "> Судалгаанд хамруулах шалгуур:</p>
        <p className="pt-1.5 pl-3">
          1.Таниулсан зөвшөөрлийн хуудастай танилцаж, өөрийн хүсэлтээр судалгаанд оролцохыг зөвшөөрсөн байх
        </p>
        <p className="font-bol pt-3">Судалгаанаас хасах шалгуур:</p>
        <p className="pt-1.5 pl-3"> 1. Энэхүү судалгаанд орохыг зөвшөөрөөгүй</p>

        <p className="pt-3"><b className="font-bold ">Эрсдэл:</b> Байхгүй </p>
        <p className="pt-3"><b className="font-bold">Нууцлал:</b> Бид энэхүү судалгааны явцад цуглуулсан мэдээллийг зөвхөн эрдэм шинжилгээ судалгааны зорилгоор ашиглах бөгөөд судалгаанаас гарсан үр дүнг хэвлэлд нийтлүүлэхэд таны хувийн мэдээллийг чандлан нууцлах болно.  Судалгаанд ашиглах шинжилгээнд таны нэрний оронд код бичиж ашиглах ба уг нууцлалыг зөвхөн судлаач л мэдэх юм. </p>
        <p className="pt-3 font-bold">
          Судалгаанд оролцогчийн эрх:
        </p>
        <p className="pt-1.5"> Та энэхүү судалгаанд өөрийн сайн дурын үндсэн дээр оролцох эрхтэй. Судалгаа авах өдрүүд болон цагийг тохиролцон судалгаанд ирэх боломжийг хангуулах </p>
        <p className="font-bold pt-3">Судалгаанд оролцогчийн үүрэг: </p>
        <p className="pl-3">- Судалгааг үнэн зөв бөглөх</p>
        <p className="font-bold pt-3">Холбоо барих хаяг: </p>
        <div className="pl-3">
          <p className="pt-1.5"> - АШУҮИС-ийн Био-Анагаахын Сургуулийн Физиологийн тэнхимийн багш Э.Мөнхсоёл утас: 88554052</p>
          <p className="pt-1.5"> - Судалгааны ёс зүйн асуудлын талаар холбогдохыг хүсвэл дараах хаягаар хандана уу:  </p>
        </div>


        <p className="pt-1.5">АШУҮИС-ийн Судалгааны Ёс Зүйн Хяналтын Хороо,  </p>
        <p className="pt-1.5">14210 Улаанбаатар хот, Сүхбаатар дүүрэг, </p>
        <p className="pt-1.5">С. Зоригийн гудамж, шуудангийн хаяг- 48/111 </p>
        <p className="pt-1.5">Утас: 70111372 </p>
        <p className="pt-1.5">email: irb@mnums.edu.mn </p>

        <p className="pt-5">Би дээрх мэдээлэлтэй танилцаж, судалгааны талаар (зорилго, ач холбогдлыг) ойлгосон тул судалгаанд оролцохыг зөвшөөрч байна. Би гарын үсэг зурснаар судалгаанд өөрийн хүсэлтээр оролцож байгаагаа илэрхийлж байна. Мөн надад энэхүү таниулсан зөвшөөрлийн хуудаснаас нэг хувийг өгсөн. </p>

        <p className="pt-7">Он, сар өдөр: 2024 оны {month} сарын {day} өдөр </p>

        <p className="font-bold flex justify-center">Баярлалаа. Танд эрүүл энхийг хүсье!</p>

      </div>
      <div className="sm:ps-20 px-4 sm:pe-10 mt-10">
        <div className="flex items-center gap-5">
          <input
            type="checkbox"
            checked={isChecked}
            onClick={() => {
              setIsChecked(!isChecked);
            }}
            className="sm:h-7 sm:w-7 h-4 w-4 "
          />
          <p className="sm:text-lg text-xs">
            Дээрх мэдээллийг уншиж танилцлаа. Тоглоомоос гарсан үр дүнг хувийн
            мэдээлэлтэй холболгүй, судалгаа, шинжлэх ухааны нийтлэл бичих
            зорилгоор ашиглахыг зөвшөөрч байна.
          </p>
        </div>
      </div>
      <div className="flex justify-end sm:me-20 me-5 sm:my-0 my-10">
        {console.log("location state = ", location.state.backUrl)}
        <Link
          disabled={!isChecked}
          // onClick={() => {
          //   if(isChecked){

          //   }
          // }}
          to={{ pathname: isChecked ? '/company/burnout/questions' : null }}
          state={{ backUrl: location.state.backUrl, id: location.state.id }}
          className="sm:px-6 disabled sm:py-2 py-1 px-2 pointer border border-black rounded-lg sm:text-xl text-sm font-bold hover:text-white hover:bg-black"
        >
          Зөвшөөрөх
        </Link>
      </div>
    </div >
  );
};

export default Permission;
