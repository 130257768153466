import { useParams } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import React, { useState, useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineSave } from "react-icons/ai";

import employeeData from "../test.json";

const EmployeePersonalDetail = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState(false);
  const [address, setAddress] = useState(false);
  const [employee, setEmployee] = useState(employeeData);
  const [editedPersonal, setEditedPersonal] = useState(null);
  useEffect(() => {
    const selectedEmployee = employeeData.find(
      (emp) => emp.id === parseInt(id, 10)
    );

    if (selectedEmployee) {
      setEmployee(selectedEmployee);
      setEditedPersonal({ ...selectedEmployee });
    }
  }, [id, employeeData]);

  const handleDetailEdit = () => {
    setDetail(true);
  };
  const handleDetailSave = () => {
    setDetail(false);
  };
  const handleAddressEdit = () => {
    setAddress(true);
  };
  const handleAddressSave = () => {
    setAddress(false);
  };
  return (
    <>
      <div>
        <div>
          <div>
            <p className="text-2xl mt-7 font-bold text-[#324D72]">
              Үндсэн мэдээлэл
            </p>
          </div>
          <div className="mt-5">
            {!detail ? (
              <div className=" ms-5 flex justify-between items-end ">
                <div className="flex gap-4 mt-2">
                  <div className="text-end ">
                    <p className="ms-4 text-gray-500 ">Бүтэн нэр</p>
                    <p className="ms-4 text-gray-500 my-2 ">Богино нэр</p>
                    <p className="ms-4 text-gray-500 ">
                      Нийгмийн даатгалын дугаар
                    </p>
                    <p className="ms-4 text-gray-500 my-2">Төрсөн өдөр</p>
                    <p className="ms-4 text-gray-500 ">Сэргээх имэйл</p>
                    <p className="ms-4 text-gray-500 my-2">Утасны дугаар</p>
                  </div>
                  <div className="text-start ">
                    <p className="text-[#324D72] font-bold">
                      {employee.lastName} {employee.firstName}
                    </p>
                    <p className="text-[#324D72] font-bold my-2">
                      {employee.firstName}
                    </p>
                    <p className="text-[#324D72] font-bold">0XXX-XX-1338</p>
                    <p className="text-[#324D72] font-bold my-2">
                      {employee.startDate}
                    </p>
                    <p className="text-[#324D72] font-bold">{employee.email}</p>
                    <p className="text-[#324D72] font-bold my-2">99009900</p>
                  </div>
                </div>
                <div className="">
                  <button
                    className="flex items-center gap-1"
                    onClick={handleDetailEdit}
                  >
                    <BiEdit className="text-xl text-[#FF9D72]" /> Засах
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex justify-between items-end">
                <div className="flex gap-4 mt-2">
                  <div className="text-end  ">
                    <p className="ms-4 mt-2 ">Бүтэн нэр</p>
                    <p className="ms-4 mt-6 my-2 ">Богино нэр</p>
                    <p className="ms-4 mt-4 w-full text-start  ">
                      Нийгмийн даатгалын дугаар
                    </p>
                    <p className="ms-4 mt-3 my-2">Төрсөн өдөр</p>
                    <p className="ms-4 mt-7">Сэргээх имэйл</p>
                    <p className="ms-4 mt-6 my-2">Утасны дугаар</p>
                  </div>
                  <div className="text-start ">
                    <input
                      className="ms-3 w-2/4  rounded"
                      type="text"
                      placeholder={`${employee.lastName} ${employee.firstName}`}
                    />
                    <input
                      className="ms-3 w-2/4  my-2  rounded"
                      type="text"
                      placeholder={employee.firstName}
                    />
                    <input
                      className="ms-3 w-2/4 rounded"
                      type="text"
                      placeholder="0XXX-XX-1338"
                    />
                    <input
                      className="ms-3 w-2/4 my-2 rounded"
                      type="date"
                      placeholder={employee.workStartedDate}
                    />
                    <input
                      className="ms-3 w-2/4 rounded"
                      type="email"
                      placeholder={employee.email}
                    />
                    <input
                      className="ms-3 w-2/4 my-2 rounded"
                      type="number"
                      placeholder="99009900"
                    />
                  </div>
                </div>
                <div className="">
                  <button
                    className="flex items-center gap-1"
                    onClick={handleDetailSave}
                  >
                    <AiOutlineSave className="text-xl text-[#FF9D72]" />{" "}
                    Хадгалах
                  </button>
                </div>
              </div>
            )}
          </div>
          <div>
            <div>
              <p className="text-2xl mt-7 font-bold text-[#324D72]">
                Гэрийн хаяг
              </p>
            </div>
            <div>
              {!address ? (
                <div className=" ms-5 flex justify-between items-end ">
                  <div className="flex gap-4 mt-2">
                    <div className="text-end ">
                      <p className="ms-4 text-gray-500 my-2">Одоогийн хаяг</p>
                    </div>
                    <div className="text-start ">
                      <p className="text-[#324D72] w-3/4 font-bold my-2">
                        Хан-Уул Дүүрэг 11-р хороо Буянт-Ухаа 2 хороолол 1002
                        байр 34 тоот
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <button
                      className="flex items-center gap-1"
                      onClick={handleAddressEdit}
                    >
                      <BiEdit className="text-xl text-[#FF9D72]" /> Засах
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex justify-between items-end">
                  <div className="flex gap-4 mt-2">
                    <div className="text-end  ">
                      <p className="ms-4 mt-3 my-2">Одоогийн хаяг</p>
                    </div>
                    <div className="text-start ">
                      <input
                        className="ms-3 w-3/4 my-2 rounded"
                        type="text"
                        placeholder="Хан-Уул Дүүрэг 11-р хороо Буянт-Ухаа 2 хороолол 1002 байр 34 тоот"
                      />
                    </div>
                  </div>
                  <div className="">
                    <button
                      className="flex items-center gap-1"
                      onClick={handleAddressSave}
                    >
                      <AiOutlineSave className="text-xl text-[#FF9D72]" />
                      Хадгалах
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-20 mt-10">
          <button className="flex items-center  text-lg gap-2">
            <AiOutlineSave className="text-[#FF9D72] text-xl" /> Нэмэлт мэдээлэл
            нэмэх
          </button>
        </div>
      </div>
    </>
  );
};

export default EmployeePersonalDetail;
