import { useParams } from "react-router-dom";
import {
  getSurveyById,
  survayAnswerData,
} from "../../../services/survey.service";
import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
const QuestionsPage = () => {
  const { surveyId, questionId } = useParams();
  const [survey, setSurvey] = useState([]);
  const [answer, setAnswer] = useState();
  const [surveyQuestionText, setSurveyQuestionText] = useState("");

  useEffect(() => {
    survayAnswerData(questionId)
      .then((data) => setAnswer(data.data))
      .catch((err) => console.log(err));
    getSurveyById(surveyId)
      .then((data) => {
        setSurvey(data.data);
        const surveyQuestion = data.data.surveyQuestions.find(
          (question) => question.id === parseInt(questionId)
        );
        if (surveyQuestion) {
          setSurveyQuestionText(surveyQuestion.text);
        }
      })
      .catch((err) => console.log(err));
  }, [questionId, surveyId]);

  if (!answer || answer.length === 0) {
    return <p>No answer data available.</p>;
  }
  const answerCounts = answer.reduce((counts, entry) => {
    const answerValue = entry.answer;
    counts[answerValue] = (counts[answerValue] || 0) + 1;
    return counts;
  }, {});

  const answerArray = Object.entries(answerCounts).map(
    ([answerValue, count]) => ({
      answer: parseInt(answerValue),
      count: count,
    })
  );
  const answerArrays = [];
  for (let i = 1; i <= 5; i++) {
    answerArrays.push(answerArray.filter((item) => item.answer === i));
  }
  const labelsWithCounts = [];
  const dataValues = [];

  for (let i = 1; i <= 5; i++) {
    const answerArray = answerArrays[i - 1];
    const total = answerArray.reduce((sum, entry) => sum + entry.count, 0);
    labelsWithCounts.push(i);
    dataValues.push(total);
  }

  const data = {
    labels: labelsWithCounts,
    datasets: [
      {
        data: dataValues,
        backgroundColor: [
          "#FF5733",
          "#FFC300",
          "#33FF57",
          "#339DFF",
          "#9933FF",
        ],
      },
    ],
  };
  console.log(survey);
  const options = {
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          render: function (args) {
            return `${args.text} - ${args.parsed}`;
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.parsed;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <>
      <div className="m-auto w-10/12 pt-11">
        <div>
          <p className="text-2xl text-[#324d72]"> {surveyQuestionText}</p>
        </div>
        <div className="mt-10 flex items-center">
          <div className="h-[400px] w-[400px] min-w-screen flex items-center justify-center">
            <Pie data={data} options={options} />
          </div>
          <div>
            {answerArray.map((item) => {
              console.log(item);
              return <div></div>;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionsPage;
