import React, { useEffect, useState } from "react";
import { IoFilter } from "react-icons/io5";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { useParams } from "react-router-dom";

import "../../../css/style.scss";
import { getOnaReport } from "../../../services/ona.servise";
import Loading from "../../../components/uncommon/Loading";
import { useTranslation } from "react-i18next";

const OnaReport = () => {
  const [data, setData] = useState(null);
  const [animate, setAnimate] = useState(false);
  const { id } = useParams();
  const [sortConfig, setSortConfig] = useState({
    key: "shares",
    direction: "ascending",
  });
  const { t } = useTranslation();
  const [highestInfluence, setHighestInfluence] = useState({});
  const [highestGoldStar, setHighestGoldStar] = useState({});
  const [highestRedFlag, setHighestRedFlag] = useState({});

  useEffect(() => {
    getOnaReport(id)
      .then((data) => {
        setData(data.data);

        const personWithHighestInfluence = data.data.reduce((prev, current) => {
          const prevInfluence = prev.question2Score + prev.question3Score;
          const currentInfluence =
            current.question2Score + current.question3Score;
          return currentInfluence > prevInfluence ? current : prev;
        });

        const personWithHighestGoldStar = data.data.reduce((prev, current) => {
          return current.question5Score > prev.question5Score ? current : prev;
        });

        const personWithHighestRedFlag = data.data.reduce((prev, current) => {
          return current.question6Score > prev.question6Score ? current : prev;
        });

        setHighestInfluence(personWithHighestInfluence);
        setHighestGoldStar(personWithHighestGoldStar);
        setHighestRedFlag(personWithHighestRedFlag);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    const sortedData = [...data].sort((a, b) => {
      let aValue, bValue;

      switch (key) {
        case "influence":
          aValue = a.question2Score + a.question3Score;
          bValue = b.question2Score + b.question3Score;
          break;
        case "stars":
          aValue = a.question5Score;
          bValue = b.question5Score;
          break;
        case "flags":
          aValue = a.question6Score;
          bValue = b.question6Score;
          break;
        default:
          aValue = bValue = 0;
      }

      if (aValue < bValue) {
        return direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setSortConfig({ key, direction });
    setData(sortedData);

    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 300);
  };

  if (data === null) {
    return <Loading />;
  }

  return (
    <div className="bg-[#F3F4F8] min-h-screen">
      <div className="w-10/12 pt-11 m-auto">
        {/* <button className="bg-white p-1 flex items-center gap-1 rounded-lg text-xs text-[#222]">
          <IoFilter />
          Шүүлтүүр
        </button> */}
        <div className="flex items-start gap-4 mt-4">
          <div className="w-[60%]">
            <div className="rounded-xl w-full bg-white px-4 py-2">
              <div className="flex justify-end">
                <div className="w-[70%] h-[72px] flex justify-center items-center gap-8 bg-[#F9F9F9] rounded-xl py-2">
                  <div className="flex flex-col items-center mt-2">
                    🤗
                    <p className="text-sm font-semibold text-[#222] text-center">
                      influence
                    </p>
                  </div>
                  <div className="flex flex-col items-center mt-2">
                    🚀
                    <p className="text-sm font-semibold text-[#222] text-center">
                      gold stars
                    </p>
                  </div>
                  <div className="flex flex-col items-center mt-2">
                    🚩
                    <p className="text-sm font-semibold text-[#222] text-center">
                      heads ups
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-4">
                <div className="relative overflow-y-auto max-h-[400px]">
                  {" "}
                  {/* Adjust max-height as needed */}
                  <table className="w-full">
                    <thead className="sticky top-0 bg-[#F9F9F9]">
                      {" "}
                      {/* Fixed header with gray background */}
                      <tr>
                        <th className="py-2 px-4"></th>
                        <th
                          className="py-2 px-4 cursor-pointer"
                          onClick={() => sortData("influence")}
                        >
                          {sortConfig.key === "influence" ? (
                            sortConfig.direction === "ascending" ? (
                              <div
                                className={`text-sm text-[#222] flex items-center gap-1 ${
                                  animate ? "animate-switch" : ""
                                }`}
                              >
                                {t("ona.report.less")}{" "}
                                <HiOutlineSwitchHorizontal />{" "}
                                {t("ona.report.lot")}
                              </div>
                            ) : (
                              <div
                                className={`text-sm text-[#222] flex items-center gap-1 ${
                                  animate ? "animate-switch" : ""
                                }`}
                              >
                                {t("ona.report.lot")}{" "}
                                <HiOutlineSwitchHorizontal />{" "}
                                {t("ona.report.less")}
                              </div>
                            )
                          ) : (
                            <div className="text-sm text-[#222] flex items-center gap-1">
                              {t("ona.report.less")}{" "}
                              <HiOutlineSwitchHorizontal />{" "}
                              {t("ona.report.lot")}
                            </div>
                          )}
                        </th>

                        <th
                          className="py-2 px-4 cursor-pointer"
                          onClick={() => sortData("stars")}
                        >
                          {sortConfig.key === "stars" ? (
                            sortConfig.direction === "ascending" ? (
                              <div
                                className={`text-sm text-[#222] flex items-center gap-1 ${
                                  animate ? "animate-switch" : ""
                                }`}
                              >
                                {t("ona.report.less")}{" "}
                                <HiOutlineSwitchHorizontal />{" "}
                                {t("ona.report.lot")}
                              </div>
                            ) : (
                              <div
                                className={`text-sm text-[#222] flex items-center gap-1 ${
                                  animate ? "animate-switch" : ""
                                }`}
                              >
                                {t("ona.report.lot")}{" "}
                                <HiOutlineSwitchHorizontal />{" "}
                                {t("ona.report.less")}
                              </div>
                            )
                          ) : (
                            <div className="text-sm text-[#222] flex items-center gap-1">
                              {t("ona.report.less")}{" "}
                              <HiOutlineSwitchHorizontal />{" "}
                              {t("ona.report.lot")}
                            </div>
                          )}
                        </th>
                        <th
                          className="py-2 px-4 cursor-pointer"
                          onClick={() => sortData("flags")}
                        >
                          {sortConfig.key === "flags" ? (
                            sortConfig.direction === "ascending" ? (
                              <div
                                className={`text-sm text-[#222] flex items-center gap-1 ${
                                  animate ? "animate-switch" : ""
                                }`}
                              >
                                {t("ona.report.less")}{" "}
                                <HiOutlineSwitchHorizontal />{" "}
                                {t("ona.report.lot")}
                              </div>
                            ) : (
                              <div
                                className={`text-sm text-[#222] flex items-center gap-1 ${
                                  animate ? "animate-switch" : ""
                                }`}
                              >
                                {t("ona.report.lot")}{" "}
                                <HiOutlineSwitchHorizontal />{" "}
                                {t("ona.report.less")}
                              </div>
                            )
                          ) : (
                            <div className="text-sm text-[#222] flex items-center gap-1">
                              {t("ona.report.less")}{" "}
                              <HiOutlineSwitchHorizontal />{" "}
                              {t("ona.report.lot")}
                            </div>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => {
                        const influence =
                          item.question2Score + item.question3Score;
                        return (
                          <tr key={index}>
                            <td className="px-4 py-2 text-sm text-[#222]">
                              {item.lastName} {item.firstName}
                            </td>
                            <td className="px-4 py-2 text-sm text-[#222]">
                              <div className="flex items-center gap-1 justify-center">
                                <p>🤗</p>
                                {influence}
                              </div>
                            </td>
                            <td className="px-4 py-2 text-sm text-[#222] ">
                              <div className="flex items-center gap-1 justify-center">
                                <p>🚀</p>
                                {item.question5Score}
                              </div>
                            </td>
                            <td className="px-4 py-2 text-sm text-[#222] ">
                              <div className="flex items-center gap-1 justify-center">
                                <p>🚩</p>
                                {item.question6Score}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[40%]">
            <div className="flex items-start justify-between gap-3 mb-4">
              <div className="flex flex-col items-center justify-center  bg-[#fff] w-[130px] h-[134px] rounded-lg px-3 py-2">
                <p className="text-3xl mb-0 pb-0">🚀</p>
                <p className="text-[#FFDD2D] font-semibold">Gold star</p>
                <p className="text-[#222] text-sm font-semibold text-center">
                  {highestGoldStar.lastName} {highestGoldStar.firstName}
                </p>
              </div>
              <div className="flex flex-col items-center justify-center bg-[#fff] w-[130px] h-[134px] rounded-lg px-3 py-2">
                <p className="text-3xl mb-0 pb-0">🤗</p>

                <p className="text-[#27ae60] font-semibold">Influence</p>
                <p className="text-[#222] text-sm font-semibold text-center">
                  {highestInfluence.lastName} {highestInfluence.firstName}
                </p>
              </div>
              <div className="flex flex-col items-center justify-center bg-[#fff] w-[130px] h-[134px] rounded-lg px-3 py-2">
                <p className="text-3xl mb-0 pb-0">🚩</p>

                <p className="text-[#FF4D4D] font-semibold">Heads up</p>
                <p className="text-[#222] text-sm font-semibold text-center">
                  {highestRedFlag.lastName} {highestRedFlag.firstName}
                </p>
              </div>
            </div>
            <div className="p-4 bg-white rounded-xl">
              <div className="relative mb-4">
                <div className="absolute top-5 left-0">
                  <p className="text-[42px] opacity-15">🤗</p>
                </div>
                <p className="text-[#27ae60] font-semibold">Influence</p>
                <div className="ms-6">
                  <p className="text-[#222] text-sm">
                    {t("ona.report.influenceDesc")}
                  </p>
                  <p className="text-[#222] text-sm mt-1">
                    {t("ona.report.influenceDesc2")}
                  </p>
                </div>
              </div>
              <div className="relative mb-4">
                <div className="absolute top-5 left-0">
                  <p className="text-[42px] opacity-15">🚀</p>
                </div>
                <p className="text-[#FFDD2D] font-semibold">Gold stars</p>
                <div className="ms-6">
                  <p className="text-[#222] text-sm mt-1">
                    {t("ona.report.goldStarDesc")}
                  </p>
                </div>
              </div>
              <div className="relative mb-4">
                <div className="absolute top-5 left-0">
                  <p className="text-[42px] opacity-15">🚩</p>
                </div>
                <p className="text-[#FF4D4D] font-semibold">Heads ups</p>
                <div className="ms-6">
                  <p className="text-[#222] text-sm">
                    {t("ona.report.redFlagDesc")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnaReport;
