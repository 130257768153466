import DownloadIcon from "@mui/icons-material/Download";
import {
  Button,
  CircularProgress,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  ArcElement,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import html2canvas from "html2canvas";
import { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getPlayerOpenReport } from "../../services/assessment.service";
import { useTranslation } from "react-i18next";

const { jsPDF } = require("jspdf");

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
);
const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "white",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "25px",
};

const data = {
  labels: [
    "Суралцах хурд",
    "Хэв таних",
    "Төлөвлөх чадвар",
    "Нухацтай сэтгэлгээ",
  ],

  datasets: [
    {
      label: "# of Votes",
      data: [44, 67, 21, 77],
      backgroundColor: [
        "rgba(255, 99, 132, 0.5)",
        "rgba(54, 162, 235, 0.5)",
        "rgba(255, 206, 86, 0.5)",
        "rgba(75, 192, 192, 0.5)",
      ],
      borderWidth: 1,
    },
  ],
};

const skillDetails = {
  agreeable: [
    {
      eName: "Cooperative",
      name: "Багаар ажиллах чадвар",
      min: "Бие даасан, ганцаараа ажиллах дуртай",
      max: "Хамтарч, Багаар ажиллах дуртай",
      txt: "Бусдыг сонирхож, анхаарал хандуулж ямарваа асуудлыг бусдын оролцоог хангаж шийдвэрлдгийг харуулсан үзүүлэлт",
    },
    {
      eName: "Assertiveness",
      name: "Өөртөө итгэлтэй, шийдэмгий байдал",
      min: "Зохицомтгой, бусдын үзэл бодолд уян хатан ханддаг",
      max: "Өөрийн үзэл бодолд тууштай, зоримог, шийдэмгий",
      txt: "Шийдэмгүй хүмүүс нь өөрийнхөө талаар өндөр үнэлэмжтэй байдаг, заримдаа бусдад буулт хийхээс татгалзах тал бий",
    },
    {
      eName: "Trusting",
      name: "Бусдад итгэх хандлага",
      min: "Сонор, хашир, үл итгэгч, сөрөгөөр ханддаг",
      max: "Бусдад итгэл хүлээлгэдэг, амархан итгэдэг, эерэгээр ханддаг",
      txt: "Энэ хандлага нь хүмүүст амархан итгэх, бусдыг ихэвчлэн үнэнч, шударга, сайхан сэтгэлтэй гэж үздэг хүмүүст илэрдэг",
    },
  ],
  conscientiousness: [
    {
      eName: "Consistence",
      name: "Тууштай байдал ",
      min: "Олон шинэ зүйлсийг турших дуртай",
      max: "Эхэлсэн зүйлээ дуусгахыг эрхэмлэдэг",
      txt: "Тогтвортой эсвэл танил орчинд ажиллах дуртай бөгөөд тогтмол арга барилаа үргэлжлүүлэхийг илүүд үздэг",
    },
    {
      eName: "Goal setting",
      name: "Зорилго төлөвлөлт",
      min: "Бүтэц болон төлөвлөлгүйгээр ажиллах талтай",
      max: "Маш сайн төлөвлөж тэрэндээ хүрхийг зорьдог",
      txt: "Үйлдэл хийхээс өмнө гарч болох сөрөг үр дагаваруудыг тооцоолж үзэхэд хангалттай хэмжээнд төвлөрч, зорилт тавьдаг",
    },
  ],
  openness: [
    {
      eName: "Curiousity",
      name: "Сониуч зан",
      min: "Тогтонги, сурсан дадлаа хадгалахыг эрхэмлэдэг",
      max: "Шинийг эрэлхийлэгч",
      txt: "Шинэ, сонирхолтой зүйлсийг туршиж үзэх сонирхолын түвшин",
    },
    {
      eName: "Risk taking",
      name: "Эрсдэл хүлээх чадвар",
      min: "Аливаад болгоомжтой, няхуур ханддаг",
      max: "Хязгаарлагдмал мэдээлэлтэйтэйгээр боломжийг ашиглах чадвартай",
      txt: "Мэдээлэл дутуу өгөгдсөн нөхцөл байдалд туршиж үзэж илүү өгөөжийн төлөө эрсдэл гаргах чадвар юм",
    },
    {
      eName: "Adaptability",
      name: "Дасан зохицох чадвар",
      min: "Тогтвортой орчин нөхцөлд тууштай ажиллах талтай",
      max: "Таамаглаагүй өөрчлөлтөд нээлттэй ханддаг",
      txt: "Төсөлөөгүй эсвэл төлөвлөөгүй өөрчлөлтөд дасан зохицох чадвар юм",
    },
  ],
  generalIntelligence: [
    {
      eName: "Inductive reasoning ",
      name: "Ерөнхийлөн дүгнэх чадвар",
      min: "Заримдаа үндэслэл ба дүгнэлтийн холбоос логикийг гаргахад хүндрэлтэй байх талтай",
      max: "Өөрийн туршлага болон ерөнхий мэдлэг дээр үндэслэн зөв дүгнэлт хийх чадвартай",
      txt: "Ерөнхийлөн дүгнэх чадвар нь тодорхой ажиглалт дээр тулгуурлан ерөнхий таамаглал дэвшүүлэх , дүгнэлт гаргахыг илтгэж байгаа юм.",
    },
    {
      eName: "Quantitative reasoning",
      name: "Тоон сэтгэлгээ",
      min: "Танд математикийн тооцоолол цээжээр хийхдээ бага зэргийн хүндрэлтэй байдаг",
      max: "Өгөгдөл дээр шинжилгээ хийж, тоотой харьцахдаа маш сайн",
      txt: "Тоон үндэслэл гэдэг нь бодит ертөнцийн асуудлыг шийдвэрлэхийн тулд математикийн ойлголт, тооцоолол хийх ур чадварыг хэлдэг юм.",
    },
    {
      eName: "Attention",
      name: "Анхаарал",
      min: "Таны анхаарал сарниж, өөр бодлуудад автдахдаа амархан байх магдлалтай",
      max: "Хийж байгаа зүйлээ дуустал төвлөрөх чадвартай",
      txt: "Анхаарал гэдэг нь том утгаараа аливаа зүйлд тогтож төвлөрөх чадвар бөгөөд анхаарал нь нөөцтэй учир маш хянамгай олон тийш цацах хэрэгтэй.",
    },
    {
      eName: "Working memory",
      name: "Ажлын санах ой",
      min: "Өөрийн бодол санаа, төлөвлөгөөгөө бичиж тэмдэглэх шаардлага үүсдэг",
      max: "Мэдээлэл тогтоох, боловсруулах хурд маш өндөр",
      txt: "Ажлын санах ой нь аливаа мэдээллийг богино хугацааны санах ойд хадгалах болон тухайн мэдээллийг удирдах чадвар юм.",
    },
    {
      eName: "Processing speed",
      name: "Боловсруулах хурд",
      min: "Мэдээлэл хүлээн аваад боловсруулахдаа цаг зарцуулах шаардлагатай байдаг",
      max: "Мэдээлэл хүлээн аваад боловсруулахад бага хугацаа зарцуулдаг",
      txt: "Боловсруулалтын хурд гэдэг нь мэдээллийг хүлээн авснаас хойш түүнийг ойлгож, хариу үйлдэл үзүүлж эхлэх хүртэлх хугацаа юм.",
    },
    {
      eName: "Deductive reasoning",
      name: "Нарийвчлан дүгнэх чадвар",
      min: "Асуудлыг бүхэллэг байдлаар танин мэдэх, ойлгохын тулд мэдээлэл цуглуулахад их цаг зарцуулдаг",
      max: "Логик, дэс дараалал, зарчимч хандаж дүгнэлтийг хийдэг",
      txt: "Нарийвчлан дүгнэх чадвар нь логик, тооцоолол, туршилт дээр тулгуурлан таамаглал, дүгнэлт хийхийг илтгэж байгаа юм.",
    },
  ],
  competencies: [
    {
      eName: "Pattern recognition",
      name: "Хэв таних",
      min: "Цоо шинэ зүйл хийх үед зүй тогтолыг нь олоход нэлээн цаг зарцуулах хэрэгтэй болдог",
      max: "Хийж буй зүйлийнхээ зүй тогтолыг олохдоо маш сайн",
      txt: "Хэв таних гэдэг нь гадна орчиноорс ирсэн мэдээллийг өөрийн санах ойтой нийцүүлэн дүгнэх ур чадвар юм",
    },
    {
      eName: "Critical thinking",
      name: "Нухацтай сэтгэлгээ",
      min: "Өөрийн өмнөх туршлага болон мэдрэмж, эмоци дээр суурилж шийдвэр гаргах талтай",
      max: "Шийдвэрийг өгөгдөл болон бодит баримтан дээр суурилж логик, дэс дараалалтай гаргадаг",
      txt: "Нухацтай сэтгэлгээ гэдэг нь олж авсан мэдээллээ ухамсартайгаар нягталж, дүгнэж, цаашид үүн дээр суурилан үйлдэл гаргах үгүйгээ шийддэг ур чадвар юм",
    },
    {
      eName: "Decision making",
      name: "Шийдвэр гаргах чадвар",
      min: "Шийдвэр гаргах нь таны хувьд хэцүү даалгавар болдог",
      max: "Хурдан, зөв шийдвэрүүд гаргаж чаддаг",
      txt: "Шийдвэр гаргах чадвар нь тухайн хүн олон сонголтуудаас хэр чанартай, хурдтай сонголт хийж байгааг илтгэх үзүүлэлт юм",
    },
    {
      eName: "Planning",
      name: "Төлөвлөх чадвар",
      min: "Туршиж үзэж, алдаа нь дээрээсээ суралцаж төлөвлөдөг",
      max: "Маш сайн төлөвлөгч",
      txt: "Төлөвлөлт гэдэг нь зорилгодоо хүрэхийн тулд шаардлагатай үйлдлүүдийн зөв дарааллыг сонгох, даалгавар бүрт өөрийн танин мэдэхүйн зохих нөөцийг хуваарилах чадвар юм.",
    },
    {
      eName: "Learning rate",
      name: "Суралцах хурд",
      min: "Алдаа нь дээрээ суралцаж бага багаар сайжирдаг",
      max: "Маш хурдан алдаж онож цааш илүү хурдан гүйцэтгэл үзүүлдэг",
      txt: "Аливаа даалгаварын мөн чанрыг ойлгож гүйцэтгэлээ сайжруулж буйг илтгэж буй үзүүлэлт юм ",
    },
  ],
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#334155",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AssessmentReportOpen = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [playerData, setPlayerData] = useState(null);
  const [assessCompany, setAssessCompany] = useState(null);
  const [radarData, setRadarData] = useState({
    labels: [
      "Ерөнхийлөн дүгнэх чадвар",
      "Нарийвчлан дүгнэх чадвар",
      "Тоон сэтгэлгээ",
      "Анхаарал",
      "Ажлын санах ой",
      "Боловсруулах хурд",
      "Хэв таних",
      "Нухацтай сэтгэлгээ",
      "Шийдвэр гаргах чадвар",
      "Төлөвлөх чадвар",
      "Суралцах хурд",
      "Сониуч зан",
      "Эрсдэл хүлээх чадвар",
      "Дасан зохицох чадвар",
      "Тууштай байдал",
      "Зорилго төлөвлөлт",
      "Багаар ажиллах чадвар",
      "Бусдад итгэх хандлага ",
      "Өөртөө итгэлтэй, шийдэмгий байдал",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  });

  const { t } = useTranslation();
  const iv2PDF = (e) => {
    /////////////////////////////
    // Hide/show button if you need
    /////////////////////////////
    console.log("AA");
    const but = e.target;
    but.style.display = "none";

    let input = window.document.getElementsByClassName("div2PDFrep")[0];

    html2canvas(input).then((canvas) => {
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;
      const img = canvas.toDataURL("image/png");

      doc.addImage(img, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(img, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      doc.save(`${playerData?.assPlayer?.player?.email}.pdf`);
      but.style.display = "block";
      //   remPdf.style.display = dis;
      //   remPdf1.style.display = dis;
    });
  };

  const reportHeader = () => {
    return (
      <div className="pt-[30px] pr-[80px] pl-[60px] pb-[30px] bg-main-blue text-white">
        <div className="flex justify-between">
          <div>
            <div className="pt-4">
              {playerData?.assPlayer?.player?.createdBy === "pinecone" ? (
                <img
                  src="/assets/pinecone.png"
                  alt="Pinecone"
                  width="150"
                  height="100"
                />
              ) : (
                <img
                  src="/assets/metacogLogo.svg"
                  alt="Metacog"
                  width="150"
                  height="100"
                />
              )}
            </div>
            <div className="text-[12px] text-center">
              {`Огноо: ${playerData?.assPlayer?.createdDate?.substring(0, 10)}`}
            </div>
          </div>
          <div>
            <div className="font-bold text-[22px] pb-4">
              {playerData?.assPlayer?.player?.name}
            </div>
            <div className="text-[12px]">
              Байгууллага: {assessCompany?.companyName}
            </div>
            <div className="text-[12px]">
              Ажлын байр: {assessCompany?.assessmentName}
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setShowModal(true);

    getPlayerOpenReport(id)
      .then((res) => {
        const { data } = res;
        setAssessCompany(data?.assessmentCompany);

        setPlayerData(data);
        setRadarData({
          labels: [
            [
              "Ерөнхийлөн дүгнэх чадвар",
              data?.generalIntelligence?.inductiveReasoning
                .toString()
                .substring(0, 4),
            ],
            [
              "Нарийвчлан дүгнэх чадвар",
              data?.generalIntelligence?.deductiveReasoning
                .toString()
                .substring(0, 4),
            ],
            [
              "Тоон сэтгэлгээ",
              data?.generalIntelligence?.quantitativeReasoing
                .toString()
                .substring(0, 4),
            ],
            [
              "Анхаарал",
              data?.generalIntelligence?.attention.toString().substring(0, 4),
            ],
            [
              "Ажлын санах ой",
              data?.generalIntelligence?.workingMemory
                .toString()
                .substring(0, 4),
            ],
            [
              "Боловсруулах хурд",
              data?.generalIntelligence?.processingSpeed
                .toString()
                .substring(0, 4),
            ],
            [
              "Хэв таних",
              data?.competencies?.patternRecognition.toString().substring(0, 4),
            ],
            [
              "Нухацтай сэтгэлгээ",
              data?.competencies?.criticalThinking.toString().substring(0, 4),
            ],
            [
              "Шийдвэр гаргах чадвар",
              data?.competencies?.decisionMaking.toString().substring(0, 4),
            ],
            [
              "Төлөвлөх чадвар",
              data?.competencies?.planning.toString().substring(0, 4),
            ],
            [
              "Суралцах хурд",
              data?.competencies?.learningRate.toString().substring(0, 4),
            ],
            [
              "Сониуч зан",
              data?.openness?.curiosity.toString().substring(0, 4),
            ],
            [
              "Эрсдэл хүлээх чадвар",
              data?.openness?.riskTaking.toString().substring(0, 4),
            ],
            [
              "Дасан зохицох чадвар",
              data?.openness?.adaptability.toString().substring(0, 4),
            ],
            [
              "Тууштай байдал",
              data?.conscientiousness?.consistence.toString().substring(0, 4),
            ],
            [
              "Зорилго төлөвлөлт",
              data?.conscientiousness?.goalSetting.toString().substring(0, 4),
            ],
            [
              "Багаар ажиллах чадвар",
              data?.agreeable?.cooperative.toString().substring(0, 4),
            ],
            [
              "Бусдад итгэх хандлага",
              data?.agreeable?.trusting.toString().substring(0, 4),
            ],
            [
              "Өөртөө итгэлтэй, шийдэмгий байдал",
              data?.agreeable?.assertiveness.toString().substring(0, 4),
            ],
          ],

          datasets: [
            {
              label: "Оюун ухааны үзүүлэлтүүд",
              data: [
                data?.generalIntelligence?.inductiveReasoning,
                data?.generalIntelligence?.deductiveReasoning,
                data?.generalIntelligence?.quantitativeReasoing,
                data?.generalIntelligence?.attention,
                data?.generalIntelligence?.workingMemory,
                data?.generalIntelligence?.processingSpeed,
                data?.competencies?.patternRecognition,
              ],
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1,
            },
            {
              label: "Ур чадварууд",
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                data?.competencies?.patternRecognition,
                data?.competencies?.criticalThinking,
                data?.competencies?.decisionMaking,
                data?.competencies?.planning,
                data?.competencies?.learningRate,
                data?.openness?.curiosity,
              ],
              backgroundColor: "rgba(255,204,0,0.2)",
              borderColor: "rgba(255,204,0,1)",
              borderWidth: 1,
            },
            {
              label: "Өөрчлөлтөнд бэлэн байдал",
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                data?.openness?.curiosity,
                data?.openness?.riskTaking,
                data?.openness?.adaptability,
              ],
              backgroundColor: "rgba(165,147,179,0.2)",
              borderColor: "rgba(165,147,179,1)",
              borderWidth: 1,
            },
            {
              label: "Хариуцлагтай байдал",
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                data?.openness?.adaptability,
                data?.conscientiousness?.consistence,
                data?.conscientiousness?.goalSetting,
              ],
              backgroundColor: "rgba(157,186,137,0.2)",
              borderColor: "rgba(157,186,137,1)",
              borderWidth: 1,
            },
            {
              label: "Нийцэрхэг байдал",
              data: [
                data?.generalIntelligence?.inductiveReasoning,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                data?.conscientiousness?.goalSetting,
                data?.agreeable?.cooperative,
                data?.agreeable?.trusting,
                data?.agreeable?.assertiveness,
              ],
              backgroundColor: "rgba(136,158,184,0.2)",
              borderColor: "rgba(136,158,184,1)",
              borderWidth: 1,
            },
            // {
            //   labels: [
            //     "Ерөнхийлөн дүгнэх чадвар",
            //     "Нарийвчлан дүгнэх чадвар",
            //     "Тоон сэтгэлгээ",
            //     "Анхаарал",
            //     "Ажлын санах ой",
            //     "Боловсруулах хурд",
            //     "Хэв таних",
            //     "Нухацтай сэтгэлгээ",
            //     "Шийдвэр гаргах чадвар",
            //     "Төлөвлөх чадвар",
            //     "Суралцах хурд",
            //     "Сониуч зан",
            //     "Эрсдэл хүлээх чадвар",
            //     "Дасан зохицох чадвар",
            //     "Тууштай байдал",
            //     "Зорилго төлөвлөлт",
            //     "Багаар ажиллах чадвар",
            //     "Бусдад итгэх хандлага ",
            //     "Өөртөө итгэлтэй, шийдэмгий байдал",
            //   ],
            //   data: [
            //     50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            //     50, 50, 50,
            //   ],
            //   backgroundColor: "rgba(136,158,184,0.2)",
            //   borderColor: "rgba(136,158,184,1)",
            //   borderWidth: 1,
            // },
          ],
        });
        setShowModal(false);
      })
      .catch((err) => {
        toast.error(t("toast.playerNotFound"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setShowModal(false);
        // navigate("/notfound");
      });
  }, []);

  return (
    <div>
      <Modal
        open={showModal}
        onClose={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <div className="flex  justify-center content-center	">
            <div className="">
              <CircularProgress className="ml-11" />
              <p className="text-center text-lg">Та түр хүлээнэ үү ...</p>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
      <div className="w-full flex justify-end">
        <Button onClick={iv2PDF} endIcon={<DownloadIcon />}>
          Download PDF
        </Button>
      </div>

      <div className="ml-auto mr-auto table w-[720px] bg-white mt-7 mb-7 div2PDFrep">
        {/* <div className="p-4 bg-[#FFB596]">
          <div className="font-bold text-white text-4xl pl-11 pb-4 pt-3">
            Бие хүний онцлог тайлан
          </div>
          <div className="  text-white text-xl pl-11 pr-11 ">
            <div>{` Хэнд: ${playerData?.assPlayer?.player?.name} `}</div>
            <div className="flex justify-between">
              <div>{`    ${playerData?.assPlayer?.player?.email}`}</div>
              <div>
                {`Огноо: ${playerData?.assPlayer?.lastModifiedDate?.substring(
                  0,
                  10
                )}`}
              </div>
            </div>
          </div>
        </div> */}
        {reportHeader()}
        <Radar data={radarData} />
        {console.log("playerData?.top3Skills", playerData)}
        {playerData !== null &&
        playerData.top3Skills !== null &&
        playerData.top3Skills !== undefined &&
        playerData.top3Skills.length > 0 &&
        playerData.bottom3Skills !== null &&
        playerData.bottom3Skills !== undefined &&
        playerData.bottom3Skills.length > 0 ? (
          <div className="pt-[50px] pl-[30px] pr-[40px]">
            <div className="text-[24px] font-bold pb-4">
              Хувь хүний онцлог талууд
            </div>
            <div className="pt-7">
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="right">Давуу тал</StyledTableCell>
                      <StyledTableCell align="left">Сул тал</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {playerData.top3Skills.map((row, index) => (
                      <StyledTableRow key={`${row.name}_attRows`}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="right"
                        >
                          <p className="font-bold pb-2">{row.mnName}</p>
                          <div className="text-[12px]">{row.description}</div>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <p className="font-bold pb-2">
                            {playerData?.bottom3Skills[index].mnName}
                          </p>
                          <div className="text-[12px]">
                            {playerData?.bottom3Skills[index].description}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <hr className="mt-11 mb-[31px]"></hr>
          </div>
        ) : null}
        <div className="p-4 bg-main-blue flex justify-end ">
          <div className=" text-white text-end italic ">
            {playerData !== null &&
            playerData.assPlayer !== null &&
            playerData.assPlayer.player !== null &&
            playerData.assPlayer.player.createdBy !== null &&
            playerData.assPlayer.player.createdBy === "pinecone" ? (
              playerData.assPlayer.mpi < 38 ? (
                <div>
                  - Энэхүү тайланд сайн муу гэсэн тал байхгүй бөгөөд хувь хүний
                  танин мэдэхүйн ур чадвар, зан төлөвийн шинж чанаруудыг
                  илрүүлэх зорилгоор ашигладаг аргачлал юм. Уламжлалт тестүүд
                  шиг зөвхөн үр дүнг чухалчлах бус, дээрх үр дүнд хүрсэн зам
                  буюу хувь хүн бүрийн сэтгэлгээний онцлогийн харуулж байгаа юм.
                  Дээрх үзүүлэлтийн ерөнхий байдлаас харахад тухайн хүн нь
                  технологийн чиглэл буюу мэдээллийн технологи, программ
                  хангамжийн салбарыг оролдож үзээд дараа дараагийн шийдвэрээ
                  гаргах хэрэгтэй гэж харагдаж байна. Дээрх үр дүнд үндслэн
                  эрхэм таныг манай Pinecone Academy-ийн Software Engineer
                  бэлтгэх 1 жилийн хөтөлбөрт 5% ийн хөнгөлөлттэй үнээр
                  суралцахыг урьж байна. /Жич: Энэхүү үр дүн нь тухайн хүнийг
                  аль салбарт илүү амжилт гаргах магадлалыг гаргадаг бөгөөд
                  тухайн хүний хувь хүний хүсэл зориг, хичээл зүтгэл тухайн
                  салбарт амжилт гаргах эсэхэд нь мөн нөлөөлдөг гэдгийг анхаарна
                  уу./
                </div>
              ) : playerData.assPlayer.mpi > 38 &&
                playerData.assPlayer.mpi < 48 ? (
                <div>
                  - Энэхүү тайланд сайн муу гэсэн тал байхгүй бөгөөд хувь хүний
                  танин мэдэхүйн ур чадвар, зан төлөвийн шинж чанаруудыг
                  илрүүлэх зорилгоор ашигладаг аргачлал юм. Уламжлалт тестүүд
                  шиг зөвхөн үр дүнг чухалчлах бус, дээрх үр дүнд хүрсэн зам
                  буюу хувь хүн бүрийн сэтгэлгээний онцлогийн харуулж байгаа юм.
                  Дээрх үзүүлэлтийн ерөнхий байдлаас харахад тухайн хүн нь
                  технологийн чиглэл буюу мэдээллийн технологи, программ
                  хангамжийн салбарыг сонгож <b>СОНИРХОХЫГ</b> зөвлөж байна.
                  Дээрх үр дүнд үндслэн эрхэм таныг манай Pinecone Academy-ийн
                  Software Engineer бэлтгэх 1 жилийн хөтөлбөрт 5% ийн
                  хөнгөлөлттэй үнээр суралцахыг урьж байна. /Жич: Энэхүү үр дүн
                  нь тухайн хүнийг аль салбарт илүү амжилт гаргах магадлалыг
                  гаргадаг бөгөөд тухайн хүний хувь хүний хүсэл зориг, хичээл
                  зүтгэл тухайн салбарт амжилт гаргах эсэхэд нь мөн нөлөөлдөг
                  гэдгийг анхаарна уу./
                </div>
              ) : playerData.assPlayer.mpi > 48 ? (
                <div>
                  - Энэхүү тайланд сайн муу гэсэн тал байхгүй бөгөөд хувь хүний
                  танин мэдэхүйн ур чадвар, зан төлөвийн шинж чанаруудыг
                  илрүүлэх зорилгоор ашигладаг аргачлал юм. Уламжлалт тестүүд
                  шиг зөвхөн үр дүнг чухалчлах бус, дээрх үр дүнд хүрсэн зам
                  буюу хувь хүн бүрийн сэтгэлгээний онцлогийн харуулж байгаа юм.
                  Дээрх үзүүлэлтийн ерөнхий байдлаас харахад тухайн хүн нь
                  технологийгн чиглэл тэр дундаа мэдээллийн технологи, программ
                  хангамжийн салбарт <b>ИРЭЭДҮЙТЭЙ</b> гэж харагдаж байна. Дээрх
                  үр дүнд үндслэн эрхэм таныг манай Pinecone Academy-ийн
                  Software Engineer бэлтгэх 1 жилийн хөтөлбөрт 5% ийн
                  хөнгөлөлттэй үнээр суралцахыг урьж байна. /Жич: Энэхүү үр дүн
                  нь тухайн хүнийг аль салбарт илүү амжилт гаргах магадлалыг
                  гаргадаг бөгөөд тухайн хүний хувь хүний хүсэл зориг, хичээл
                  зүтгэл тухайн салбарт амжилт гаргах эсэхэд нь мөн нөлөөлдөг
                  гэдгийг анхаарна уу./
                </div>
              ) : null
            ) : (
              "*Энэхүү тайлан нь сайн, муу гэсэн тал байхгүй ба “Хоёр туйлт график”-аар тухайн хүний зан төлөв, онцлог шинж чанаруудыг илтгэж байгаа ба зарлагдсан ажлын байранд хэр нийцтэй эсэхэд тулгуур мэдээлэл болж өгөх юм."
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentReportOpen;
