import React, { useState } from 'react';
import { IoEllipsisVertical } from "react-icons/io5";
import { Menu, MenuItem, ListItemIcon, Typography } from "@mui/material";

const AgCellMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuItems = props.menuItems || [];

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (action) => {
        if (props.menuActions && props.menuActions[action]) {
            props.menuActions[action](props.data);
        }
        handleClose();
    };

    return (
        <div>
            <button onClick={handleOpenMenu} className="focus:outline-none">
                <IoEllipsisVertical />
            </button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuItems.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => handleMenuClick(item.action)}
                        className="text-gray-700 hover:bg-gray-200"
                    >
                        {
                            item.icon
                                ?
                                <ListItemIcon className="min-w-max mr-3">
                                    {item.icon}
                                </ListItemIcon> :
                                null
                        }
                        <Typography variant="inherit">{item.label}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default AgCellMenu;
