import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getDepartments } from "../../services/employee.service";
import { Bar } from "react-chartjs-2";
import { findDepartmentSkillScores } from "../../services/department.service";
import { useTranslation } from "react-i18next";

const SkillDashboard = () => {
  const { t } = useTranslation();
  let data = [
    {
      label: t("NewAssessment.inductiveName"),
      value: 0,
    },
    {
      label: t("NewAssessment.deductiveName"),
      value: 0,
    },
    {
      label: t("NewAssessment.quantitativeName"),
      value: 0,
    },
    {
      label: t("NewAssessment.attentionName"),
      value: 0,
    },
    {
      label: t("NewAssessment.workingMemoryName"),
      value: 0,
    },
    {
      label: t("NewAssessment.processingSpeedName"),
      value: 0,
    },
    {
      label: t("NewAssessment.patternRecognitionName"),
      value: 0,
    },
    {
      label: t("NewAssessment.criticalThinkingName"),
      value: 0,
    },
    {
      label: t("NewAssessment.decisionMakingName"),
      value: 0,
    },
    {
      label: t("NewAssessment.planningName"),
      value: 0,
    },
    {
      label: t("NewAssessment.learningSpeedName"),
      value: 0,
    },
    {
      label: t("NewAssessment.curiosityName"),
      value: 0,
    },
    {
      label: t("NewAssessment.riskTakingName"),
      value: 0,
    },
    {
      label: t("NewAssessment.adaptability"),
      value: 0,
    },
    {
      label: t("NewAssessment.consistence"),
      value: 0,
    },
    {
      label: t("NewAssessment.goalSetting"),
      value: 0,
    },
    {
      label: t("NewAssessment.cooperative"),
      value: 0,
    },
    {
      label: t("NewAssessment.trusting"),
      value: 0,
    },
    {
      label: t("NewAssessment.assertiveness"),
      value: 0,
    },
  ];
  const skillKeyMapping = {
    [t("NewAssessment.adaptability")]: "adaptability",
    [t("NewAssessment.assertiveness")]: "assertiveness",
    [t("NewAssessment.attentionName")]: "attention",
    [t("NewAssessment.consistence")]: "consistence",
    [t("NewAssessment.cooperative")]: "cooperative",
    [t("NewAssessment.criticalThinkingName")]: "criticalThinking",
    [t("NewAssessment.curiosityName")]: "curiosity",
    [t("NewAssessment.decisionMakingName")]: "decistionMaking",
    [t("NewAssessment.deductiveName")]: "deductionReasoning",
    [t("NewAssessment.goalSetting")]: "goalSetting",
    [t("NewAssessment.inductiveName")]: "inductiveReasoning",
    [t("NewAssessment.learningSpeedName")]: "learningRate",
    [t("NewAssessment.patternRecognitionName")]: "patternRecognition",
    [t("NewAssessment.planningName")]: "planning",
    [t("NewAssessment.processingSpeedName")]: "processingSpeed",
    [t("NewAssessment.quantitativeName")]: "quant",
    [t("NewAssessment.riskTakingName")]: "riskTaking",
    [t("NewAssessment.trusting")]: "trusting",
    [t("NewAssessment.workingMemoryName")]: "workingMemory",
  };

  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const [scores, setScores] = useState([]);
  const [orderedEmployees, setOrderedEmployees] = useState([]);

  useEffect(() => {
    fetchDepartments();
  }, []);

  useEffect(() => {
    if (selectedSkill) {
      sortEmployeesBySkill(selectedSkill);
    }
  }, [selectedSkill, scores]);

  const fetchDepartments = async () => {
    try {
      const departmentsData = await getDepartments();
      setDepartments(departmentsData.data);
    } catch (err) {
      console.log(err);
    }
  };

  const sortEmployeesBySkill = (skill) => {
    if (scores.length > 0 && skill) {
      const sortedEmployees = scores.sort((a, b) => b[skill] - a[skill]);
      setOrderedEmployees([...sortedEmployees]);
    } else {
      setOrderedEmployees([]);
    }
  };
  const getDepartmentScore = async (departmentId) => {
    setSelectedDepartment(departmentId);
    try {
      const res = await findDepartmentSkillScores({ departmentId });
      const departmentScores = res.data;
      setScores(departmentScores);
      sortEmployeesBySkill(selectedSkill);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSkillSelection = (newValue) => {
    const selectedSkillKey = skillKeyMapping[newValue];
    setSelectedSkill(selectedSkillKey);
  };

  const chartData = () => {
    const labels = orderedEmployees.map((employee) => employee.firstName);
    const dataValues = orderedEmployees.map(
      (employee) => employee[selectedSkill]
    );

    return {
      labels,
      datasets: [
        {
          label: selectedSkill,
          data: dataValues,
          backgroundColor: "rgba(75,192,192,1)", // Set your preferred color
          borderColor: "rgba(0,0,0,1)", // Set your preferred border color
        },
      ],
    };
  };

  return (
    <>
      <div className="mt-5">
        <div className="mb-6 w-1/4">
          <label
            htmlFor="department"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            {t("Dashboard.department")}
          </label>
          <div className="relative">
            <select
              id="department"
              className="w-[300px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
              required
              value={selectedDepartment}
              onChange={(e) => {
                getDepartmentScore(e.target.value);
              }}
            >
              <option value="" disabled hidden>
                {t("Dashboard.choose")}
              </option>
              {departments.map((data) => (
                <option key={data.id} value={data.id}>
                  {`${data.name} `}
                </option>
              ))}
            </select>
          </div>
        </div>

        <Autocomplete
          options={data.map((item) => item.label)}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Dashboard.selectSkill")}
              variant="outlined"
            />
          )}
          onChange={(event, newValue) => {
            handleSkillSelection(newValue);
          }}
        />

        <div className="mt-5">
          {selectedSkill && (
            <div>
              <h2>{t("Dashboard.skillDistrubution")}</h2>
              <div>
                <Bar
                  data={chartData()}
                  options={{
                    indexAxis: "y",
                    scales: {
                      x: {
                        beginAtZero: true,
                        display: true,
                      },
                      y: {
                        categoryPercentage: 0.4,
                        stacked: true,
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SkillDashboard;
