import React from "react";
import MainTable from "../../../components/table/MainTable";
// import AgCellMenu from "../../../components/menu/AgCellMenu";

import { useNavigate } from "react-router-dom";

const OnBoardingEmp = ({ emp }) => {
  const clickOnCell = (e) => {
    window.open(
      `/company/employee/${e.data.action.employeeId}/onboard`,
      "_blank"
    );
  };

  return (
    <div className="w-full mb-2 mt-6">
      <MainTable
        columnDefs={[
          {
            headerName: "Employee Name",
            field: "fullName",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
          },
          {
            headerName: "Total Questions",
            field: "questionCount",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
          },
          {
            headerName: "Questions Answered",
            field: "progress",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
          },
          {
            headerName: "Completion Percentage",
            field: "percentage",
            sortable: true,
            flex: 2,
            onCellClicked: clickOnCell,
          },
          {
            headerName: "Onboarding Program",
            field: "onboardName",
            sortable: true,
            flex: 2,
            onCellClicked: clickOnCell,
          },

          // {
          //   headerName: "",
          //   field: "action",
          //   sortable: false,
          //   cellRenderer: AgCellMenu,
          //   flex: 1,
          //   cellRendererParams: (params) => {
          //     let menuItems = [
          //       {
          //         label: (
          //           <svg
          //             width="10"
          //             height="12"
          //             viewBox="0 0 10 12"
          //             fill="none"
          //             xmlns="http://www.w3.org/2000/svg"
          //           >
          //             <path
          //               d="M7.66732 4V10.6667H2.33398V4H7.66732ZM6.66732 0H3.33398L2.66732 0.666667H0.333984V2H9.66732V0.666667H7.33398L6.66732 0ZM9.00065 2.66667H1.00065V10.6667C1.00065 11.4 1.60065 12 2.33398 12H7.66732C8.40065 12 9.00065 11.4 9.00065 10.6667V2.66667Z"
          //               fill="#636466"
          //             />
          //           </svg>
          //         ),
          //         action: "handleDelete",
          //       },
          //       {
          //         label: <MdOutlineEdit className="text-[#636466]" />,
          //         action: "handleEdit",
          //       },
          //     ];

          //     return {
          //       menuItems: menuItems,
          //       menuActions: {
          //         handleDelete: () => {
          //           handleDelete(params.data.action.id, params.data.text);
          //         },
          //         handleEdit: () => {
          //           navigate(
          //             `/company/edit/survey/${params.data.action.id}`
          //           );
          //         },
          //       },
          //     };
          //   },
          // },
        ]}
        rowData={[...emp].reverse().map((elm) => {
          const totalQuestions = elm.totalQuestions || 1;
          const checkedQuestions = elm.checkedQuestions || 0;
          const percentage = (checkedQuestions / totalQuestions) * 100;

          return {
            onboardId: elm.id,
            id: elm.employeeId,
            fullName: `${elm.firstName} ${elm.lastName}`,
            questionCount: totalQuestions,
            percentage: percentage.toFixed(0),
            onboardName: elm.onboardName,
            progress: checkedQuestions,
            action: elm,
          };
        })}
      />
    </div>
  );
};

export default OnBoardingEmp;
