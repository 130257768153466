import ReactApexChart from "react-apexcharts";
import { Trans, useTranslation } from "react-i18next";
import "./../../index.scss";

const ApexChart = ({ expectedLevel, level, height }) => {
  const { t, i18n } = useTranslation();

  const state = {
    series: [
      {
        name: i18n.language === "rn" ? "Уровень" : 'Түвшин',
        data: [
          {
            x: '',
            y: level,
            goals: [
              {
                name: i18n.language === "rn" ? "Требуемый" : 'Шаардагдсан түвшин',
                value: expectedLevel,
                strokeHeight: 5,
                strokeColor: '#334155'
              }
            ]
          },

        ]
      }
    ],

    options: {
      yaxis: {
        tickAmount: 5,
        min: 0,
        max: 5,
        // title: {
        //   text: 'Value'
        // }
      },
      toolbar: {
        show: false // Hide the entire toolbar
      },
      chart: {
        height: height ? height : 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          columnWidth: '30%'
        }
      },
      colors: ['#F48D7E'],
      dataLabels: {
        enabled: false
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: i18n.language === "rn" ? ['Уровень', 'Требуемый'] : ['Түвшин', 'Шаардагдсан түвшин'],
        markers: {
          fillColors: ['#F48D7E', '#334155'],
        }
      }
    },
  };
  return (

    <div className="chart-container hide-download-icon">
      <ReactApexChart options={state.options} series={state.series} type="bar" height={height ? height : 350} />
    </div>


  );
}

export default ApexChart;