import React from 'react';

const RegistrationSuccess = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-green-600">Амжтилттай бүртгүүллээ!</h2>
        <p className="mt-4 text-gray-700">Та бүртгүүлсэн Email хаягаа шалгана уу.</p>
      </div>
    </div>
  );
}

export default RegistrationSuccess;
