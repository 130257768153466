import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { assessmentPlayerByLink } from "../../services/assessment.service";
import { createPlayer } from "../../services/user.service";

const TestForm = () => {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState(null);
  const [age, setAge] = useState(null);
  const [assPlayerId, setAssPlayerId] = useState(null);
  const [email, setEmail] = useState(null);
  const [assessmentId, setAssessmentId] = useState(null);
  const [gender, setGender] = useState(null);
  const [workWith, setWorkWith] = useState(null);
  const [major, setMajor] = useState(null);
  const [majorLevel, setMajorLevel] = useState(null);
  const [majorExperienceYear, setMajorExperienceYear] = useState(null);
  const [district, setDistrict] = useState(null);
  const [userId, setUserId] = useState(null);
  const { uniqId } = useParams();
  const [company, setCompany] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [term, setTerm] = useState(false);
  const [regNumber, setRegNumber] = useState("");
  const [photoUrl, setphotoUrl] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const navigate = useNavigate();

  const startGame = () => {
    if (
      name == null ||
      age == null ||
      // email == null ||
      gender == null ||
      workWith == null ||
      major == null ||
      majorLevel == null ||
      majorExperienceYear == null ||
      name === "" ||
      email === ""
    ) {
      toast.error(t("toast.fillAllTheField"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      if (companyName !== "mcsacademy" && i18n.language === "mn") {
        if (regNumber === "" && regNumber.length !== 10) {
          toast.error("Та Регистрийн дугаараа бүрэн бөглөнө үү !, Баярлалаа", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return;
        }
      }

      createPlayer({
        name: name,
        email: email,
        assessmentId: assessmentId ? assessmentId : 1,
        assPlayerId: assPlayerId ? assPlayerId : null,
        district: district,
        age: age,
        gender: gender,
        workWith: workWith,
        major: major,
        majorLevel: majorLevel,
        majorExperienceYear: majorExperienceYear,
        company: company,
        regNumber: regNumber,
      })
        .then((res) => {
          localStorage.setItem("refresh", "false");
          if (companyId !== null && companyId === 197) {
            navigate(`/apugame/${res.data.id}`, {
              state: {
                userId: `${res.data.id}`,
                universty: company,
              },
            });
          } else {
            navigate(`/game/${res.data.id}`, {
              state: {
                userId: `${res.data.id}`,
                universty: company,
              },
            });
          }
        })
        .catch((e) => {
          toast.error(t("toast.Error"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  useEffect(function () {
    // const isPhone = window.innerWidth <= 1200; // Example threshold for phone width

    // if (isPhone) {
    //   console.log("User is accessing from a phone.");
    //   navigate("/use-pc");
    //   return;
    // }

    localStorage.setItem("refresh", "false");

    if (uniqId === "test") {
    }
    // else if (uniqId === "invescore") {
    //   setCompany("INVESCORE");
    // } else if (uniqId === "gtm") {
    //   setCompany("GTM");
    // } else if (uniqId === "mcs") {
    //   setCompany("MCS");
    // } else if (uniqId === "oyu-tolgoi") {
    //   setCompany("OYUTOLGOI");
    // }
    else {
      assessmentPlayerByLink({ link: uniqId })
        .then((val) => {
          const { data } = val;
          if (data.language === 1) {
            i18n.changeLanguage("rn");
            setTerm(true);
          } else if (data.language === 2) {
            setTerm(true);
            i18n.changeLanguage("en");
          } else {
            i18n.changeLanguage("mn");
          }

          if (data.apuPlayedGame !== 0) {
            if (data.apuPlayedGame === 4) {
              navigate(`/congratz`);
            } else {
              navigate(`/apugame/${data.id}`, {
                state: {
                  userId: `${data.id}`,
                  universty: data?.player?.company,
                  playedGame: data.apuPlayedGame,
                },
              });
            }
          }
          if (data.playedGame !== 0) {
            if (data.playedGame === 8) {
              navigate(`/congratz`);
            } else {
              navigate(`/game/${data.id}`, {
                state: {
                  userId: `${data.id}`,
                  universty: data?.player?.company,
                  playedGame: data.playedGame,
                },
              });
            }
          }
          setName(data?.player?.name);
          setEmail(data?.player?.email);
          setAssPlayerId(data?.id);
          setAssessmentId(data?.assessmentId);
          setphotoUrl(data?.assessment?.company?.photoUrl);
          setCompanyName(data?.assessment?.createdBy);
          setCompanyId(data?.companyId);
        })
        .catch((err) => {
          console.log("Erro = ", err);
          if (err.response.status === 403) {
            console.log("Error 401 = ", err?.response?.data.message);
            navigate("/not-in-time", {
              state: {
                assessmentId: err?.response?.data.message,
              },
            });
          } else {
            navigate("/notfound");
          }
        });
    }
  }, []);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (showModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [showModal]);

  return userId == null ? (
    <div className="px-8 pt-8 pb-8 min-h-screen bg-[url('/public/assets/gameBackground.png')]">
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Үйлчилгээний нөхцөл</DialogTitle>
        <DialogContent dividers={"paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <p className="font-semibold">1. Ерөнхий зүйл </p>{" "}
            <p>
              Энэхүү үйлчилгээний нөхцөлийг “Метаког Метрикс” ХХК (“
              <b>Метаког Метрикс”</b>
              )-ийн Metacog Metrics вэбсайтыг ашигалж буй этгээд дагаж мөрдөнө.{" "}
              <br></br>
              <br></br>
              Хэрэглэгч энэхүү үйлчилгээний нөхцөлтэй бүрэн танилцаж, хүлээн
              зөвшөөрсөн тохиолдолд вебсайт дахь “Зөвшөөрч байна” гэсэн
              сонголтыг сонгоно. Ийнхүү “Зөвшөөрч байна” сонголтыг сонгосноор
              энэхүү үйчилгээний нөхцөл хүчин төгөлдөр хэрэгжинэ. Метаког
              Метрикс нь Хэрэглэгчид урьдчилан мэдэгдэл, анхааруулга
              илгээхгүйгээр энэхүү үйлчилгээний нөхцөлд дангаар өөрчлөлт оруулах
              эрхтэй бөгөөд Хэрэглэгч вебсайтыг үргэлжлүүлэн ашигласнаар тухайн
              өөрчлөлтийг хүлээн зөвшөөрсөнд тооцно.
            </p>
            <br></br>
            <p className="font-semibold">2. Метаког Метриксийн үйлчилгээ</p>
            <p>
              Вебсайтын тодорхой үйлчилгээ, платформд хандах, ашиглахын тулд та
              бидэнтэй нэг бол гэрээ байгуулан сайт дээр хэрэглэгчийн бүртгэл ("
              <b>Бүртгэл</b>") үүсгэж бүртгүүлэх шаардлагатай эсвэл бидний
              урилгаар нэг удаагийн зөвхөн танд зориулагдсан холбоосоор хандах
              боломжтой.<br></br>
              <br></br> Симуляци эхлүүлэхийн өмнө нэр, цахим шуудангийн хаяг,
              боловсролын түвшин, ажиллаж буй салбар зэрэг хувийн болон
              мэргэжлийн зарим мэдээллийг оруулах буюу баталгаажуулахыг танаас
              шаардах болно. <br></br>
              <br></br> Хэрэглэгч нь үйлчилгээтэй холбоотойгоор дараахыг ойлгож
              хүлээн зөвшөөрч байгаа болно. Үүнд:
              <p className="pl-4">
                {" "}
                • Вебсайтын бүртгэлд үнэн зөв, бодит, хамгийн сүүлийн үеийн,
                бүрэн мэдээллийг өгнө;
              </p>{" "}
              <p className="pl-4">
                {" "}
                • Вебсайтын бүртгэл, ашиглах эрхээ гуравдагч этгээдэд
                шилжүүлэхгүй, дахин худалдахгүй байх;
              </p>{" "}
              <p className="pl-4">
                {" "}
                • Нэгээс олон бүртгэл үүсгэхгүй байх;
              </p>{" "}
              <p className="pl-4">
                {" "}
                • Тоглоомыг нухацтайгаар хүлээн авч, тоглоомыг анхааралтай
                тоглох;
              </p>{" "}
              <p className="pl-4">
                {" "}
                • Тоглоом бүрийг эхлүүлэхийн өмнө заавартай анхааралтай
                танилцах;
              </p>{" "}
              <p className="pl-4">
                {" "}
                • Тоглоомыг тоглож эхэлсний дараа зогсоох, эсхүл зориудаар
                дуусгахгүй байх;
              </p>{" "}
              <p className="pl-4">
                {" "}
                • 12 сарын хугацаанд нэгээс олон удаа Тоглоомыг тоглохгүй байх;
              </p>{" "}
              <p className="pl-4">
                {" "}
                • Тоглоом дууссанаас хойш 12 сарын хугацаанд тоглоомын явцын
                мэдээлэл эсхүл өөрийн бүртгэлийг устгахгүй байх.
              </p>{" "}
            </p>
            <br></br>
            <p className="font-semibold">3. Метаког Метриксийн тоглоомууд</p>
            <p>
              Шаардлагатай мэдээллийг оруулан бүртгүүлсний дараа та сайт дээр
              сэтгэлзүй болон тархи судлалын шинжлэх ухаанд суурилсан онлайн
              тоглоомуудыг ("<b>Тоглоом</b>") тоглох боломжтой болно.<br></br>
              <br></br>
              Та тоглоом бүрийг тоглох үед Метаког Метрикс нь таны Тоглоомын
              явцын мэдээлэл болон Тоглоомын өгөгдлийг цуглуулж, хадгалах
              эрхтэй. <br></br>
              <br></br>"<b>Тоглоомын өгөгдөл </b>" гэдэг нь тоглоом тоглох явцад
              таны зан байдал, үйлдлээс цуглуулсан өгөгдөл, таны хэрэглэгчийн
              тоглоомтой бодит цагийн харилцан үйлчлэлийн талаарх мета өгөгдөл
              багтана.
            </p>
            <br></br>
            <p className="font-semibold">4. Мэдээлэл хамгаалах, дамжуулах</p>
            <p>
              Хэрэглэгч нь вебсайтад оруулсан өөрийн мэдээллийн үнэн зөвийг
              хариуцна. <br></br>
              <br></br>Метаког Метрикс нь Монгол Улсын Хүний хувийн мэдээлэл
              хамгаалах тухай хууль болон бусад холбогдох хууль тогтоомжид
              заасны дагуу Хэрэглэгчийн мэдээллийг хамгаална.<br></br>
              <br></br> Метаког Метрикс нь Хэрэглэгчийн овог, нэр, и-мэйл хаяг,
              регистрийн дугаар болон Тоглоом тоглох явцад Хэрэглэгчийн үйлдлээс
              цугларсан өгөгдөл, үр дүн болон бусад шаардлагатай мэдээллийг
              хүний нөөцийн зорилгоор ажил олгогчийн эрх бүхий этгээдэд
              дамжуулах эрхтэй. Ийнхүү Хэрэглэгчийн мэдээллийн дамжуулах
              тохиолдолд Монгол Улсын Хүний хувийн мэдээлэл хамгаалах тухай
              хуулийн дагуу мэдээллийн эзний зөвшөөрлийн вебсайтаар дамжуулан
              авна.
            </p>
            <br></br>
            <p className="font-bold">5. Бидэнтэй холбогдох</p>
            Хэрэв танд сайттай холбоотой асуулт, гомдол байвал бидэнтэй
            hello@metacog.mn цахим хаягаар холбогдоно уу.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
          >
            Гарах
          </Button>
          <Button
            onClick={() => {
              setTerm(true);
              setShowModal(false);
            }}
          >
            Зөвшөөрөх
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <FormControl className="w-full flex justify-center">
        <div className="flex justify-center">
          <div className="p-10 bg-white rounded-xl">
            <div className="flex justify-center ">
              <img
                className="pb-4 bg-transparent	"
                src="/assets/longLogo.png"
                width={180}
                alt="Italian Trulli"
              />
              {photoUrl ? (
                <img
                  className="pb-4 bg-transparent	"
                  src={photoUrl}
                  // src="/assets/academy.png"
                  // height={80}
                  width={150}
                  alt="Logo"
                />
              ) : null}
            </div>
            <div className="flex justify-center w-96 text-xs	bg-slate-200">
              <Box component="span" sx={{ p: 2, border: "1px dashed grey" }}>
                <Trans
                  i18nKey="Form.aboutMetacog"
                  components={{ 1: <br />, 2: <span /> }}
                ></Trans>
              </Box>
            </div>
            <p className="flex justify-center text-sm font-semibold">
              {t("Form.goodLuck")}.
            </p>
            <div>
              <TextField
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className="col-span-3"
                margin="normal"
                value={name != null ? name : ""}
                required
                fullWidth
                label={`${t("Form.name")}`}
                autoFocus
              />
            </div>
            <div>
              <TextField
                id="outlined-uncontrolled"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="col-span-3"
                value={email != null ? email : ""}
                margin="normal"
                required
                fullWidth
                label={`${t("Form.sendEmail")}`}
              />
            </div>
            <div>
              <TextField
                onChange={(e) => setAge(e.target.value)}
                select // tell TextField to render select
                fullWidth
                margin="normal"
                label={t("Form.age")}
                required
              >
                <MenuItem key={"15-19"} value={"15-19 "}>
                  15-19
                </MenuItem>
                <MenuItem key={"20-24"} value={"20-24"}>
                  20-24
                </MenuItem>
                <MenuItem key={"25-29"} value={"25-29"}>
                  25-29
                </MenuItem>
                <MenuItem key={"30-34"} value={"30-34"}>
                  30-34
                </MenuItem>
                <MenuItem key={"35-39"} value={"35-39"}>
                  35-39
                </MenuItem>
                <MenuItem key={"40+"} value={"40+"}>
                  40+
                </MenuItem>
              </TextField>
            </div>
            <div>
              <TextField
                onChange={(e) => setGender(e.target.value)}
                select // tell TextField to render select
                className={
                  company !== "INVESCORE" ? "col-span-3" : "col-span-4"
                }
                margin="normal"
                label={t("Form.gender")}
                required
                fullWidth
              >
                <MenuItem key={"gender"} value={"MALE"}>
                  {t("Form.men")}
                </MenuItem>
                <MenuItem key={"Wgender"} value={"WOMEN"}>
                  {t("Form.women")}
                </MenuItem>
                <MenuItem key={"Ogender"} value={"OTHER"}>
                  {t("Form.other")}
                </MenuItem>
              </TextField>
            </div>
            {companyName === "mcsacademy" || i18n.language !== "mn" ? null : (
              <div>
                <TextField
                  onChange={(e) => {
                    setRegNumber(e.target.value);
                  }}
                  className="col-span-3"
                  margin="normal"
                  required
                  fullWidth
                  label="Регистрийн дугаар"
                />
              </div>
            )}

            <div>
              <TextField
                fullWidth
                onChange={(e) => {
                  setWorkWith(e.target.value);
                }}
                className="col-span-8"
                margin="normal"
                required
                select
                label={`${t("Form.profession")}`}
              >
                <MenuItem key={"h15"} value={"Захиргаа, хүний нөөц"}>
                  {t("Form.administration")}
                </MenuItem>
                <MenuItem key={"h1"} value={"Инженер"}>
                  {t("Form.engineer")}
                </MenuItem>
                <MenuItem key={"h2"} value={"IT "}>
                  {t("Form.it")}
                </MenuItem>
                <MenuItem key={"h3"} value={"Санхүү"}>
                  {t("Form.financial")}
                </MenuItem>
                <MenuItem key={"h5"} value={"Борлуулалт, маркетинг "}>
                  {t("Form.sale")}
                </MenuItem>
                <MenuItem key={"h4"} value={"Хууль, эрх зүй "}>
                  {t("Form.law")}
                </MenuItem>
                <MenuItem key={"h6"} value={"Менежмент"}>
                  {t("Form.manager")}
                </MenuItem>
                <MenuItem key={"h7"} value={"Эрүүл мэнд, нийгмийн хамгаалал"}>
                  {t("Form.health")}
                </MenuItem>
                <MenuItem key={"h8"} value={"Боловсрол, шинжлэх ухаан"}>
                  {t("Form.education")}
                </MenuItem>
                <MenuItem key={"h9"} value={"Урлаг, дизайн "}>
                  {t("Form.art")}
                </MenuItem>
                <MenuItem key={"h10"} value={"Судалгаа, хөгжил"}>
                  {t("Form.research")}
                </MenuItem>
                <MenuItem key={"h1№"} value={"Сэтгүүл зүй"}>
                  {t("Form.journalism")}
                </MenuItem>
                <MenuItem key={"h12"} value={"Бусад"}>
                  {t("Form.other")}
                </MenuItem>
              </TextField>
            </div>
            <div>
              <TextField
                fullWidth
                onChange={(e) => setMajor(e.target.value)}
                select
                className="col-span-2"
                margin="normal"
                required
                label={`${t("Form.workingSector")}`}
              >
                <MenuItem
                  key={"workingScore1"}
                  value={"ХАА, ойн аж ахуйн, загас барилт, ан агнуур"}
                >
                  {t("Form.agriculture")}
                </MenuItem>
                <MenuItem
                  key={"workingScore2"}
                  value={"Уул уурхай, олборлолт, Боловсруулах үйлдвэрлэл "}
                >
                  {t("Form.mining")}
                </MenuItem>
                <MenuItem
                  key={"workingScore3"}
                  value={"Цахилгаан, хий, уур, агааржуулалт "}
                >
                  {t("Form.electricity")}
                </MenuItem>
                <MenuItem key={"workingScore4"} value={"Барилга "}>
                  {t("Form.construction")}
                </MenuItem>
                <MenuItem key={"workingScore5"} value={"Мэдээлэл, холбоо "}>
                  {t("Form.information")}
                </MenuItem>
                <MenuItem
                  key={"workingScore6"}
                  value={"Санхүүгийн болон даатгалын үйл ажиллагаа "}
                >
                  {t("Form.insurance")}
                </MenuItem>
                <MenuItem
                  key={"workingScore7"}
                  value={"Үл хөдлөх хөрөнгийн үйл ажиллагаа "}
                >
                  {t("Form.realEstate")}
                </MenuItem>
                <MenuItem
                  key={"workingScore8"}
                  value={
                    "Мэргэжлийн шинжлэх ухаан болон техникийн үйл ажиллагаа "
                  }
                >
                  {t("Form.proResearch")}
                </MenuItem>
                <MenuItem
                  key={"workingScore9"}
                  value={"Удирдлагын болон дэмжлэг үзүүлэх үйл ажиллагаа "}
                >
                  {t("Form.administrationSupport")}
                </MenuItem>
                <MenuItem key={"workingScore10"} value={"Төрийн удирдлага "}>
                  {t("Form.stateManagement")}
                </MenuItem>
                <MenuItem key={"workingScore11"} value={"Боловсрол "}>
                  {t("Form.edu")}
                </MenuItem>
                <MenuItem
                  key={"workingScore12"}
                  value={
                    "Хүний эрүүл мэнд ба нийгмийн халамжийн үйл ажиллагаа "
                  }
                >
                  {t("Form.welfare")}
                </MenuItem>
                <MenuItem
                  key={"workingScore13"}
                  value={"Урлаг, үзвэр, тоглоом наадамь Дизайн, Энтертайнмент "}
                >
                  {t("Form.entertainment")}
                </MenuItem>
                <MenuItem
                  key={"workingScore14"}
                  value={"Хууль, хуулийн үйлчилгээ "}
                >
                  {t("Form.legal")}
                </MenuItem>
                <MenuItem
                  key={"workingScore15"}
                  value={"Үйлчилгээний бусад үйл ажиллагаа "}
                >
                  {t("Form.service")}
                </MenuItem>
                {/* <MenuItem key={"workingScore16"} value={"Хүн хөлслөн ажиллуулдаг өрхийн үйл ажиллагаа "}>
                  {t('Form.employ')}
                </MenuItem> */}
                <MenuItem
                  key={"workingScore17"}
                  value={
                    "Олон улсын байгууллага, суурин төлөөлөгчийн үйл ажиллагаа "
                  }
                >
                  {t("Form.international")}
                </MenuItem>
                <MenuItem key={"workingScore18"} value={"Хэвлэл мэдээлэл"}>
                  {t("Form.media")}
                </MenuItem>
              </TextField>
            </div>

            <div>
              <TextField
                fullWidth
                required
                onChange={(e) => setMajorLevel(e.target.value)}
                select // tell TextField to render select
                className="col-span-6"
                margin="normal"
                label={`${t("Form.expertLevel")}`}
              >
                <MenuItem key={"c0"} value={"Оюутан"}>
                  {t("Form.student")}
                </MenuItem>
                <MenuItem key={"c1"} value={"Ажилтан"}>
                  {t("Form.worker")}
                </MenuItem>
                <MenuItem key={"c6"} value={"Мэргэжилтэн"}>
                  {t("Form.specialist")}
                </MenuItem>
                <MenuItem key={"c2"} value={"Дунд шатны удирдлага "}>
                  {t("Form.management")}
                </MenuItem>
                <MenuItem key={"c3"} value={"Дээд шатны удирдлага"}>
                  {t("Form.officer")}
                </MenuItem>
              </TextField>
            </div>

            <div>
              <TextField
                fullWidth
                onChange={(e) => setMajorExperienceYear(e.target.value)}
                select // tell TextField to render select
                className="col-span-6"
                margin="normal"
                required
                label={`${t("Form.experienceYear")}`}
              >
                <MenuItem key={"0-1"} value={"0-1"}>
                  0-1
                </MenuItem>
                <MenuItem key={"1-3"} value={"1-3"}>
                  1-3
                </MenuItem>
                <MenuItem key={"4-7"} value={"4-7"}>
                  4-7
                </MenuItem>
                <MenuItem key={"7-10"} value={"7-10"}>
                  7-10
                </MenuItem>
                <MenuItem key={"10+"} value={"10+"}>
                  10+
                </MenuItem>
              </TextField>
            </div>
            {i18n.language === "mn" ? (
              <div>
                <Checkbox checked={term} onChange={() => setTerm(!term)} />
                <Button onClick={() => setShowModal(true)}>
                  Үйлчилгээний нөхцөл
                </Button>
              </div>
            ) : null}

            <div className="mt-6 w-full">
              <Button
                onClick={startGame}
                className="w-full"
                variant="contained"
                disabled={term ? false : true}
              >
                {t("Form.start")}
              </Button>
            </div>
          </div>
        </div>

        {/* <div className="grid grid-cols-12 gap-4"></div> */}
      </FormControl>
    </div>
  ) : (
    <Navigate to={`/game/${userId}/switch`} />
  );
};

export default TestForm;
