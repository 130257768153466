import "./../../App.css";
import { Fragment, useEffect } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import {
  Button,
  ButtonGroup,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const unityContext = new UnityContext({
  productName: "Test",
  companyName: "Metacog",

  loaderUrl: "../../../unity/test/unityTest.loader.js",
  dataUrl: "../../../unity/test/unityTest.data",
  frameworkUrl: "../../../unity/test/unityTest.framework.js",
  codeUrl: "../../../unity/test/unityTest.wasm",
});


function Metacog() {
  const [change, setChange] = useState(false);
  const [start, setStart] = useState(false);
  const [progress, setProgress] = useState(0);
  const [gameOrder, setGameOrder] = useState(0);

  useEffect(function () {
    console.log("AA")
    window.alert = function () { };

    unityContext.on("progress", (progression) => {
      // Now we can use the progression to for example
      // display it on our React app.
      setProgress(progression * 100);
      unityContext.send("MetaCog", "ChangeLang", '0');

      console.log("progression = ", progress);
    });


    unityContext.on("GameOver1", function (result) {
      console.log("GameOver1 = ", result);

      //   console.log("QUITTED = " + username);
      //   console.log("YEAHHHH", score);

      // return <Redirect to='/game/:id/switch'/>;

      // window.location.href = "/game/:id/switch"
      // history.push("/game/:id/switch");

      // browserHistory.push("/game/:id/switch");

      // props.history and props.location are available now inside this component
      // let navigate = useNavigate();
      // return navigate("/game/123/switch");
      //   setChange(true)
    });
    setTimeout(() => setStart(true), 5000);
  }, []);

  const order = (com) => {
    let w = gameOrder;
    if (com === "next") {
      if (gameOrder <= 7) w = gameOrder + 1;
    } else {
      if (gameOrder >= 0) w = gameOrder - 1;
    }

    unityContext.send("MetaCog", "GameLang", `${w},0`);
    setGameOrder(w);
  };
  return !change ? (
    <Fragment>
      <div className="wrapper flex h-screen justify-center">
        {/* <div className="unity-wrapper"> */}
        <div className="">
          <div className="mt-4 flex justify-center">
            <div className=" text-center text-lg">
              {/* Та түр хүлээнэ үү */}
              <div className="font-bold">{progress.toFixed(2)}%</div>{" "}
              {/* <div style={{ width: 350 }} className="mt-2 mb-4">
                <BorderLinearProgress variant="determinate" value={progress} />
              </div> */}
            </div>
          </div>
          <div className="">
            <Unity className="unity-canvas-test" unityContext={unityContext} />
          </div>
          <div className="mt-4 flex justify-evenly	">
            {/* <button onClick={order}>BACK</button>
            <button onClick={order}>NEXT</button> */}
            <ButtonGroup
              variant="text"
              aria-label="text button group"
              size="large"
            >
              <Button onClick={(e) => order("back")}>ӨМНӨХ</Button>
              <Button onClick={() => order("next")}>ДАРААХ</Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </Fragment>
  ) : (
    <Navigate to="/game/123/switch" replace={true} />
  );
}

export default Metacog;
