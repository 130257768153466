import { useEffect, useState } from "react";
import axios from 'axios';
import MainInput from "../components/input/MainInput";
import { FaRegUser } from "react-icons/fa6";
import { FiLock } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { login } from "../services/user.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/AuthContext";

export default function Login() {
  const [show, setShow] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { authLogin } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');

        if (response.data.country_name !== 'Mongolia') {
          changeLanguageHandler("en")
        }

      } catch (error) {
        console.error('Error fetching country data: ', error);
      }
    };

    fetchCountry();
    const storedRememberMe = localStorage.getItem("rememberMe");
    console.log(localStorage);
    if (storedRememberMe === "true") {
      const storedUsername = localStorage.getItem("username");
      const storedPassword = localStorage.getItem("password");
      setUsername(storedUsername || "");
      setPassword(storedPassword || "");
      setRememberMe(true);
    }
  }, []);

  const changeLanguageHandler = (e) => {
    const languageValue = e;
    i18n.changeLanguage(languageValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    login({
      username: data.get("username"),
      password: data.get("password"),
    })
      .then((res) => {
        const { accessToken: token } = res.data;

        Cookies.set("access_token", token, { expires: 1 });
        authLogin(data.get("username"), ["premium"]);

        if (rememberMe) {
          localStorage.setItem("username", data.get("username"));
          localStorage.setItem("password", data.get("password"));
          localStorage.setItem("rememberMe", true);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberMe");
        }

        navigate({ pathname: "/" });
      })
      .catch((error) => {
        toast.error(t("Login.toastWrongPassword"));
      });
  };
  return (
    <>
      <div className="min-h-screen min-w-screen bg-[#324d72] flex">
        <div className="mt-10 flex-1 flex justify-end items-center">
          <div className="ms-20">
            <div>
              <div className="flex items-center">
                <img
                  className=" h-[30px] ms-2 mt-2"
                  src="/oneplaceIcon.svg"
                  alt="logo"
                />
                <p className="text-4xl font-black text-white">oneplace.hr</p>
              </div>

              <p className="text-white font-bold text-xl mt-5">
                To take care of your Team
              </p>
            </div>
            <div className="mt-10  ">
              <img
                src="/signup.png"
                className="rounded-l-xl w-[580px] h-full"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 bg-white flex justify-center items-center">
          <div className="text-start">
            <div>
              <div className="flex justify-between items-center">
                <p className="font-black text-4xl"> {t("Login.login")}</p>
                <div className="relative">
                  <select
                    className="appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    defaultValue={Cookies.get("metacog_lang") || "mn"}
                    onChange={(e) => {
                      changeLanguageHandler(e.target.value);
                      Cookies.set("metacog_lang", e.target.value, {
                        expires: 30,
                      });
                    }}
                  >
                    <option value="mn">MN</option>
                    <option value="en">EN</option>
                    <option value="rn">RU</option>
                  </select>

                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
              <p className="text-lg text-gray-600 mt-5">
                {t("Login.nameAndPassword")}
              </p>
            </div>
            <form type="form" onSubmit={handleSubmit} className="mt-20">
              <div className="relative ">
                <div className="mb-5">
                  <MainInput
                    placeholder={`${t("Login.username")}`}
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    icon={<FaRegUser />}
                  />
                </div>
                <div>
                  <MainInput
                    placeholder={t("Login.password")}
                    id="password"
                    name="password"
                    value={password}
                    type={show ? "text" : "password"}
                    buttonLabel={!show ? <FiEyeOff /> : <FiEye />}
                    icon={<FiLock />}
                    showButton={true}
                    onChange={(e) => setPassword(e.target.value)}
                    onClickButton={() => {
                      setShow(!show);
                    }}
                  />
                </div>
              </div>
              <div className="flex mt-4 justify-between items-center">
                <div>
                  <label className="text-gray-500 text-sm">
                    <input
                      className="me-2"
                      type="checkbox"
                      checked={rememberMe}
                      name="value2"
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    {t("Login.save")}
                  </label>
                </div>
                {/* <div>
                  <button className="underline text-sm text-gray-500">
                    Нууц үгээ мартсан?
                  </button>
                </div> */}
              </div>
              <div className="flex justify-center mt-10">
                <button
                  type="submit"
                  className="bg-[#324d76] text-center w-full rounded rounded-lg py-3 text-white hover:bg-[#324d90] hover:text-gray-300"
                >
                  {t("Login.login")}
                </button>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
