import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSearchAlt } from "react-icons/bi";
import { totalRequest } from "../../services/assessmentPlayer.service";
import MainTable from "../../components/table/MainTable";
import Loading from "../../components/uncommon/Loading";
import { Add } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import MainButton from "../../components/buttons/MainButton";

const AssessmentDemo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [countAss, setCountAss] = useState(0);
  const [invitedCount, setInvitedCount] = useState(0);
  const [startedCount, setStartedCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState("");
  const [assessmentNameFilter, setAssessmentNameFilter] = useState("");

  useEffect(() => {
    totalRequest().then((data) => {
      setEmployees(data.data);
      console.log("data :", data);
    });
  }, []);

  useEffect(() => {
    const countAssessmentName = new Set(
      employees.map((employee) => employee.assessmentName)
    ).size;
    const invitedEmployeesCount = employees.filter(
      (employee) => employee.status === "invited"
    ).length;

    const startedEmployeesCount = employees.filter(
      (employee) => employee.status === "started"
    ).length;

    const completedEmployeesCount = employees.filter(
      (employee) => employee.status === "completed"
    ).length;
    setCountAss(countAssessmentName);
    setInvitedCount(invitedEmployeesCount);
    setStartedCount(startedEmployeesCount);
    setCompletedCount(completedEmployeesCount);
  }, [employees]);

  if (employees.length === 0) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setSelectedStatusFilter(e.target.value);
  };

  const handleButtonClick = () => {
    navigate("/customer/assessments/new");
  };

  const cellClicked = (params) => {
    navigate(`/demo/player/${params.data.id}`);
  };

  const filteredEmployees = employees.filter((employee) => {
    const fullName = `${employee.name} ${employee.lastName}`.toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    const createdDate = new Date(employee.createdDate);
    const assessmentNames = `${employee.assessmentName}`.toLowerCase();

    const isWithinDateRange =
      (!startDate || createdDate >= new Date(startDate)) &&
      (!endDate || createdDate <= new Date(endDate));

    const isStatusMatch =
      !selectedStatusFilter ||
      selectedStatusFilter === "All" ||
      employee.status === selectedStatusFilter;

    const isAssessmentNameMatch =
      !assessmentNameFilter ||
      assessmentNames.includes(assessmentNameFilter.toLowerCase());

    return (
      isAssessmentNameMatch &&
      fullName.includes(searchTermLower) &&
      isWithinDateRange &&
      isStatusMatch
    );
  });

  return (
    <div className="m-auto w-10/12 pt-11">
      <div>
        <p className="text-2xl font-black">{t("total.title")}</p>
      </div>
      <div>
        <div className="flex justify-between items-center my-10 gap-5">
          <div className="flex items-center px-4 py-1 shadow-xl rounded-lg">
            <div>
              <img
                className="w-16"
                src="/icons/jobVacanny.png"
                alt="jobVacanny"
              />
            </div>
            <div className="ms-5 font-bold">
              <p className="text-4xl text-[#4F78A8]">{countAss}</p>
              <p className="text-lg">{t("total.workPlace")}</p>
            </div>
          </div>
          <div className="flex items-center px-4 py-1 shadow-xl rounded-lg">
            <div>
              <img
                className="w-10"
                src="/icons/controller.png"
                alt="controller"
              />
            </div>
            <div className="ms-5 font-bold">
              <p className="text-4xl text-[#4F78A8]">{invitedCount}</p>
              <p className="text-lg">{t("total.invitation")}</p>
            </div>
          </div>
          <div className="flex items-center px-4 py-1 shadow-xl rounded-lg">
            <div>
              <img className="w-10" src="/icons/anketNumber.png" alt="anket" />
            </div>
            <div className="ms-5 font-bold">
              <p className="text-4xl text-[#4F78A8]">{completedCount}</p>
              <p className="text-lg">{t("total.answered")}</p>
            </div>
          </div>

          <div className="flex items-center px-4 py-1 shadow-xl rounded-lg">
            <div>
              <img className="w-10" src="/icons/warning.png" alt="warning" />
            </div>
            <div className="ms-5 font-bold">
              <p className="text-4xl text-[#4F78A8]">{startedCount}</p>
              <p className="text-lg">{t("total.notFinished")}</p>
            </div>
          </div>
        </div>
        <div>
          <div className="mb-10 flex gap-10">
            <div className="relative">
              <label className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-300">
                <BiSearchAlt />
              </label>
              <input
                type="text"
                placeholder={t("total.inputPlaceHolder")}
                className="pl-8 border border-gray-300 text-gray-900 font-bold rounded-lg py-2 pr-4"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </div>{" "}
            <div className="relative">
              <label className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-300">
                <BiSearchAlt />
              </label>
              <input
                type="text"
                placeholder={"Ажлын байраар шүүх"}
                className="pl-8 border border-gray-300 text-gray-900 font-bold rounded-lg py-2 pr-4  "
                value={assessmentNameFilter}
                onChange={(e) => {
                  setAssessmentNameFilter(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="mb-5 flex gap-5">
              <div className="relative">
                <p>{t("total.startDate")}</p>

                <label className="absolute left-2 top-2/3 transform -translate-y-1/2 text-gray-300">
                  <BiSearchAlt />
                </label>
                <input
                  placeholder={t("total.startDate")}
                  className="pl-8 border border-gray-300 text-gray-900 font-bold rounded-lg py-2 pr-4"
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div className="relative">
                <p>{t("total.endDate")}</p>
                <label className="absolute left-2 top-2/3 transform -translate-y-1/2 text-gray-300">
                  <BiSearchAlt />
                </label>
                <input
                  placeholder={t("total.endDate")}
                  className="pl-8 border border-gray-300 text-gray-900 font-bold rounded-lg py-2 pr-4"
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
              <select
                value={selectedStatusFilter}
                onChange={handleStatusFilterChange}
                className="border border-gray-300 text-gray-900 font-bold rounded-lg  w-[230px] h-[43px] mt-6"
              >
                <option disabled value="">
                  тоглоомын статус шүүх
                </option>
                <option value="All">Бүгд</option>
                <option value="completed">{t("Assessment.completed")}</option>
                <option value="invited">
                  {t("Assessment.sendInvitation")}
                </option>
                <option value="started">{t("Assessment.started")}</option>
                <option value="declined">{t("Assessment.declined")}</option>
                <option value="applied">{t("Assessment.applied")}</option>
                <option value="interview">{t("Assessment.interview")}</option>
              </select>
            </div>
            <div className="mb-4">
              <MainButton
                label={t("Home.addNewJob")}
                isSelected={true}
                lIcon={<Add className="ml-1" />}
                onClick={() => handleButtonClick()}
              />
            </div>
          </div>
        </div>
        <div>
          <MainTable
            columnDefs={[
              {
                headerName: t("EmployeeTable.tableSurname"),
                field: "fullName",
                sortable: true,
                flex: 2,
                onCellClicked: cellClicked,
                cellRenderer: (params) => {
                  return (
                    <div>
                      {params.data.name} {params.data.lastName}
                    </div>
                  );
                },
              },

              {
                headerName: t("total.position"),
                field: "assessmentName",
                sortable: true,
                flex: 2,
              },
              {
                headerName: t("total.resume"),
                field: "createdDate",
                sortable: true,
                flex: 2,
              },
              {
                headerName: t("total.status"),
                field: "status",
                sortable: true,
                flex: 3,
                cellRenderer: (params) => {
                  switch (params.data.status) {
                    case "completed":
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/greenEllipse.png" alt="" />
                          </div>
                          <p>{t("Assessment.completed")}</p>
                        </div>
                      );
                    case "started":
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/blueDot.png" alt="" />
                          </div>
                          <p>{t("Assessment.started")}</p>
                        </div>
                      );
                    case "applied":
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/orangeDot.png" alt="" />
                          </div>
                          <p>{t("Assessment.applied")}</p>
                        </div>
                      );
                    case "declined":
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/redDot.png" alt="" />
                          </div>
                          <p>{t("Assessment.declined")}</p>
                        </div>
                      );
                    case "interview":
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/purpleDot.png" alt="" />
                          </div>
                          <p>{t("Assessment.interview")}</p>
                        </div>
                      );
                    default:
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/grayDot.png" alt="" />
                          </div>
                          <p>{t("Assessment.sendInvitation")}</p>
                        </div>
                      );
                  }
                },
              },
              {
                headerName: t("Assessment.gameResult"),
                field: "finish",
                sortable: true,
                flex: 2,
                cellRenderer: (params) => {
                  switch (params.data.status) {
                    case "completed":
                      return <img src="/icons/circle-check.png" alt="" />;
                    default:
                      return <img src="/icons/circle-alert.png" alt="" />;
                  }
                },
              },
            ]}
            rowData={filteredEmployees}
          />
        </div>
      </div>
    </div>
  );
};

export default AssessmentDemo;
