import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { addSurveys, getSurveyById } from "../../../services/survey.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import {
  getCompanyEmployee,
  getDepartments,
} from "../../../services/employee.service";
import {
  updateSurvey,
  updateSurveyName,
  addSurveyQuestion,
  deleteQuestion,
} from "../../../services/survey.service";
import Loading from "../../../components/uncommon/Loading";

const SurveyQuestions = () => {
  //global
  const navigate = useNavigate();
  const modalRef = useRef(null);

  //add
  const { t } = useTranslation();
  const [todos, setTodos] = useState([]);
  const [todoText, setTodoText] = useState("");
  const [title, setTitle] = useState("");
  const [addingQuestion, setAddingQuestion] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedEmployees, setCheckedEmployees] = useState({});
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  //isEdit
  const { id } = useParams();
  const edit = !!id;
  const [questions, setQuestions] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [newQuestion, setNewQuestion] = useState("");

  //new
  useEffect(() => {
    if (id !== undefined) {
      getSurveyById(id).then((data) => {
        setQuestions(data.data);
      });
    }
  }, [id]);

  //add
  useEffect(() => {
    getCompanyEmployee()
      .then((data) => {
        setEmployees(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getDepartments()
      .then((data) => {
        setDepartments(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(employees);

  if (edit) {
    if (!questions) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
  }

  const handleQuillChange = (index, text) => {
    if (questions) {
      const updatedQuestions = [...questions.surveyQuestions];
      updatedQuestions[index].text = text;
      setQuestions({ ...questions, surveyQuestions: updatedQuestions });
    }
  };

  //new
  const addNewQuestion = () => {
    if (newQuestion.trim() !== "") {
      const newQues = {
        text: newQuestion,
        surveyId: id,
      };

      addSurveyQuestion(newQues)
        .then((response) => {
          // Update the state with the new question
          setQuestions({
            ...questions,
            surveyQuestions: [...questions.surveyQuestions, response.data],
          });

          setNewQuestion("");
          setAddingQuestion(false);
        })
        .catch((error) => {
          console.error("Error adding question:", error);
        });
    }
  };

  // name save

  const handleEditName = () => {
    setIsEdit(true);
    setEditedName(questions.name);
  };

  const handleSaveName = () => {
    const updatedSurvey = {
      id: id,
      name: editedName,
    };

    setQuestions({ ...questions, name: editedName });
    setIsEdit(false);

    updateSurveyName(updatedSurvey)
      .then((response) => {
        console.log("Name saved successfully!");
      })
      .catch((error) => {
        console.error("Error saving name:", error);
      });
  };

  //delete
  const handleDelete = (id) => {
    deleteQuestion(id)
      .then((response) => {
        toast.success(t("toast.deleteSuccess"));
        const updatedQuestions = questions.surveyQuestions.filter(
          (question) => question.id !== id
        );

        setQuestions({ ...questions, surveyQuestions: updatedQuestions });
      })
      .catch((error) => {
        toast.error(t("toast.Error"));
        console.error("Error deleting question:", error);
      });
  };

  //add

  const removeHtmlTags = (html) => {
    return html.replace(/<[^>]*>?/gm, "");
  };

  const addTodo = () => {
    if (todoText.trim() !== "") {
      setTodos([...todos, removeHtmlTags(todoText)]);
      setTodoText("");
    }
    setAddingQuestion(false);
  };

  const handleTextChange = (value) => {
    setTodoText(value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      addTodo();
    }
  };

  //department aarn filterdej bga
  const getDepartmentName = (departmentId) => {
    const department = departments.find((dept) => dept.id === departmentId);
    return department ? department.name : "Unknown Department";
  };

  const employeesByDepartment = employees.reduce((dep, employee) => {
    const departmentId = employee.departmentId;
    const departmentName = getDepartmentName(departmentId);

    if (!dep[departmentName]) {
      dep[departmentName] = [];
    }

    dep[departmentName].push(employee);
    return dep;
  }, {});

  //modal garj ireh uyd gadna tald darval arilah func
  const handleModalClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //department check
  const handleDepartmentCheck = (departmentName) => {
    const updatedCheckedEmployees = { ...checkedEmployees };
    if (updatedCheckedEmployees[departmentName]) {
      updatedCheckedEmployees[departmentName] = false;
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = false;
        setSelectedEmployees((prevSelected) =>
          prevSelected.filter((emp) => emp.id !== employee.id)
        );
      });
    } else {
      updatedCheckedEmployees[departmentName] = true;
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = true;
        const selectedEmployee = employees.find(
          (emp) => emp.id === employee.id
        );
        setSelectedEmployees((prevSelected) => [
          ...prevSelected,
          selectedEmployee,
        ]);
      });
    }
    setCheckedEmployees(updatedCheckedEmployees);
  };

  //check hiisen employee goo avj bga
  const handleEmployeeCheck = (employeeId) => {
    const updatedCheckedEmployees = { ...checkedEmployees };
    updatedCheckedEmployees[employeeId] = !checkedEmployees[employeeId];
    setCheckedEmployees(updatedCheckedEmployees);

    if (updatedCheckedEmployees[employeeId]) {
      const employee = employees.find((emp) => emp.id === employeeId);
      setSelectedEmployees((prevSelected) => [...prevSelected, employee]);
    } else {
      setSelectedEmployees((prevSelected) =>
        prevSelected.filter((emp) => emp.id !== employeeId)
      );
    }
  };

  console.log("selectedEmployees", selectedEmployees);
  const addSurvey = () => {
    try {
      if (!edit) {
        const employeeData = selectedEmployees.map((employee) => ({
          email: employee.email,
          id: employee.id,
        }));
        const survey = {
          name: title,
          surveyQuestions: todos.map((text, index) => ({ text: text })),
          surveyEmployees: employeeData,
        };
        addSurveys(survey);
        navigate("/company/survey");
      } else {
        if (questions) {
          const sanitizedQuestions = questions.surveyQuestions.map(
            (question) => ({
              id: question.id,
              text: question.text.replace(/<\/?p>/g, ""),
              surveyId: id,
            })
          );
          updateSurvey(sanitizedQuestions)
            .then((response) => {
              toast.success(t("toast.editSuccess"));
              navigate("/company/survey");
            })
            .catch((error) => {
              console.error("Error saving questions:", error);
            });
        }
      }
    } catch (error) {
      console.error("Error saving the survey:", error);
    }
  };

  let i = 0;

  return (
    <>
      <div className="m-auto w-10/12 pt-11">
        <div>
          {!edit ? (
            <div className="flex justify-between items-center">
              <div className="flex justify-between items-center">
                <input
                  type="text"
                  className="text-2xl text-[#324d72] font-bold border-x-0 border-t-0 border-b-4 focus:rounded-lg"
                  placeholder={t("Survey.addTitle")}
                  value={title}
                  onChange={handleTitleChange}
                />
              </div>
              <button
                onClick={openModal}
                className="py-2 px-4 bg-[#324d72] text-lg rounded-lg text-white hover:bg-[#324d80]"
              >
                {t("Survey.inviteSurvey")}
              </button>
            </div>
          ) : !isEdit ? (
            <div className="flex items-center">
              <p className="text-2xl text-bold text-[#324d72]">
                {questions.name}
              </p>
              <button
                onClick={handleEditName}
                className="bg-none border-[#324d72] border-2 py-1 px-2 ms-5 rounded-lg"
              >
                {t("Survey.surveyNameChange")}
              </button>
            </div>
          ) : (
            <div>
              <input
                className="border-y-0 py-2 px-5 border-t-0 border-b-2 focus:rounded-lg "
                placeholder={questions.name}
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
              />
              <button
                onClick={handleSaveName}
                className="bg-none border-[#324d72] border-2 py-1 px-2 ms-5 rounded-lg"
              >
                {t("Survey.surveyNameSave")}
              </button>
            </div>
          )}

          <hr className="my-5" />
        </div>

        {todos.map((todo, index) => {
          i++;
          return (
            <div className="mt-5">
              <p className="text-xl text-bol">
                {i} {t("Survey.thQuestion")}
              </p>
              <div className="flex items-center" key={index}>
                <div>
                  <p
                    className="text-lg ms-2 text-bold"
                    dangerouslySetInnerHTML={{ __html: todo }}
                  />
                </div>
                <button
                  className="text-red-600 rounded-lg border-2 border-[#ff0000] ml-2"
                  onClick={() => {
                    const updatedTodos = todos.filter((_, i) => i !== index);
                    setTodos(updatedTodos);
                  }}
                >
                  <Delete style={{ height: "15px" }} />
                </button>
              </div>
            </div>
          );
        })}
        <div className="mt-5">
          {!edit ? (
            addingQuestion ? (
              <div>
                <div className="flex items-center">
                  <div className="w-[500px]">
                    <ReactQuill
                      value={todoText}
                      onChange={handleTextChange}
                      placeholder={t("Survey.addQuestion")}
                    />
                  </div>

                  <button
                    className="bg-blue-500 text-white px-4 py-2 ms-5 rounded-lg"
                    onClick={addTodo}
                  >
                    {t("Survey.addQuestion")}
                  </button>
                </div>
              </div>
            ) : (
              <button
                className="flex items-center px-4 py-2 ms-5 rounded-lg"
                onClick={() => setAddingQuestion(!addingQuestion)}
              >
                <AiOutlinePlusCircle className="text-[#FF9D72] text-base me-1" />{" "}
                {t("Survey.addQuestion")}
              </button>
            )
          ) : (
            <div flex>
              <div>
                {questions.surveyQuestions.map((item, index) => {
                  return (
                    <div key={item.id} className="mb-6">
                      <div className="flex items-center gap-4">
                        <label className="text-xl">{index + 1}-р асуулт</label>
                        <button
                          className="border-2 p-1 flex items-center border-[#ff0000] rounded-lg text-[#ff0000]"
                          onClick={() => {
                            handleDelete(item.id);
                          }}
                        >
                          <Delete style={{ height: "15px" }} />
                        </button>
                      </div>
                      <ReactQuill
                        theme="snow"
                        onChange={(text) => handleQuillChange(index, text)}
                        className="ms-4 mt-2"
                        value={item.text}
                      />
                    </div>
                  );
                })}
              </div>
              <div>
                <div className="mt-5">
                  <div className="">
                    {addingQuestion ? (
                      <div className="flex items-center">
                        <input
                          className="border-y-0 py-2 px-5 border-t-0 border-b-2 focus:rounded-lg "
                          placeholder="Add a new question"
                          value={newQuestion}
                          onChange={(e) => setNewQuestion(e.target.value)}
                        />
                        <button
                          onClick={addNewQuestion}
                          className="bg-none border-[#324d72] border-2 py-1 px-2 ms-5 rounded-lg"
                        >
                          {t("Survey.addQuestion")}
                        </button>
                      </div>
                    ) : (
                      <button
                        className="flex items-center px-4 py-2 ms-5 rounded-lg"
                        onClick={() => setAddingQuestion(!addingQuestion)}
                      >
                        <AiOutlinePlusCircle className="text-[#FF9D72] text-base me-1" />{" "}
                        {t("Survey.addQuestion")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="relative">
            <div className="mb-10">
              <div className="ms-3 gap-3">
                <div className="mb-2 rounded">
                  <label></label>
                </div>
              </div>
            </div>
            <div className="w-full flex items-center justify-end sticky bottom-0 bg-[#fff] pt-4 mb-10">
              <Link
                className="border hover:bg-[#f0f0f0] focus:ring-4 focus:bg-[#f0f0f0] bg-white font-medium rounded-lg text-sm px-5 py-2.5 mr-4 mb-2"
                to="/company/survey"
              >
                {t("Research.Questions.backButton")}
              </Link>
              <button
                onClick={addSurvey}
                className="text-white  bg-[#324D72] hover:bg-[#324d60] px-20 focus:ring-4  focus:bg-[#324d60] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
              >
                {t("Research.Questions.saveButton")}
              </button>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <div
            onClick={handleModalClickOutside}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          >
            <div
              ref={modalRef}
              className="modal p-10 bg-white rounded-lg overflow-y-auto h-[600px]"
            >
              <h2 className="text-2xl font-semibold mb-4">
                {t("Survey.selectEmployees")}
              </h2>
              <div>
                {Object.entries(employeesByDepartment).map(
                  ([departmentName, departmentEmployees]) => (
                    <div className="mb-4" key={departmentName}>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          onChange={() => handleDepartmentCheck(departmentName)}
                          checked={checkedEmployees[departmentName] || false}
                        />
                        <p className="text-xl ms-2 ">{departmentName}</p>
                      </div>
                      {departmentEmployees.map((employee) => (
                        <div
                          className="ms-4 flex items-center"
                          key={employee.id}
                        >
                          <div>
                            <input
                              type="checkbox"
                              onChange={() => handleEmployeeCheck(employee.id)}
                              checked={checkedEmployees[employee.id] || false}
                            />
                            <label className="ms-2 text-lg">
                              {employee.lastName}, {employee.firstName}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                )}
              </div>
              <div className="relative">
                <button
                  onClick={closeModal}
                  className="bg-gray-300 text-gray-700 px-4 py-2 mt-4 rounded-lg hover-bg-gray-400 text-lg hover:bg-gray-500 focus:ring-4"
                >
                  {t("Survey.close")}
                </button>
                <button
                  onClick={closeModal}
                  className="ms-2 py-2 px-4 bg-[#324d72] text-lg rounded-lg text-white hover:bg-[#324d80] focus:ring-4"
                >
                  {t("Survey.select")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default SurveyQuestions;
