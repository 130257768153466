const MainButton = ({
  isSelected,
  label,
  onClick,
  rIcon,
  lIcon,
  color,
  labelColor,
  justify,
  customClass,
}) => {
  const baseStyles = "w-full p-2 text-left rounded-[10px] flex";
  const bgColor = isSelected ? "bg-main-blue" : color || "";
  const hoverBgColor = isSelected ? "hover:bg-[#f6f6f6]" : "hover:bg-[#f6f6f6]";
  const textColor = isSelected ? "text-white" : labelColor || "text-black";
  const hoverTextColor = isSelected
    ? "hover:text-[#666874]"
    : "hover:text-[#666874]";
  const textCenter = "text-center";
  const lIconMargin = lIcon ? "mr-2" : "";
  justify = justify ? justify : "justify-center";

  return (
    <button
      onClick={onClick}
      className={`${baseStyles} ${bgColor} ${hoverBgColor} ${textColor} ${hoverTextColor} ${textCenter} ${lIconMargin} ${justify} ${customClass}`}
    >
      <div className={`${lIconMargin}`}>{lIcon}</div>
      {label}
      {rIcon}
    </button>
  );
};

export default MainButton;
