import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AgCellMenu from "../../components/menu/AgCellMenu";
import MainTable from "../../components/table/MainTable";

export default function HomeTable({ createdAssessments, deleteAss, isActive }) {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const clickOnCell = (e) => {
    if (e.event.button === 1) {
      window.open(`/customer/assessments/${e.data.action.id}`, "_blank");
    } else {
      navigate(`/customer/assessments/${e.data.action.id}`);
    }
  };

  const deleteOrActive1 = (params) => {
    deleteAss(params.action.id);
  };

  createdAssessments = createdAssessments.filter((elm) => {
    return elm.isActive === isActive;
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <MainTable
          refresh={isActive}
          columnDefs={[
            {
              headerName: t("Home.job"),
              field: "name",
              sortable: true,
              onCellClicked: clickOnCell,
              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center">
                    {hprops.menuIcon != null ? (
                      <img
                        src={`${hprops.menuIcon}`}
                        className="mr-1.5"
                        width={20}
                        height={20}
                      />
                    ) : null}
                    {hprops.displayName}
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/house.png" },
            },
            {
              headerName: t("Home.createdDate"),
              field: "createdDate",
              sortable: true,
              onCellClicked: clickOnCell,
              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center">
                    {hprops.menuIcon != null ? (
                      <img
                        src={`${hprops.menuIcon}`}
                        className="mr-1.5"
                        width={20}
                        height={20}
                      />
                    ) : null}
                    {hprops.displayName}
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/calendar.png" },
            },
            {
              headerName: t("Home.status"),
              field: "isActive",
              sortable: true,
              onCellClicked: clickOnCell,
              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center">
                    {hprops.menuIcon != null ? (
                      <img
                        src={`${hprops.menuIcon}`}
                        className="mr-1.5"
                        width={20}
                        height={20}
                      />
                    ) : null}
                    {hprops.displayName}
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/chart.png" },
              cellRenderer: (params) => {
                return (
                  <p className="text-main-blue">
                    {params.data.isActive === true ? (
                      <>
                        <div className="flex items-center justify-center">
                          <img
                            src="/icons/greenEllipse.png"
                            className="mr-[5px]"
                          />{" "}
                          {t("Home.open")}
                        </div>
                      </>
                    ) : (
                      <div className="flex items-center justify-center text-main-blue">
                        <img src="/icons/redEllipse.png" className="mr-[5px]" />{" "}
                        {t("Home.closed")}
                      </div>
                    )}
                  </p>
                );
              },
            },
            {
              headerName: t("Home.anket"),
              field: "allAnket",
              sortable: true,
              flex: 2,
              onCellClicked: clickOnCell,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center">
                    {hprops.menuIcon != null ? (
                      <img
                        src={`${hprops.menuIcon}`}
                        className="mr-1.5"
                        width={20}
                        height={20}
                      />
                    ) : null}
                    {hprops.displayName}
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/location.png" },
            },
            {
              headerName: t("Home.progress"),
              field: "gameProcess",
              sortable: true,
              flex: 2,
              onCellClicked: clickOnCell,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center">
                    {hprops.menuIcon != null ? (
                      <img
                        src={`${hprops.menuIcon}`}
                        className="mr-1.5"
                        width={20}
                        height={20}
                      />
                    ) : null}
                    {hprops.displayName}
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/document.png" },
            },
            {
              headerName: "",
              field: "action",
              sortable: false,
              cellRenderer: AgCellMenu,
              flex: 1,
              cellRendererParams: (params) => {
                let menuItems = [];
                if (params.data.isActive) {
                  // Assuming the row data has an isActive attribute.
                  menuItems.push({
                    label: t("DashboardAssessment.removeJob"),
                    action: "deleteOrActive",
                  });
                } else {
                  menuItems.push({
                    label: t("DashboardAssessment.activateRemovedJob"),
                    action: "deleteOrActive",
                  });
                }

                return {
                  menuItems: menuItems,
                  menuActions: {
                    deleteOrActive: deleteOrActive1,
                  },
                };
              },
            },
          ]}
          rowData={createdAssessments.map((elm) => {
            return {
              name: elm.name,
              createdDate: elm.createdDate.substring(0, 16),
              isActive: elm.isActive,
              allAnket: elm.assessmentPlayerCount,
              gameProcess:
                isNaN(elm.completedCount) ||
                isNaN(elm.assessmentPlayerCount) ||
                elm.assessmentPlayerCount === 0
                  ? "0%"
                  : `${Math.round(
                      (elm.completedCount / elm.assessmentPlayerCount) * 100
                    )}%`,
              action: elm,
            };
          })}
        />
      </Paper>
    </Box>
  );
}
