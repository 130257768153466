import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { FaPlus, FaUserPlus } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Loading from "../components/uncommon/Loading";
import { getAllPlayers, getCompanyData } from "../services/dashboard.service";
import { useTranslation } from "react-i18next";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const ControlDashboard = () => {
  const [data, setData] = useState([]);
  const [company, setCompany] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [genderCounts, setGenderCounts] = useState({
    male: 0,
    women: 0,
    others: 0,
    null: 0,
  });
  const [currentMonthCount, setCurrentMonthCount] = useState(0);
  const [percentageIncrease, setPercentageIncrease] = useState(0);
  const [experienceYearCounts, setExperienceYearCounts] = useState({});
  const [workWithData, setWorkWithData] = useState({});
  const [burnoutPrev, setBurnOutPrev] = useState(0);
  const [softSkillPrev, setSoftSkillPrev] = useState();
  const [companyAvgMpi, setCompanyAvgMpi] = useState(0);
  const [overAllAvgMpi, setOverAllAvgMpi] = useState(0);
  const [companyAvgCi, setCompanyAvgCi] = useState(0);
  const [overAllAvgCi, setOverAllAvgCi] = useState(0);
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    getAllPlayers()
      .then((response) => {
        setData(response.data.key);
      })
      .catch((err) => {
        console.log("error :", err);
      });
    getCompanyData()
      .then((data) => {
        setCompany(data.data);

        const CAvgMpi = data.data.mpici[0]?.companyAvgMpi.toFixed(0);
        const OAvgMpi = data.data.mpici[0]?.overallAvgMpi.toFixed(0);
        const CAvgCi = data.data.mpici[0]?.companyAvgCi.toFixed(0);
        const OAvgCi = data.data.mpici[0]?.overallAvgCi.toFixed(0);

        setCompanyAvgMpi(Number(CAvgMpi));
        setOverAllAvgMpi(Number(OAvgMpi));
        setCompanyAvgCi(Number(CAvgCi));
        setOverAllAvgCi(Number(OAvgCi));
        setBurnOutPrev(
          data.data.burnoutCount[0].currentMonthCount -
            data.data.burnoutCount[0].lastMonthCount
        );
        setSoftSkillPrev(
          data.data.skillCount[0].currentMonthCount -
            data.data.skillCount[0].lastMonthCount
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      // Filter data based on complete_type
      const filteredData = data.filter(
        (item) =>
          item.complete_type === "started" || item.complete_type === "completed"
      );

      // Set filteredData state
      setFilteredData(filteredData);

      // Count genders
      const counts = data.reduce(
        (acc, item) => {
          if (item.gender === "MALE") {
            acc.male += 1;
          } else if (item.gender === "WOMEN") {
            acc.women += 1;
          } else if (item.gender === "OTHER") {
            acc.others += 1;
          } else {
            acc.null += 1;
          }
          return acc;
        },
        { male: 0, women: 0, others: 0, null: 0 }
      );

      setGenderCounts(counts);

      // Count experience years
      const experienceYears = data.map((item) => item.major_experience_year);

      const filteredExperienceYears = experienceYears.filter(
        (year) => year !== null
      );

      const countExp = filteredExperienceYears.reduce((counts, year) => {
        counts[year] = (counts[year] || 0) + 1;
        return counts;
      }, {});

      const totalCount = Object.values(countExp).reduce(
        (total, count) => total + count,
        0
      );

      const percentages = {};
      for (let year in countExp) {
        percentages[year] = Math.round((countExp[year] / totalCount) * 100);
      }

      setExperienceYearCounts(percentages);

      // Count work with values
      const workWithValues = data.map((item) => item.work_with);

      const filteredWorkWithValues = workWithValues.filter(
        (value) => value !== null
      );

      const workWithCounts = filteredWorkWithValues.reduce((counts, value) => {
        counts[value] = (counts[value] || 0) + 1;
        return counts;
      }, {});

      const workWithTotalCount = Object.values(workWithCounts).reduce(
        (total, count) => total + count,
        0
      );

      const workWithPercentages = {};
      for (let value in workWithCounts) {
        workWithPercentages[value] = Math.round(
          (workWithCounts[value] / workWithTotalCount) * 100
        );
      }

      const workWithData = {};
      for (let value in workWithCounts) {
        workWithData[value] = {
          count: workWithCounts[value],
          percentage: workWithPercentages[value],
        };
      }

      setWorkWithData(workWithData);

      //data counts

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
      const lastMonthYear = lastMonth === 11 ? currentYear - 1 : currentYear;

      const currentMonthStartDate = new Date(currentYear, currentMonth, 1);
      const currentMonthEndDate = new Date(currentYear, currentMonth + 1, 0);
      const lastMonthStartDate = new Date(lastMonthYear, lastMonth, 1);
      const lastMonthEndDate = new Date(currentYear, currentMonth, 0);

      const countThisMonthData = data.filter((item) => {
        const createdDate = new Date(item.created_date);
        return (
          createdDate >= currentMonthStartDate &&
          createdDate <= currentMonthEndDate
        );
      }).length;

      const countLastMonthData = data.filter((item) => {
        const createdDate = new Date(item.created_date);
        return (
          createdDate >= lastMonthStartDate && createdDate <= lastMonthEndDate
        );
      }).length;

      const percentageIncrease =
        countThisMonthData !== 0 && countLastMonthData !== 0
          ? Math.round(
              ((countThisMonthData - countLastMonthData) / countLastMonthData) *
                100
            )
          : countLastMonthData === 0
          ? 100
          : -100;

      setCurrentMonthCount(countThisMonthData);
      setPercentageIncrease(percentageIncrease);
    }
  }, [data]);

  const doghChart = {
    labels: ["Эмэгтэй", "Бусад", "Эрэгтэй"],
    datasets: [
      {
        data: [genderCounts?.women, genderCounts?.others, genderCounts?.male],
        backgroundColor: ["#FF9CE9", "#6CB7A1", "#A7BAFF"],
        hoverBackgroundColor: ["#FF9CE9", "#6CB7A1", "#A7BAFF"],
        cutout: "70%",
        borderRadius: 6,
      },
    ],
  };

  const doghChart2 = {
    labels: [
      "Engaged",
      "Inefficient",
      "Хэт ачаалалтай",
      "Анхаарах хэрэгтэй",
      "Burnout",
    ],
    datasets: [
      {
        data: [100],
        backgroundColor: "#ccc ",
        cutout: "60%",
        borderRadius: 8,
      },
    ],
  };

  const yearServiceChart = {
    labels: ["0-1", "1-3", "4-7", "7-10", "10+"],
    datasets: [
      {
        data: [
          experienceYearCounts["0-1"],
          experienceYearCounts["1-3"],
          experienceYearCounts["4-7"],
          experienceYearCounts["7-10"],
          experienceYearCounts["10+"],
        ],
        backgroundColor: ["#6CB7A1", "#324D72", "#FF9D72", "#66BCCE"],
        hoverBackgroundColor: ["#6CB7A1", "#324D72", "#FF9D72", "#66BCCE"],
        barThickness: 20,
        borderRadius: 4,
        borderBottomRightRadius: 4,
      },
    ],
  };

  const highestTop5Percentages = Object.entries(workWithData)
    .sort(([, a], [, b]) => b.percentage - a.percentage)
    .slice(0, 5);

  const sectorChart = {
    labels: highestTop5Percentages.map(([value]) => value),
    datasets: [
      {
        data: highestTop5Percentages.map(([, { percentage }]) => percentage),
        backgroundColor: [
          "#6CB7A1",
          "#324D72",
          "#FF9D72",
          "#66BCCE",
          "#2851BB",
        ],
        borderRadius: 4,
        barThickness: 12,
        borderBottomRightRadius: 4,
      },
    ],
  };
  const employeeMpiChart = {
    labels: ["", ""],
    axis: "y",
    datasets: [
      {
        label: "",
        backgroundColor: ["#324D72", "#06B496"],
        data: [companyAvgMpi, overAllAvgMpi],
        barThickness: 8,
        borderRadius: 4,
        categoryPercentage: 0.2,
        barPercentage: 0.2,
      },
    ],
  };
  const employeeCiChart = {
    labels: ["", ""],
    axis: "y",
    datasets: [
      {
        label: "",
        backgroundColor: ["#324D72", "#06B496"],
        data: [companyAvgCi, overAllAvgCi],
        barThickness: 8,
        borderRadius: 4,
      },
    ],
  };

  if (data === null || company.length === 0) {
    return <Loading />;
  }

  return (
    <div className="px-5 p-5 pt m-auto bg-[#F3F4F8] min-h-screen">
      <p className="text-2xl mt-5 font-bold">
        {t("Dashboard.aboutRecruiting")}
      </p>
      <div>
        <div className="flex items-start gap-5 mt-[16px]  justify-center">
          <div className="bg-white rounded-xl p-3 h-[242px] flex flex-col justify-center">
            <p className="text-sm text-[#324d72] font-bold">
              {t("Dashboard.currentMonthRecruiting")}
            </p>
            <div className="flex items-center justify-between my-3 px-3">
              <p className="text-[28px] font-bold text-[#202224] mt-3">
                {currentMonthCount}
              </p>
              <div className="flex items-center justify-center rounded-full bg-[#FFF5EE] px-2 py-3 mt-2">
                <FaUserPlus className="ms-2 text-[#d48d79] h-8 w-8" />
              </div>
            </div>
            <div className="flex items-center gap-2">
              <svg
                width="20"
                height="13"
                viewBox="0 0 20 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 0.5L16.29 2.79L11.41 7.67L7.41 3.67L0 11.09L1.41 12.5L7.41 6.5L11.41 10.5L17.71 4.21L20 6.5V0.5H14Z"
                  fill="#00B69B"
                />
              </svg>

              <p className="text-sm text-[#606060]">
                <span className="text-[#00B69B] ">
                  {Math.abs(percentageIncrease).toFixed(1)}%
                </span>
                {percentageIncrease.toFixed(1) > 0
                  ? t("Dashboard.morePrevMonth")
                  : t("Dashboard.lessPrevMonth")}
              </p>
            </div>
          </div>
          <div className="bg-white rounded-xl p-3 h-[242px]">
            <p className="text-sm text-[#324d72] font-bold">
              {t("Dashboard.genderPercentage")}
            </p>
            <div className="flex flex-col justify-center mt-6">
              <div className="flex justify-center">
                <div style={{ width: "120px", height: "120px" }}>
                  <Doughnut
                    data={doghChart}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center gap-4 mt-2">
                <div className="flex items-center gap-1">
                  <div className="w-2 h-2 bg-[#FF9CE9] rounded-full" />
                  <p className="text-[10px] opacity-80">{t("Form.men")}</p>
                </div>
                <div className="flex items-center gap-1">
                  <div className="w-2 h-2 bg-[#6CB7A1] rounded-full" />
                  <p className="text-[10px] opacity-80">{t("Form.other")}</p>
                </div>
                <div className="flex items-center gap-1">
                  <div className="w-2 h-2 bg-[#A7BAFF] rounded-full" />
                  <p className="text-[10px] opacity-80">{t("Form.women")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-xl p-3 h-[242px]">
            <p className="text-sm text-[#324d72] font-bold">
              {t("Dashboard.byWorkingYear")}
            </p>
            <div className="flex flex-col justify-center mt-8 ">
              <div className="flex items-center">
                <div>
                  <div className="flex items-start">
                    <div className="flex flex-col justify-between h-[120px]">
                      <p className="text-[#1C1C1C] text-xs opacity-40 ">100</p>
                      <p className="text-[#1C1C1C] text-xs opacity-40 ">75</p>
                      <p className="text-[#1C1C1C] text-xs opacity-40 ">50</p>
                      <p className="text-[#1C1C1C] text-xs opacity-40 ">25</p>
                      <p className="text-[#1C1C1C] text-xs opacity-40 ">0</p>
                    </div>
                    <div className="w-[220px]">
                      <Bar
                        data={yearServiceChart}
                        options={{
                          elements: {
                            bar: {
                              borderWidth: 1,
                            },
                          },
                          plugins: { legend: { display: false } },
                          scales: {
                            x: { display: false },
                            y: { display: false, max: 100 },
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex ms-7 flex-wrap items-center justify-between px-1 me-1">
                    <p className="text-[#1C1C1C] text-xs opacity-40">0-1</p>
                    <p className="text-[#1C1C1C] text-xs opacity-40">1-3</p>
                    <p className="text-[#1C1C1C] text-xs opacity-40">4-7</p>
                    <p className="text-[#1C1C1C] text-xs opacity-40">7-10</p>
                    <p className="text-[#1C1C1C] text-xs opacity-40">10+</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-xl p-3 h-[242px]">
            <p className="text-sm text-[#324d72] font-bold mb-2">
              {t("Dashboard.bySector")}
            </p>
            <div className="flex flex-col justify-center">
              <div className="flex items-center">
                <div>
                  <div className="flex justify-center">
                    <div className="flex items-start">
                      <div className="flex flex-col justify-between h-[135px] w-[30px]">
                        <p className="text-[#1C1C1C] text-xs opacity-40 ">
                          100
                        </p>
                        <p className="text-[#1C1C1C] text-xs opacity-40 ">75</p>
                        <p className="text-[#1C1C1C] text-xs opacity-40 ">50</p>
                        <p className="text-[#1C1C1C] text-xs opacity-40 ">25</p>
                        <p className="text-[#1C1C1C] text-xs opacity-40 ">0</p>
                      </div>
                      <div className="w-[250px]">
                        <Bar
                          data={sectorChart}
                          options={{
                            elements: {
                              bar: {
                                borderWidth: 1,
                              },
                            },
                            plugins: { legend: { display: false } },
                            scales: {
                              x: { display: false },
                              y: { display: false, max: 100 },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center justify-center gap-3">
                    {sectorChart.labels.map((label, index) => (
                      <div className="flex items-center" key={index}>
                        <div
                          className={`w-[10px] h-[10px] rounded-full bg-${sectorChart.datasets[0].backgroundColor[index]} me-1`}
                          style={{
                            backgroundColor:
                              sectorChart.datasets[0].backgroundColor[index],
                          }}
                        />
                        <p className="text-[10px]">{label}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-2xl mt-10 font-bold">
        {t("Dashboard.aboutEmployeePsychology")}
      </p>
      <div className="mt-[16px] flex items-start gap-5 justify-center">
        <div className="bg-white px-4 py-4 rounded-xl w-[300xp] h-[370px]">
          <p className="text-sm text-[#324d72] font-bold">
            {t("Dashboard.burnoutInCompany")}
          </p>
          <div className="flex items-start gap-6 mt-6">
            <div style={{ width: "120px" }}>
              <Doughnut
                data={doghChart2}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
            <div className="w-full">
              <div className="flex gap-2 items-center mb-2 w-full">
                <div className="w-2 h-2 bg-[#06B496] rounded-full" />
                <div className="flex justify-between items-center w-full">
                  <p className="text-xs">Engaged</p>
                  <p className="text-xs">0</p>
                </div>
              </div>
              <div className="flex gap-2 items-center mb-2 w-full">
                <div className="w-2 h-2 bg-[#FF9D72] rounded-full" />
                <div className="flex justify-between items-center w-full">
                  <p className="text-xs">
                    {t("burnout.generalReport.overloaded")}
                  </p>
                  <p className="text-xs">0</p>
                </div>
              </div>
              <div className="flex gap-2 items-center mb-2 w-full">
                <div className="w-2 h-2 bg-[#F18B8B] rounded-full" />
                <div className="flex justify-between items-center w-full">
                  <p className="text-xs">
                    {t("burnout.generalReport.payAttention")}
                  </p>
                  <p className="text-xs">0</p>
                </div>
              </div>
              <div className="flex gap-2 items-center mb-2 w-full">
                <div className="w-2 h-2 bg-[#F0CB6D] rounded-full" />
                <div className="flex justify-between items-center w-full">
                  <p className="text-xs">Inefficient</p>
                  <p className="text-xs">0</p>
                </div>
              </div>
              <div className="flex gap-2 items-center mb-2 w-full">
                <div className="w-2 h-2 bg-[#DF2D2D] rounded-full" />
                <div className="flex justify-between items-center w-full">
                  <p className="text-xs">Burnout</p>
                  <p className="text-xs">0</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-8 gap-2">
            <div className="border rounded-xl p-2 border-[#48F81C]">
              <p className="text-sm opacity-70">
                {t("Dashboard.burnoutLowDep")}
              </p>
              <p className="my-2">-</p>
              <img src="./burnout/5.svg" alt="icon" />
            </div>
            <div className="border rounded-xl p-2 border-[#F81B1B] border-opacity-30">
              <p className="text-sm opacity-70">
                {t("Dashboard.burnoutHighDep")}
              </p>
              <p className="my-2">-</p>
              <img src="./burnout/1.svg" alt="icon" />
            </div>
          </div>
        </div>
        <div className="">
          <div className="w-[410px] bg-white rounded-xl px-2 py-3 mb-2">
            <p className="text-sm text-[#324d72] font-bold">
              {t("Dashboard.intelligenceMpiScore")}
            </p>
            <div className="flex gap-4 justify-center items-bottom mt-4">
              <div>
                <p className="text-[#667085] text-xs text-center">
                  {t("Dashboard.basicIntelligence")}
                  <br /> {t("Dashboard.mpiByindicator")}
                </p>
                <div className="flex items-start justify-end relative">
                  <div className="w-[130px]">
                    <Bar
                      data={employeeMpiChart}
                      options={{
                        indexAxis: "y",
                        plugins: {
                          legend: { display: false },
                        },
                        scales: {
                          y: {
                            display: false,
                          },
                          x: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </div>{" "}
                  <p className="text-xs absolute top-1">{companyAvgMpi}</p>
                  <p className="text-xs absolute bottom-1">{overAllAvgMpi}</p>
                </div>
              </div>
              <img className="mt-4" src="./dashboard/Line.png" alt="vector" />
              <div>
                <p className="text-[#667085] text-xs text-center">
                  {t("Dashboard.bySkill")}
                  <br /> ({t("Dashboard.suchAsCriticalThinking")})
                </p>
                <div className="flex items-start justify-end relative">
                  <div className="w-[130px]">
                    <Bar
                      data={employeeCiChart}
                      options={{
                        indexAxis: "y",
                        plugins: {
                          legend: { display: false },
                        },
                        scales: {
                          y: {
                            display: false,
                          },
                          x: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </div>
                  <p className="text-xs absolute top-1">{companyAvgCi}</p>
                  <p className="text-xs absolute bottom-1">{overAllAvgCi}</p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-2 rounded-full">
                <div className="w-2 h-2 bg-[#06B496]" />
                <p className="text-xs">{t("Dashboard.overall")}</p>
              </div>
              <div className="flex items-center gap-2 rounded-full mt-1">
                <div className="w-2 h-2 bg-[#324D72]" />
                <p className="text-xs">{t("Dashboard.companyOverall")}</p>
              </div>
            </div>
          </div>

          <div className="flex items-start gap-1">
            <div className="w-[135px] h-[160px] rounded-xl relative bg-[#F3E8FF] flex flex-col justify-center px-3">
              <img
                className="absolute top-0 right-0 h-[50px] w-[50px]"
                src="./dashboard/softSkill.png"
                alt="img"
              />
              <p className="text-sm text-[#667085]">
                Soft Skill {t("Dashboard.game")}
              </p>
              <p className="text-sm mt-1 text-[#151D48]">
                {t("Dashboard.Total")}:
              </p>
              <p className="text-[24px] ms-2 mt-0.5 text-[#1c1c1c]">
                {company.skillCount[0].currentMonthCount}
              </p>
              <p className="text-sm mt-0.5 text-[#151D48]">
                {t("Dashboard.gamePlayed")}
              </p>
              <p className="mt-1 text-[#4079ED] text-xs">
                {softSkillPrev > 0 ? `+${softSkillPrev} ` : ` ${softSkillPrev}`}
                {t("Dashboard.diffMonth")}
              </p>
            </div>
            <div className="w-[135px] h-[160px] rounded-xl relative bg-[#DCFCE7] flex flex-col justify-center px-3">
              <img
                className="absolute top-0 right-0 h-[50px] w-[50px]"
                src="./dashboard/survey.png"
                alt="img"
              />
              <p className="text-sm text-[#667085]">
                Survey {t("Dashboard.game")}
              </p>
              <p className="text-sm mt-1 text-[#151D48]">
                {t("Dashboard.Total")}:
              </p>
              <p className="text-[24px] ms-2 mt-0.5 text-[#1c1c1c]">0</p>
              <p className="text-sm mt-0.5 text-[#151D48]">
                {t("Dashboard.gamePlayed")}
              </p>
              <p className="mt-1 text-[#4079ED] text-xs">
                0 {t("Dashboard.diffMonth")}
              </p>
            </div>
            <div className="w-[135px] h-[160px] rounded-xl relative bg-[#FFF4DE] flex flex-col justify-center px-3">
              <img
                className="absolute top-0 right-0 h-[50px] w-[50px]"
                src="./dashboard/burnout.png"
                alt="img"
              />
              <p className="text-sm text-[#667085]">
                Burnout {t("Dashboard.game")}
              </p>
              <p className="text-sm mt-1 text-[#151D48]">
                {t("Dashboard.Total")}:
              </p>
              <p className="text-[24px] ms-2 mt-0.5 text-[#1c1c1c]">
                {company.burnoutCount[0].currentMonthCount}
              </p>
              <p className="text-sm mt-0.5 text-[#151D48]">
                {t("Dashboard.gamePlayed")}
              </p>
              <p className="mt-1 text-[#4079ED] text-xs">
                {burnoutPrev > 0 ? `+${burnoutPrev} ` : ` ${burnoutPrev}`}{" "}
                {t("Dashboard.diffMonth")}
              </p>
            </div>
          </div>
        </div>
        <div>
          <p className="text-lg">{t("Dashboard.getEmployeeSurvey")}</p>
          <div
            onClick={() => {
              navigate("/company/newSurvey");
            }}
            className="w-[300px] flex justify-center items-center py-3 relative bg-white rounded-xl mt-3"
          >
            <p className="text-[#324d72]">
              {t("Dashboard.satisfactionSurvey")}
            </p>
            <IoIosArrowForward className="absolute right-5" />
          </div>
          <div className="flex justify-between gap-2 items-start mt-4">
            <div className="rounded-xl bg-white px-1 py-2 w-[300px] h-[210px]">
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    navigate("/company/newSurvey");
                  }}
                  className="rounded-full p-2 text-[#8E8E8E] bg-[#E3E3E3]"
                >
                  <FaPlus className="h-3 w-3" />
                </button>
              </div>
              <div className="flex items-center justify-center mt-2">
                <img src="./dashboard/asuumj.png " alt="img" />
              </div>
              <div>
                <p className="text-center text-[#202224] text-sm text-semibold my-2">
                  {t("Dashboard.createSurvey")}
                </p>
                <p className="text-center text-[#202224] opacity-80 text-[10px]">
                  {t("Dashboard.results1to5")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlDashboard;
