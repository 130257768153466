import React, { useEffect, useState } from "react";
import { getEmployee } from "../../../services/employee.service";
import Loading from "../../../components/uncommon/Loading";
import { TfiMenuAlt } from "react-icons/tfi";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { FaChevronRight, FaRegBell } from "react-icons/fa6";
import { MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip);

const Surveys = () => {
  const percentage = 45;
  const [hover, setHover] = useState(false);
  const totalSlices = 10;
  const filledSlices = Math.floor((percentage / 100) * totalSlices);
  const grad = (percentage % 10) / 10;

  const chartData = Array(totalSlices).fill(1);

  const getGradient = (ctx, chartArea) => {
    if (!chartArea) return "#E9EDF5";

    const { left, right, top, bottom } = chartArea;
    const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);

    gradientSegment.addColorStop(0, "#324D72");

    gradientSegment.addColorStop(grad, "#324D72");

    gradientSegment.addColorStop(1, "#E9EDF5");

    return gradientSegment;
  };

  const data = {
    datasets: [
      {
        data: chartData,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          return chartData.map((_, index) => {
            if (index < filledSlices) return "#324D72";
            if (index === filledSlices) return getGradient(ctx, chartArea);
            return "#E9EDF5";
          });
        },
        borderWidth: 2,
        borderColor: "#FFFFFF",
        cutout: "70%",
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: { enabled: false },
    },
  };

  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      className="bg-[#FFFFFF] rounded-xl shadow-xl flex items-center gap-4 px-4 py-2"
    >
      <div className="relative flex items-center justify-center w-32 h-32">
        <Doughnut data={data} options={options} />
        <div className="absolute text-xl font-semibold text-gray-900 mt-1">
          {percentage}%
        </div>
      </div>
      <div>
        <p className="text-[#636466] font-semibold mb-3">Survey Name</p>
        <div className="flex items-center gap-2">
          <div>
            <p className="text-[#636466] text-xs">Асуулт:</p>
            <p className="text-[#222222] text-xs font-semibold">25</p>
          </div>
          <div>
            <p className="text-[#636466] text-xs">Илгээсэн огноо:</p>
            <p className="text-[#222222] text-xs font-semibold">2023/10/21</p>
          </div>
          <div>
            <p className="text-[#636466] text-xs">Сүүлд хандсан:</p>
            <p className="text-[#222222] text-xs font-semibold">2023/12/21</p>
          </div>
        </div>
        <button
          className={`text-[#636466] text-xs mt-3 flex items-center transition-opacity duration-300 ease-in-out ${
            hover ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          Сануулах <FaChevronRight />
        </button>
      </div>
    </div>
  );
};
const OverviewEmployee = ({ data }) => {
  const [employee, setEmployee] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setEmployee(data);
  }, [data]);

  if (employee === null) {
    return <Loading />;
  }

  return (
    <div className="mt-6 ps-5 pe-20">
      {console.log(employee)}
      <div className="py-4 px-6 shadow-custom rounded-xl bg-white w-full">
        <div className="flex items-center justify-between">
          <p className="text-[#293951] font-semibold mb-2">Ерөнхий мэдээлэл</p>
          <button
            onClick={() => {
              navigate(`/company/employee/add/${employee.id}`);
            }}
          >
            <MdOutlineEdit />
          </button>
        </div>
        <div className="flex items-center gap-4">
          <div className="text-center">
            <div className="flex items-center justify-center">
              <img className="w-[70px] h-[70px]" src="/user.svg" alt="pfp" />
            </div>
            <p className="text-sm text-[#1E293B] font-semibold">
              {employee.lastName} {employee.firstName}
            </p>
            {/* <p className="text-[#666874] text-sm font-semibold">
              {employee.position}
            </p> */}
          </div>
          <div className="w-full">
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-sm">Мейл хаяг</p>
              <p className="text-[#666874] text-sm">
                {employee.email === null ? "Байхгүй" : employee.email}
              </p>
            </div>
            <hr className="my-2 text-[#F3F4F6]" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-sm">Хэлтэс</p>
              <p className="text-[#666874] text-sm">
                {employee.depName === null ? "" : employee.depName}
              </p>
            </div>
            <hr className="my-2 text-[#F3F4F6]" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-sm">Алба</p>
              <p className="text-[#666874] text-sm">
                {employee.albaName === null ? "" : employee.albaName}
              </p>
            </div>
            <hr className="my-2 text-[#F3F4F6]" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-sm">Баг</p>
              <p className="text-[#666874] text-sm">
                {employee.teamName === null ? "" : employee.teamName}
              </p>
            </div>
            <hr className="my-2 text-[#F3F4F6]" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-sm">Ажилллаж эхэлсэн огноо</p>
              <p className="text-[#666874] text-sm">
                {employee.workingDate === null ? "" : employee.workingDate}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-6 w-full flex items-start justify-between gap-10">
        <div className="w-[45%]">
          <p className="text-[#293951] font-semibold mb-4 flex items-center gap-2">
            <TfiMenuAlt />
            Гүйцэтгэх шаардлагатай зүйлс
          </p>
          <div className="flex items-center gap-4 py-2 px-5 bg-white rounded-xl shadow-xl mb-4">
            <div className="bg-[#E2B93B] bg-opacity-20 border border-[#E2B93B] text-[#E2B93B] font-black rounded-full px-3 py-0.5">
              !
            </div>
            <div>
              <p className="text-[#636466] font-semibold text-sm mb-3">
                Task name- 2 даалгавар дутуу
              </p>
              <div className="flex items-center gap-3 mb-1">
                <input
                  className="rounded-full bg-[#EBEBEB] border-[#BCBCBC] w-[12px] h-[12px]"
                  type="checkbox"
                />
                <p className="text-xs text-[#575763]">
                  Ажлын хувцас хүлээн авах
                </p>
              </div>
              <div className="flex items-center gap-3 mb-1">
                <input
                  className="rounded-full bg-[#EBEBEB] border-[#BCBCBC] w-[12px] h-[12px]"
                  type="checkbox"
                />
                <p className="text-xs text-[#575763]">
                  Хөдөлмөрийн гэрээтэй танилцах
                </p>
              </div>
            </div>
          </div>
          <div>
            <Surveys />
          </div>
        </div>
        <div className="w-[45%]">
          <p className="text-[#293951] font-semibold mb-4 flex items-center gap-2">
            <FaRegBell />
            Сүүлийн үеийн шинэчлэлүүд
          </p>
          <Surveys />
        </div>
      </div> */}
    </div>
  );
};

export default OverviewEmployee;
