import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

import translationMN from "./locales/mn/translation.json";
import translationRN from "./locales/rn/translation.json";
import translationEN from "./locales/en/translation.json";
import translationKZ from "./locales/kz/translation.json";

const resources = {
    mn: {
        translation: translationMN,
    },
    rn: {
        translation: translationRN,
    },
    en: {
        translation: translationEN,
    },
    kz: {
        translation: translationKZ,
    }
};

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'mn',
        debug: true,
        resources,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;