import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import MainInput from "../../components/input/MainInput";
import MainButton from "../../components/buttons/MainButton";
import { ArrowRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["clean"], // remove formatting button
];

const NewAssessmentGeneral = ({
  changePage,
  jobName,
  setJobName,
  jobType,
  setJobType,
  jobDepartment,
  setJobDepartment,
  salary,
  setSalary,
  introText,
  setIntroText,
  reqText,
  setReqText,
  employmentStatus,
  setEmploymentStatus,
  jobTypes,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex justify-center">
      <div>
        <p className="font-bold mt-[30px] text-[20px] pb-[30px]">
          {t("NewAssessmentGeneral.createNewJob")}
        </p>
        <div className="flex justify-between">
          <div>
            <p>{t("NewAssessmentGeneral.jobName")}</p>
            <div className="w-[350px]">
              <MainInput
                type="text"
                id="jobName"
                className={`border ${
                  jobName === "" ? "border-red-300" : "border-gray-300"
                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5`}
                value={jobName}
                onChange={(e) => {
                  setJobName(e.target.value);
                }}
              />
            </div>
          </div>
          <div>
            <p>{t("NewAssessmentGeneral.jobType")}</p>
            <div className="w-[350px]">
              <FormControl
                sx={{ m: 1, minWidth: 340 }}
                size="small"
                className="requirementSelection rounded-[10px]"
              >
                <Select
                  defaultValue={jobType}
                  sx={{ color: "black" }}
                  className={`${jobType === "" ? "border border-red-300" : ""}`}
                  onChange={(e) => {
                    setJobType(e.target.value);
                  }}
                >
                  {jobTypes &&
                    jobTypes?.map((option) => (
                      <MenuItem value={option.id} key={option.id}>
                        <Box display="flex" alignItems="center">
                          <span>
                            {i18n.language === "rn"
                              ? option.ruName
                              : i18n.language === "en"
                              ? option.enName
                              : option.name}
                          </span>
                        </Box>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="flex mt-[40px] justify-between">
          <div>
            <p>{t("NewAssessmentGeneral.department")}</p>
            <div className="w-[350px]">
              <MainInput
                type="text"
                id="location"
                className={` border ${
                  jobDepartment === "" ? "border-red-300" : "border-gray-300"
                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 w-[327px]`}
                value={jobDepartment}
                onChange={(e) => {
                  setJobDepartment(e.target.value);
                }}
              />
            </div>
          </div>
          <div>
            <p>{t("NewAssessmentGeneral.salary")}</p>
            <div className="w-[350px]">
              <MainInput
                type="number"
                id="salary"
                className={`border ${salary === "" ? "border-red-300" :"border-gray-300"} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 w-[327px]`}
                value={salary}
                onChange={(e) => {
                  setSalary(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="pt-[40px]">
          <p>{t("NewAssessmentGeneral.introText")}</p>
          <ReactQuill
            theme="snow"
            value={introText}
            onChange={setIntroText}
            modules={{ toolbar: toolbarOptions }}
          />
        </div>
        <div className="pt-[40px]">
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => {
                setEmploymentStatus(e.target.value);
              }}
              value={employmentStatus}
            >
              <FormControlLabel
                value="fullTime"
                control={<Radio />}
                label={t("NewAssessmentGeneral.fullTime")}
              />
              <FormControlLabel
                value="partTime"
                control={<Radio />}
                label={t("NewAssessmentGeneral.partTime")}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="pt-[40px]">
          <p>{t("NewAssessmentGeneral.reqText")}</p>
          <ReactQuill
            theme="snow"
            value={reqText}
            onChange={setReqText}
            modules={{ toolbar: toolbarOptions }}
          />
        </div>
        <div className="flex justify-end mt-[30px]">
          <div className="w-[217px]">
            <MainButton
              onClick={() => {
                changePage("req");
              }}
              customClass="border-solid border-2 border-black-500"
              rIcon={<ArrowRight className="ml-1" />}
              label={t("NewAssessmentGeneral.continue")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAssessmentGeneral;
