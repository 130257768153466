/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Modal,
  Slider,
  Switch,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MuiTooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import {
  ArcElement,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import html2pdf from "html2pdf.js";

import DownloadIcon from "@mui/icons-material/Download";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import { PolarArea, Radar } from "react-chartjs-2";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Level from "../../components/uncommon/level";
import MixedChart from "../../components/uncommon/mixedChart";
import NormalDistributionChart from "../../components/uncommon/normalDistributionChart";
import { getPlayerReport } from "../../services/game.service";

// const { jsPDF } = require("jspdf");

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
);
const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "white",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "25px",
};

const skillDetails = {
  agreeable: [
    {
      eName: "Cooperative",
      name: "Багаар ажиллах чадвар",
      min: "Бие даасан, ганцаараа ажиллах дуртай",
      max: "Хамтарч, Багаар ажиллах дуртай",
      txt: "Бусдыг сонирхож, анхаарал хандуулж ямарваа асуудлыг бусдын оролцоог хангаж шийдвэрлдгийг харуулсан үзүүлэлт",
    },
    {
      eName: "Assertiveness",
      name: "Өөртөө итгэлтэй, шийдэмгий байдал",
      min: "Зохицомтгой, бусдын үзэл бодолд уян хатан ханддаг",
      max: "Өөрийн үзэл бодолд тууштай, зоримог, шийдэмгий",
      txt: "Шийдэмгүй хүмүүс нь өөрийнхөө талаар өндөр үнэлэмжтэй байдаг, заримдаа бусдад буулт хийхээс татгалзах тал бий",
    },
    {
      eName: "Trusting",
      name: "Бусдад итгэх хандлага",
      min: "Сонор, хашир, үл итгэгч, сөрөгөөр ханддаг",
      max: "Бусдад итгэл хүлээлгэдэг, амархан итгэдэг, эерэгээр ханддаг",
      txt: "Энэ хандлага нь хүмүүст амархан итгэх, бусдыг ихэвчлэн үнэнч, шударга, сайхан сэтгэлтэй гэж үздэг хүмүүст илэрдэг",
    },
  ],
  conscientiousness: [
    {
      eName: "Consistence",
      name: "Тууштай байдал ",
      min: "Олон шинэ зүйлсийг турших дуртай",
      max: "Эхэлсэн зүйлээ дуусгахыг эрхэмлэдэг",
      txt: "Тогтвортой эсвэл танил орчинд ажиллах дуртай бөгөөд тогтмол арга барилаа үргэлжлүүлэхийг илүүд үздэг",
    },
    {
      eName: "Goal setting",
      name: "Зорилго төлөвлөлт",
      min: "Бүтэц болон төлөвлөлгүйгээр ажиллах талтай",
      max: "Маш сайн төлөвлөж тэрэндээ хүрхийг зорьдог",
      txt: "Үйлдэл хийхээс өмнө гарч болох сөрөг үр дагаваруудыг тооцоолж үзэхэд хангалттай хэмжээнд төвлөрч, зорилт тавьдаг",
    },
  ],
  openness: [
    {
      eName: "Curiousity",
      name: "Сониуч зан",
      min: "Тогтонги, сурсан дадлаа хадгалахыг эрхэмлэдэг",
      max: "Шинийг эрэлхийлэгч",
      txt: "Шинэ, сонирхолтой зүйлсийг туршиж үзэх сонирхолын түвшин",
    },
    {
      eName: "Risk taking",
      name: "Эрсдэл хүлээх чадвар",
      min: "Аливаад болгоомжтой, няхуур ханддаг",
      max: "Хязгаарлагдмал мэдээлэлтэйтэйгээр боломжийг ашиглах чадвартай",
      txt: "Мэдээлэл дутуу өгөгдсөн нөхцөл байдалд туршиж үзэж илүү өгөөжийн төлөө эрсдэл гаргах чадвар юм",
    },
    {
      eName: "Adaptability",
      name: "Дасан зохицох чадвар",
      min: "Тогтвортой орчин нөхцөлд тууштай ажиллах талтай",
      max: "Таамаглаагүй өөрчлөлтөд нээлттэй хандаж үүндээ",
      txt: "Төсөлөөгүй эсвэл төлөвлөөгүй өөрчлөлтөд дасан зохицох чадвар юм",
    },
  ],
  generalIntelligence: [
    {
      eName: "Inductive reasoning ",
      name: "Ерөнхийлөн дүгнэх чадвар",
      min: "Заримдаа үндэслэл ба дүгнэлтийн холбоос логикийг гаргахад хүндрэлтэй байх талтай",
      max: "Өөрийн туршлага болон ерөнхий мэдлэг дээр үндэслэн зөв дүгнэлт хийх чадвартай",
      txt: "Ерөнхийлөн дүгнэх чадвар нь тодорхой ажиглалт дээр тулгуурлан ерөнхий таамаглал дэвшүүлэх , дүгнэлт гаргахыг илтгэж байгаа юм.",
    },
    {
      eName: "Quantitative reasoning",
      name: "Тоон сэтгэлгээ",
      min: "Танд математикийн тооцоолол цээжээр хийхдээ бага зэргийн хүндрэлтэй байдаг",
      max: "Өгөгдөл дээр шинжилгээ хийж, тоотой харьцахдаа маш сайн",
      txt: "Тоон үндэслэл гэдэг нь бодит ертөнцийн асуудлыг шийдвэрлэхийн тулд математикийн ойлголт, тооцоолол хийх ур чадварыг хэлдэг юм.",
    },
    {
      eName: "Attention",
      name: "Анхаарал",
      min: "Таны анхаарал сарниж, өөр бодлуудад автдахдаа амархан байх магдлалтай",
      max: "Хийж байгаа зүйлээ дуустал төвлөрөх чадвартай",
      txt: "Анхаарал гэдэг нь том утгаараа аливаа зүйлд тогтож төвлөрөх чадвар бөгөөд анхаарал нь нөөцтэй учир маш хянамгай олон тийш цацах хэрэгтэй.",
    },
    {
      eName: "Working memory",
      name: "Ажлын санах ой",
      min: "Өөрийн бодол санаа, төлөвлөгөөгөө бичиж тэмдэглэх шаардлага үүсдэг",
      max: "Мэдээлэл тогтоох, боловсруулах хурд маш өндөр",
      txt: "Ажлын санах ой нь аливаа мэдээллийг богино хугацааны санах ойд хадгалах болон тухайн мэдээллийг удирдах чадвар юм.",
    },
    {
      eName: "Processing speed",
      name: "Боловсруулах хурд",
      min: "Мэдээлэл хүлээн аваад боловсруулахдаа цаг зарцуулах шаардлагатай байдаг",
      max: "Мэдээлэл хүлээн аваад боловсруулахад бага хугацаа зарцуулдаг",
      txt: "Боловсруулалтын хурд гэдэг нь мэдээллийг хүлээн авснаас хойш түүнийг ойлгож, хариу үйлдэл үзүүлж эхлэх хүртэлх хугацаа юм.",
    },
    {
      eName: "Deductive reasoning",
      name: "Нарийвчлан дүгнэх чадвар",
      min: "Асуудлыг бүхэллэг байдлаар танин мэдэх, ойлгохын тулд мэдээлэл цуглуулахад их цаг зарцуулдаг",
      max: "Логик, дэс дараалал, зарчимч хандаж дүгнэлтийг хийдэг",
      txt: "Нарийвчлан дүгнэх чадвар нь логик, тооцоолол, туршилт дээр тулгуурлан таамаглал, дүгнэлт хийхийг илтгэж байгаа юм.",
    },
  ],
  competencies: [
    {
      eName: "Pattern recognition",
      name: "Хэв таних",
      min: "Цоо шинэ зүйл хийх үед зүй тогтолыг нь олоход нэлээн цаг зарцуулах хэрэгтэй болдог",
      max: "Хийж буй зүйлийнхээ зүй тогтолыг олохдоо маш сайн",
      txt: "Хэв таних гэдэг нь гадна орчиноорс ирсэн мэдээллийг өөрийн санах ойтой нийцүүлэн дүгнэх ур чадвар юм",
    },
    {
      eName: "Critical thinking",
      name: "Нухацтай сэтгэлгээ",
      min: "Өөрийн өмнөх туршлага болон мэдрэмж, эмоци дээр суурилж шийдвэр гаргах талтай",
      max: "Шийдвэрийг өгөгдөл болон бодит баримтан дээр суурилж логик, дэс дараалалтай гаргадаг",
      txt: "Нухацтай сэтгэлгээ гэдэг нь олж авсан мэдээллээ ухамсартайгаар нягталж, дүгнэж, цаашид үүн дээр суурилан үйлдэл гаргах үгүйгээ шийддэг ур чадвар юм",
    },
    {
      eName: "Decision making",
      name: "Шийдвэр гаргах чадвар",
      min: "Шийдвэр гаргах нь таны хувьд хэцүү даалгавар болдог",
      max: "Хурдан, зөв шийдвэрүүд гаргаж чаддаг",
      txt: "Шийдвэр гаргах чадвар нь тухайн хүн олон сонголтуудаас хэр чанартай, хурдтай сонголт хийж байгааг илтгэх үзүүлэлт юм",
    },
    {
      eName: "Planning",
      name: "Төлөвлөх чадвар",
      min: "Туршиж үзэж, алдаа нь дээрээсээ суралцаж төлөвлөдөг",
      max: "Маш сайн төлөвлөгч",
      txt: "Төлөвлөлт гэдэг нь зорилгодоо хүрэхийн тулд шаардлагатай үйлдлүүдийн зөв дарааллыг сонгох, даалгавар бүрт өөрийн танин мэдэхүйн зохих нөөцийг хуваарилах чадвар юм.",
    },
    {
      eName: "Learning rate",
      name: "Суралцах хурд",
      min: "Алдаа нь дээрээ суралцаж бага багаар сайжирдаг",
      max: "Маш хурдан алдаж онож цааш илүү хурдан гүйцэтгэл үзүүлдэг",
      txt: "Аливаа даалгаварын мөн чанрыг ойлгож гүйцэтгэлээ сайжруулж буйг илтгэж буй үзүүлэлт юм ",
    },
  ],
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#334155",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AssessmentReport = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [playerData, setPlayerData] = useState();
  const [skillName, setSkillName] = useState(null);
  const [chartData, setChartData] = useState({
    labels: [
      //   "Суралцах хурд",
      //   "Хэв таних",
      //   "Төлөвлөх чадвар",
      //   "Нухацтай сэтгэлгээ",
    ],

    datasets: [
      //   {
      //     label: "# of Votes",
      //     data: [44, 67, 21, 77],
      //     backgroundColor: [
      //       "rgba(255, 99, 132, 0.5)",
      //       "rgba(54, 162, 235, 0.5)",
      //       "rgba(255, 206, 86, 0.5)",
      //       "rgba(75, 192, 192, 0.5)",
      //     ],
      //     borderWidth: 1,
      //   },
    ],
  });
  const [chartIndex, setChartIndex] = useState(null);
  const [radarData, setRadarData] = useState({
    labels: [
      t("NewAssessment.inductiveName"),
      t("NewAssessment.deductiveName"),
      t("NewAssessment.quantitativeName"),
      t("NewAssessment.attentionName"),
      t("NewAssessment.workingMemoryName"),
      t("NewAssessment.processingSpeedName"),
      t("NewAssessment.patternRecognitionName"),
      t("NewAssessment.criticalThinkingName"),
      t("NewAssessment.decisionMakingName"),
      t("NewAssessment.planningName"),
      t("NewAssessment.learningSpeedName"),
      t("NewAssessment.curiosityName"),
      t("NewAssessment.riskTakingName"),
      t("NewAssessment.adaptability"),
      t("NewAssessment.consistence"),
      t("NewAssessment.goalSetting"),
      t("NewAssessment.cooperative"),
      t("NewAssessment.trusting"),
      t("NewAssessment.assertiveness"),
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  });
  const [skillLvlDetails, setSkillLvlDetails] = useState([]);
  const [top3Skills, setTop3Skills] = useState([]);
  const [bot3Skills, setBot3Skills] = useState([]);
  const [assessCompany, setAssessCompany] = useState(null);
  const [mpiPercentage, setMpiPercentage] = useState(0);
  const [ciPercentage, setCiPercentage] = useState(0);
  const [iqPercentage, setIqPercentage] = useState(0);
  const [personalityPercentage, setPersonalityPercentage] = useState(0);
  const [iLevel, setILevel] = useState(null);
  const [levelRadar, setLevelRadar] = useState(false);

  useEffect(() => {
    setShowModal(true);
    getPlayerReport(id)
      .then((res) => {
        console.log("1273812738123718923718")

        const { data } = res;
        setPlayerData(data);

        setRadarData({
          labels: [
            [
              t("NewAssessment.inductiveName"),
              data?.generalIntelligence?.inductiveReasoning
                .toString()
                .substring(0, 4),
            ],
            [
              t("NewAssessment.deductiveName"),
              data?.generalIntelligence?.deductiveReasoning
                .toString()
                .substring(0, 4),
            ],
            [
              t("NewAssessment.quantitativeName"),
              data?.generalIntelligence?.quantitativeReasoing
                .toString()
                .substring(0, 4),
            ],
            [
              t("NewAssessment.attentionName"),
              data?.generalIntelligence?.attention.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.workingMemoryName"),
              data?.generalIntelligence?.workingMemory
                .toString()
                .substring(0, 4),
            ],
            [
              t("NewAssessment.processingSpeedName"),
              data?.generalIntelligence?.processingSpeed
                .toString()
                .substring(0, 4),
            ],
            [
              t("NewAssessment.patternRecognitionName"),
              data?.competencies?.patternRecognition.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.criticalThinkingName"),
              data?.competencies?.criticalThinking.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.decisionMakingName"),
              data?.competencies?.decisionMaking.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.planningName"),
              data?.competencies?.planning.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.learningSpeedName"),
              data?.competencies?.learningRate.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.curiosityName"),
              data?.openness?.curiosity.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.riskTakingName"),
              data?.openness?.riskTaking.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.adaptability"),
              data?.openness?.adaptability.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.consistence"),
              data?.conscientiousness?.consistence.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.goalSetting"),
              data?.conscientiousness?.goalSetting.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.cooperative"),
              data?.agreeable?.cooperative.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.trusting"),
              data?.agreeable?.trusting.toString().substring(0, 4),
            ],
            [
              t("NewAssessment.assertiveness"),
              data?.agreeable?.assertiveness.toString().substring(0, 4),
            ],
          ],

          datasets: [
            {
              label: t("Report.generalIntelligencies"),
              data: [
                data?.generalIntelligence?.inductiveReasoning,
                data?.generalIntelligence?.deductiveReasoning,
                data?.generalIntelligence?.quantitativeReasoing,
                data?.generalIntelligence?.attention,
                data?.generalIntelligence?.workingMemory,
                data?.generalIntelligence?.processingSpeed,
                data?.competencies?.patternRecognition,
              ],
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1,
            },
            {
              label: t("Report.skills"),
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                data?.competencies?.patternRecognition,
                data?.competencies?.criticalThinking,
                data?.competencies?.decisionMaking,
                data?.competencies?.planning,
                data?.competencies?.learningRate,
                data?.openness?.curiosity,
              ],
              backgroundColor: "rgba(255,204,0,0.2)",
              borderColor: "rgba(255,204,0,1)",
              borderWidth: 1,
            },
            {
              label: t("Report.readinessForChange"),
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                data?.openness?.curiosity,
                data?.openness?.riskTaking,
                data?.openness?.adaptability,
              ],
              backgroundColor: "rgba(165,147,179,0.2)",
              borderColor: "rgba(165,147,179,1)",
              borderWidth: 1,
            },
            {
              label: t("Report.responsibility"),
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                data?.openness?.adaptability,
                data?.conscientiousness?.consistence,
                data?.conscientiousness?.goalSetting,
              ],
              backgroundColor: "rgba(157,186,137,0.2)",
              borderColor: "rgba(157,186,137,1)",
              borderWidth: 1,
            },
            {
              label: t("Report.consistency"),
              data: [
                data?.generalIntelligence?.inductiveReasoning,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                data?.conscientiousness?.goalSetting,
                data?.agreeable?.cooperative,
                data?.agreeable?.trusting,
                data?.agreeable?.assertiveness,
              ],
              backgroundColor: "rgba(136,158,184,0.2)",
              borderColor: "rgba(136,158,184,1)",
              borderWidth: 1,
            },
          ],
        });

        setShowModal(false);
        setSkillLvlDetails(data?.skillDetails);
        setAssessCompany(data?.assessmentCompany);
        setTop3Skills(data?.top3Skills);
        setBot3Skills(data?.bottom3Skills);
        setMpiPercentage(data?.mpiPercent);
        setIqPercentage(data?.iqPercentage);
        setPersonalityPercentage(data?.personalityPercentage);
        setILevel(data?.ilevel);
        setCiPercentage(data?.ciPercent);

        console.log("data?.iLevel = ", data?.ilevel);
      })
      .catch((err) => {
        // toast.error(t("toast.playerNotFound"), {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
        setShowModal(false);
      });
  }, []);

  const iv2PDF = (e) => {
    const but = e.target;
    but.style.display = "none";

    let sw = window.document.getElementsByClassName("switchbtn")[0];
    sw.style.display = "none";
    // div2PDFrep
    if (skillLvlDetails === null || skillLvlDetails.length === 0) {
      let remPdf = window.document.getElementsByClassName("remPdf")[0];
      let remPdf1 = window.document.getElementsByClassName("remPdf")[1];
      let dis =
        window.document.getElementsByClassName("remPdf")[0].style.display;

      remPdf.style.display = "none";
      remPdf1.style.display = "none";
      let input = window.document.getElementsByClassName("div2PDFrep")[0];

      html2canvas(input).then((canvas) => {
        var imgWidth = 210;
        var pageHeight = 300;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF("p", "mm", "a4");
        var position = 0;
        const img = canvas.toDataURL("image/jpeg");

        doc.addImage(img, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(img, "JPEG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save(`${playerData?.assPlayer?.player?.email}.pdf`);
        but.style.display = "block";
        remPdf.style.display = dis;
        remPdf1.style.display = dis;
      });
    } else {
      let input = window.document.getElementsByClassName("reportDownload")[0];
      let pdfH = window.document.getElementsByClassName("pdfHeight");
      let pdfRHeight = window.document.getElementsByClassName("pdfRHeight");

      // console.log("pdfH = ", pdfH.length);
      // for (let i = 0; i < pdfH.length; i++) {
      //   pdfH[i].style.display = "block";
      // }

      // for(let i = 0; i < pdfRHeight.length; i++) {
      //   pdfRHeight[i].style.display = "none";
      // }

      html2canvas(input).then((canvas) => {
        var imgWidth = 210;
        var pageHeight = 300;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF("p", "mm", "a4");
        var position = 0;
        const img = canvas.toDataURL("image/jpeg");

        doc.addImage(img, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(img, "JPEG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save(`${playerData?.assPlayer?.player?.email}.pdf`);
        but.style.display = "block";
        // remPdf.style.display = dis;
        // remPdf1.style.display = dis;
      });

      for (let i = 0; i < pdfH.length; i++) {
        pdfH[i].style.display = "none";
      }

      for (let i = 0; i < pdfRHeight.length; i++) {
        pdfRHeight[i].style.display = "block";
      }
    }

    sw.style.display = "block";
  };

  const handleDownload = () => {
    if (skillLvlDetails === null || skillLvlDetails.length === 0) {
      const element = window.document.getElementsByClassName("div2PDFrep")[0];
      html2pdf()
        .from(element)
        .save(`${playerData?.assPlayer?.player?.email}.pdf`);
    }
    {
      const element =
        window.document.getElementsByClassName("reportDownload")[0];
      html2pdf()
        .from(element)
        .save(`${playerData?.assPlayer?.player?.email}.pdf`);
    }
  };

  const selectSkill = (skill) => {
    setChartIndex(null);
    console.log("skill = ", skill);
    console.log("skill = ", playerData[skill]);

    if (skill === "openness") {
      setSkillName({
        name: "Өөрчлөлтөнд бэлэн байдал",
        eName: "openness",
        data: skillDetails[skill],
        txt: playerData[skill].txt,
      });

      setChartData({
        labels: ["Сониуч зан", "Эрсдэл хүлээх чадвар", "Дасан зохицох чадвар"],
        datasets: [
          {
            label: "# of Votes",
            data: [
              playerData[skill].curiosity,
              playerData[skill].riskTaking,
              playerData[skill].adaptability,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(54, 162, 235, 0.5)",
              "rgba(255, 206, 86, 0.5)",
            ],
            borderWidth: 1,
          },
        ],
      });
    } else if (skill === "generalIntelligence") {
      setSkillName({
        name: "Оюун ухааны үзүүлэлтүүд",
        eName: "generalIntelligence",
        data: skillDetails[skill],
        txt: playerData[skill].txt,
      });

      setChartData({
        labels: [
          "Ерөнхийлөн дүгнэх чадвар",
          ["Тоон ", "сэтгэлгээ"],
          "Анхаарал",
          "Ажлын ой",
          "Боловсруулах хурд",
          "Нарийвчлан дүгнэх чадвар",
        ],
        datasets: [
          {
            label: "# of Votes",
            data: [
              playerData[skill].inductiveReasoning,
              playerData[skill].quantitativeReasoing,
              playerData[skill].attention,
              playerData[skill].workingMemory,
              playerData[skill].processingSpeed,
              playerData[skill].deductiveReasoning,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(54, 162, 235, 0.5)",
              "rgba(255, 206, 86, 0.5)",
              "rgba(75, 192, 192, 0.5)",
            ],
            borderWidth: 1,
          },
        ],
      });
    } else if (skill === "conscientiousness") {
      setSkillName({
        name: "Хариуцлагатай байдал",
        eName: "conscientiousness",
        data: skillDetails[skill],
        txt: playerData[skill].txt,
      });

      setChartData({
        labels: ["Тууштай байдал", "Зорилго төлөвлөлт"],
        datasets: [
          {
            label: "# of Votes",
            data: [
              playerData[skill].consistence,
              playerData[skill].goalSetting,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(54, 162, 235, 0.5)",
            ],
            borderWidth: 1,
          },
        ],
      });
    } else if (skill === "competencies") {
      setSkillName({
        name: "Ур чадварууд",
        eName: "competencies",
        data: skillDetails[skill],
        txt: playerData[skill].txt,
      });

      setChartData({
        labels: [
          "Хэв таних",
          "Шүүмжлэлт сэтгэлгээ",
          "Шийдвэр гаргах чадвар",
          "Төлөвлөх чадвар",
          "Суралцах хурд",
        ],
        datasets: [
          {
            label: "# of Votes",
            data: [
              playerData[skill].patternRecognition,
              playerData[skill].criticalThinking,
              playerData[skill].decisionMaking,
              playerData[skill].planning,
              playerData[skill].learningRate,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(54, 162, 235, 0.5)",
              "rgba(255, 206, 86, 0.5)",
              "rgba(75, 192, 192, 0.5)",
            ],
            borderWidth: 1,
          },
        ],
      });
    } else if (skill === "agreeable") {
      setSkillName({
        name: "Нийцэрхэг байдал",
        eName: "agreeable",
        data: skillDetails[skill],
        txt: playerData[skill].txt,
      });
      setChartData({
        labels: [
          ["Багаар ажиллах", "чадвар"],
          ["Өөртөө итгэлтэй,", "шийдэмгий ", "байдал"],
          ["Бусдад итгэх,", "хандлага"],
        ],
        datasets: [
          {
            label: "# of Votes",
            data: [
              playerData[skill].cooperative,
              playerData[skill].assertiveness,
              playerData[skill].trusting,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(54, 162, 235, 0.5)",
              "rgba(255, 206, 86, 0.5)",
              "rgba(75, 192, 192, 0.5)",
            ],
            borderWidth: 1,
          },
        ],
      });
    }
  };

  const selectIndex = (ind) => {
    if (ind && ind[0]) {
      setChartIndex(ind[0].index);
    }
  };

  const generateLevelText = (name) => {
    if (name === "inductive") {
      switch (iLevel["inductiveLvl"]) {
        case 1:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд өөрийн ажигласан туршлагаас үндэслэн
              үнэлгээ, дүгнэлт хийх чадвар <b>сул</b> хөгжсөн байна. Иймд
              байгууллагын соёлд дасан зохицох, ажлын процесст суралцах зэрэгт
              чиглүүлэг, менторинг шаардлагатай.{" "}
            </p>
          );
        case 2:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд өөрийн ажигласан туршлагаас үндэслэн
              үнэлгээ, дүгнэлтийг хийх, байгууллагын тогтсон соёл, хэв загварыг
              танин мэдэх чадвар <b>дундаж</b> түвшинд хөгжсөн байна. Аливаа
              нөхцөл байдалд түгээмэл гарч буй шийдвэр, давтагдаж буй нийтлэг
              хариу үйлдэл зэргийг бие даан ойлгох, дасан зохицоход хугацаа өгөх
              шаардлагатай нь харагдаж байна.{" "}
            </p>
          );
        case 3:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд өөрийн ажигласан туршлага дээр
              үндэслэн үнэлгээ, дүгнэлтийг гаргах, байгууллагын тогтсон соёл,
              хэв загварыг танин мэдэх чадвар <b>өндөр</b> хөгжсөн байна. Аливаа
              нөхцөл байдалд түгээмэл гарч буй шийдвэр, давтагдаж буй нийтлэг
              хариу үйлдэл зэрэгт тулгуурлан хөрвөх, дасан зохицох чадвартай нь
              харагдаж байна.{" "}
            </p>
          );
        default:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд өөрийн ажигласан туршлага дээр
              үндэслэн үнэлгээ, дүгнэлтийг гаргах, байгууллагын тогтсон соёл,
              хэв загварыг танин мэдэх чадвар <b>өндөр</b> хөгжсөн байна. Аливаа
              нөхцөл байдалд түгээмэл гарч буй шийдвэр, давтагдаж буй нийтлэг
              хариу үйлдэл зэрэгт тулгуурлан хөрвөх, дасан зохицох чадвартай нь
              харагдаж байна.{" "}
            </p>
          );
      }
    } else if (name === "deductive") {
      switch (iLevel["deductiveLvl"]) {
        case 1:
          return (
            <p>
              {" "}
              Энэхүү ажил горилогчийн хувьд аливаа нөхцөл байдал, учир
              шалтгааныг онол, дүрэм, журам, түгээмэл тогтсон хандлагаас хэрхэн
              уялдаатай байгааг тайлбарлах, ойлгох чадвар <b>сул</b> хөгжсөн
              байна. Иймд нийгэмд буй түгээмэл ойлголт, онол, тогтсон ойлголт
              зэргээс хэрэгжилтийн явцад ялгаатай нөхцөл байдал үүсэх зэрэгт
              дасан зохицоход туслах чиглүүлэг шаардлагатай.{" "}
            </p>
          );
        case 2:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд аливаа нөхцөл байдал, учир
              шалтгааныг онол, дүрэм, журам, түгээмэл тогтсон хандлагаас хэрхэн
              уялдаатай байгааг тайлбарлах, ойлгох чадвар <b>дундаж</b> түвшинд
              хөгжсөн байна. Тухайлсан дүрэм, заавраас бодит нөхцөл байдалд
              зөрүүтэй эсвэл алдаатай байх үед тэрхүү алдаа, асуудлыг олох,
              аливаа нийтэд хүлээн зөвшөөрөгдсөн онол, дүрэм нь нэгжид хэрхэн
              задарч хүрч байгааг ойлгох, тайлбарлах чадвартай нь харагдаж
              байна.
            </p>
          );
        case 3:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд аливаа нөхцөл байдал, учир
              шалтгааныг дүрэм, журам, онол, түгээмэл тогтсон хандлагад үндэслэн
              тайлбарлах, ойлгох чадвар <b>өндөр</b> хөгжсөн байна. Тухайлсан
              дүрэм, заавраас бодит нөхцөл байдалд зөрүүтэй эсвэл алдаатай байх
              үед тэрхүү алдаа, асуудлыг олох чадвартай нь харагдаж байна.
              Зорилтот хэрэглэгчийн бүлэгт зориулсан маркетингийн төлөвлөлт
              хийх, хөрөнгө оруулалтаас хамгийн <b>өндөр</b> ашигтай байх
              хувилбарыг боловсруулах, хэрэглэгчтэй харилцах хамгийн сайн арга
              зүйг гаргах зэрэг нийтлэг онол, тогтсон хандлагад тулгуурласан
              шийдвэр гаргалтыг хийх чадвар сайн хөгжсөн байна.
            </p>
          );
        default:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд аливаа нөхцөл байдал, учир
              шалтгааныг дүрэм, журам, онол, түгээмэл тогтсон хандлагад үндэслэн
              тайлбарлах, ойлгох чадвар <b>өндөр</b> хөгжсөн байна. Тухайлсан
              дүрэм, заавраас бодит нөхцөл байдалд зөрүүтэй эсвэл алдаатай байх
              үед тэрхүү алдаа, асуудлыг олох чадвартай нь харагдаж байна.
              Зорилтот хэрэглэгчийн бүлэгт зориулсан маркетингийн төлөвлөлт
              хийх, хөрөнгө оруулалтаас хамгийн <b>өндөр</b> ашигтай байх
              хувилбарыг боловсруулах, хэрэглэгчтэй харилцах хамгийн сайн арга
              зүйг гаргах зэрэг нийтлэг онол, тогтсон хандлагад тулгуурласан
              шийдвэр гаргалтыг хийх чадвар сайн хөгжсөн байна.
            </p>
          );
      }
    } else if (name === "workingMemory") {
      switch (iLevel["workingMemoryLvl"]) {
        case 1:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд ажлын ойн багтаамж доогуур түвшинд
              байна. Өөрөөр хэлбэл, олон хүнтэй харилцах, ажил давхацсанаас
              үүдэн ажлыг зэрэгцүүлэн гүйцэтгэх зэрэг ажлуудад гүйцэтгэл{" "}
              <b>доогуур</b> байх магадлал өндөр байна. Иймд тайван,
              төлөвлөгөөний дагуух ажлыг гүйцэтгэхэд томилох нь ажилтны сэтгэл
              ханамж, тогтвортой ажиллах магадлалыг нэмэгдүүлнэ.
            </p>
          );
        case 2:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд ажлын ойн багтаамж <b>дундаж</b>{" "}
              түвшинд буюу олон зүйлсийг зэрэг хийх чадвартай нь харагдаж байна.
              Ажлын ачаалал 6 сар болон түүнээс хугацаанд их байх нь ажлаас
              халшрах, өөртөө итгэлгүй болох зэрэг хам шинжийг нэмэгдүүлэх
              боломжтой байна.
            </p>
          );
        case 3:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд ажлын ойн багтаамж <b>өндөр</b> буюу
              олон зүйлсийг зэрэг хийх, түүндээ стресстэхгүй байх боломж өндөр
              байна.
            </p>
          );
        default:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд ажлын ойн багтаамж <b>дундаж</b>{" "}
              түвшинд буюу олон зүйлсийг зэрэг хийх чадвартай нь харагдаж байна.
              Ажлын ачаалал 6 сар болон түүнээс хугацаанд их байх нь ажлаас
              халшрах, өөртөө итгэлгүй болох зэрэг хам шинжийг нэмэгдүүлэх
              боломжтой байна.
            </p>
          );
      }
    } else if (name === "attention") {
      switch (iLevel["attentionLvl"]) {
        case 1:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд зориудаар анхаарлаа төвлөрүүлэх
              чадвар <b>сул</b> хөгжсөн байна. Аливаа ажлын даалгавар нь удаан
              хугацаанд сууж үргэлжилдэг, төвлөрөх хэрэгцээ шаардлага өндөр ажил
              тохиромжгүй бөгөөд ийм нөхцөлд бүтээмж багатай байх магадлалтай.
              Анхаарлыг богино хугацаагаар, өөр зүйлсэд олон давтамжтайгаар
              хийгддэг ажлуудад томилоход тохиромжтой.{" "}
            </p>
          );
        case 2:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд зориудаар анхаарлаа төвлөрүүлэх
              чадвар <b>дундаж</b> түвшинд хөгжсөн байна. Аливаа нөхцөл байдлын
              учир шалтгааныг ойлгосны үндсэнд гүйцэтгэлээ төлөвлөх чадвар
              дундаж түвшинд буюу зарим тохиолдолд нөхцөл байдлаар удирдуулан
              үйлдэл хийх магадлалтай.
            </p>
          );
        case 3:
          return (
            <p>
              {" "}
              Энэхүү ажил горилогчийн хувьд зориудаар анхаарлаа төвлөрүүлэх
              чадвар <b>сайн</b> хөгжсөнөөс гадна аливаа үүрэг даалгаврын
              гүйцэтгэлийг бүрэн хангахын тулд өөрийгөө дайчилж, идэвхжүүлдэг нь
              харагдаж байна. Аливаа ажлыг тогтвортойгоор, тууштай хандан
              гүйцэтгэх чадвар сайтай.
            </p>
          );
        default:
          return (
            <p>
              {" "}
              Энэхүү ажил горилогчийн хувьд зориудаар анхаарлаа төвлөрүүлэх
              чадвар <b>сайн</b> хөгжсөнөөс гадна аливаа үүрэг даалгаврын
              гүйцэтгэлийг бүрэн хангахын тулд өөрийгөө дайчилж, идэвхжүүлдэг нь
              харагдаж байна. Аливаа ажлыг тогтвортойгоор, тууштай хандан
              гүйцэтгэх чадвар сайтай.
            </p>
          );
      }
    } else if (name === "processingSpeed") {
      switch (iLevel["processingSpeedLvl"]) {
        case 1:
          return (
            <p>
              Энэхүү ажил горилогчийн аливаа мэдээллийг боловсруулах хурд нь{" "}
              <b>сул</b> хөгжсөн бөгөөд үүнээс үүдэн бие даан шийдвэр гаргах,
              даалгаврыг биелүүлэх, зааварчилгааны дагуу ажиллах зэрэг
              чадваруудад сөргөөр нөлөөлсөн байх магадлалтай. Өөрөөр хэлбэл,
              аливаа шинэ зүйлд дасан зохицох, дадал ба зан үйлийг төлөвшүүлэхэд
              багагүй хугацаа шаардагдаж болзошгүй.{" "}
            </p>
          );
        case 2:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд аливаа мэдээллийг боловсруулах хурд
              нь <b>дундаж</b> түвшинд хөгжсөн бөгөөд өмнө нь хийж байсан
              туршлагад үндэслэн, эсвэл хөнгөн даалгавруудыг гүйцэтгэх
              чадвартай. Аливаа шинэ мэдээллийг боловсруулсан туршлагатай, эсвэл
              суралцаж үзсэн зүйлдээ хурдан дасан зохицож шийдвэрүүдийг бие даан
              гаргах чадвартай байна.{" "}
            </p>
          );
        case 3:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд аливаа мэдээллийг боловсруулах хурд{" "}
              <b>өндөр</b> түвшинд хөгжсөн бөгөөд өмнө нь хийж байсан туршлагад
              үндэслэсэн, эсвэл түгээмэл, хөнгөн даалгавруудыг хурдтайгаар
              гүйцэтгэх чадвар маш сайтайг илтгэж байна. Өмнөх туршлагад
              үндэслэн мэдээллийг автоматаар боловсруулахдаа маш сайн бөгөөд
              нөхцөл байдалд тохируулан бодох, суралцах чадвартай нь харагдаж
              байна. Аливаа үүрэг, даалгаврыг ойлгосныхоо дараа, өөриймсгөөр
              хийх хандлагатай.{" "}
            </p>
          );
        default:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд аливаа мэдээллийг боловсруулах хурд{" "}
              <b>өндөр</b> түвшинд хөгжсөн бөгөөд өмнө нь хийж байсан туршлагад
              үндэслэсэн, эсвэл түгээмэл, хөнгөн даалгавруудыг хурдтайгаар
              гүйцэтгэх чадвар маш сайтайг илтгэж байна. Өмнөх туршлагад
              үндэслэн мэдээллийг автоматаар боловсруулахдаа маш сайн бөгөөд
              нөхцөл байдалд тохируулан бодох, суралцах чадвартай нь харагдаж
              байна. Аливаа үүрэг, даалгаврыг ойлгосныхоо дараа, өөриймсгөөр
              хийх хандлагатай.{" "}
            </p>
          );
      }
    } else if (name === "quantitativeReasoing") {
      switch (iLevel["quantitativeReasoingLvl"]) {
        case 1:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд тоон өгөгдөлд дүн шинжилгээ хийх
              чадвар <b>сул</b> хөгжсөн байна. Энэ нь тоон сэтгэлгээг хөгжүүлэх
              шаардлагатай амьдралын туршлага бага байсантай холбоотой байж
              болох бөгөөд ерөнхийлөн дүгнэх болон нарийвчлан дүгнэх чадваруудын
              оноо, түвшинг харьцуулан харна уу.
            </p>
          );
        case 2:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд тоон өгөгдөлд дүн шинжилгээ хийх
              чадвар <b>дундаж</b> түвшинд хөгжсөн байна. Энэ нь тоон сэтгэлгээг
              хөгжүүлэх шаардлагатай амьдралын туршлага бага байсантай холбоотой
              байж болох бөгөөд ерөнхийлөн дүгнэх болон нарийвчлан дүгнэх
              чадваруудын оноо, түвшинг харьцуулан харна уу.
            </p>
          );
        case 3:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд тоон өгөгдөлд дүн шинжилгээ хийх
              чадвар <b>өндөр</b> хөгжсөн байгаа бөгөөд тоон сэтгэлгээг
              хөгжүүлэхэд шаардлагатай амьдралын туршлага их хуримтлуулсан нь
              харагдаж байна. Энэ нь бусад чадваруудад дам сайнаар нөлөөлдөг
              бөгөөд суралцах чадвар өндөр байхад сайнаар нөлөөлөх боломжтой
              байдаг.{" "}
            </p>
          );
        default:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд тоон өгөгдөлд дүн шинжилгээ хийх
              чадвар <b>өндөр</b> хөгжсөн байгаа бөгөөд тоон сэтгэлгээг
              хөгжүүлэхэд шаардлагатай амьдралын туршлага их хуримтлуулсан нь
              харагдаж байна. Энэ нь бусад чадваруудад дам сайнаар нөлөөлдөг
              бөгөөд суралцах чадвар өндөр байхад сайнаар нөлөөлөх боломжтой
              байдаг.{" "}
            </p>
          );
      }
    } else if (name === "criticalThinking") {
      switch (iLevel["criticalThinkingLvl"]) {
        case 1:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд нухацтай сэтгэлгээний <b>эхний</b>{" "}
              түвшин буюу аливаа мэдээлэлд задлан шинжлэх чадвар сайн хөгжсөн
              байна. Аливаа мэдээллийн холбоо хамаарал бүхий өгөгдлийг ангилах
              чадвартай байна. Түүнчлэн олон төрлийн ялгаатай мэдээллийн ижил
              шинжийг холбох эсвэл ялгаатай талуудыг олж харах чадвартай байна.{" "}
            </p>
          );
        case 2:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд аливаа мэдээллийн шалтгаан ба үр
              дагаврын хамаарлыг олж, боломжит үр дүнгүүдийг хийсвэрлэн нэгтгэн
              харах чадвартай буюу нухацтай сэтгэлгээ <b>дундаж</b> түвшинд
              хөгжсөн байна. Мөн гарч болох үр дагавруудыг, бодит үйлдлүүдийн
              онцлог илэрхийллийг тайлбарлах чадвар сайн хөгжсөн байна.
            </p>
          );
        case 3:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд үндэслэл бүхий үнэлгээ, дүгнэлт
              гаргах чадвар сайн хөгжсөн бөгөөд асуудлыг шалтгаан-үр дагавар,
              бодитой байдалд тулгуурлан шүүмжлэх, шийдвэрлэх чадвар{" "}
              <b>өндөр</b> түвшинд хөгжсөн байна. Мэдээллийг утга учир болон
              чухалчлах байдлаар эрэмбэлэн үнэлэх, бүтээлч байдлаар шүүмжлэх
              чадвартай буюу нөхцөл байдалд үндэслэл бүхий нухацтай хариу үйлдэл
              үзүүлэх, үнэ цэнтэй эсрэг нотолгоо гаргаж ирэх чадвартай.{" "}
            </p>
          );
        default:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд үндэслэл бүхий үнэлгээ, дүгнэлт
              гаргах чадвар сайн хөгжсөн бөгөөд асуудлыг шалтгаан-үр дагавар,
              бодитой байдалд тулгуурлан шүүмжлэх, шийдвэрлэх чадвар{" "}
              <b>өндөр</b> түвшинд хөгжсөн байна. Мэдээллийг утга учир болон
              чухалчлах байдлаар эрэмбэлэн үнэлэх, бүтээлч байдлаар шүүмжлэх
              чадвартай буюу нөхцөл байдалд үндэслэл бүхий нухацтай хариу үйлдэл
              үзүүлэх, үнэ цэнтэй эсрэг нотолгоо гаргаж ирэх чадвартай.{" "}
            </p>
          );
      }
    } else if (name === "decisionMaking") {
      switch (iLevel["decisionMakingLvl"]) {
        case 1:
          return (
            <p>
              {" "}
              Энэхүү ажил горилогчийн хувьд өдөр тутмын үйл ажиллагааг төлөвлөж
              шийдвэр гаргах чадвар хөгжсөн буюу бүхэллэг байдлаар авч үзвэл
              шийдвэр гаргах чадвар <b>сул</b> хөгжсөн байна. Эрсдэлтэй шийдвэр
              гаргах дургүй бөгөөд бүх зүйлс тодорхой үед шийдвэр гаргах дуртай,
              өмнөх буруу гаргасан шийдвэрүүдээс үүдэн шийдвэр гаргахаас зугтах,
              өөртөө итгэлгүй байхад нөлөөлсөн байх боломжтой.{" "}
            </p>
          );
        case 2:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд өдөр тутмын үйл ажиллагаа болон
              түүнээс үүдэх дунд хугацааны үр дагавар болоод эрсдэлийг тооцоолох
              чадвартай буюу бүхэллэг байдлаар авч үзвэл <b>дундаж</b> түвшинд
              буюу тактикийн шийдвэр гаргах чадвартай нь ажиглагдаж байна. Ийм
              түвшний хүмүүс цөөн тооны хүн удирдах, дунд түвшний менежментийг
              хариуцах чадвартай байдаг.
            </p>
          );
        case 3:
          return (
            <p>
              {" "}
              Энэхүү ажил горилогчийн хувьд эрсдэл бүхий шинэ шийдвэрийг
              чухалчилдаг бөгөөд алсын хараа, урт хугацаанд оновчтой байх, үр
              дүнтэй байх сонголтод тулгуурлан шийдвэрүүдийг гаргадаг,
              стратегийн шийдвэр гаргагч буюу оновчтой шийдвэр гаргах чадвар{" "}
              <b>өндөр</b> хөгжсөн байна. Шийдвэр гаргахдаа ойрын асуудал,
              зовлонг үл хайхран шийдвэртээ тууштай байх чадвартай.
            </p>
          );
        default:
          return (
            <p>
              {" "}
              Энэхүү ажил горилогчийн хувьд эрсдэл бүхий шинэ шийдвэрийг
              чухалчилдаг бөгөөд алсын хараа, урт хугацаанд оновчтой байх, үр
              дүнтэй байх сонголтод тулгуурлан шийдвэрүүдийг гаргадаг,
              стратегийн шийдвэр гаргагч буюу оновчтой шийдвэр гаргах чадвар{" "}
              <b>өндөр</b> хөгжсөн байна. Шийдвэр гаргахдаа ойрын асуудал,
              зовлонг үл хайхран шийдвэртээ тууштай байх чадвартай.
            </p>
          );
      }
    } else if (name === "planning") {
      switch (iLevel["planningLvl"]) {
        case 1:
          return (
            <p>
              {" "}
              Энэхүү ажил горилогчийн хувьд төлөвлөх чадвар <b>сул</b> хөгжсөн
              байгаа бөгөөд бие даан шийдвэр гаргах, тухайлсан ажлын гүйцэтгэх
              хугацааг таамаглах, хийж буй үйлдлээс гарах боломжит үр
              дагавруудыг тооцоолох зэрэгт бусдын дэмжлэг, чиглүүлэг
              шаардлагатай. Түүнчлэн амархан сатаарах, бүтээмжтэй байх хугацаа
              богино байх зэрэг эрсдэлтэй магадлалуудыг хянаж үзвэл зохистой.
            </p>
          );
        case 2:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд төлөвлөх чадвар <b>дундаж</b>{" "}
              түвшинд хөгжсөн байгаа бөгөөд аливаа төсөл, ажлын эцсийн зорилго,
              санааг ойлгох, түүнд оюун санааны хувьд дасан зохицоход тодорхой
              хугацаа шаардлагатай. Аливаа ажлыг чухалчлах байдлын зэргээр
              эрэмбэлэх, төлөвлөөгүй нөхцөл, асуудал тулгарах зэрэгт дэмжлэг
              шаардлагатай байх магадлалтай.
            </p>
          );
        case 3:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд төлөвлөх чадвар <b>өндөр</b> хөгжсөн
              байгаа бөгөөд аливаа зорилгод хүрэхийн тулд хийгдэх алхмуудаа
              төлөвлөх, алхмуудыг өөрсдийн чадвар, хугацаа зэрэг нөөцөд
              тулгуурлан хуваах, хуваарилах чадвар сайтай нь харагдаж байна.
              Ачаалал ихтэй, харилцааны болон ажлын кросс-координац ихтэй
              нөхцөлд тайван, эрч хүчтэйгээр ажиллах чадвартай.
            </p>
          );
        default:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд төлөвлөх чадвар <b>өндөр</b> хөгжсөн
              байгаа бөгөөд аливаа зорилгод хүрэхийн тулд хийгдэх алхмуудаа
              төлөвлөх, алхмуудыг өөрсдийн чадвар, хугацаа зэрэг нөөцөд
              тулгуурлан хуваах, хуваарилах чадвар сайтай нь харагдаж байна.
              Ачаалал ихтэй, харилцааны болон ажлын кросс-координац ихтэй
              нөхцөлд тайван, эрч хүчтэйгээр ажиллах чадвартай.
            </p>
          );
      }
    } else if (name === "learningRate") {
      switch (iLevel["learningRateLvl"]) {
        case 1:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд шинэ мэдээлэл, туршлагыг харьцангуй
              удаан хэмнэлээр хүлээн авч боловсруулах онцлогтой буюу суралцах
              хурд <b>доогуур</b> түвшинд хөгжсөн байна. Суралцах хурд удаан
              хэдий ч тухайн хүний оюун ухааны үзүүлэлтээс хамааран тогтоолт
              сайтай, дүгнэлт гаргах чадвар сайн зэрэг онцлогууд байдаг тул
              нэгдсэн дүгнэлтүүдийг харахыг зөвлөж байна.
            </p>
          );
        case 2:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд зохих хугацаанд шаардлагатай
              мэдээлэл, мэдлэгийг ойлгох, хэрэгжүүлэх, суралцах чадвартай бөгөөд
              суралцах хурд <b>дундаж</b> түвшинд хөгжсөн байна. Гэсэн хэдий ч
              ойлголт, сурсан чадвараа бататгах, дадлагажихын тулд давтлага,
              сургуулилт шаардлагатай бөгөөд дундаж түвшний онцлог нь энэ юм.
            </p>
          );
        case 3:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд мэдээллийг түргэн шуурхай
              боловсруулж, ойлголт, ухагдахуунуудыг хурдан холбож, тухайн
              ойлголтуудаа үр дүнтэйгээр ашиглах чадвартай буюу суралцах{" "}
              <b>өндөр</b> хурдтай байна. Суралцах хурд өндөр түвшинд хөгжсөн
              хүмүүсийн хувьд мэдлэг, ур чадварыг нэгдмэл, цогц байдлаар ашиглах
              чадвартай бөгөөд академик, нарийн мэргэжлийн түвшинд ажиллахад
              илүү тохиромжтой.
            </p>
          );
        default:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд мэдээллийг түргэн шуурхай
              боловсруулж, ойлголт, ухагдахуунуудыг хурдан холбож, тухайн
              ойлголтуудаа үр дүнтэйгээр ашиглах чадвартай буюу суралцах{" "}
              <b>өндөр</b> хурдтай байна. Суралцах хурд өндөр түвшинд хөгжсөн
              хүмүүсийн хувьд мэдлэг, ур чадварыг нэгдмэл, цогц байдлаар ашиглах
              чадвартай бөгөөд академик, нарийн мэргэжлийн түвшинд ажиллахад
              илүү тохиромжтой.
            </p>
          );
      }
    } else if (name === "patternRecognition") {
      switch (iLevel["patternRecognitionLvl"]) {
        case 1:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд хүрээлэн буй орчны мэдээлэл,
              тулгамдаж буй асуудалд давтагдаж буй хэв загварыг таних, ойлгоход{" "}
              <b>багагүй бэрхшээлтэй</b> байх бөгөөд аливаа үүрэг, даалгаврыг
              биелүүлэхэд илүү цаг, хичээл зүтгэл, эсвэл нэмэлт заавар,
              чиглүүлэг шаардлагатай.
            </p>
          );
        case 2:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд хэв таних чадвар <b>дундаж</b>{" "}
              түвшинд хөгжсөн бөгөөд хэв загварыг таних чадвар дээрээ тулгуурлан
              суралцах чадвар сайн хөгжсөн байна. Нарийн төвөгтэй, хийсвэр хэв
              загварыг танихад нэмэлт туслалцаа, дэмжлэг шаардлагатай.
            </p>
          );
        case 3:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд хэв загварыг хүлээн авч, ойлгох
              чадвар <b>өндөр</b> хөгжсөн байгаа бөгөөд харах, сонсох гэх мэт
              мэдрэхүйн хэв загвараас эхлэн хийсвэр ухагдахууны хэв загварыг ч
              танин ойлгох өндөр чадвартай.
            </p>
          );
        default:
          return (
            <p>
              Энэхүү ажил горилогчийн хувьд хэв загварыг хүлээн авч, ойлгох
              чадвар <b>өндөр</b> хөгжсөн байгаа бөгөөд харах, сонсох гэх мэт
              мэдрэхүйн хэв загвараас эхлэн хийсвэр ухагдахууны хэв загварыг ч
              танин ойлгох өндөр чадвартай.{" "}
            </p>
          );
      }
    }
  };

  const generateLevelTextRu = (name) => {
    if (name === "inductive") {
      switch (iLevel["inductiveLvl"]) {
        case 1:
          return (
            <p>
              У кандидата <b>недостаточно</b> развиты навыки анализа и выводов
              на основе собственного опыта наблюдений. В связи с этим, ему может
              потребоваться дополнительное руководство и наставничество для
              успешной адаптации к организационной культуре и освоения рабочих
              процессов.
            </p>
          );
        case 2:
          return (
            <p>
              У кандидата способность оценивать и делать выводы на основе
              собственного опыта, а также понимать культуру и структуру
              организации развита на <b>среднем</b> уровне. Это означает, что
              ему потребуется время для адаптации и интеграции в коллектив,
              чтобы эффективно взаимодействовать и реагировать в различных
              ситуациях.
            </p>
          );
        case 3:
          return (
            <p>
              Кандидат обладает <b>выдающейся</b> способностью анализировать и
              делать выводы на основе своего опыта, а также глубоко понимает
              культуру и структуру организации. Его гибкость и способность к
              адаптации к различным ситуациям, опираясь на универсальные
              стратегии и стандартные реакции, делают его ценным игроком для
              команды.
            </p>
          );
        default:
          return (
            <p>
              Кандидат обладает <b>выдающейся</b> способностью анализировать и
              делать выводы на основе своего опыта, а также глубоко понимает
              культуру и структуру организации. Его гибкость и способность к
              адаптации к различным ситуациям, опираясь на универсальные
              стратегии и стандартные реакции, делают его ценным игроком для
              команды.
            </p>
          );
      }
    } else if (name === "deductive") {
      switch (iLevel["deductiveLvl"]) {
        case 1:
          return (
            <p>
              У данного кандидата <b>слабо</b> развита способность к анализу и
              пониманию связи между конкретными ситуациями и общепринятыми
              теориями, правилами и процедурами. Его подход к решению проблем
              часто не соответствует устоявшимся методологиям и популярным
              взглядам в данной области. В связи с этим возникает потребность в
              дополнительном руководстве и наставничестве для его адаптации и
              успешного внедрения в организационные процессы.
            </p>
          );
        case 2:
          return (
            <p>
              У данного кандидата способность объяснять и понимать, каким
              способом любые ситуации и причины связаны с теориями, правилами и
              процедурами, развита на <b>среднем</b> уровне. В случаях
              расхождения или несоответствия с установленными правилами и
              инструкциями в реальной ситуации, он продемонстрирует способность
              выявлять и анализировать ошибки или проблемы. Кандидат также
              способен понимать и объяснить, как и почему в определенном
              контексте нарушаются или ломаются общепринятые теории и правила.
            </p>
          );
        case 3:
          return (
            <p>
              У кандидата способность объяснять и понимать любую ситуацию и
              причины, связанные с правилами, процедурами, теориями и
              общепринятыми взглядами, <b>высокоразвита</b>. Кандидат будет
              демонстрировать умение выявлять ошибки и проблемы при наличии
              расхождений или ошибок с установленными правилами и инструкциями в
              реальной ситуации. Кандидат обладает развитой способностью
              принимать решения на основе общих теорий и устоявшихся подходов,
              таких как маркетинговое планирование для целевых групп клиентов,
              разработка вариантов для <b>наибольшего</b> возврата инвестиций и
              определение оптимальных методов общения с клиентами.
            </p>
          );
        default:
          return (
            <p>
              У кандидата способность объяснять и понимать любую ситуацию и
              причины, связанные с правилами, процедурами, теориями и
              общепринятыми взглядами, <b>высокоразвита</b>. Кандидат будет
              демонстрировать умение выявлять ошибки и проблемы при наличии
              расхождений или ошибок с установленными правилами и инструкциями в
              реальной ситуации. Кандидат обладает развитой способностью
              принимать решения на основе общих теорий и устоявшихся подходов,
              таких как маркетинговое планирование для целевых групп клиентов,
              разработка вариантов для <b>наибольшего</b> возврата инвестиций и
              определение оптимальных методов общения с клиентами.
            </p>
          );
      }
    } else if (name === "workingMemory") {
      switch (iLevel["workingMemoryLvl"]) {
        case 1:
          return (
            <p>
              Объем рабочей памяти у этого кандидата <b>низкий</b>. Другими
              словами, существует высокая вероятность, что его
              производительность будет <b>ниже</b> при выполнении задач,
              требующих одновременного общения с большим количеством людей или
              параллельного выполнения множества задач из-за перекрывающихся
              обязанностей. Следовательно, постановка спокойных и хорошо
              спланированных задач может повысить вероятность удовлетворенности
              и стабильности в работе данного сотрудника.
            </p>
          );
        case 2:
          return (
            <p>
              У этого кандидата <b>средний</b> объем рабочей памяти, что
              указывает на его способность к многозадачности. Однако тяжелая
              рабочая нагрузка в течение 6 месяцев и более может усилить такие
              симптомы, как снижение работоспособности и потеря уверенности в
              себе.
            </p>
          );
        case 3:
          return (
            <p>
              Этот кандидат обладает <b>высокой</b> работоспособностью и
              способностью выполнять множество задач одновременно без ощущения
              стресса.
            </p>
          );
        default:
          return (
            <p>
              Этот кандидат обладает <b>высокой</b> работоспособностью и
              способностью выполнять множество задач одновременно без ощущения
              стресса.
            </p>
          );
      }
    } else if (name === "attention") {
      switch (iLevel["attentionLvl"]) {
        case 1:
          return (
            <p>
              У этого кандидата <b>слабая</b> способность к длительной
              концентрации внимания. Задачи, требующие долгого сидения или
              высокого уровня сосредоточенности, могут быть для него
              неподходящими и привести к снижению производительности. Он лучше
              всего справляется с задачами, которые можно выполнить за короткие
              промежутки времени и которые не требуют постоянного внимания.
            </p>
          );
        case 2:
          return (
            <p>
              У этого кандидата <b>средний</b> уровень способности к
              целенаправленной концентрации внимания. Его способность
              планировать и адаптироваться к различным ситуациям находится на{" "}
              <b>среднем</b> уровне. Это может обозначать, что кандидат способен
              работать в условиях, которые иногда требуют гибкости, но также
              может нуждаться в стабильности и четкости рабочего процесса.{" "}
            </p>
          );
        case 3:
          return (
            <p>
              Этот кандидат демонстрирует <b>хорошо развитую</b> способность к
              целенаправленной концентрации и сосредоточенности на задаче. Его
              высокий уровень самомотивации и самодисциплины обеспечивает
              стабильность и последовательность в выполнении работы.
            </p>
          );
        default:
          return (
            <p>
              У данного кандидата <b>слабо</b> развита скорость обработки
              информации, что может затруднить его способность к
              самостоятельному принятию решений, выполнению заданий и следованию
              инструкциям. Возможно, ему потребуется дополнительное время для
              адаптации к новым обстоятельствам, формирования привычек и
              выработки соответствующего поведения.
            </p>
          );
      }
    } else if (name === "processingSpeed") {
      switch (iLevel["processingSpeedLvl"]) {
        case 1:
          return (
            <p>
              У данного кандидата <b>слабо</b> развита скорость обработки
              информации, что может затруднить его способность к
              самостоятельному принятию решений, выполнению заданий и следованию
              инструкциям. Возможно, ему потребуется дополнительное время для
              адаптации к новым обстоятельствам, формирования привычек и
              выработки соответствующего поведения.
            </p>
          );
        case 2:
          return (
            <p>
              Скорость обработки данного кандидата <b>средняя</b>, он успешно
              справляется с легкими задачами и имеет опыт обработки новой
              информации. Его способность быстро адаптироваться к новому
              позволяет ему самостоятельно принимать решения и эффективно
              работать с полученными данными.
            </p>
          );
        case 3:
          return (
            <p>
              У этого кандидата <b>высокая</b> скорость обработки данных,
              которая основана на опыте и позволяет ему быстро и успешно
              выполнять как стандартные, так и легкие задачи. Его способность
              автоматически обрабатывать информацию на основе предыдущего опыта,
              а также способность адаптироваться и обучаться в новых контекстах
              подчеркивают его качества. Кандидат склонен самостоятельно
              выполнять разнообразные роли и задачи, понимая их сущность.
            </p>
          );
        default:
          return (
            <p>
              У этого кандидата <b>высокая</b> скорость обработки данных,
              которая основана на опыте и позволяет ему быстро и успешно
              выполнять как стандартные, так и легкие задачи. Его способность
              автоматически обрабатывать информацию на основе предыдущего опыта,
              а также способность адаптироваться и обучаться в новых контекстах
              подчеркивают его качества. Кандидат склонен самостоятельно
              выполнять разнообразные роли и задачи, понимая их сущность.
            </p>
          );
      }
    } else if (name === "quantitativeReasoing") {
      switch (iLevel["quantitativeReasoingLvl"]) {
        case 1:
          return (
            <p>
              У данного кандидата <b>слабо</b> развиты навыки количественного
              анализа данных. Это может быть обусловлено недостаточным жизненным
              опытом, который способствует развитию количественного мышления.
            </p>
          );
        case 2:
          return (
            <p>
              У данного кандидата наблюдается <b>средний</b> уровень навыков
              анализа числовых данных. Этот факт может быть обусловлен
              ограниченным жизненным опытом, необходимым для развития глубокого
              количественного мышления. Рекомендуется также произвести анализ
              оценок и уровней компетенций в контексте способностей к обобщению
              и детализации.
            </p>
          );
        case 3:
          return (
            <p>
              Этот кандидат обладает <b>сильно</b> развитыми навыками анализа
              количественных данных и накопил обширный жизненный опыт,
              способствующий развитию глубокого количественного мышления. Это
              качество оказывает положительное влияние на другие аспекты
              компетенций и может благоприятно влиять на способность к обучению.
            </p>
          );
        default:
          return (
            <p>
              Этот кандидат обладает <b>сильно</b> развитыми навыками анализа
              количественных данных и накопил обширный жизненный опыт,
              способствующий развитию глубокого количественного мышления. Это
              качество оказывает положительное влияние на другие аспекты
              компетенций и может благоприятно влиять на способность к обучению.
            </p>
          );
      }
    } else if (name === "criticalThinking") {
      switch (iLevel["criticalThinkingLvl"]) {
        case 1:
          return (
            <p>
              У этого кандидата хорошо развит <b>первый</b> уровень критического
              мышления, что означает его способность анализировать разнообразную
              информацию. Он способен классифицировать связанные данные и
              устанавливать связи между различными видами информации, что
              позволяет ему видеть разные аспекты и функции в данных.
            </p>
          );
        case 2:
          return (
            <p>
              У данного кандидата наблюдается <b>средний</b> уровень способности
              выявлять причинно-следственные связи в различных информационных
              контекстах и абстрактно обобщать потенциальные результаты, а также
              уровень развития критического мышления. Кроме того, хорошо развито
              умение анализировать возможные последствия и конкретные проявления
              реальных действий.
            </p>
          );
        case 3:
          return (
            <p>
              У кандидата на данную позицию выделяется <b>высокий</b> уровень
              развития способности проводить обоснованные оценки и формулировать
              выводы. Его способность анализировать и критически оценивать
              ситуации на основе причинно-следственных связей и реалий также
              имеет высокий уровень развития. Кандидат проявляет умение
              оценивать информацию с точки зрения ее смысла и значимости, а
              также демонстрирует способность конструктивно критиковать и
              разумно решать проблемы. Кроме того, он способен представлять
              ценные контраргументы в диалоге и реагировать на ситуации с
              серьезностью и осмысленностью.
            </p>
          );
        default:
          return (
            <p>
              У кандидата на данную позицию выделяется <b>высокий</b> уровень
              развития способности проводить обоснованные оценки и формулировать
              выводы. Его способность анализировать и критически оценивать
              ситуации на основе причинно-следственных связей и реалий также
              имеет высокий уровень развития. Кандидат проявляет умение
              оценивать информацию с точки зрения ее смысла и значимости, а
              также демонстрирует способность конструктивно критиковать и
              разумно решать проблемы. Кроме того, он способен представлять
              ценные контраргументы в диалоге и реагировать на ситуации с
              серьезностью и осмысленностью.
            </p>
          );
      }
    } else if (name === "decisionMaking") {
      switch (iLevel["decisionMakingLvl"]) {
        case 1:
          return (
            <p>
              У данного кандидата наблюдается слабо развитая способность к
              планированию повседневных задач и принятию решений. Его уровень
              развития в области принятия решений является недостаточным.
              Отсутствие желания рисковать и предпочтение принимать решения в
              определенных ситуациях, а также предыдущие неудачи могут
              способствовать избеганию принятия решений и подрывать уверенность
              в себе.
            </p>
          );
        case 2:
          return (
            <p>
              В случае данного кандидата можно отметить, что он обладает
              способностью анализировать повседневную деятельность и оценивать
              среднесрочные последствия и риски, а также принимать тактические
              решения на уровне средней компетентности при общем рассмотрении.
              Люди данного уровня способны управлять ограниченным числом
              сотрудников и нести ответственность за средний уровень управления.
            </p>
          );
        case 3:
          return (
            <p>
              Данный кандидат фокусируется на инновационных решениях, связанных
              с определенными рисками, основанных на видении, долгосрочной
              оптимальности и эффективности. Он обладает способностью принимать
              стратегические и рациональные решения. Однажды приняв решение, он
              способен придерживаться его, несмотря на текущие трудности и
              сложности.
            </p>
          );
        default:
          return (
            <p>
              Данный кандидат фокусируется на инновационных решениях, связанных
              с определенными рисками, основанных на видении, долгосрочной
              оптимальности и эффективности. Он обладает способностью принимать
              стратегические и рациональные решения. Однажды приняв решение, он
              способен придерживаться его, несмотря на текущие трудности и
              сложности.
            </p>
          );
      }
    } else if (name === "planning") {
      switch (iLevel["planningLvl"]) {
        case 1:
          return (
            <p>
              У данного соискателя <b>недостаточно развита</b> способность к
              планированию, и он нуждается в поддержке и руководстве окружающих
              для принятия самостоятельных решений, оценки времени выполнения
              работы и расчета возможных последствий действий. Также стоит
              учитывать факторы риска, такие как легкость отвлекаться и
              недостаточное продуктивное время.
            </p>
          );
        case 2:
          return (
            <p>
              У этого кандидата наблюдается <b>средний</b> уровень навыков
              планирования, и ему потребуется определенное время, чтобы
              полностью освоить и понять конечные цели и задачи любого проекта
              или работы. Он также может столкнуться с трудностями в
              приоритизации задач и в управлении непредвиденными ситуациями и
              проблемами, что может потребовать дополнительной поддержки и
              руководства.
            </p>
          );
        case 3:
          return (
            <p>
              Данный кандидат демонстрирует <b>превосходные</b> навыки в области
              планирования и систематизации задач. Он способен стратегически
              выстраивать процессы, учитывая доступные ресурсы и временные
              рамки. К тому же, он эффективно справляется с работой в условиях
              высокого давления, умеет обеспечивать качественное
              междепартаментское взаимодействие и обладает отличными
              коммуникативными навыками.
            </p>
          );
        default:
          return (
            <p>
              Данный кандидат демонстрирует <b>превосходные</b> навыки в области
              планирования и систематизации задач. Он способен стратегически
              выстраивать процессы, учитывая доступные ресурсы и временные
              рамки. К тому же, он эффективно справляется с работой в условиях
              высокого давления, умеет обеспечивать качественное
              междепартаментское взаимодействие и обладает отличными
              коммуникативными навыками.
            </p>
          );
      }
    } else if (name === "learningRate") {
      switch (iLevel["learningRateLvl"]) {
        case 1:
          return (
            <p>
              Данный кандидат имеет особенности восприятия и обработки новой
              информации: процесс обучения у него может проходить{" "}
              <b>несколько медленнее</b> по сравнению с другими. Однако стоит
              отметить, что несмотря на <b>более низкую</b> скорость обучения, у
              кандидата есть ярко выраженные качества, такие как
              целеустремленность и здравомыслие. Поэтому рекомендуется учитывать
              его потенциал и глубокий анализ ситуаций при принятии решений.
            </p>
          );
        case 2:
          return (
            <p>
              Данный кандидат обладает способностью к восприятию, применению и
              усвоению информации, при этом его скорость обучения соответствует{" "}
              <b>среднему</b> уровню. Хотя ему может потребоваться
              дополнительное время для закрепления знаний и навыков через
              повторение и практику, это является типичным для людей с таким
              уровнем обучаемости.
            </p>
          );
        case 3:
          return (
            <p>
              Данный кандидат выделяется <b>высокой</b> обучаемостью, что
              позволяет ему быстро воспринимать и анализировать информацию,
              легко находить связи между различными концепциями и эффективно
              применять приобретенные знания. Люди с таким уровнем скорости
              обучения часто способны к глубокому и комплексному анализу, что
              делает их наиболее подходящими для академической и
              специализированной деятельности.
            </p>
          );
        default:
          return (
            <p>
              Данный кандидат выделяется <b>высокой</b> обучаемостью, что
              позволяет ему быстро воспринимать и анализировать информацию,
              легко находить связи между различными концепциями и эффективно
              применять приобретенные знания. Люди с таким уровнем скорости
              обучения часто способны к глубокому и комплексному анализу, что
              делает их наиболее подходящими для академической и
              специализированной деятельности.
            </p>
          );
      }
    } else if (name === "patternRecognition") {
      switch (iLevel["patternRecognitionLvl"]) {
        case 1:
          return (
            <p>
              Данный кандидат может столкнуться с{" "}
              <b>значительными трудностями</b> при попытке определить и понять
              повторяющиеся модели в предоставленной информации или ситуациях.
              Для успешного выполнения определенных задач ему, возможно,
              потребуется дополнительное время, усиленная концентрация или
              детализированные инструкции и руководство.
            </p>
          );
        case 2:
          return (
            <p>
              У данного кандидата уровень распознавания образов находится на{" "}
              <b>среднем</b> уровне. Хотя он обладает хорошо развитыми базовыми
              навыками распознавания, ему может потребоваться дополнительная
              помощь или ресурсы при работе со сложными или абстрактными
              образами.
            </p>
          );
        case 3:
          return (
            <p>
              У данного кандидата <b>сильно</b> развитая способность к
              восприятию и пониманию различных паттернов. Он успешно определяет
              и анализирует как абстрактные концептуальные паттерны, так и
              сенсорные, связанные с такими чувствами, как зрение и слух.
            </p>
          );
        default:
          return (
            <p>
              У данного кандидата <b>сильно</b> развитая способность к
              восприятию и пониманию различных паттернов. Он успешно определяет
              и анализирует как абстрактные концептуальные паттерны, так и
              сенсорные, связанные с такими чувствами, как зрение и слух.
            </p>
          );
      }
    }
  };

  const reportHeader = () => {
    return (
      <div className="pt-[30px] pr-[80px] pl-[60px] pb-[30px] bg-dark-blue text-white">
        <div className="flex justify-between">
          <div>
            <div className="pt-4">
              <img
                src="/assets/metacogLogo.svg"
                alt="Metacog"
                width="150"
                height="100"
              />
            </div>
            <div className="text-[12px] text-center">
              {`${t(
                "Report.date"
              )}: ${playerData?.assPlayer?.createdDate?.substring(0, 10)}`}
            </div>
          </div>
          <div>
            <div className="font-bold text-[22px] pb-4">
              {playerData?.assPlayer?.player?.name}
            </div>
            <div className="text-[12px]">
              <Trans i18nKey="Report.company"></Trans>:{" "}
              {assessCompany?.companyName}
            </div>
            <div className="text-[12px]">
              <Trans i18nKey="DashboardAssessment.job"></Trans>:{" "}
              {assessCompany?.assessmentName}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const switchRadarData = () => {
    if (levelRadar == false) {
      setLevelRadar(true);
      setRadarData({
        labels: [
          [
            t("NewAssessment.inductiveName"),
            skillLvlDetails.find((x) => x.name === "inductive").level,
          ],
          [
            t("NewAssessment.deductiveName"),
            skillLvlDetails.find((x) => x.name === "deductive").level,
          ],
          [
            t("NewAssessment.quantitativeName"),
            skillLvlDetails.find((x) => x.name === "quantitativeReasoing")
              .level,
          ],
          [
            t("NewAssessment.attentionName"),
            skillLvlDetails.find((x) => x.name === "attention").level,
          ],
          [
            t("NewAssessment.workingMemoryName"),
            skillLvlDetails.find((x) => x.name === "workingMemory").level,
          ],
          [
            t("NewAssessment.processingSpeedName"),
            skillLvlDetails.find((x) => x.name === "processingSpeed").level,
          ],
          [
            t("NewAssessment.patternRecognitionName"),
            skillLvlDetails.find((x) => x.name === "patternRecognition").level,
          ],
          [
            t("NewAssessment.criticalThinkingName"),
            skillLvlDetails.find((x) => x.name === "criticalThinking").level,
          ],
          [
            t("NewAssessment.decisionMakingName"),
            skillLvlDetails.find((x) => x.name === "decisionMaking").level,
          ],
          [
            t("NewAssessment.planningName"),
            skillLvlDetails.find((x) => x.name === "planning").level,
          ],
          [
            t("NewAssessment.learningSpeedName"),
            skillLvlDetails.find((x) => x.name === "learningRate").level,
          ],
          [
            t("NewAssessment.curiosityName"),
            skillLvlDetails.find((x) => x.name === "curiosity").level,
          ],
          [
            t("NewAssessment.riskTakingName"),
            skillLvlDetails.find((x) => x.name === "riskTaking").level,
          ],
          [
            t("NewAssessment.adaptability"),
            skillLvlDetails.find((x) => x.name === "adaptability").level,
          ],
          [
            t("NewAssessment.consistence"),
            skillLvlDetails.find((x) => x.name === "consistence").level,
          ],
          [
            t("NewAssessment.goalSetting"),
            skillLvlDetails.find((x) => x.name === "goalSetting").level,
          ],
          [
            t("NewAssessment.cooperative"),
            skillLvlDetails.find((x) => x.name === "cooperative").level,
          ],
          [
            t("NewAssessment.trusting"),
            skillLvlDetails.find((x) => x.name === "trusting").level,
          ],
          [
            t("NewAssessment.assertiveness"),
            skillLvlDetails.find((x) => x.name === "assertiveness").level,
          ],
        ],

        datasets: [
          {
            label: t("Report.generalIntelligencies"),
            data: [
              skillLvlDetails.find((x) => x.name === "inductive").level,
              skillLvlDetails.find((x) => x.name === "deductive").level,
              skillLvlDetails.find((x) => x.name === "quantitativeReasoing")
                .level,
              skillLvlDetails.find((x) => x.name === "attention").level,
              skillLvlDetails.find((x) => x.name === "workingMemory").level,
              skillLvlDetails.find((x) => x.name === "processingSpeed").level,
              skillLvlDetails.find((x) => x.name === "patternRecognition")
                .level,
            ],
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
          },
          {
            label: t("Report.skills"),
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              skillLvlDetails.find((x) => x.name === "patternRecognition")
                .level,
              skillLvlDetails.find((x) => x.name === "criticalThinking").level,
              skillLvlDetails.find((x) => x.name === "decisionMaking").level,
              skillLvlDetails.find((x) => x.name === "planning").level,
              skillLvlDetails.find((x) => x.name === "learningRate").level,
              skillLvlDetails.find((x) => x.name === "curiosity").level,
            ],
            backgroundColor: "rgba(255,204,0,0.2)",
            borderColor: "rgba(255,204,0,1)",
            borderWidth: 1,
          },
          {
            label: t("Report.readinessForChange"),
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              skillLvlDetails.find((x) => x.name === "curiosity").level,
              skillLvlDetails.find((x) => x.name === "riskTaking").level,
              skillLvlDetails.find((x) => x.name === "adaptability").level,
            ],
            backgroundColor: "rgba(165,147,179,0.2)",
            borderColor: "rgba(165,147,179,1)",
            borderWidth: 1,
          },
          {
            label: t("Report.responsibility"),
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              skillLvlDetails.find((x) => x.name === "adaptability").level,
              skillLvlDetails.find((x) => x.name === "consistence").level,
              skillLvlDetails.find((x) => x.name === "goalSetting").level,
            ],
            backgroundColor: "rgba(157,186,137,0.2)",
            borderColor: "rgba(157,186,137,1)",
            borderWidth: 1,
          },
          {
            label: t("Report.consistency"),
            data: [
              skillLvlDetails.find((x) => x.name === "inductive").level,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              skillLvlDetails.find((x) => x.name === "goalSetting").level,
              skillLvlDetails.find((x) => x.name === "cooperative").level,
              skillLvlDetails.find((x) => x.name === "trusting").level,
              skillLvlDetails.find((x) => x.name === "assertiveness").level,
            ],
            backgroundColor: "rgba(136,158,184,0.2)",
            borderColor: "rgba(136,158,184,1)",
            borderWidth: 1,
          },
        ],
      });
    } else {
      setLevelRadar(false);

      setRadarData({
        labels: [
          [
            t("NewAssessment.inductiveName"),
            playerData?.generalIntelligence?.inductiveReasoning
              .toString()
              .substring(0, 4),
          ],
          [
            t("NewAssessment.deductiveName"),
            playerData?.generalIntelligence?.deductiveReasoning
              .toString()
              .substring(0, 4),
          ],
          [
            t("NewAssessment.quantitativeName"),
            playerData?.generalIntelligence?.quantitativeReasoing
              .toString()
              .substring(0, 4),
          ],
          [
            t("NewAssessment.attentionName"),
            playerData?.generalIntelligence?.attention
              .toString()
              .substring(0, 4),
          ],
          [
            t("NewAssessment.workingMemoryName"),
            playerData?.generalIntelligence?.workingMemory
              .toString()
              .substring(0, 4),
          ],
          [
            t("NewAssessment.processingSpeedName"),
            playerData?.generalIntelligence?.processingSpeed
              .toString()
              .substring(0, 4),
          ],
          [
            t("NewAssessment.patternRecognitionName"),
            playerData?.competencies?.patternRecognition
              .toString()
              .substring(0, 4),
          ],
          [
            t("NewAssessment.criticalThinkingName"),
            playerData?.competencies?.criticalThinking
              .toString()
              .substring(0, 4),
          ],
          [
            t("NewAssessment.decisionMakingName"),
            playerData?.competencies?.decisionMaking.toString().substring(0, 4),
          ],
          [
            t("NewAssessment.planningName"),
            playerData?.competencies?.planning.toString().substring(0, 4),
          ],
          [
            t("NewAssessment.learningSpeedName"),
            playerData?.competencies?.learningRate.toString().substring(0, 4),
          ],
          [
            t("NewAssessment.curiosityName"),
            playerData?.openness?.curiosity.toString().substring(0, 4),
          ],
          [
            t("NewAssessment.riskTakingName"),
            playerData?.openness?.riskTaking.toString().substring(0, 4),
          ],
          [
            t("NewAssessment.adaptability"),
            playerData?.openness?.adaptability.toString().substring(0, 4),
          ],
          [
            t("NewAssessment.consistence"),
            playerData?.conscientiousness?.consistence
              .toString()
              .substring(0, 4),
          ],
          [
            t("NewAssessment.goalSetting"),
            playerData?.conscientiousness?.goalSetting
              .toString()
              .substring(0, 4),
          ],
          [
            t("NewAssessment.cooperative"),
            playerData?.agreeable?.cooperative.toString().substring(0, 4),
          ],
          [
            t("NewAssessment.trusting"),
            playerData?.agreeable?.trusting.toString().substring(0, 4),
          ],
          [
            t("NewAssessment.assertiveness"),
            playerData?.agreeable?.assertiveness.toString().substring(0, 4),
          ],
        ],

        datasets: [
          {
            label: t("Report.generalIntelligencies"),
            data: [
              playerData?.generalIntelligence?.inductiveReasoning,
              playerData?.generalIntelligence?.deductiveReasoning,
              playerData?.generalIntelligence?.quantitativeReasoing,
              playerData?.generalIntelligence?.attention,
              playerData?.generalIntelligence?.workingMemory,
              playerData?.generalIntelligence?.processingSpeed,
              playerData?.competencies?.patternRecognition,
            ],
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
          },
          {
            label: t("Report.skills"),
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              playerData?.competencies?.patternRecognition,
              playerData?.competencies?.criticalThinking,
              playerData?.competencies?.decisionMaking,
              playerData?.competencies?.planning,
              playerData?.competencies?.learningRate,
              playerData?.openness?.curiosity,
            ],
            backgroundColor: "rgba(255,204,0,0.2)",
            borderColor: "rgba(255,204,0,1)",
            borderWidth: 1,
          },
          {
            label: t("Report.readinessForChange"),
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              playerData?.openness?.curiosity,
              playerData?.openness?.riskTaking,
              playerData?.openness?.adaptability,
            ],
            backgroundColor: "rgba(165,147,179,0.2)",
            borderColor: "rgba(165,147,179,1)",
            borderWidth: 1,
          },
          {
            label: t("Report.responsibility"),
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              playerData?.openness?.adaptability,
              playerData?.conscientiousness?.consistence,
              playerData?.conscientiousness?.goalSetting,
            ],
            backgroundColor: "rgba(157,186,137,0.2)",
            borderColor: "rgba(157,186,137,1)",
            borderWidth: 1,
          },
          {
            label: t("Report.consistency"),
            data: [
              playerData?.generalIntelligence?.inductiveReasoning,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              playerData?.conscientiousness?.goalSetting,
              playerData?.agreeable?.cooperative,
              playerData?.agreeable?.trusting,
              playerData?.agreeable?.assertiveness,
            ],
            backgroundColor: "rgba(136,158,184,0.2)",
            borderColor: "rgba(136,158,184,1)",
            borderWidth: 1,
          },
        ],
      });
    }
  };

  return (
    <div>
      <Modal
        open={showModal}
        // onClose={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <div className="flex  justify-center content-center	">
            <div className="">
              <CircularProgress className="ml-11" />
              <p className="text-center text-lg">Та түр хүлээнэ үү ...</p>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
      <div className="w-full flex justify-end">
        <Button onClick={handleDownload} endIcon={<DownloadIcon />}>
          Download PDF
        </Button>
      </div>
      {skillLvlDetails === null || skillLvlDetails === undefined ? (
        <div className="ml-auto mr-auto table w-[793px] bg-white mt-7 mb-7 div2PDFrep">
          <div className="p-4 bg-dark-blue">
            <div className="font-bold text-white  pl-11 pb-4 pt-3 flex justify-between">
              <div className="text-3xl">Бие хүний онцлог тайлан</div>
              <div className="text-3xl underline">{`Оноо: ${parseFloat(
                playerData?.assPlayer?.mpi
              ).toFixed(2)}`}</div>
            </div>
            <div className="  text-white text-xl pl-11 pr-11 ">
              <div className="flex justify-between">
                <div>{` Хэнд: ${playerData?.assPlayer?.player?.name} `}</div>
              </div>
              <div className="flex justify-between">
                <div>{`    ${playerData?.assPlayer?.player?.email}`}</div>
                <div>
                  {`Огноо: ${playerData?.assPlayer?.createdDate?.substring(
                    0,
                    10
                  )}`}
                </div>
              </div>
            </div>
          </div>

          <div className="grid pl-6 pr-6 pt-7 remPdf">
            <div className="grid overflow-hidden grid-cols-1 grid-rows-2 gap-2 justify-items-center ">
              <div className="box w-[70px] h-[70px]">
                <button onClick={() => selectSkill("generalIntelligence")}>
                  {skillName?.eName === "generalIntelligence" ? (
                    <img src="/assets/ArtificialIntelligenceBold.svg" alt="" />
                  ) : (
                    <img src="/assets/ArtificialIntelligence.svg" alt="" />
                  )}
                </button>
              </div>
              <div className="box text-[#333333] font-bold">
                Оюун ухааны үзүүлэлтүүд
              </div>
            </div>
          </div>
          <div className="grid overflow-hidden grid-cols-3 grid-rows-2 gap-2 pl-6 pr-6 remPdf">
            <div className="box">
              <div className="grid overflow-hidden grid-cols-1 grid-rows-2 gap-2 justify-items-center">
                <div className="box w-[70px] h-[70px]">
                  <button onClick={() => selectSkill("conscientiousness")}>
                    {skillName?.eName === "conscientiousness" ? (
                      <img src="/assets/HandleWithCareBold.svg" alt="" />
                    ) : (
                      <img src="/assets/HandleWithCare.svg" alt="" />
                    )}
                  </button>
                </div>
                <div className="box text-[#333333] font-bold">
                  Хариуцлагатай байдал
                </div>
              </div>
            </div>
            <div className="box row-span-2 flex h-[250px] justify-center">
              <img
                src={
                  playerData?.assPlayer?.player?.gender !== "WOMEN"
                    ? "/assets/menAvatar.svg"
                    : "/assets/wAvatar.svg"
                }
                alt=""
              />
            </div>
            <div className="box">
              <div className="grid overflow-hidden grid-cols-1 grid-rows-2 gap-2 justify-items-center">
                <div className="box w-[70px] h-[70px]">
                  <button onClick={() => selectSkill("openness")}>
                    {skillName?.eName === "openness" ? (
                      <img src="/assets/ChatBold.svg" alt="" />
                    ) : (
                      <img src="/assets/Chat.svg" alt="" />
                    )}
                  </button>
                </div>
                <div className="box text-[#333333] font-bold">
                  Өөрчлөлтөд бэлэн байдал
                </div>
              </div>
            </div>
            <div className="box">
              <div className="grid overflow-hidden grid-cols-1 grid-rows-2 gap-2 justify-items-center">
                <div className="box w-[70px] h-[70px]">
                  <button onClick={() => selectSkill("competencies")}>
                    {skillName?.eName === "competencies" ? (
                      <img src="/assets/CircuitBold.svg" alt="" />
                    ) : (
                      <img src="/assets/Circuit.svg" alt="" />
                    )}
                  </button>
                </div>
                <div className="box text-[#333333] font-bold">
                  {t("Report.subSkills")}
                </div>
              </div>
            </div>
            <div className="box">
              <div className="grid overflow-hidden grid-cols-1 grid-rows-2 gap-2 justify-items-center">
                <div className="box w-[70px] h-[70px]">
                  <button onClick={() => selectSkill("agreeable")}>
                    {skillName?.eName === "agreeable" ? (
                      <img src="/assets/AutismBold.svg" alt="" />
                    ) : (
                      <img src="/assets/Autism.svg" alt="" />
                    )}
                  </button>
                </div>
                <div className="box text-[#333333] font-bold">
                  {t("Report.consistency")}
                </div>
              </div>
            </div>
          </div>

          {skillName && skillName.name ? (
            <div className="pl-6 pr-6 ">
              <div className="bg-[#FFB596] rounded-2xl p-4 text-lg text-white shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] text-center">
                {skillName?.txt}
              </div>
              <div
                className="text-3xl table m-auto pt-11 font-bold"
                style={{
                  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                {skillName?.name}
              </div>
              <PolarArea
                className="mb-4 chartPolaris"
                options={{
                  onClick: (event, elements, chart) => {
                    selectIndex(elements);
                  },
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      enabled: false,
                    },
                    datalabels: {
                      display: false,
                    },
                  },
                  scales: {
                    r: {
                      min: 20,
                      max: 80,
                      pointLabels: {
                        display: true,
                        centerPointLabels: true,
                        font: {
                          size: 16,
                        },
                      },
                    },
                  },
                }}
                data={chartData}
              />
            </div>
          ) : null}

          {chartIndex !== null ? (
            <div className="pl-6 pr-6 ">
              <div>
                <div className="bg-[#FFB596] rounded-2xl p-4 text-lg text-white shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] text-center">
                  {skillName?.data[chartIndex]?.txt}
                </div>
              </div>
              <div
                className="text-3xl table m-auto pt-11 pb-11"
                style={{
                  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                {skillName?.data[chartIndex]?.name}
              </div>
              <div className="w-[500px] m-auto">
                <Slider
                  size="small"
                  value={chartData?.datasets[0]?.data[chartIndex]}
                  min={20}
                  max={80}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
              </div>
              <div className="flex justify-between pb-20 text-center">
                <div
                  className={`w-[200px] ${chartData?.datasets[0]?.data[chartIndex] <= 50
                    ? "text-[#333333]"
                    : "text-[#838383]"
                    }`}
                >
                  {skillName?.data[chartIndex]?.min}
                </div>
                <div
                  className={`w-[200px] ${chartData?.datasets[0]?.data[chartIndex] >= 50
                    ? "text-[#333333]"
                    : "text-[#838383]"
                    }`}
                >
                  {skillName?.data[chartIndex]?.max}
                </div>
              </div>
            </div>
          ) : null}
          <Radar data={radarData} />
          <div className="p-4 bg-[#FFB596] flex justify-end ">
            <div className=" text-white text-end italic ">
              *Энэхүү тайлан нь сайн, муу гэсэн тал байхгүй ба “Хоёр туйлт
              график”-аар тухайн хүний зан төлөв, онцлог шинж чанаруудыг илтгэж
              байгаа ба зарлагдсан ажлын байранд хэр нийцтэй эсэхэд тулгуур
              мэдээлэл болж өгөх юм.
            </div>
          </div>
        </div>
      ) : (
        <div className="ml-auto mr-auto w-[720px] bg-white reportDownload">
          {reportHeader()}
          <div className="pt-[50px] pl-[30px] pr-[40px]">
            <div>
              <div className="flex">
                <div></div>
                <div className="text-[24px] font-bold">
                  <Trans
                    i18nKey="Report.introduction"
                    components={{ 1: <br /> }}
                  ></Trans>
                </div>
              </div>
              <div className="text-[12px] pl-[20px] mt-4">
                <Trans
                  i18nKey="Report.introduction1"
                  components={{ 1: <br /> }}
                ></Trans>
              </div>
            </div>
            <div className="pt-11">
              <div className="flex">
                <div></div>
                <div className="text-[24px] font-bold">
                  <Trans
                    i18nKey="Report.aboutGameAssessment"
                    components={{ 1: <br /> }}
                  ></Trans>
                </div>
              </div>
              <div className="text-[12px] pl-[20px] mt-4">
                <Trans
                  i18nKey="Report.aboutGameAssessment1"
                  components={{ 1: <br /> }}
                ></Trans>
              </div>
            </div>
            <div className="pt-11">
              <div className="flex">
                <div></div>
                <div className="text-[24px] font-bold">
                  {t("Report.noteForRead")}
                </div>
              </div>
              <div className="text-[12px] pl-[20px] mt-4">
                <Trans
                  i18nKey="Report.noteForRead1"
                  components={{ 1: <br /> }}
                ></Trans>
              </div>
            </div>
            <hr className="mt-11 mb-[31px]"></hr>
          </div>
          {reportHeader()}
          <div className="pt-[30px] pl-[30px] pr-[40px]">
            <div>
              <div className="flex">
                <div></div>
                <div className="text-[24px] font-bold">
                  {t("Report.generalConclusion")}
                </div>
              </div>
              <div className="text-[12px] pl-[20px] pt-4">
                {t("Report.generalConclusion1")}
              </div>

              <div className="flex justify-evenly pt-11">
                <div></div>
                <div className="w-[200px]">
                  <div className="font-bold text-[16px]">
                    <Trans i18nKey="Report.generalIntelligenceCompatibility"></Trans>
                  </div>
                  <div className="p-2 text-[24px] font-bold bg-[#414E5E] w-[100px] rounded text-center m-auto mt-4 text-white">
                    {`${parseFloat(iqPercentage).toFixed(0)}`}%
                  </div>
                </div>
                <div></div>
                <div className="w-[200px]">
                  <div className="font-bold text-[16px]">
                    {t("Report.competenceCompatibility")}
                  </div>
                  {i18n.language === "rn" ? <br></br> : null}
                  <div className="p-2 text-[24px] font-bold bg-[#F48D7E] w-[100px] rounded text-center m-auto mt-4 text-white">
                    {`${parseFloat(personalityPercentage).toFixed(0)}`}%
                  </div>
                </div>
              </div>

              <div className="pt-11 text-[16px] ">
                {t("Report.requireImportantSkill")}
              </div>
              <div className="pt-11">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 500 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell >{t('Report.skill')}</StyledTableCell> */}
                        <StyledTableCell align="center">
                          {t("NewAssessment.description")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t("Report.compliance")}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {skillLvlDetails
                        .filter((s) => {
                          if (s.expectedLevel === 5) return s;
                        })
                        .map((row) => (
                          <>
                            <StyledTableRow key={`${row.name}_generalRows_2`}>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                                colSpan={2}
                              >
                                {i18n.language === "rn"
                                  ? row?.ruName
                                  : row.mnName}
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key={`${row.mnName}_generalRows`}>
                              {/* <StyledTableCell component="th" scope="row">
                                {i18n.language === 'rn' ? row?.ruName : row?.mnName}
                              </StyledTableCell> */}
                              <StyledTableCell align="center">
                                {i18n.language === "rn"
                                  ? row?.ruDescription
                                  : row?.description}
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{ width: "160px" }}
                              >
                                <MixedChart
                                  expectedLevel={row.expectedLevel}
                                  level={row.level}
                                  height={240}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          </>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="pt-11"></div>
            </div>
            <hr className="mt-11 mb-[31px]"></hr>
          </div>
          <div className="h-[60px] pdfHeight hidden"></div>
          {reportHeader()}
          <div className="pt-[50px] pl-[30px] pr-[40px] pb-4">
            <div className="text-[24px] font-bold">
              {t("Report.generalIntelligencies")}
            </div>
            <div className="text-[12px] pl-[20px] pt-4">
              <Trans i18nKey="Report.generalIntelligenceis1"></Trans>
            </div>
            <div className="font-bold pt-11">{t("Report.scoreAndSkill")}:</div>
            <div className="flex mt-4">
              <div>
                <div className="flex p-4 bg-main-peach rounded-xl w-[200px] justify-center font-bold text-white  mt-4">
                  <div>
                    <div className="flex">
                      <div>{t("Report.mpiScore")} - </div>
                      <span className="ml-1">
                        {`${parseFloat(playerData?.assPlayer?.mpi).toFixed(2)}`}
                      </span>
                    </div>
                    <div className="flex">
                      <div> </div>
                      <span className="ml-1">
                        {i18n.language === "rn"
                          ? iLevel?.ruGiName
                          : iLevel?.giiName}{" "}
                        ({iLevel?.giiLvl})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-4">
                {playerData?.assPlayer?.mpi != null ? (
                  <NormalDistributionChart
                    targetValue={playerData?.assPlayer?.mpi}
                  />
                ) : null}
                {i18n.language === "mn" ? (
                  <p className="text-[12px] italic">
                    *{playerData?.assPlayer?.player?.name} нийт ажил горилогчдын{" "}
                    {parseFloat(mpiPercentage).toFixed(1)}% аас илүү үзүүлэлттэй
                    байна
                  </p>
                ) : (
                  <p className="text-[12px] italic">
                    *{playerData?.assPlayer?.player?.name}{" "}
                    {t("NewAssessment.graphdescription")}
                    {parseFloat(mpiPercentage).toFixed(1)}% кандидатов
                  </p>
                )}
              </div>
            </div>
            <div className="h-[500px] pdfHeight hidden"></div>
            <div className="pt-11">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {/* <StyledTableCell>{t('Report.subSkills')}</StyledTableCell> */}
                      <StyledTableCell align="center">
                        {t("Report.score")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("Report.definition")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("Report.level")}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {skillLvlDetails
                      .filter(
                        (s) =>
                          s.name === "inductive" ||
                          s.name === "deductive" ||
                          s.name === "quantitativeReasoing" ||
                          s.name === "attention" ||
                          s.name === "workingMemory" ||
                          s.name === "processingSpeed"
                      )
                      .map((row) => (
                        <>
                          <StyledTableRow key={`${row.mnName}_intelRows_2`}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                              colSpan={3}
                            >
                              {i18n.language === "rn"
                                ? row?.ruName
                                : row.mnName}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow key={`${row.mnName}_intelRows`}>
                            {/* <StyledTableCell component="th" scope="row">
                                {i18n.language === "rn" ? row.ruName : row.mnName}
                              </StyledTableCell> */}
                            <StyledTableCell
                              align="left"
                              style={{ width: "160px" }}
                            >
                              <MixedChart
                                expectedLevel={row.expectedLevel}
                                level={row.level}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {i18n.language === "rn"
                                ? row.ruDescription
                                : row.description}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {i18n.language === "rn"
                                ? generateLevelTextRu(row.name)
                                : generateLevelText(row.name)}
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="h-[250px] pdfHeight hidden"></div>
          {reportHeader()}
          <div className="pt-[20px] pl-[30px] pr-[40px]">
            <div className="text-[24px] font-bold">
              {t("Report.competencies")}
            </div>
            <div className="text-[12px] pl-[20px] pt-4">
              {t("Report.competencies1")}
            </div>
            <div className="font-bold mt-7">{t("Report.scoreAndSkill")}:</div>
            <div className="flex mt-2">
              <div className="flex p-4 bg-main-peach rounded-xl w-[200px] justify-center font-bold text-white mt-4">
                <div>
                  <div className="flex">
                    <div>{t("Report.ciScore")} - </div>
                    <span className="ml-1">
                      {`${parseFloat(playerData?.assPlayer?.ci).toFixed(2)}`}
                    </span>
                  </div>
                  <div className="flex">
                    <span className="ml-1">
                      {" "}
                      {i18n.language === "mn"
                        ? iLevel?.ciName
                        : iLevel?.ruCiName}{" "}
                      ({iLevel?.ciLvl})
                    </span>
                  </div>
                </div>
              </div>
              <div className="ml-4">
                {playerData?.assPlayer?.mpi != null ? (
                  <NormalDistributionChart
                    targetValue={playerData?.assPlayer?.ci}
                  />
                ) : null}
                {i18n.language === "mn" ? (
                  <p className="text-[12px] italic">
                    *{playerData?.assPlayer?.player?.name} нийт ажил горилогчдын{" "}
                    {parseFloat(ciPercentage).toFixed(1)}% аас илүү үзүүлэлттэй
                    байна
                  </p>
                ) : (
                  <p className="text-[12px] italic">
                    *{playerData?.assPlayer?.player?.name}{" "}
                    {t("NewAssessment.graphdescription")}
                    {parseFloat(ciPercentage).toFixed(1)}% кандидатов
                  </p>
                )}
              </div>
            </div>
            <div className="pt-11">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {/* <StyledTableCell >{t('Report.subSkills')}</StyledTableCell> */}
                      <StyledTableCell align="center">
                        {t("Report.score")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("Report.definition")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("Report.level")}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {skillLvlDetails
                      .filter(
                        (s) =>
                          s.name === "patternRecognition" ||
                          s.name === "criticalThinking" ||
                          s.name === "decisionMaking" ||
                          s.name === "planning" ||
                          s.name === "learningRate"
                      )
                      .map((row) => (
                        <>
                          <StyledTableRow key={`${row.name}_comptRows_2`}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                              colSpan={3}
                            >
                              {i18n.language === "rn"
                                ? row?.ruName
                                : row.mnName}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow key={`${row.name}_comptRows_1`}>
                            <StyledTableCell
                              align="left"
                              style={{ width: "160px" }}
                            >
                              <MixedChart
                                expectedLevel={row.expectedLevel}
                                level={row.level}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {i18n.language === "rn"
                                ? row.ruDescription
                                : row.description}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {i18n.language === "rn"
                                ? generateLevelTextRu(row.name)
                                : generateLevelText(row.name)}
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="h-4 pdfRHeight"></div>
          {reportHeader()}
          <div className="pt-[30px] pl-[30px] pr-[40px] pb-4  ">
            <div className="text-[24px] font-bold">
              {t("Report.behavioralIndicators")}
            </div>
            <div className="text-[12px] pl-[20px] pt-4">
              {t("Report.behavioralIndicators1")}
            </div>
            <hr className="mt-4 mb-4"></hr>
            <div className="font-bold text-center pb-4">
              {t("Report.readinessForChange")}
            </div>
            <div className="flex h-[50px] font-bold text-[16px] justify-center mt-11">
              <img src={"/assets/report/curiosity.png"} alt="Logo" />
              <span className="flex flex-col justify-center">
                {t("NewAssessment.curiosityName")}
              </span>
            </div>
            <div className="flex">
              <div className="flex">
                <div className="w-[200px] text-center">
                  {t("Report.curiosity1")}
                </div>
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex">
                  <MuiTooltip title={`${t("Report.curiosity3")}`}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                  <Level
                    lvl={
                      skillLvlDetails?.find((s) => s.name === "curiosity")
                        ?.level
                    }
                  />
                  <MuiTooltip title={`${t("Report.curiosity4")}`}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                </div>
              </div>
              <div className="w-[120px] text-center">
                {t("Report.curiosity2")}
              </div>
            </div>
            <div className="flex h-[50px] font-bold text-[16px] justify-center mt-11 mt-11">
              <img src={"/assets/report/risk-taking.png"} alt="Logo" />
              <span className="flex flex-col justify-center">
                {t("NewAssessment.riskTakingName")}
              </span>
            </div>
            <div className="flex">
              <div className="w-[200px] text-center">
                {t("Report.riskTaking1")}
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex">
                  <MuiTooltip title={`${t("Report.riskTaking3")}`}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                  <Level
                    lvl={
                      skillLvlDetails?.find((s) => s.name === "riskTaking")
                        ?.level
                    }
                  />
                  <MuiTooltip title={`${t("Report.riskTaking4")}`}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                </div>
              </div>
              <div className="text-center w-[150px]">
                {t("Report.riskTaking2")}
              </div>
            </div>
            <div className="flex h-[50px] font-bold text-[16px] justify-center mt-11">
              <img src={"/assets/report/adaptability.png"} alt="Logo" />
              <span className="flex flex-col justify-center">
                {t("NewAssessment.adaptability")}
              </span>
            </div>
            <div className="flex">
              <div className="w-[200px] text-center">
                {t("Report.adaptability1")}
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex">
                  <MuiTooltip title={t("Report.adaptability3")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                  <Level
                    lvl={
                      skillLvlDetails?.find((s) => s.name === "adaptability")
                        ?.level
                    }
                  />
                  <MuiTooltip title={t("Report.adaptability4")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                </div>
              </div>
              <div className="text-center w-[150px]">
                {t("Report.adaptability2")}
              </div>
            </div>
            <hr className="mt-7 mb-7"></hr>
            <div className="font-bold text-center pb-4">
              {t("Report.consistency")}
            </div>

            <div className="flex h-[50px] font-bold text-[16px] justify-center mt-11">
              <img src={"/assets/report/cooperative.png"} alt="Logo" />
              <span className="flex flex-col justify-center">
                {t("NewAssessment.cooperative")}
              </span>
            </div>
            <div className="flex">
              <div className="w-[200px] text-center">
                {t("Report.cooperative1")}
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex">
                  <MuiTooltip title={t("Report.cooperative3")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                  <Level
                    lvl={
                      skillLvlDetails?.find((s) => s.name === "cooperative")
                        ?.level
                    }
                  />
                  <MuiTooltip title={t("Report.cooperative4")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                </div>
              </div>
              <div className="text-center w-[150px]">
                {t("Report.cooperative2")}
              </div>
            </div>
            <div className="flex h-[50px] font-bold text-[16px] justify-center mt-11">
              <img src={"/assets/report/trust.png"} alt="Logo" />
              <span className="flex flex-col justify-center">
                {t("NewAssessment.trusting")}
              </span>
            </div>
            <div className="flex">
              <div className="w-[200px] text-center">
                {t("Report.trusting1")}
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex">
                  <MuiTooltip title={t("Report.trusting3")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                  <Level
                    lvl={
                      skillLvlDetails?.find((s) => s.name === "trusting")?.level
                    }
                  />
                  <MuiTooltip title={t("Report.trusting4")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                </div>
              </div>
              <div className="text-center w-[150px]">
                {t("Report.trusting2")}
              </div>
            </div>
            <div className="flex h-[50px] font-bold text-[16px] justify-center mt-11 ">
              <img src={"/assets/report/assertive.png"} alt="Logo" />
              <span className="flex flex-col justify-center w-[180px]">
                {t("NewAssessment.assertiveness")}
              </span>
            </div>
            <div className="flex">
              <div className="w-[200px] text-center">
                {t("Report.assertiveness1")}
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex">
                  <MuiTooltip title={t("Report.assertiveness3")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                  <Level
                    lvl={
                      skillLvlDetails?.find((s) => s.name === "assertiveness")
                        ?.level
                    }
                  />
                  <MuiTooltip title={t("Report.assertiveness4")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                </div>
              </div>
              <div className="text-center w-[150px]">
                {t("Report.assertiveness2")}
              </div>
            </div>
            <hr className="mt-7 mb-7"></hr>
            <div className="font-bold text-center pb-4">
              {t("Report.responsibility")}
            </div>

            <div className="flex h-[50px] font-bold text-[16px] justify-center mt-11 ">
              <img src={"/assets/report/goal-setting.png"} alt="Logo" />
              <span className="flex flex-col justify-center w-[220px]r">
                {t("NewAssessment.consistence")}
              </span>
            </div>
            <div className="flex">
              <div className="w-[200px] text-center">
                {t("Report.consistence1")}
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex">
                  <MuiTooltip title={t("Report.consistence3")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                  <Level
                    lvl={
                      skillLvlDetails?.find((s) => s.name === "consistence")
                        ?.level
                    }
                  />
                  <MuiTooltip title={t("Report.consistence4")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                </div>
              </div>
              <div className="text-center w-[150px]">
                {t("Report.consistence2")}
              </div>
            </div>
            <div className="flex h-[50px] font-bold text-[16px] justify-center mt-11 ">
              <img src={"/assets/report/consistency.png"} alt="Logo" />
              <span className="flex flex-col justify-center w-[220px]r">
                {t("NewAssessment.goalSetting")}
              </span>
            </div>
            <div className="flex">
              <div className="w-[200px] text-center">
                {t("Report.goalSetting1")}
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex">
                  <MuiTooltip title={t("Report.goalSetting3")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                  <Level
                    lvl={
                      skillLvlDetails?.find((s) => s.name === "goalSetting")
                        ?.level
                    }
                  />
                  <MuiTooltip title={t("Report.goalSetting4")}>
                    <InfoOutlinedIcon />
                  </MuiTooltip>
                </div>
              </div>
              <div className="text-center w-[150px]">
                {t("Report.goalSetting2")}
              </div>
            </div>
            <hr className="mt-11 mb-[31px]"></hr>
          </div>
          <div className="h-[35px] pdfHeight hidden"></div>

          {reportHeader()}
          <div className="pt-[50px] pl-[30px] pr-[40px]">
            <div className="text-[24px] font-bold pb-4">
              {t("Report.charCandidate")}
            </div>
            <div className="pt-7">
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="right">
                        {t("Report.advantage")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {t("Report.weakness")}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {top3Skills.map((row, index) => (
                      <StyledTableRow key={`${row.name}_attRows`}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="right"
                          width="50%"
                        >
                          <p className="font-bold pb-2">
                            {i18n.language === "rn" ? row.ruName : row.mnName}
                          </p>
                          <div className="text-[12px]">
                            {i18n.language === "rn"
                              ? row.ruDescription
                              : row.description}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="left" width="50%">
                          <p className="font-bold pb-2">
                            {i18n.language === "rn"
                              ? bot3Skills[index].ruName
                              : bot3Skills[index].mnName}
                          </p>
                          <div className="text-[12px]">
                            {i18n.language === "rn"
                              ? bot3Skills[index].ruDescription
                              : bot3Skills[index].description}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <hr className="mt-11 mb-[31px]"></hr>
          </div>
          <div className="h-[260px] pdfHeight hidden"></div>
          {reportHeader()}
          <div className="flex justify-end switchbtn pt-4">
            <div className="mt-1.5 mr-2">Т Score</div>
            <FormControlLabel
              control={
                <Switch
                  checked={levelRadar}
                  onChange={(e) => {
                    switchRadarData();
                  }}
                />
              }
              label={t("Report.level")}
            />
          </div>
          <div className="pt-[20px] pl-[30px] pr-[40px]">
            <Radar data={radarData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentReport;
