import api from "../helper/request";

const createAssessmentApi = (obj) => api.post("/assessment", obj);
const companyAssessmentApi = () => api.get("assessment");
const companyAssessmentSearch = (obj) =>
  api.get("/assessment/company", { params: obj });

const assessmentCompanyList = () => api.get("/assessment/info-list");

const assessmentDelete = (id) => api.delete(`/assessment/${id}`);

const assessmentPlayers = (obj) => api.get(`/assessment/players/${obj}`);
const createAssessmentPlayers = (obj) =>
  api.post(`/assessment/create/player`, obj);

const assessmentPlayerByLink = (obj) => api.post(`/assessment/find`, obj);
const assessmentById = (id) => api.get(`/assessment/${id}`);
const getPlayerOpenReport = (obj) => api.get(`/assessment/open-report/${obj}`);
const sentPlayerReportUrl = (obj) =>
  api.get(`/assessment/send-report-url/${obj}`);

const completedPlayerCount = () => api.post("/assessment/count/player");
const changeTime = (obj) => api.post("/assessment/time", obj);
const getTime = (obj) => api.post("/assessment/get-time", obj);

const findAssessmentWithSkillScore = (obj) =>
  api.get(`/assessment-with-skillScore/${obj}`);

const changeAssPlayerAppliedToInvited = (obj) =>
  api.post("/assessment/change/status", obj);

const downloadAssessmentExcel = (obj) =>
  api
    .get("/assessment/players/export/xls", {
      params: obj,
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "canditates.xlsx");
      document.body.appendChild(link);
      link.click();
    });

const findAllAssessmentReq = () => api.get("assessment-requirement");

const findAssessmentEmail = (obj) =>
  api.get("/assessment/email", { params: obj });

const createAssessmentEmail = (obj) => api.post("/assessment/email", obj);
const getAssessmentLink = (id) => api.get(`/assessment/link/${id}`);

const getB2cExp = (id) => api.get(`/assPlayer/b2c/player/${id}`);

export {
  findAllAssessmentReq,
  findAssessmentWithSkillScore,
  createAssessmentApi,
  getTime,
  companyAssessmentApi,
  companyAssessmentSearch,
  assessmentPlayers,
  createAssessmentPlayers,
  assessmentPlayerByLink,
  changeAssPlayerAppliedToInvited,
  assessmentDelete,
  assessmentById,
  getPlayerOpenReport,
  sentPlayerReportUrl,
  completedPlayerCount,
  changeTime,
  downloadAssessmentExcel,
  findAssessmentEmail,
  createAssessmentEmail,
  assessmentCompanyList,
  getAssessmentLink,
  getB2cExp,
};
