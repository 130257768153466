import { Box, CircularProgress } from "@mui/material";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import MainButton from "../../components/buttons/MainButton";
import MainInput from "../../components/input/MainInput";
import { createAccount } from "../../services/user.service";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const AccountForm = () => {
  const [role, setRole] = useState("");
  const [isCompany, setIsCompany] = useState(true);
  const [confirm, setConfirm] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [register, setRegister] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gameLanguage, setGameLanguage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const addCompany = () => {
    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (
      email === "" ||
      companyName === "" ||
      !emailRegex.test(email) ||
      phoneNumber === ""
    ) {
      toast.error(t("toast.fillTheAllFieldCorrect"));
    } else {
      setLoading(true);
      createAccount({
        email,
        companyName,
        phoneNumber,
        isCompany,
        gameLanguage,
      })
        .then((res) => {
          setEmail("");
          setCompanyName("");
          setIsCompany(true);
          setPhoneNumber("");
          toast.success(t("toast.Success"));
          setConfirm(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err?.response?.data?.message || "Error");
        });
    }
  };

  return (
    <div className="px-8 pt-8 pb-8 h-screen bg-[url('/public/assets/gameBackground.png')]">
      <ToastContainer />
      <div className="flex justify-center items-center h-full">
        {confirm === false ? (
          <div className="p-10 bg-white rounded-xl ">
            <div>
              <div className="lg:flex md:flex justify-between">
                <p className="font-bold lg:pr-[150px] md:pr-[150px]">
                  {t("Account.registration")}
                </p>
                <p className="text-[#219653]">{t("Account.useTrial")}</p>
              </div>
              <div className="border-solid border-2 border-[#E5E6E8] rounded-[10px] mt-[30px]">
                <div className="pr-7 pl-7 mt-[30px]">
                  <div className="mt-[30px] lg:flex md:flex justify-between">
                    <div>
                      <button
                        className={`mr-[16px] border-solid border-[1px] p-1.5 rounded-[100px] ${
                          isCompany === true
                            ? "bg-[#4F78A8] border-[#4F78A8]"
                            : "bg-[#E5E6E8] border-[#E5E6E8] text-[#777985]"
                        }  bg-opacity-50 `}
                        onClick={() => {
                          setIsCompany(true);
                        }}
                      >
                        {t("Account.company")}
                      </button>
                      {/* <button className={`border-solid border-[1px] p-1.5 rounded-[100px] ${isCompany === false ? 'bg-[#4F78A8] border-[#4F78A8]' : 'bg-[#E5E6E8] border-[#E5E6E8] text-[#777985]'}  bg-opacity-50 `}
                                                    onClick={() => {
                                                        setIsCompany(false)
                                                    }}
                                                >
                                                    {t('Account.individual')}
                                                </button> */}
                    </div>
                    <div className="lg:mt-0 md:mt-0 mt-4">
                      <select
                        className="appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline "
                        defaultValue={Cookies.get("metacog_lang") || "mn"}
                        onChange={(e) => {
                          const languageValue = e.target.value;
                          i18n.changeLanguage(languageValue);
                          Cookies.set("metacog_lang", e.target.value, {
                            expires: 30,
                          });

                          switch (languageValue) {
                            case "mn":
                              setGameLanguage(0);
                              break;
                            case "en":
                              setGameLanguage(2);
                              break;
                            case "rn":
                              setGameLanguage(1);
                              break;
                            case "kz":
                              setGameLanguage(3);
                              break;
                            default:
                              setGameLanguage(0);
                          }
                        }}
                      >
                        <option value="mn" className="flex items-center">
                          <img
                            src="/assets/mongoliaflag.png"
                            width={30}
                            alt="Mongolia Flag"
                            className="mr-2"
                          />
                          MN
                        </option>
                        <option value="en" className="flex items-center">
                          <img
                            src="/assets/rnFlag.png"
                            width={30}
                            alt="British Flag"
                            className="mr-2"
                          />
                          EN
                        </option>
                        <option value="rn" className="flex items-center">
                          <img
                            src="/assets/rnFlag.png"
                            width={30}
                            alt="Russia Flag"
                            className="mr-2"
                          />
                          RU
                        </option>
                        <option value="rn" className="flex items-center">
                          <img
                            src="/assets/rnFlag.png"
                            width={30}
                            alt="Kaz Flag"
                            className="mr-2"
                          />
                          KZ
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="lg:flex md:flex justify-between  pl-7 pt-[30px] lg:pr-0 md:pr-0 pr-7">
                  <div className="w-full mr-7">
                    <p>
                      {isCompany === true
                        ? t("Account.companyName")
                        : t("Account.name")}
                    </p>
                    <div>
                      <MainInput
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        placeholder={t("Account.enterName")}
                        type="text"
                        id="email"
                        name="email"
                      />
                    </div>
                  </div>

                  {/* <div className="w-full lg:pt-0 md:pt-0 pt-[30px]">
                                    <p> {
                                        isCompany === true ?
                                            'Байгууллагын РД' : "Хувь хүний РД"
                                    }</p>
                                    <MainInput
                                        value={register}
                                        onChange={(e) => {
                                            setRegister(e.target.value)
                                        }}
                                        placeholder={`Регистр оруулах`}
                                        type={`${isCompany === true ? 'number' : 'text'}`}
                                        id="email"
                                        name="email"
                                    />
                                </div> */}
                </div>
                <div className="lg:flex md:flex justify-between pr-7 pl-7 pt-[30px]">
                  <div className="w-full mr-7">
                    <p>{t("Account.email")}</p>
                    <div>
                      <MainInput
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder={`example@mail.com`}
                        type="text"
                        id="email"
                        name="email"
                      />
                    </div>
                  </div>

                  <div className="w-full lg:pt-0 md:pt-0 pt-[30px]">
                    <p>{t("Account.phoneNumber")}</p>
                    <MainInput
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      placeholder={t("Account.phoneNumber1")}
                      type="number"
                      id="phone"
                      name="phone"
                    />
                  </div>
                </div>
                <hr className="mt-[30px]" />
                <div className="flex justify-end py-4">
                  <div className="lg:w-[250px] md:w-[250px] sm:w-[225px]  mr-7">
                    <MainButton
                      onClick={() => {
                        addCompany();
                      }}
                      label={
                        !loading ? t("Account.getTrail") : <CircularProgress />
                      }
                      color={"bg-main-blue"}
                      labelColor={"text-white"}
                      customClass="w-[150px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-[90px] lg:px-[230px] md:px-[180px] px-[10px] py-[20px] ">
            <div className="flex justify-center">
              <img
                src="/icons/successShield.png"
                width={150}
                height={150}
                alt=""
              />
            </div>
            <div className="p-2 text-center">
              <p className="font-bold text-[29px] ">Success</p>
              <p>
                {" "}
                We've sent you a trial link. It should be in your email inbox
                ...
              </p>
              <p>If you have any questions, get in touch with us</p>
              <a className="underline" href="hello@metacog.mn">
                hello@metacog.mn
              </a>
              <p className="mt-4"> Thank you!</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountForm;
