import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import SubDetail from "./subscription/SubDetail";
import { useNavigate } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";
import { FaFileContract } from "react-icons/fa6";
import "../css/additional-styles/costum-input-range.scss";
import { MdOutlineQuestionMark } from "react-icons/md";
import { LuPhoneCall } from "react-icons/lu";
import { IoIosMail } from "react-icons/io";
import { FaChevronRight } from "react-icons/fa6";

const Subscription = () => {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [popUp, setPopUp] = useState(false);
  const [hover, setHover] = useState(false);
  const [popUp2, setPopUp2] = useState(false);
  const [help, setHelp] = useState(false);
  const [videoPopUp, setVideoPopUp] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const popUpRef = useRef(null);
  const popUpRef2 = useRef(null);
  const videoPopUpRef = useRef(null);
  const div1Ref = useRef(null);

  useEffect(() => {
    if (selected === "starter") {
      setPrice(1750000);
    } else if (selected === "advanced") {
      setPrice(2150000);
    } else if (selected === "premium") {
      setPrice(3150000);
    } else if (selected === "payasyougo") {
      setPrice(0);
    } else {
      setPrice(0);
    }
  }, [selected]);

  useEffect(() => {
    const totalPrice = price * value;
    setTotal(totalPrice);
  }, [price, value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        setPopUp(false);
      }
    };

    if (popUp) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popUp]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef2.current && !popUpRef2.current.contains(event.target)) {
        setPopUp2(false);
      }
    };

    if (popUp2) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popUp2]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        videoPopUpRef.current &&
        !videoPopUpRef.current.contains(event.target)
      ) {
        setVideoPopUp(false);
      }
    };

    if (videoPopUp) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [videoPopUp]);

  const scrollToDiv = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const ChoosePacakge = (item) => {
    setSelected(item);
  };

  const today = new Date();
  const year = today.getFullYear().toString().slice(-2);
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const day = ("0" + today.getDate()).slice(-2);

  const formattedDate = `${year}/${month}/${day}`;
  const getUpdatedDate = () => {
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let newMonth = Number(month) + Number(value);
    if (newMonth > 12) {
      newMonth = newMonth - 12;
      year = Number(year) + 1;
    }
    const newMonthString = ("0" + newMonth).slice(-2);
    return `${year % 100}/${newMonthString}/${day}`;
  };

  const handleHelpClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      setHelp(false);
      setIsExiting(false);
    }, 300); // Match this duration to the animation duration
  };

  return (
    <div className="w-11/12 m-auto py-11 relative">
      {!help && !isExiting && (
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setHelp(true)}
          className="flex items-center fixed right-0 top-[200px] justify-center w-[48px] h-[80px] bg-[#fff4f2] border-y-2 border-l-2 rounded-l-lg border-[#F48D79]"
        >
          <div className="relative">
            <MdOutlineQuestionMark className="h-[40px] text-[#F48D79]" />
            {hover && (
              <div className="absolute -left-[115px] top-1">
                <div className="relative flex items-center justify-center w-[100px]">
                  <img
                    className="absolute"
                    src="./helpBg.svg"
                    alt="background"
                  />
                  <p className="pe-4 ps-2 py-2 text-white z-20 font-semibold text-sm">
                    Тусламж
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {(help || isExiting) && (
        <div
          className={`flex fixed right-0 top-[200px] bg-white rounded-bl-lg border-[#F48D79] border-2 z-20 ${
            help ? "animate-slide-in-left" : "animate-slide-out-left"
          }`}
        >
          <div className="relative">
            <div
              onClick={handleHelpClose}
              className="flex items-center absolute left-[-48px] -top-[2px] justify-center w-[48px] h-[80px] bg-white border-y-2 border-l-2 rounded-l-lg border-[#F48D79]"
            >
              <FaChevronRight className="w-8" />
            </div>
            <div className="py-4 ps-4 pe-10">
              <p className="text-[#909090]">Холбоо барих</p>
              <p className="flex items-center gap-2 mt-2">
                <LuPhoneCall className="text-[#5F9A61] w-5 h-5" />
                +976 7777-4001
              </p>
              <p className="flex items-center gap-2 mt-2">
                <IoIosMail className="text-[#4F7293] h-6 w-6" />
                Support@oneplace.hr
              </p>
              <p
                onClick={() => {
                  setVideoPopUp(true);
                }}
                className="text-[#909090] mt-4"
              >
                Видео заавар үзэх
              </p>
              <div
                onClick={() => {
                  setVideoPopUp(true);
                }}
              >
                <img className="mt-2" src="./ytThumb.png" alt="thumbnail" />
              </div>
            </div>
          </div>
        </div>
      )}

      <div ref={div1Ref}>
        <div className="flex items-start justify-center">
          <div>
            <div className="flex">
              <img src="./active.svg" alt="icon" />
              {selected === "" ? (
                <img src="./grayLine.svg" alt="icon" />
              ) : (
                <img src="./activeLine.svg" alt="icon" />
              )}
            </div>
            <p className="text-[#eb8875]">БАГЦ СОНГОХ</p>
          </div>
          <div>
            <div className="flex">
              {selected === "" ? (
                <img src="./inactive.svg" alt="icon" />
              ) : (
                <img src="./active.svg" alt="icon" />
              )}
              <img src="./grayLine.svg" alt="icon" />
            </div>
            <p>Хугацаа сонгох</p>
          </div>
          <div>
            <div className="flex">
              <img src="./inactive.svg" alt="icon" />
              <img src="./grayLine.svg" alt="icon" />
            </div>
            <p>Төлбөр төлөх</p>
          </div>
          <img src="./inactive.svg" alt="icon" />
        </div>
        <p className="text-2xl my-8 font-bold">Багц сонгох</p>
      </div>
      <div className="shadow-lg        rounded-xl p-5 flex gap-10 my-8">
        <div className="w-[60%]">
          <p
            className={`${
              selected === "" ? "text-[#B3B3B3]" : " text-black"
            } text-lg`}
          >
            Ашиглах хугацааг сонгоно уу.
          </p>
          <div className="mt-8 relative ">
            <div
              className={`flex justify-center text-2xl ${
                selected === "" ? "text-[#B3B3B3]" : " text-black"
              }`}
            >
              {value}-сар
            </div>
            <input
              value={value}
              type="range"
              onChange={(e) => {
                console.log(e.target.value);
                setValue(e.target.value);
              }}
              className="w-full h-[2px] z-40 range-input"
              disabled={selected === "" ? true : false}
              max={12}
              min={1}
            />
            <p
              className={`absolute -bottom-4 left-0 ${
                selected === "" ? "text-[#B3B3B3]" : " text-black"
              }`}
            >
              1
            </p>
            <p
              className={`absolute -bottom-4 right-0 ${
                selected === "" ? "text-[#B3B3B3]" : " text-black"
              }`}
            >
              12
            </p>
          </div>
          {selected === "" ? (
            <div></div>
          ) : (
            <div className="flex items gap-4 mt-10">
              <button
                onClick={() => {
                  setPopUp(true);
                }}
                className="border border-[#DEDEDE] rounded-lg px-10 py-1 gap-4 text-sm flex items-center hover:bg-[#F4F4F4]"
              >
                <FaFileContract />
                Үйлчилгээний нөхцөл
              </button>
              <button
                onClick={() => {
                  setPopUp2(true);
                }}
                className="border border-[#DEDEDE] rounded-lg px-4 py-1 gap-4 flex items-center hover:bg-[#F4F4F4]"
              >
                Гэрээ байгуулах
                <GoArrowRight />
              </button>
            </div>
          )}
        </div>
        <div className="w-[30%] border rounded-xl p-5">
          <p
            className={`${
              selected === "" ? "text-[#B3B3B3]" : "text-black"
            } text-center text-3xl font-semibold`}
          >
            Нийт :
          </p>
          <p
            className={`${
              selected === "" ? "text-[#b3b3b3]" : "text-black"
            } text-center mt-4 text-4xl font-semibold`}
          >
            ₮ {total.toLocaleString()}
          </p>
          <p className="text-center mt-2 text-sm">
            {formattedDate} - {getUpdatedDate()}
          </p>
        </div>
      </div>
      <div>
        <SubDetail
          div1Ref={div1Ref}
          scrollToDiv={scrollToDiv}
          selected={selected}
          func={ChoosePacakge}
        />
      </div>
      {selected === "" ? (
        <div>
          {" "}
          <div className="flex items-center gap-2 mt-4 ms-2">
            <input type="checkbox" />
            <p
              onClick={() => {
                setPopUp(!popUp);
              }}
              className="text-xl underline"
            >
              Үйлчилгээний нөхцөл
            </p>
          </div>
          <hr className="mt-10 mb-5" />
          <div className="flex items-center justify-end gap-8">
            <button className="px-6 py-2 border rounded-lg hover:bg-[#f4f4f4] hover:font-bold">
              Буцах
            </button>
            <button
              disabled
              className="bg-[#F3F5F6] rounded-lg px-6 py-2 text-[#656C6E]"
            >
              Төлбөр төлөх
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {popUp && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
          <div
            ref={popUpRef}
            className="bg-white p-4 rounded-xl max-w-[500px] overflow-y-auto max-h-[580px] "
          >
            <div className="mt-4 border-b borer-[#D9D9D9] pb-6">
              <p className="text-3xl text-[#494949] font-bold">
                Үйлчилгээний нөхцөл
              </p>
              <p className="mt-2 text-lg font-semibold text-[#7C7C7C]">
                Сүүлд шинэчлэгдсэн: 1/12/2021
              </p>
            </div>
            <div className="flex justify-center relative">
              <button
                onClick={() => {
                  setPopUp(false);
                }}
                className=" px-16 py-2 bg-[#000] text-white rounded-full opacity-80  fixed bottom-[120px] hover:py-3 hover:opacity-100"
              >
                Зөвшөөрөх
              </button>
            </div>
            <div className="mt-4 mb-8 overflow-hidden">
              <p>
                Метакогт тавтай морилно уу!
                <br />
                <br /> Метаког платформд бүртгүүлснээр та манай үйлчилгээний
                нөхцөл ба нууцлалын бодлогыг зөвшөөрч байгаа тул уншиж танилцана
                уу. <br /> <br /> Үйлчилгээний тодорхойлолт: <br />
                <br /> Метаког нь сэтгэл зүй болон тархи судлалын онолуудад
                суурилсан тоглоомыг санал болгодог. Төлбөр төлсний дараа та
                манай тоглоомд хандах хувийн холбоосыг хүлээн авах болно.
                Дуусгасны дараа, манай платформ нь таны зөөлөн ур чадварыг
                дүгнэсэн тайлан болон AI-д суурилсан зөвлөмжийг үүсгэн илгээх
                болно. <br />
                <br /> Хувийн нууц ба өгөгдлийн хамгаалалт: <br />
                <br />
                Өгөгдөл цуглуулах: Бид үйлчилгээг сайжруулах болон хувийн
                туршлагыг хэрэглэгчиддээ санал болгох зорилгоор бүртгэл үед
                хувийн мэдээллийг цуглуулдаг. <br />
                <br /> Өгөгдлийг ашиглах: <br />
                <br />
                Таны мэдээллийг тайлан болон зөвлөмж бий болгоход ашиглан. Бид
                таны хувийн мэдээллийг таны зөвшөөрөлгүйгээр гуравдагч талуудтай
                хуваалцахгүй. <br />
                <br /> Өгөгдлийг ашиглах: <br />
                <br /> Бид таны хувийн мэдээллийг хуваалцахгүйгээр, таны зөөлөн
                ур чадвар ба сонирхлын дагуу гуравдагч этгээдийн ажлын
                саналуудаас танд хүргүүлж болзошгүй. <br />
                <br /> Хэрэглэгчийн Үүрэг: <br />
                <br /> Нэвтрэх мэдээллээ үнэн бодитоор оруулах. <br />
                <br /> Үйлчилгээг хувийн болон ашиг сонирхлын бус зорилгоор
                ашигла. <br />
                <br /> Тайлан нь зөвхөн танд зориулагдсан бөгөөд гуравдагч
                итгээдэд хуваалцахгүй байх. <br />
                <br /> Метаког тоглоомууд: <br />
                <br /> Бүртгүүлсний дараа та сайт дээр тархи судлалд суурилсан
                онлайн тоглоомуудыг ("Тоглоом") тоглох боломжтой болно. Та
                тоглоом бүрийг тоглох үед “Метаког” нь таны Тоглоомын явцын
                мэдээллийг цуглуулж, хадгалах эрхтэй гэдгийг та зөвшөөрч байна.
                "Тоглоомын мэдээлэл" гэдэг нь тоглоом тоглох явцад таны гаргасан
                шийдвэр, үйлдлээс цуглуулсан өгөгдөл юм, үүнд мөн таны
                тоглоомтой харилцах, хариу үйлдлийн талаарх мета өгөгдөл
                багтана. <br />
                <br /> Та доорх мэдээлэлтэй танилцаад хүлээн зөвшөөрч байна:{" "}
                <br />
                <br />
                Сайтын бүртгэлийн маягтын дагуу өөрийнхөө тухай үнэн зөв
                одоогийн, бүрэн мэдээллийг өгөх; <br />
                <br />
                Профайл мэдээллээ оруулахдаа жинхэнэ нэр, имэйл хаягаа ашиглана
                уу; <br />
                <br />
                Өөрийн ашиглах эрхээ гуравдагч этгээдэд шилжүүлэхгүй, дахин
                худалдахгүй байх;
                <br />
                <br /> Үүний дагуу та дараахь зүйлийг хийхийг зөвшөөрч байна.
                <br />
                <br />
                Тоглоомыг нухацтай авч, тоглоомыг анхааралтай тоглох; <br />
                Тоглоом бүрийг эхлүүлэхийн өмнө зааврыг анхааралтай унших;{" "}
                <br />
                Тоглоомыг хагаст нь зогсоож, эсвэл тодорхой нэг Тоглоомыг
                давтахын тулд тоглоомыг зориудаар дуусгахгүй; <br /> Анхаарал
                төвлөрөх орчин бүрдүүлж өөрийнхөөрөө шийдвэр гарган тоглох.{" "}
                <br />
                <br />
                Өмчлөлийн эрх: <br />
                <br /> Метаког-оос санал болгосон бүх агуулга, тоглоомын дизайн
                болон тайлан нь бид эсвэл манай эрх зүйн эзэмшигчидийн өмч юм.
                Эдгээр агуулгыг энэхүү нөхцөлд заасан зөвшөөрөлгүйгээр ашиглахыг
                хориглоно.
                <br />
                <br /> Бусад: <br />
                <br />
                Бид энэхүү нөхцөл болон манай үйлчилгээг аливаа цагт өөрчлөх
                эрхтэй. “Метаког” нь энэхүү Үйлчилгээний нөхцөлд нэмэлт өөрчлөлт
                оруулах бүрт Хэрэглэгчидэд мэдэгдэх үүргийг хүлээнэ. <br />
                <br />
                Үйлчилгээтэй холбоотой санал, гомдол, асуудал, маргааныг талууд
                эв зүйгээр харилцан зөвшилцөх замаар шийдвэрлэнэ. Хэрэв
                шийдвэрлэх боломжгүй бол Монгол Улсын хууль, тогтоомжид заасны
                дагуу шийдвэрлүүлнэ. <br />
                <br /> Бидэнтэй холбогдох:  <br />
                <br />
                Хэрэв танд сайттай холбоотой асуулт, гомдол байвал бидэнтэй
                hello@metacog.mn цахим хаягаар эсвэл холбогдоно уу.
              </p>
            </div>
          </div>
        </div>
      )}
      {popUp2 && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
          <div
            ref={popUpRef2}
            className="bg-white px-6 py-10 rounded-xl flex items-center gap-10"
          >
            <div className="relative">
              <img src="./subscriptionPopUp.svg" alt="photo" />
              <img
                className="absolute bottom-5 -right-6"
                src="./hand.png"
                alt="icon"
              />
            </div>
            <div className="flex flex-col justify-center">
              <p className="text-center w-[300px]">
                Төлбөр төлөхөөс өмнө гэрээ хийх шаардлагатай ба гэрээтэй
                танилцах явцдаа та өөрийн мэдээллийг доор харагдаж буй товч дээр
                даран оруулах боломжтой.
              </p>
              <button
                onClick={() => {
                  setPopUp2(false);
                  navigate("contract");
                }}
                className="px-8 py-2 border rounded-lg mt-8 hover:bg-[#DEDEDE]"
              >
                Гэрээ байгуулах
              </button>
            </div>
          </div>
        </div>
      )}
      {videoPopUp && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
          <div
            ref={videoPopUpRef}
            className="bg-white px-10 rounded-xl flex items-center"
          >
            <button></button>

            <iframe
              src="https://player.vimeo.com/video/947235897?h=9d88384908"
              width="640"
              height="450"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;
