
import { useTranslation } from "react-i18next";

const HandBook = () => {
    const { t, i18n } = useTranslation();


    return (
        <div className='flex justify-center'>

            <div className='w-3/4 ' >
                <iframe
                    src={i18n.language === "rn" ? `/pdf/handbook_ru.pdf` : `/pdf/handbook.pdf`}
                    width="100%"
                    height="700px"
                >
                    Your browser doesn't support iframes
                </iframe>
            </div>
        </div>
    );
}
export default HandBook;