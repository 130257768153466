import React, { useState, useEffect } from "react";
import { BiEdit } from "react-icons/bi";
import { useParams } from "react-router-dom";
import employeeData from "../test.json";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineSave } from "react-icons/ai";

const EmployeeDetail = () => {
  const { id } = useParams();
  const [employee, setEmployee] = useState(employeeData);
  const [editedEmployee, setEditedEmployee] = useState(null);
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingCondition, setIsEditingCondition] = useState(false);
  useEffect(() => {
    const selectedEmployee = employeeData.find(
      (emp) => emp.id === parseInt(id, 10)
    );

    if (selectedEmployee) {
      setEmployee(selectedEmployee);
      setEditedEmployee({ ...selectedEmployee });
    }
  }, [id, employeeData]);

  const handleDetailEditClick = () => {
    setIsEditingDetails(true);
  };

  const handleDetailSaveClick = () => {
    setIsEditingDetails(false);
  };

  const handleEmailEditClick = () => {
    setIsEditingEmail(true);
  };

  const handleEmailSaveClick = () => {
    setIsEditingEmail(false);
  };

  const handleConditionEditClick = () => {
    setIsEditingCondition(true);
  };
  const handleConditionSaveClick = () => {
    setIsEditingCondition(false);
  };

  return (
    <div className="employee-detail">
      {/* delgerrengui section */}
      <div>
        <div>
          <p className="text-2xl font-bold text-[#324D72]">
            Дэлгэрэнгүй мэдээлэл
          </p>
        </div>
        <div className="mt-5">
          {employee ? (
            isEditingDetails ? (
              <div className="ms-5 flex justify-between items-end">
                <div className=" flex">
                  <div className="flex items-center  ms-10">
                    <div className="text-end">
                      <p>Хэлтэс</p>
                      <p className="my-7">Програм хөгжүүлэгч</p>
                      <p>Эхэлсэн өдөр</p>
                    </div>
                    <div className="text-start ">
                      <input
                        className="ms-3 w-2/4  rounded"
                        type="text"
                        placeholder={employee.department}
                      />
                      <input
                        className="ms-3 w-2/4  px-2 my-2  rounded"
                        type="text"
                        placeholder={employee.programmis ? "" : "байхгүй"}
                      />
                      <input
                        className="ms-3 w-2/4   rounded"
                        type="date"
                        placeholder={
                          employee.workStartedDate
                            ? employee.workStartedDate
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <button
                    className="flex items-center gap-1"
                    onClick={handleDetailSaveClick}
                  >
                    <AiOutlineSave className="text-xl text-[#FF9D72]" />{" "}
                    Хадгалах
                  </button>
                </div>
              </div>
            ) : (
              <div className="ms-5 flex justify-between items-end">
                <div className=" flex">
                  <div className="flex ms-10">
                    <div className="text-end">
                      <p className="text-gray-500">Хэлтэс</p>
                      <p className="text-gray-500">Програм хөгжүүлэгч</p>
                      <p className="text-gray-500">Эхэлсэн өдөр</p>
                    </div>
                    <div className="text-start">
                      <p className="ms-3">
                        {employee.department ? employee.department : "байхгүй"}
                      </p>
                      <p className="ms-3">
                        {employee.programmis ? "" : "байхгүй"}
                      </p>
                      <p className="ms-3">
                        {employee.workStartedDate
                          ? employee.workStartedDate
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <button
                    className="flex items-center gap-1"
                    onClick={handleDetailEditClick}
                  >
                    <BiEdit className="text-xl text-[#FF9D72]" /> Засах
                  </button>
                </div>
              </div>
            )
          ) : (
            <p>Employee not found</p>
          )}
        </div>
      </div>
      {/* programmist section */}
      <div>
        <div>
          <p className="text-2xl mt-7 font-bold text-[#324D72]">
            Програм хөгжүүлэгч үүрэг
          </p>
        </div>
        <div className="flex gap-4 mt-2">
          <div className="text-end">
            <p className="ms-4 text-gray-500 ">Дээд албан тушаалтан</p>
          </div>
          <div className="mt-8 text-start underline underline-[#324d72]">
            <p className="text-[#324D72]  font-bold">Гансүх Санчир</p>
            <p className="text-[#324D72] font-bold">Галбадрах Зулцэцэг</p>
            <p className="text-[#324D72] font-bold">Ганбат Ганзул</p>
            <p className="text-[#324D72] font-bold">Бадрал Хүслэн</p>
            <p className="text-[#324D72] font-bold">Галбадрах Билгүүн</p>
          </div>
        </div>
      </div>
      {/* email section */}
      <div className="mb-7">
        <div>
          <p className="text-2xl my-7 font-bold text-[#324D72]">Имейл</p>
        </div>
        <div>
          {employee ? (
            isEditingEmail ? (
              <div className="ms-5 flex justify-between items-start">
                <div className=" flex">
                  <div className="flex items-center  ms-10">
                    <div className="text-end">
                      <p>Хувийн имэйл</p>
                      <p className="mt-5">Ажлын имэйл</p>
                    </div>
                    <div className="text-start ">
                      <input
                        className="ms-3 w-2/4  rounded"
                        type="email"
                        placeholder={employee.email}
                      />
                      <input
                        className="ms-3 w-2/4  px-2 my-2  rounded"
                        type="email"
                        placeholder={employee.email ? "" : "байхгүй"}
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <button
                    className="flex items-center gap-1"
                    onClick={handleEmailSaveClick}
                  >
                    <AiOutlineSave className="text-xl text-[#FF9D72]" />{" "}
                    Хадгалах
                  </button>
                </div>
              </div>
            ) : (
              <div className="ms-5 flex justify-between items-start">
                <div className=" flex">
                  <div className="flex ms-10">
                    <div className="text-end">
                      <p className="text-gray-500">Хувийн имэйл</p>
                      <p className="text-gray-500">Ажлын имэйл</p>
                    </div>
                    <div className="text-start ms-3">
                      <p>{employee.email ? employee.email : "байхгүй"}</p>
                      <p>{employee.email ? "" : "байхгүй"}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="flex items-center gap-1"
                    onClick={handleEmailEditClick}
                  >
                    <BiEdit className="text-xl text-[#FF9D72]" /> Засах
                  </button>
                </div>
              </div>
            )
          ) : (
            <p>Employee not found</p>
          )}
        </div>
      </div>
      {/* work place section */}
      <div className="mb-7">
        <div>
          <div>
            <p className="text-2xl mb-7 font-bold text-[#324D72]">Ажлын хаяг</p>
          </div>
          <div className="flex gap-4 mt-2">
            <div className="text-end">
              <p className="ms-4 text-gray-500 ">Ажлын хаяг</p>
            </div>
            <div className="text-start">
              <p className=" w-3/4 font-bold">
                Хан-Уул Дүүрэг 11-р хороо Буянт-Ухаа 2 хороолол 1002 байр 34
                тоот
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* work condition */}
      <div className="mb-7">
        <div>
          <p className="text-2xl my-7 font-bold text-[#324D72]">Ажлын нөхцөл</p>
        </div>
        <div>
          {employee ? (
            isEditingCondition ? (
              <div className="ms-5 flex justify-between items-start">
                <div className=" flex">
                  <div className="flex items-center  ms-10">
                    <div className="text-end">
                      <p>Ажилтны төрөл </p>
                      <p className="my-7">Албан тушаал </p>
                      <p>Цалин </p>
                    </div>
                    <div className="text-start ">
                      <input
                        className="ms-3 w-2/4  rounded"
                        type="text"
                        placeholder="Цалин/Илүү цаг байхгүй"
                      />
                      <input
                        className="ms-3 w-2/4  px-2 my-2  rounded"
                        type="text"
                        placeholder="Захирал"
                      />
                      <input
                        className="ms-3 w-2/4  px-2 my-2  rounded"
                        type="text"
                        placeholder="36,000,000₮ (Жилд)"
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <button
                    className="flex items-center gap-1"
                    onClick={handleConditionSaveClick}
                  >
                    <AiOutlineSave className="text-xl text-[#FF9D72]" />{" "}
                    Хадгалах
                  </button>
                </div>
              </div>
            ) : (
              <div className="ms-5 flex justify-between items-start">
                <div className=" flex">
                  <div className="flex ms-10">
                    <div className="text-end">
                      <p className="text-gray-500">Ажилтны төрөл </p>
                      <p className="text-gray-500">Албан тушаал </p>
                      <p className="text-gray-500">Цалин </p>
                    </div>
                    <div className="text-start ms-3">
                      <p>Цалин/Илүү цаг байхгүй</p>
                      <p>Захирал</p>
                      <p>36,000,000₮ (Жилд)</p>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="flex items-center gap-1"
                    onClick={handleConditionEditClick}
                  >
                    <BiEdit className="text-xl text-[#FF9D72]" /> Засах
                  </button>
                </div>
              </div>
            )
          ) : (
            <p>Employee not found</p>
          )}
        </div>
      </div>
      {/* <div className="mb-20 mt-10">
        <button className="flex items-center  text-lg gap-2">
          <AiOutlinePlusCircle className="text-[#FF9D72] text-xl" /> Нэмэлт
          мэдээлэл нэмэх
        </button>
      </div> */}
    </div>
  );
};

export default EmployeeDetail;
