import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { companyAssessmentPlayer } from "../../services/assessmentPlayer.service";
import { findSkillScoreByAP } from "../../services/skillScore.service";
import { findDepartmentSkillScores } from "../../services/department.service";
import { useTranslation } from "react-i18next";
import TagDropDown from "../../components/charts/TagDropDown";
import { getDepartments } from "../../services/employee.service";

const EmployeeDashboard = () => {
  const { t } = useTranslation();
  const legendItems = [
    {
      text: "High score",
      fillStyle: "#55D6A0",
    },
    {
      text: "Mid score",
      fillStyle: "#F0CB6D",
    },
    {
      text: "Low score",
      fillStyle: "#F18B8B",
    },
  ];

  let data = [
    {
      label: t("NewAssessment.inductiveName"),
      value: 0,
      img: "./assets/report/inductive.png",
    },
    {
      label: t("NewAssessment.deductiveName"),
      value: 0,
      img: "./assets/report/deductive.png",
    },
    {
      label: t("NewAssessment.quantitativeName"),
      value: 0,
      img: "./assets/report/quantitative.png",
    },
    {
      label: t("NewAssessment.attentionName"),
      value: 0,
      img: "./assets/report/attention.png",
    },
    {
      label: t("NewAssessment.workingMemoryName"),
      value: 0,
      img: "./assets/report/memory.png",
    },
    {
      label: t("NewAssessment.processingSpeedName"),
      value: 0,
      img: "./assets/report/processingSpeed.png",
    },
    {
      label: t("NewAssessment.patternRecognitionName"),
      value: 0,
      img: "./assets/report/pattern.png",
    },
    {
      label: t("NewAssessment.criticalThinkingName"),
      value: 0,
      img: "./assets/report/critical-thinking.png",
    },
    {
      label: t("NewAssessment.decisionMakingName"),
      value: 0,
      img: "./assets/report/decision-making.png",
    },
    {
      label: t("NewAssessment.planningName"),
      value: 0,
      img: "./assets/report/planning.png",
    },
    {
      label: t("NewAssessment.learningSpeedName"),
      value: 0,
      img: "./assets/report/learning.png",
    },
    {
      label: t("NewAssessment.curiosityName"),
      value: 0,
      img: "./assets/report/curiosity.png",
    },
    {
      label: t("NewAssessment.riskTakingName"),
      value: 0,
      img: "./assets/report/risk-taking.png",
    },
    {
      label: t("NewAssessment.adaptability"),
      value: 0,
      img: "./assets/report/adaptability.png",
    },
    {
      label: t("NewAssessment.consistence"),
      value: 0,
      img: "./assets/report/consistency.png",
    },
    {
      label: t("NewAssessment.goalSetting"),
      value: 0,
      img: "./assets/report/goal-setting.png",
    },
    {
      label: t("NewAssessment.cooperative"),
      value: 0,
      img: "./assets/report/cooperative.png",
    },
    {
      label: t("NewAssessment.trusting"),
      value: 0,
      img: "./assets/report/trust.png",
    },
    {
      label: t("NewAssessment.assertiveness"),
      value: 0,
      img: "./assets/report/assertive.png",
    },
  ];

  const [departments, setDepartments] = useState([]);
  const [players, setPlayers] = useState([]);
  const [sortedData, setSortedData] = useState(data);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const [scores, setScores] = useState([]);
  const [employee, setEmployee] = useState([]);

  const chartData = {
    labels: sortedData.map((item) => item.label),
    datasets: [
      {
        backgroundColor: sortedData.map((item, index) => {
          if (index < 6) {
            return "#55D6A0";
          } else if (index >= sortedData.length - 2) {
            return "#F18B8B";
          } else {
            return "#F0CB6D";
          }
        }),
        data: sortedData.map((item) => item.value),
        barPercentage: 0.8,
      },
    ],
  };


  useEffect(() => {
    companyAssessmentPlayer().then((res) => {
      setPlayers(res.data);
    });

    fetchPlayers();
  }, []);

  const fetchPlayers = async () => {
    try {
      const departmentsData = await getDepartments();
      setDepartments(departmentsData.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onSelect = (sel) => {
    let w = employee.filter((e) => e.employId === sel.employId);
    let a;
    if (w.length === 0) {
      setEmployee((employee) => [...employee, sel]);
      a = [...employee, sel];
    } else {
      setEmployee(employee.filter((e) => e.employId !== sel.employId));
      a = employee.filter((e) => e.employId !== sel.employId);
    }
    generateSkillScore(a);
  };

  const generateSkillScore = (scores) => {
    const totalEmployees = scores.length;

    setSortedData(
      [
        {
          label: t("NewAssessment.inductiveName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.inductiveReasoning, 0) /
              totalEmployees
            : 0,
          img: "./assets/report/inductive.png",
        },
        {
          label: t("NewAssessment.deductiveName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.deductionReasoning, 0) /
              totalEmployees
            : 0,
          img: "./assets/report/deductive.png",
        },
        {
          label: t("NewAssessment.quantitativeName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.quant, 0) / totalEmployees
            : 0,
          img: "./assets/report/quantitative.png",
        },
        {
          label: t("NewAssessment.attentionName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.attention, 0) / totalEmployees
            : 0,
          img: "./assets/report/attention.png",
        },
        {
          label: t("NewAssessment.workingMemoryName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.workingMemory, 0) / totalEmployees
            : 0,
          img: "./assets/report/memory.png",
        },
        {
          label: t("NewAssessment.processingSpeedName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.processingSpeed, 0) / totalEmployees
            : 0,
          img: "./assets/report/processingSpeed.png",
        },
        {
          label: t("NewAssessment.patternRecognitionName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.patternRecognition, 0) /
              totalEmployees
            : 0,
          img: "./assets/report/pattern.png",
        },
        {
          label: t("NewAssessment.criticalThinkingName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.criticalThinking, 0) /
              totalEmployees
            : 0,
          img: "./assets/report/critical-thinking.png",
        },
        {
          label: t("NewAssessment.decisionMakingName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.decistionMaking, 0) / totalEmployees
            : 0,
          img: "./assets/report/decision-making.png",
        },
        {
          label: t("NewAssessment.planningName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.planning, 0) / totalEmployees
            : 0,
          img: "./assets/report/planning.png",
        },
        {
          label: t("NewAssessment.learningSpeedName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.learningRate, 0) / totalEmployees
            : 0,
          img: "./assets/report/learning.png",
        },
        {
          label: t("NewAssessment.curiosityName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.curiosity, 0) / totalEmployees
            : 0,
          img: "./assets/report/curiosity.png",
        },
        {
          label: t("NewAssessment.riskTakingName"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.riskTaking, 0) / totalEmployees
            : 0,
          img: "./assets/report/risk-taking.png",
        },
        {
          label: t("NewAssessment.adaptability"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.adaptability, 0) / totalEmployees
            : 0,
          img: "./assets/report/adaptability.png",
        },
        {
          label: t("NewAssessment.consistence"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.consistence, 0) / totalEmployees
            : 0,
          img: "./assets/report/consistency.png",
        },
        {
          label: t("NewAssessment.goalSetting"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.goalSetting, 0) / totalEmployees
            : 0,
          img: "./assets/report/goal-setting.png",
        },
        {
          label: t("NewAssessment.cooperative"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.cooperative, 0) / totalEmployees
            : 0,
          img: "./assets/report/cooperative.png",
        },
        {
          label: t("NewAssessment.trusting"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.trusting, 0) / totalEmployees
            : 0,
          img: "./assets/report/trust.png",
        },
        {
          label: t("NewAssessment.assertiveness"),
          value: totalEmployees
            ? scores.reduce((a, b) => a + b.assertiveness, 0) / totalEmployees
            : 0,
          img: "./assets/report/assertive.svg",
        },
      ].sort((a, b) => b.value - a.value)
    );
  };

  const getDepartmentScore = (dd) => {
    setSelectedDepartment(dd);
    findDepartmentSkillScores({ departmentId: dd }).then((res) => {
      const scores = res.data;
      setScores(scores);
      setEmployee(scores);

      generateSkillScore(scores);
    });
  };

  const chartOptions = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    scales: {
      x: {
        beginAtZero: true,
        display: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      <div className="mt-5">
        <div>
          <div className="flex justify-between items-center">
            <div className="mb-6 w-1/4">
              <label
                htmlFor="department"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {t("Dashboard.department")}
              </label>
              <div className="relative">
                <select
                  id="Candidate"
                  className="w-[300px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                  required
                  value={selectedDepartment}
                  onChange={(e) => {
                    getDepartmentScore(e.target.value);
                  }}
                >
                  <option value="" disabled hidden>
                    {t("Dashboard.choose")}
                  </option>
                  {departments.map((data) => (
                    <option key={data.id} value={data.id}>
                      {`${data.name} `}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="20"
                viewBox="0 0 31 20"
                fill="none"
              >
                <path
                  d="M11.4303 0L0 9.63952L11.4303 19.0504V11.4303H30.4807V7.62017H11.4303V0Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="flex items-start">
              <div className="mb-6 me-5">
                <label
                  htmlFor="Player"
                  className="block mb-2 text-sm font-medium text-ray-900"
                >
                  {t("Dashboard.candidate")}
                </label>
                <div className="relative">
                  <select
                    id="Player"
                    className="w-[350px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                    required
                    value={selectedPlayer}
                    onChange={(e) => {
                      findSkillScoreByAP({ assPlayerId: e.target.value }).then(
                        (res) => {
                          const data = res.data;
                          const selectedPlayer = players.find(
                            (p) => p.apId === e.target.value
                          );
                          const { name } = selectedPlayer;

                          let score1 = {
                            adaptability: data.adaptability,
                            assertiveness: data.assertiveness,
                            attention: data.attention,
                            consistence: data.consistence,
                            cooperative: data.cooperative,
                            criticalThinking: data.criticalThinking,
                            curiosity: data.curiosity,
                            decistionMaking: data.decisionMaking,
                            deductionReasoning: data.deductiveReasoning,
                            employId: data.playerId,
                            firstName: name,
                            goalSetting: data.goalSetting,
                            inductiveReasoning: data.inductiveReasoning,
                            learningRate: data.learningRate,
                            patternRecognition: data.patternRecognition,
                            planning: data.planning,
                            processingSpeed: data.processingSpeed,
                            quant: data.quantitativeReasoing,
                            riskTaking: data.riskTaking,
                            trusting: data.trusting,
                            workingMemory: data.workingMemory,
                            gii: data.gii,
                            ci: data.ci,
                          };
                          setScores([...scores, score1]);
                          setEmployee([...employee, score1]);
                          generateSkillScore([...scores, score1]);
                        }
                      );
                      setSelectedPlayer(e.target.value);
                    }}
                  >
                    <option value="" disabled hidden>
                      {t("Dashboard.choose")}
                    </option>
                    {players.map((data) => (
                      <option key={data.apId} value={data.apId}>
                        {`${data.name} ${data.email}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-6 text-center">
                <label
                  htmlFor="myCheck"
                  className="block text-sm font-medium text-ray-900 mb-2"
                >
                  Shortlist by IQ Score
                </label>
                <input
                  type="checkbox"
                  id="myCheck"
                  className="h-9 w-9 rounded-lg border-[#D1D5DB] mx-auto"
                />
              </div>
            </div>
          </div>
          <TagDropDown
            employees={employee}
            scores={scores}
            onSelect={onSelect}
          />

          {console.log(scores)}
          <div>
            <div className="my-10">
              <button className="py-2 px-4 bg-[#55D6A0] text-[#f5f5f6] rounded-lg">
                Impact -{" "}
                {sortedData
                  .reduce((sum, item) => sum + item.value, 0)
                  .toFixed(2)}
              </button>
            </div>
          </div>
        </div>
        <div className="w-full  mb-10 flex gap-10 items-start">
          <div className="w-3/4">
            <Bar
              className="h-[500px]" // Increase the height value here
              data={chartData}
              options={chartOptions}
            />
          </div>
          <div className="w-1/4">
            <ul>
              {legendItems.map((item, index) => (
                <li key={index} className="mb-5">
                  <span
                    className="px-2 py-[-20px] me-1"
                    style={{ backgroundColor: item.fillStyle }}
                  ></span>
                  {item.text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeDashboard;
