import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSolidUser } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import MainButton from "../../components/buttons/MainButton";
import MainInput from "../../components/input/MainInput";
import { useUserContext } from "../../context/UserContext";
import { changeAccountInfo } from "../../services/user.service";
import { FaRegSave } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const UserInformation = ({ handlePasswordChange }) => {
  const { t } = useTranslation();
  const { user, setUser } = useUserContext();
  const navigate = useNavigate();
  // zurag avah func
  const userImg = null;
  const [selectedFileName, setSelectedFileName] = useState(userImg);
  const fileInputRef = useRef(null);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [username, setUsername] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [photoUrl, setPhotoUrl] = useState(null);
  const [editUserName, setEditUserName] = useState(false);
  const [editComName, setEditComName] = useState(false);
  const [editingName, setEditingName] = useState("");
  const [editingCom, setEditingCom] = useState("");

  useEffect(() => {
    setName(user?.name);
    setLastName(user?.lastName);
    setEmail(user?.userEmail);
    setCompanyName(user?.companyName);
    setUsername(user?.userName);
    setCreatedDate(user?.userCreatedDate);
    setPhotoUrl(user?.photoUrl);
  }, [user]);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 25 * 1024 * 1024) {
        toast.error(t("toast.imgError"));
        return;
      }
      const imageUrl = URL.createObjectURL(file);

      setPhotoUrl(imageUrl);
      const formData = new FormData();

      formData.append("name", name);
      formData.append("lastName", lastName);
      formData.append("userEmail", email);
      formData.append("file", fileInputRef.current.files[0]);

      changeAccountInfo(formData)
        .then((response) => {
          toast.success(t("toast.editSuccess"));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //chart
  const userUsedNumbers = 40;
  const totalRights = 100;

  const secondData = totalRights - userUsedNumbers;

  const data = {
    labels: [
      t("Profile.MyInformation.totalUsed"),
      t("Profile.MyInformation.remain"),
    ],
    datasets: [
      {
        data: [userUsedNumbers, secondData],
        backgroundColor: ["#FD6B3A", "#FFF3ED"],
      },
    ],
  };

  const options = {
    cutout: "60%",
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          font: {
            size: 15,
          },
        },
      },
      datalabels: {
        color: "white",
        formatter: (value) => {
          return Math.round(value) + "%";
        },
        anchor: "center",
        align: "center",
        font: {
          size: 15,
        },
        offset: 100,
      },
    },
  };

  return (
    <>
      <div className="mt-10 gap-20 flex">
        {console.log(user)}

        <ToastContainer />
        <div className="w-3/4">
          <div className="flex items-center gap-5">
            <div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
              {photoUrl ? (
                <div>
                  {selectedFileName ? (
                    <div className="relative">
                      <img
                        src={selectedFileName}
                        alt="selected user img"
                        className="m-0 w-[120px] h-[120px] rounded-full text-[#666874]"
                        onClick={handleImageClick}
                      />
                      <div
                        className="absolute right-[-13px] bottom-[0px] p-1 border rounded-full bg-[#324D72] border-[#324D72] text-white hover:text-[#324d72] hover:bg-white"
                        onClick={handleImageClick}
                      >
                        <FiEdit2 className="cursor-pointer w-5 h-5 m-[6px]" />
                      </div>
                    </div>
                  ) : (
                    <div className="relative">
                      <img
                        src={photoUrl}
                        alt="Selected"
                        style={{ width: "100px", borderRadius: "100%" }}
                        onClick={handleImageClick}
                      />
                      <div
                        className="absolute right-[-13px] bottom-[0px] p-1 border rounded-full bg-[#324D72] border-[#324D72] text-white hover:text-[#324d72] hover:bg-white"
                        onClick={handleImageClick}
                      >
                        <FiEdit2 className="cursor-pointer w-5 h-5 m-[6px]" />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`border relative rounded-full w-max ${
                    selectedFileName ? "p-0" : "p-2"
                  }`}
                  onClick={handleImageClick}
                >
                  {selectedFileName ? (
                    <div className="relative">
                      <img
                        src={selectedFileName}
                        alt="selected user img"
                        className="m-0 w-[120px] h-[120px] rounded-full text-[#666874]"
                        onClick={handleImageClick}
                      />
                      <div
                        className="absolute right-[-13px] bottom-[0px] p-1 border rounded-full bg-[#324D72] border-[#324D72] text-white hover:text-[#324d72] hover:bg-white"
                        onClick={handleImageClick}
                      >
                        <FiEdit2 className="cursor-pointer w-5 h-5 m-[6px]" />
                      </div>
                    </div>
                  ) : (
                    <BiSolidUser className="m-0 cursor-pointer w-[110px] h-[112px] text-[#666874]" />
                  )}
                  <div className="absolute right-[-3px] bottom-[0px] p-1 border rounded-full bg-[#324D72] border-[#324D72] text-white hover:bg-white hover:text-[#324d72]">
                    <FiEdit2 className="cursor-pointer w-5 h-5 m-[6px]" />
                  </div>
                </div>
              )}
            </div>
            <div className="ms-10 gap-2">
              {console.log(editingName)}
              {editUserName ? (
                <div className="flex items-center gap-2">
                  <div className="ms-10">
                    <input
                      className="rounded-lg"
                      type="text"
                      placeholder={username}
                      onChange={(e) => {
                        setEditingName(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        changeAccountInfo({ userName: editingName })
                          .then((res) => {
                            setUser({ ...user, userName: editingName });
                            setEditUserName(false);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                      className="bg-[#324d72] border rounded-full p-2 text-white hover:text-[#324d72] hover:bg-white"
                    >
                      <FaRegSave className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-2 ">
                  <div className="me-">
                    <button
                      onClick={() => {
                        setEditUserName(true);
                      }}
                      className="bg-[#324d72] border rounded-full p-2 text-white hover:text-[#324d72] hover:bg-white"
                    >
                      <FiEdit2 className="h-3 w-3" />
                    </button>
                  </div>
                  <p>
                    {t("Profile.MyInformation.username")}:{" "}
                    <span className="font-[900]">{username}</span>{" "}
                  </p>
                </div>
              )}
              {editComName ? (
                <div className="flex items-center gap-2">
                  <div className="ms-10">
                    <input
                      className="rounded-lg"
                      type="text"
                      placeholder={user?.companyName}
                    />
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setEditComName(false);
                      }}
                      className="bg-[#324d72] border rounded-full p-2 text-white hover:text-[#324d72] hover:bg-white"
                    >
                      <FaRegSave className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <div>
                    <button
                      onClick={() => {
                        setEditComName(true);
                      }}
                      className="bg-[#324d72] border rounded-full p-2 text-white hover:text-[#324d72] hover:bg-white"
                    >
                      <FiEdit2 className="h-3 w-3" />
                    </button>
                  </div>
                  <p>
                    {t("Profile.MyInformation.company")}:{" "}
                    <span className="font-bold"> {companyName}</span>
                  </p>
                </div>
              )}
              <p className="ms-10 mt-1">
                {t("Profile.MyInformation.startedDate")}:{" "}
                <span className="font-[900]">
                  {createdDate?.substring(0, 10)}
                </span>{" "}
              </p>
              <p className="ms-10 mt-1">
                {t("Profile.MyInformation.endDate")}:{" "}
                <span className="font-[900]">{endDate}</span>
              </p>
            </div>
          </div>
          <div>
            <div className="my-4">
              <label htmlFor="employeeNameInput" className="block mb-2">
                {t("Assessment.name")}
              </label>
              <MainInput
                type="text"
                className="w-2/3"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="my-4">
              <label htmlFor="employeeNameInput" className="block mb-2">
                {t("Assessment.lastName")}
              </label>
              <MainInput
                type="text"
                className="w-2/3"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
            <div className="my-4">
              <label htmlFor="companyNameInput" className="block mb-2">
                {t("Account.email")}
              </label>
              <MainInput
                type="text"
                className="w-2/3"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="bg-white mb-4">
            <div className="pr-4 py-4 ">
              <button
                onClick={handlePasswordChange}
                className="btn hover-bg-gray-100"
              >
                <FiEdit2 className="me-2" />
                <span className="hidden xs:block">
                  {t("Settings.changePassword")}
                </span>
              </button>
            </div>
          </div>
        </div>
        {/* <div className="w-1/4">
          <div>
            <div>
              <p className="text-2xl font-bold">
                {t("Profile.MyInformation.totalGameRights")}
              </p>
            </div>
          </div>
          <div>
            <div className=" w-3/4 h-3/4">
              <Doughnut
                className="w-full h-full"
                data={data}
                options={options}
              />
            </div>
          </div>
        </div> */}
      </div>
      <div className="flex justify-end gap-5">
        <div>
          <button
            onClick={() => {
              navigate("/");
            }}
            className="border border-[#ABADB5] px-4 py-2 rounded-[10px]"
          >
            {t("Profile.backButton")}
          </button>
        </div>
        <div>
          <MainButton
            label={t("Profile.saveButton")}
            color={"bg-main-blue"}
            labelColor={"text-white"}
            onClick={() => {
              const formData = new FormData();
              formData.append("name", name);
              formData.append("lastName", lastName);
              formData.append("userEmail", email);
              if (fileInputRef.current.files[0]) {
                formData.append("file", fileInputRef.current.files[0]);
              }

              changeAccountInfo(formData).then((res) => {
                setUser(res.data);
                toast.success(t("ResponseMsg.successfullyStored"));
              });
            }}
          />
        </div>
      </div>
    </>
  );
};
export default UserInformation;
