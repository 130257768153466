import React, { useEffect, useRef, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import "../../css/additional-styles/utility-patterns.scss";
import { IoIosArrowDown, IoIosArrowUp, IoMdArrowBack } from "react-icons/io";
import { FiMinus } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import { MdOutlineModeEdit } from "react-icons/md";
import { GoDownload } from "react-icons/go";
import { GrLinkNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

const Accordion = ({ data, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lastname, setLastname] = useState(data.lastname);
  const [firstname, setFirstname] = useState(data.firstname);
  const [position, setPosition] = useState(data.position);
  const [email, setEmail] = useState(data.email);

  const handleInputChange = (field, value) => {
    switch (field) {
      case "lastname":
        setLastname(value);
        break;
      case "firstname":
        setFirstname(value);
        break;
      case "position":
        setPosition(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
    // Create an updated representative object
    const updatedRepresentative = {
      lastname: field === "lastname" ? value : lastname,
      firstname: field === "firstname" ? value : firstname,
      position: field === "position" ? value : position,
      email: field === "email" ? value : email,
    };
    onChange(updatedRepresentative);
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center">
        <p className="text-black text-lg">1. Эрх бүхий төлөөлөгч</p>
        <button
          className="bg-[#000] text-white rounded-full p-2"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </button>
      </div>
      {isOpen && (
        <div className="mt-3">
          <label className="block text-sm">Овог*</label>

          <input
            className="rounded-lg w-full border-opacity-40"
            type="text"
            value={lastname}
            onChange={(e) => handleInputChange("lastname", e.target.value)}
          />
          <label className="block text-sm mt-2">Нэр*</label>
          <input
            className="rounded-lg w-full border-opacity-40"
            type="text"
            value={firstname}
            onChange={(e) => handleInputChange("firstname", e.target.value)}
          />
          <label className="block text-sm mt-2">Албан тушаал*</label>
          <input
            className="rounded-lg w-full border-opacity-40"
            type="text"
            value={position}
            onChange={(e) => handleInputChange("position", e.target.value)}
          />
          <label className="block text-sm mt-2">И-мейл хаяг*</label>
          <input
            className="rounded-lg w-full border-opacity-40"
            type="text"
            value={email}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
        </div>
      )}
    </div>
  );
};

const Contract = () => {
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const [displayForm, setDisplayForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [firstWord, setFirstWord] = useState("");
  const [lastNameFisrt, setLastNameFirst] = useState("");
  const [download, setDownload] = useState(false);
  const [isPayAsYouGo, setIsPayAsYouGo] = useState(true);
  const [formData, setFormData] = useState({
    ceoFirstName: "",
    ceoLastName: "",
    address: "",
    lastname: "",
    firstname: "",
    companyName: "",
    register: null,
    phoneNumber: null,
    representatives: [
      {
        lastname: "",
        firstname: "",
        position: "",
        email: "",
      },
    ],
  });
  const div0Ref = useRef(null);
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const div4Ref = useRef(null);
  const div5Ref = useRef(null);
  const div6Ref = useRef(null);
  const div7Ref = useRef(null);
  const div8Ref = useRef(null);
  const div9Ref = useRef(null);
  const div10Ref = useRef(null);
  const div11Ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (!submitted && offset > 180) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [submitted]);

  useEffect(() => {
    if (formData.ceoLastName && formData.ceoLastName !== "") {
      const first = formData.ceoLastName.slice(0, 1);
      setFirstWord(first);
    }
    if (formData.lastname && formData.lastname !== "") {
      const first = formData.lastname.slice(0, 1);
      setLastNameFirst(first);
    }
  }, [formData.ceoLastName, formData.lastname]);

  const scrollToDiv = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleDeleteRepresentative = () => {
    if (formData.representatives.length > 1) {
      const updatedRepresentatives = [...formData.representatives];
      updatedRepresentatives.splice(1, 1);
      setFormData({
        ...formData,
        representatives: updatedRepresentatives,
      });
    }
  };

  const handleChange = (updatedRepresentative) => {
    const updatedFormData = { ...formData };
    updatedFormData.representatives[0] = updatedRepresentative;
    setFormData(updatedFormData);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleAddRepresentative = () => {
    let emptyFieldFound = false;

    Object.entries(formData.representatives[0]).forEach(([key, value]) => {
      if (value === "") {
        emptyFieldFound = true;
        return;
      }
    });

    if (emptyFieldFound) {
      toast.error("Бүх талбарыг бөглөнө үү.");
      return false;
    }

    if (!emailRegex.test(formData.representatives[0].email)) {
      toast.error("И-Мейл хаягаа зөв оруулна уу.");
      return false;
    }

    setFormData({
      ...formData,
      representatives: [
        ...formData.representatives,
        {
          lastname: "",
          firstname: "",
          position: "",
          email: "",
        },
      ],
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const emptyFields = [];
    Object.entries(formData).forEach(([key, value]) => {
      if (
        key !== "phoneNumber" &&
        typeof value === "string" &&
        value.trim() === ""
      ) {
        emptyFields.push(key);
      }
    });

    const emptyRepresentativeFields = [];
    formData.representatives.forEach((representative, index) => {
      Object.entries(representative).forEach(([key, value]) => {
        if (typeof value === "string" && value.trim() === "") {
          emptyRepresentativeFields.push(
            `Representative ${index + 1} - ${key}`
          );
        }
      });
    });

    if (emptyFields.length > 0 || emptyRepresentativeFields.length > 0) {
      const errorMessage = emptyFields
        .concat(emptyRepresentativeFields)
        .join(", ");
      toast.error(`Бүх талбарыг бөглөнө үү`);
      return false;
    }

    if (formData.register === null || isNaN(formData.register)) {
      toast.error("Байгууллагын регистерийн дугаарыг зөв оруулна уу.");
      return false;
    }
    let registerDigits = formData.register.toString();
    if (registerDigits.length !== 7) {
      toast.error("Байгууллагын регистерийн дугаар 7 оронтой байх ёстой.");
      return false;
    }

    if (formData.phoneNumber === null || isNaN(formData.phoneNumber)) {
      toast.error("Утасны дугаар зөв оруулна уу.");
      return false;
    }

    let phoneDigits = formData.phoneNumber.toString();
    if (phoneDigits.length !== 8) {
      toast.error("Утасны дугаар 8 оронтой байх ёстой");
      return false;
    }

    const invalidEmailFound =
      formData.representatives.filter((representative) => {
        return !emailRegex.test(representative.email);
      }).length > 0;

    if (invalidEmailFound) {
      toast.error("И-Мейл хаягаа зөв оруулна уу.");
      return false;
    }
    setSubmitted(true);
  };

  const handleDownload = () => {
    setDownload(true);
    toast.success("Гэрээний хуулбарыг амжилттай татлаа.");
  };

  const handleSubmit = () => {
    if (download) {
      toast.success("Гэрээ амжилттай хийгдлээ.");
      setDisplayForm(false);
      setSubmitted(false);
      setFirstWord("");
      setFormData({
        address: "",
        lastname: "",
        firstname: "",
        companyName: "",
        register: "",
        phoneNumber: null,
        representatives: [
          {
            lastname: "",
            firstname: "",
            position: "",
            email: "",
          },
        ],
      });
      navigate("/subscription/payment");
    } else {
      toast.warning("Гэрээний хуулбарыг татаж авна уу!");
    }
  };

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const endYear = Number(year) + 1;

  return (
    <div>
      <div className="bg-[#F2F2F2] pt-10 px-[100px]">
        <div className="flex items-center justify-between ">
          <div>
            <button
              onClick={() => {
                if (submitted) {
                  setSubmitted(false);
                } else {
                  navigate("/subscription");
                }
              }}
              className="flex items-center gap-2 font-bold text-xl px-4 py-2 border-2 rounded-xl"
            >
              <IoArrowBackOutline />
              Буцах
            </button>
          </div>
        </div>
        <p className="pt-3 pb-5 text-3xl font-bold ms-4">Гэрээ</p>
      </div>
      <div className="w-10/12 mt-10 m-auto">
        <div className="flex items-start gap-10 w-full relative">
          <div
            className={` w-[70%] ${
              submitted ? "m-auto shadow-xl py-10 px-20 mt-10" : ""
            }`}
          >
            {submitted && (
              <div className="flex items-start justify-between mb-6">
                <div>
                  <p className="text-xl">Батлав</p>
                  <p className="text-xl">“МЕТАКОГ МЕТРИКС” ХХК</p>
                  <p className="text-lg">Гүйцэтгэх захирал</p>
                  <p className="mt-3">............................ </p>
                  <p className="text-lg">Б. Хүрэлбаатар</p>
                </div>
                <div>
                  <p className="text-xl">Батлав</p>
                  <p className="text-xl">
                    {formData.companyName
                      ? `“${formData.companyName}” ХХК`
                      : "........................................"}
                  </p>
                  <p className="text-lg">Гүйцэтгэх захирал</p>
                  <p className="mt-3">............................ </p>
                  <p className="text-lg">
                    {firstWord !== "" ? `${firstWord}. ${" "}` : "---"}
                    {formData.ceoFirstName
                      ? formData.ceoFirstName
                      : "----------------"}
                  </p>
                </div>
              </div>
            )}
            {/* гэрээний хэсэг */}
            <div
              ref={div0Ref}
              className={`${
                submitted ? "shadow-none" : "shadow-lg rounded-xl px-8 py-10"
              }`}
            >
              {submitted ? (
                <div>
                  <p className="text-center mb-2 text-xl font-bold uppercase">
                    Үйлчилгээ үзүүлэх гэрээ
                  </p>
                  <div className="my-2 flex justify-between items-center">
                    <p>
                      {year} оны {month} -р сарын {day} -ний өдөр
                    </p>
                    <p>№ __</p>
                    <p>Улаанбаатар хот</p>
                  </div>
                </div>
              ) : (
                <p className="text-center mb-2 text-lg">
                  <span className="font-bold">Үйлчилгээ үзүүлэх гэрээ </span>
                  <br /> {year} оны {month} сарын {day} өдөр Улаанбаатар хот.
                </p>
              )}
              <p>
                Энэхүү Үйлчилгээ үзүүлэх гэрээ (“Гэрээ”)-г нэг талаас Баянзүрх
                дүүрэг 26-р хороо, Хүннү гудамж-215, 803 тоот “Метаког Метрикс”
                ХХК (РД:6817629) , (цаашид “Үйлчилгээ үзүүлэгч” гэх) ,  нөгөө
                талаас{" "}
                <span
                  onClick={() => {
                    if (!displayForm) {
                      setDisplayForm(true);
                      console.log("clicking");
                    }
                  }}
                  className="underline font-bold text-[#324d72] cursor-pointer"
                >
                  {formData.address === "" ? "__________" : formData.address}
                </span>{" "}
                тоот хаягт байрлах{" "}
                <span
                  onClick={() => {
                    if (!displayForm) {
                      setDisplayForm(true);
                    }
                  }}
                  className="underline font-bold text-[#324d72]"
                >
                  {formData.register === "" ? "______" : formData.register}
                </span>{" "}
                тоот регистрийн дугаартай “
                <span
                  onClick={() => {
                    if (!displayForm) {
                      setDisplayForm(true);
                    }
                  }}
                  className="underline font-bold text-[#324d72]"
                >
                  {formData.companyName === ""
                    ? "__________"
                    : formData.companyName}
                </span>
                ” ХХК (цаашид “Хэрэглэгч” гэх) түүнийг төлөөлж{" "}
                <span
                  onClick={() => {
                    if (!displayForm) {
                      setDisplayForm(true);
                    }
                  }}
                  className="text-[#324d72] underline font-bold"
                >
                  {formData.lastname === "" ? "_____" : formData.lastname}
                </span>{" "}
                овогтой{" "}
                <span
                  onClick={() => {
                    if (!displayForm) {
                      setDisplayForm(true);
                    }
                  }}
                  className="text-[#324d72] underline font-bold"
                >
                  {formData.firstname === "" ? "_______" : formData.firstname}
                </span>{" "}
                нар (цаашид хамтад нь “Талууд” гэх ) харилцан тохиролцож
                байгуулав.
              </p>
            </div>
            {/* 1r hesg*/}
            <div
              ref={div1Ref}
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p
                className={`font-bold mb-5 ${
                  submitted ? "text-center text-xl" : "text-lg"
                }`}
              >
                Нэг. Гэрээний зорилго
              </p>
              <div className="flex gap-2 items-start">
                <p>1.</p>
                <p>
                  1.1.   Энэхүү гэрээгээр үйлчилгээ үзүүлэгч нь хүний нөөцийн
                  сонгон шалгаруулалтын платформоор дамжуулан ажил горилогч руу
                  шалгаруулалтын урилга явуулах, явц хянах, тайлан хянах, хүний
                  нөөцийн сонгон шалгаруулалтыг удирдах багц үйлчилгээг /цаашид
                  “Платформ” гэх/ болон  бусад нэмэлт үйлчилгээг /цаашид “Нэмэлт
                  үйлчилгээ” гэх/ техникийн өндөр түвшинд үзүүлэх, Хэрэглэгч
                  өөрийн ашиглах үйлчилгээний төлбөрийг төлөхтэй холбоотой
                  талуудын эдлэх эрх, хүлээх үүрэг, хариуцлагыг тогтоох,
                  хэрэгжүүлэхтэй холбогдсон харилцааг зохицуулна.
                </p>
              </div>{" "}
            </div>{" "}
            {/* 2r hesg*/}
            <div
              ref={div2Ref}
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p
                className={`font-bold mb-5 ${
                  submitted ? "text-center text-xl" : "text-lg"
                }`}
              >
                Хоёр. Гэрээний хугацаа
              </p>
              <div className="flex gap-2 items-start">
                <p>1.</p>
                <p>
                  2.1. Энэхүү гэрээг {year} оны {month} дүгээр сарын  {day}-ны
                  өдрөөс эхлэн {endYear} оны {month} дүгээр сарын {day}-ны
                  өдрийг дуустал 1 жилийн хүчинтэй хугацаатай байгуулав.
                </p>
              </div>{" "}
            </div>
            {/* 3r hesg*/}
            <div
              ref={div3Ref}
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p
                className={`font-bold mb-5 ${
                  submitted ? "text-center text-xl" : "text-lg"
                }`}
              >
                Гурав. Үйлчилгээний төлбөр, төлөх нөхцөл
              </p>
              <div className="flex gap-2 items-start mb-4">
                <p>1.</p>
                <p>
                  3.1  
                  {isPayAsYouGo
                    ? `Нэг
                  хүн тоглоход ₮55.000 бөгөөд тухайн сарын хэрэглээг дараа сарын
                  05-ны өдрийн дотор нэхэмжлэх илгээж байх болно.`
                    : `Хэрэглэгч нь “Платформ”– ыг ашиглахдаа ямар нэгэн
                  хязгааргүй ашиглах бөгөөд сар болгоны сүүлээр үйлчилгээ
                  үзүүлэгч нь тоглуулсан хүний тоогоор нэхэмжилж байх болно.`}
                </p>
              </div>
              {isPayAsYouGo && (
                <div className="flex gap-2 items-start mb-4">
                  <p>2.</p>
                  <p>
                    3.2   Хэрэглэгч нь үндсэн багцуудад хамаарахгүй төлбөрийн
                    нөхцөлтэй үйлчилгээ авч байгаа тохиолдолд аккаунт нээж
                    өгсөний хариуд үйлчилгээ үзүүлэгч талд нэг удаагийн хураамж
                    болох 700,000 төгрөгийг шилжүүлэх үүрэгтэй.
                  </p>
                </div>
              )}
              <div className="flex gap-2 items-start mb-4">
                <p>{isPayAsYouGo ? "3." : "2."}</p>
                <p>
                  {isPayAsYouGo ? "3.3" : "3.2"}   Энэхүү тарифын үнэд НӨАТ
                  болон холбогдох бүх татвар хураамжууд орсон болно.
                </p>
              </div>
              <div className="flex gap-2 items-start">
                <p>{isPayAsYouGo ? "4." : "3."}</p>
                <p>
                  {isPayAsYouGo ? "3.4" : "3.3"}   Хэрэглэгч нь нэхэмжлэх хүлээн
                  авснаас хойш ажлын 3 хоногийн дотор үйлчилгээний төлбөрийг
                  үйлчилгээ үзүүлэгчийн
                  <span className="font-bold"> 3215122950</span> дугаартай{" "}
                  <span className="font-bold">Голомт Банк</span>ны дансанд 
                  шилжүүлнэ.
                </p>
              </div>
            </div>
            {/* 4r hesg*/}
            <div
              ref={div4Ref}
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p
                className={`font-bold mb-5 ${
                  submitted ? "text-center text-xl" : "text-lg"
                }`}
              >
                Дөрөв. Хэрэглэгчийн эрх, үүрэг
              </p>
              <div className="flex gap-2 items-start mb-4">
                <p>1.</p>
                <p>
                  4.1. Хэрэглэгч дараах эрхтэй: <br /> {""}
                  &nbsp;&nbsp;&nbsp;&nbsp;4.1.1 Хэрэглэгч нь үйлчилгээний
                  чанартай холбоотой хүсэлтийг үйлчилгээ үзүүлэгчид тавьж
                  шийдвэрлүүлэх, гэрээгээр тохиролцсон нөхцөлийн дагуу үйлчилгээ
                  үзүүлэхийг шаардах, хэрэглээтэй холбоотой санал, хүсэлт,
                  гомдлоо үйлчилгээ үзүүлэгчийн{" "}
                  <span className="font-bold">hello@metacog.mn</span> хаягт
                  илгээн гаргах эрхтэй. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;4.1.2 Хэрэглэгч нь гэрээний нөхцөл,
                  үйлчилгээний төлбөр, үйлчилгээ үзүүлэгчийн санал болгож буй
                  бусад нэмэлт үйлчилгээний талаар мэдээлэл, нэмэлт тодруулга
                  авах эрхтэй. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;4.1.3 Үйлчилгээ үзүүлэгч нь гэрээний
                  үүргээ биелүүлээгүй тохиолдолд гэрээг цуцлах, учирсан хохирлыг
                  шаардах, эсхүл түүнд өөрчлөлт оруулахыг шаардах эрхтэй. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;4.1.4 Үйлчилгээний хэвийн үйл
                  ажиллагааг хангуулахаар техникийн зөвлөгөө, тусламж дэмжлэгийг
                  үйлчилгээ үзүүлэгчээс авах эрхтэй. <br />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;4.1.5 Үйлчилгээ үзүүлэгчээс шалтгаалан
                  үйлчилгээг ашиглах боломжгүй байсан хугацааны төлбөрийг
                  гэрээнд заасан багцийн төлбөрөөс хасаж төлнө
                </p>
              </div>
              <div className="flex gap-2 items-start">
                <p>2.</p>
                <p>
                  4.2. Хэрэглэгч дараах үүрэгтэй: <br />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp; 4.2.1 Хэрэглэгч нь систем рүү хандах
                  эрхийн нууцлалыг хангаж, зөвхөн өөрийн байгууллагын тогтоосон
                  хэм хэмжээнд ашиглаж, гуравдагч этгээдэд ашиглуулахгүй байх
                  үүрэгтэй. <br /> &nbsp;&nbsp;&nbsp;&nbsp; 4.2.2 Хэрэглэгч нь
                  сонгон шалгаруулалтад оролцож буй ажил горилогчид ерөнхий
                  зааварчилгаа өгөх үүрэгтэй. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 4.2.3 Үйлчилгээний төлбөрийг гэрээнд
                  заасан хугаацаны дагуу төлөх үүрэгтэй. Хэрэв үйлчилгээний
                  төлбөрийг хугацаанд нь төлөөгүйгээс үйлчилгээг түр хаасан
                  хугацаан дахь аливаа хохирол, үр дагавар, хариуцлагыг
                  Хэрэглэгч хариуцна. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 4.2.4 Хэрэглэгчийн системтэй
                  холбоотой гэмтэл, үйлчилгээнд доголдол гарвал үйлчилгээ
                  үзүүлэгчийн{" "}
                  <span className="font-bold">hello@metacog.mn</span> хаяг - руу
                  холбогдон нэн даруй мэдэгдэнэ. <br /> &nbsp;&nbsp;&nbsp;&nbsp;
                  4.2.5 Хэрэглэгчийн гэрээ хариуцсан ажилтан эсвэл админ нь
                  байгууллагын бусад хэрэглэгчдэд үйлчилгээний талаар мэдээлэл
                  хүргэж, зааварчилгаа өгч, системийн эрхийн бүрэн ашиглалтыг
                  хариуцна.
                </p>
              </div>
            </div>
            {/* 5r hesg*/}
            <div
              ref={div5Ref}
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p
                className={`font-bold mb-5 ${
                  submitted ? "text-center text-xl" : "text-lg"
                }`}
              >
                Тав. Үйлчилгээ үзүүлэгчийн эрх, үүрэг
              </p>
              <div className="flex gap-2 items-start mb-4">
                <p>1.</p>
                <p>
                  5.1. Үйлчилгээ үзүүлэгч дараах эрхтэй: <br />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.1.1 Үйлчилгээ үзүүлэгч нь
                  Хэрэглэгчийн төхөөрөмжийн механик гэмтэл, зөөврийн
                  компьютерийн хүчин чадлаас, цахилгааны хэлбэлзлээс шалтгаалсан
                  гэмтэл, түүнчлэн хэрэглэгчийн интернэтийн байнгын холболтын
                  сүлжээний гэмтэл саатлыг хариуцахгүй. <br />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.1.2 Хэрэглэгчийн хаяг өөрчлөгдсөн,
                  гэрээний хэрэгжилт хариуцсан ажилтан солигдсон зэрэг талаар
                  үйлчилгээ үзүүлэгчид мэдэгдээгүйн улмаас илгээсэн төлбөрийн
                  нэхэмжлэхийг хүлээж аваагүйтэй холбоотой үр дагаврыг үйлчилгээ
                  үзүүлэгч хариуцахгүй. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.1.3 Үйлчилгээ үзүүлэгч нь гэрээний
                  үүргээ биелүүлэхийг хэрэглэгчээс шаардах, гэрээний үүргээ
                  биелүүлээгүй тохиолдолд үйлчилгээг түр хязгаарлах эрхтэй.
                  <br /> &nbsp;&nbsp;&nbsp;&nbsp; 5.1.4 Хэрэглэгч үйлчилгээний
                  төлбөрийг төлөөгүйгээс гэрээнд заасан нөхцөл байдал үүссэн
                  тохиолдолд хэрэглэгчид учирсан аливаа хохирол, үр дагавар,
                  хариуцлагыг үйлчилгээ үзүүлэгч хариуцахгүй. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.1.5 Гэрээний төлбөр гэрээнд заасан
                  нэмэлт хугацаанд төлөгдөөгүй бол үйлчилгээ үзүүлэгч гэрээг
                  цуцлах эрхтэй бөгөөд төлбөр барагдуулах явцад холбогдон гарах
                  аливаа нэмэлт зардлыг үйлчилгээний төлбөрийн хамт нэхэмжлэх
                  эрхтэй. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.1.6 Үйлчилгээ үзүүлэгч нь ажил
                  горилогчийн мэдээллийг эзэмших эрхтэй бөгөөд, хэрвээ тухайн
                  ажил горилогч Хэрэглэгчээс өөр компанид ажилд орох сонирхлоор
                  тоглоомуудыг тоглох үед Үйлчилгээ үзүүлэгч нь үүнийг
                  хязгаарлах, удирдах эрхтэй.
                </p>
              </div>
              <div className="flex gap-2 items-start">
                <p>2.</p>
                <p>
                  5.2. Үйлчилгээ үзүүлэгч дараах үүрэгтэй: <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.2.1 Үйлчилгээ үзүүлэгч үйлчилгээний
                  хэвийн ажиллагаанд байнгын хяналт тавьж, тасралтгүй ажиллагааг
                  ханган гэрээгээр тохирсон үйлчилгээг чанартай үзүүлэх. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.2.2{" "}
                  <span className="font-bold">hello@metacog.mn</span> хаягаар
                  ирсэн хэрэглэгчийн санал, хүсэлт, гомдлыг хүлээн авч шуурхай
                  арга хэмжээ аван гарсан эвдрэл, доголдлыг засварлах үүрэгтэй.{" "}
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.2.3{" "}
                  <span className="font-bold">hello@metacog.mn</span> хаягаар
                  ирсэн хэрэглэгчийн санал, хүсэлт, гомдлыг хүлээн авснаас хойш
                  ажлын 2 хоногийн дотор хэрэглэгч талд хэр хугацааны дотор
                  засварлаж, тус хугацаанд хэвийн ажиллагаанд шилжих тухай
                  мэдээллээр хангана. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.2.4 Үйлчилгээ үзүүлэгч нь тоног
                  төхөөрөмж, системийн шинэчлэл, өргөтгөл хийхэд үйлчилгээг
                  зогсоох шаардлагатай бол үйлчилгээг түр зогсоох тухай
                  хэрэглэгчид урьдчилан мэдэгдэнэ. <br />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.2.5 Ажил горилогчоос системд
                  оруулсан мэдээллийн аюулгүй байдал, хувийн мэдээллийг
                  хамгаалах үүрэгтэй. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.2.6 Үйлчилгээ үзүүлэгч нь системд
                  ямарваа нэгэн өөрчлөлт, шинэчлэлт сайжруулалт хийсэн
                  тохиолдолд Хэрэглэгчид нэн даруй мэдэгдэх үүрэгтэй. <br />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp; 5.2.7 Системд орсон аливаа өөрчлөлт,
                  шинэчлэлтийг мэдэгдээгүйгээс системийн хэвийн ашиглалтад
                  үүссэн асуудлыг үйлчилгээ үзүүлэгч бүрэн хариуцна.
                </p>
              </div>
            </div>{" "}
            {/* 6r hesg*/}
            <div
              ref={div6Ref}
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p
                className={`font-bold mb-5 ${
                  submitted ? "text-center text-xl" : "text-lg"
                }`}
              >
                Зургаа. Гэрээг дуусгавар болгох, цуцлах{" "}
              </p>
              <div className="flex gap-2 items-start mb-4">
                <p>1.</p>
                <p>
                  6.1 Гэрээний хугацаа дуусгавар болж гэрээг цаашид сунгахгүй
                  талаар талууд нөгөө талдаа санал гаргаснаар гэрээ дуусгавар
                  болно.
                </p>
              </div>
              <div className="flex gap-2 items-start mb-4">
                <p>2.</p>
                <p>
                  6.2 Дараах тохиолдолд аль нэг талын санаачилгаар гэрээнд
                  заасан нөхцөл журмын дагуу гэрээг хугацаанаас нь өмнө цуцалж
                  болно. Үүнд: <br /> &nbsp;&nbsp;&nbsp;&nbsp; 6.2.1 Гэрээний
                  талуудын аль нэг гэрээгээр хүлээсэн үүргээ удаа дараа (удаа
                  дараа гэдэгт хоёр ба түүнээс олон удааг ойлгоно) зөрчсөн
                  тохиолдолд; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 6.2.2 Хэрэглэгч нь үйлчилгээ
                  үзүүлэгчээс хууль бус үйлдэл хийхийг удаа дараа шаардсан
                  тохиолдолд; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 6.2.3 Үйлчилгээ үзүүлэгч нь
                  хэрэглэгчид хууль бус үйлдэл хийхийг удаа дараа шаардсан
                  тохиолдолд; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; 6.2.4 Хуульд заасан бусад үндэслэл
                  бий болсон тохиолдолд;
                </p>
              </div>
              <div className="flex gap-2 items-start mb-4">
                <p>3.</p>
                <p>
                  6.3 Мэдэгдэл хүлээн авсан тал нь уг мэдэгдэлтэй холбогдуулан
                  санал гаргагч талаас нэмэлт мэдээллийг хүсэж болох ба хуанлийн
                  21 хоногийн хугацаанд талууд тохиролцон гэрээг үргэлжлүүлэх,
                  цуцлах асуудлыг шийдвэрлэнэ. Хэрэв талууд ямар нэгэн шийдвэрт
                  хүрээгүй, мэдэгдэл хүлээн авсан тал ямар нэгэн санал
                  ирүүлээгүй тохиолдолд мэдэгдэл хүргүүлснээс хойш хуанлийн 21
                  хоногийн дараа гэрээ цуцлагдсанд тооцно.
                </p>
              </div>
              <div className="flex gap-2 items-start">
                <p>4.</p>
                <p>
                  6.4 Талуудын мэдэгдэл хүргүүлсэн хоногийг хуульд зааснаар
                  шийдвэрлэнэ. Үүнд: мэдэгдлийг шуудангаар хүргүүлсэн бол
                  шууданд өгсөн өдрөөс, и-мэйлээр хүргүүлсэн бол мэдэгдэл хүлээн
                  авагчийн хүлээн авсан өдрөөр, бичгээр хүргүүлсэн бол хүлээн
                  авсан этгээдийн эрх бүхий ажилтан хүлээн авсан тухай гарын
                  үсэг зурсан өдрөөс эхлэн тоолно.
                </p>
              </div>
            </div>{" "}
            {/* 7r hesg*/}
            <div
              ref={div7Ref}
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p
                className={`font-bold mb-5 ${
                  submitted ? "text-center text-xl" : "text-lg"
                }`}
              >
                Долоо. Талуудын хариуцлага
              </p>
              <div className="flex gap-2 items-start mb-4">
                <p>1.</p>
                <p>
                  7.1 Гэрээний нэг тал гэрээгээр хүлээсэн үүргээ зохих ёсоор
                  биелүүлээгүйгээс нөгөө талд учирсан хохирлыг хариуцаж,
                  барагдуулна.
                </p>
              </div>
              <div className="flex gap-2 items-start mb-4">
                <p>2.</p>
                <p>
                  7.2 Хэрэглэгч нь гэрээнд заасан төлбөрөө хугацаанд нь төлөөгүй
                  тохиолдолд хугацаа хэтэрсэн хоног тутамд гүйцэтгээгүй
                  төлбөрийн үнийн дүнгийн 0.3 хувьтай тэнцэх хэмжээний алдангийг
                  үйлчилгээ үзүүлэгч талд төлнө.
                </p>
              </div>
              <div className="flex gap-2 items-start mb-4">
                <p>3.</p>
                <p>
                  7.3 Үйлчилгээ үзүүлэгч нь системийн доголдлыг гэрээний 5.2.3-т
                  заасан хугацаанд засварлаагүй тохиолдолд хугацаа хэтэрсэн
                  хоног тутамд тухайн сарын багцийн үнийн дүнгийн 0.3-тай тэнцэх
                  алдангийг хэрэглэгчид төлөх, эсхүл багцын төлбөрөөр суутгана.
                </p>
              </div>
              <div className="flex gap-2 items-start mb-4">
                <p>4.</p>
                <p>
                  7.4 Талуудын гэрээний үүргээ биелүүлээгүй, цаашид биелүүлэх
                  боломжгүй нь гэнэтийн болон давагдашгүй хүчин зүйл (дайн,
                  байлдаан, иргэний бослого хөдөлгөөн, террорист халдлага,
                  байгалийн гамшигт үзэгдэл, гал түймэр, үер, хур тунадас, газар
                  хөдлөлт, шороон шуурга, тэдгээрийн улмаас цахилгаан тасарсан,
                  төрийн эрх бүхий байгууллагаас гарсан шийдвэр, хорио, цээрийн
                  дэглэм, гоц халдварт өвчин, ажил хаялт, нийтийг хамарсан эмх
                  замбараагүй байдал, үймээн самуун, зэвсэгт мөргөлдөөн, түлш,
                  эрчим хүчний хязгаарлалт, сүлжээний хэт ачаалал, доголдол,
                  тасалдал)- аас болон шалтгаалсан тохиолдолд талууд хариуцлага
                  хүлээхгүй бөгөөд гэрээг цааш үргэлжлүүлэх эсэхийг харилцан
                  тохиролцож шийдвэрлэнэ.
                </p>
              </div>
              <div className="flex gap-2 items-start">
                <p>5.</p>
                <p>
                  7.5 Талууд энэхүү гэрээг хийхтэй холбогдон олж авсан аливаа
                  нууцад хамаарах мэдээллийг хуульд заасан үндэслэл, эрх бүхий
                  байгууллагын шаардлага, нөгөө талын бичгээр өгсөн зөвшөөрлөөс
                  бусад тохиолдолд гуравдагч этгээдэд задруулахгүй.
                </p>
              </div>
            </div>{" "}
            {/* 8r hesg*/}
            <div
              ref={div8Ref}
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p
                className={`font-bold mb-5 ${
                  submitted ? "text-center text-xl" : "text-lg"
                }`}
              >
                Найм. Үйлчилгээ үзүүлэх нөхцөл
              </p>
              <div className="flex gap-2 items-start mb-4">
                <p>1.</p>
                <p>
                  8.1 Энэхүү гэрээний дагуу үйлчилгээ үзүүлэгч нь хэрэглэгчээс
                  болон түүнээс хамаарахгүй гуравдагч этгээдийн учруулсан,
                  үүссэн гэмтлийг хурдан шуурхай засварлана. Засварлах хугацаа
                  нь гэмтлийн нөхцөл байдлаас хамааран харилцан адилгүй байдаг
                  тул тухай бүрт ойролцоо хугацааг Хэрэглэгчид мэдэгдэх
                  үүрэгтэй.
                </p>
              </div>
              <div className="flex gap-2 items-start mb-4">
                <p>2.</p>
                <p>
                  8.2 Засварлах хугацаа удаашрах тохиолдолд хэрэглэгчид мэдэгдэх
                  ба ийнхүү мэдээллээр хангаж ажилласан нь ямар нэгэн хариуцлага
                  хүлээх үндэслэл болохгүй.
                </p>
              </div>
              <div className="flex gap-2 items-start">
                <p>3.</p>
                <p>
                  8.3 Гэрээний 8.1-д заасан засварлах хугацаа ажлын 5 хоногоос
                  дээш байх тохиолдолд гэрээний 4.1.5-д заасны дагуу багцын
                  төлбөрийг тухайн хойшилсон хугацаагаар хойшлуулах эсхүл
                  төлбөрөөс хасч тооцно.
                </p>
              </div>
            </div>{" "}
            {/* 9r hesg*/}
            <div
              ref={div9Ref}
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p
                className={`font-bold mb-5 ${
                  submitted ? "text-center text-xl" : "text-lg"
                }`}
              >
                Ес. Талуудын эрх бүхий төлөөлөгчид
              </p>
              <div className="flex gap-2 items-start mb-4">
                <p>1.</p>
                <p>
                  9.1 Энэхүү гэрээний дагуу талууд нь дараах ажилтнуудыг эрх
                  бүхий хүнд тооцно.
                </p>
              </div>
              <div className="mb-4">
                <p>Үүнд:</p>
                <ul className="list-disc">
                  <li className="ms-6">
                    <span
                      onClick={() => {
                        if (!displayForm) {
                          setDisplayForm(true);
                        }
                      }}
                      className="font-bold text-[#324d72] underline"
                    >
                      {formData.representatives[0].lastname === ""
                        ? "___________"
                        : formData.representatives[0].lastname}
                    </span>{" "}
                    овогтой{" "}
                    <span
                      onClick={() => {
                        if (!displayForm) {
                          setDisplayForm(true);
                        }
                      }}
                      className="font-bold text-[#324d72] underline"
                    >
                      {formData.representatives[0].firstname === ""
                        ? "_______________"
                        : formData.representatives[0].firstname}
                    </span>{" "}
                    нь{" "}
                    <span
                      onClick={() => {
                        if (!displayForm) {
                          setDisplayForm(true);
                        }
                      }}
                      className="font-bold text-[#324d72] underline"
                    >
                      {formData.representatives[0].position === ""
                        ? "________________"
                        : formData.representatives[0].position}
                    </span>{" "}
                    албан тушаалын дор хэрэглэгч тал болж оролцоно. /
                    <span
                      onClick={() => {
                        if (!displayForm) {
                          setDisplayForm(true);
                        }
                      }}
                      className="font-bold text-[#324d72] underline"
                    >
                      {formData.representatives[0].email === ""
                        ? "_________________"
                        : formData.representatives[0].email}
                    </span>{" "}
                    холбоо барих мейл хаяг/
                  </li>
                  {formData.representatives.length === 1 ? (
                    ""
                  ) : (
                    <li className="ms-6">
                      <span
                        onClick={() => {
                          if (!displayForm) {
                            setDisplayForm(true);
                          }
                        }}
                        className="font-bold text-[#324d72] underline"
                      >
                        {formData.representatives[1].lastname === ""
                          ? "___________"
                          : formData.representatives[1].lastname}
                      </span>{" "}
                      овогтой{" "}
                      <span
                        onClick={() => {
                          if (!displayForm) {
                            setDisplayForm(true);
                          }
                        }}
                        className="font-bold text-[#324d72] underline"
                      >
                        {formData.representatives[1].firstname === ""
                          ? "_______________"
                          : formData.representatives[1].firstname}
                      </span>{" "}
                      нь{" "}
                      <span
                        onClick={() => {
                          if (!displayForm) {
                            setDisplayForm(true);
                          }
                        }}
                        className="font-bold text-[#324d72] underline"
                      >
                        {formData.representatives[1].position === ""
                          ? "________________"
                          : formData.representatives[1].position}
                      </span>{" "}
                      албан тушаалын дор хэрэглэгч тал болж оролцоно. /
                      <span
                        onClick={() => {
                          if (!displayForm) {
                            setDisplayForm(true);
                          }
                        }}
                        className="font-bold text-[#324d72] underline"
                      >
                        {formData.representatives[1].email === ""
                          ? "_________________"
                          : formData.representatives[1].email}
                      </span>{" "}
                      холбоо барих мейл хаяг/
                    </li>
                  )}
                  <li className="ms-6">
                    Бат-Орших овогтой{" "}
                    <span className="font-bold">Хүрэлбаатар</span> нь{" "}
                    <span className="font-bold">Гүйцэтгэх захирал албан</span>{" "}
                    тушаалын дор үйлчилгээ үзүүлэгч тал болж оролцоно.
                    /khurelbaatar@metacog.mn/{" "}
                  </li>
                  <li className="ms-6">
                    Алтанхуяг овогтой <span className="font-bold">Ананд</span>{" "}
                    нь{" "}
                    <span className="font-bold">
                      Технологи хариуцсан захирал
                    </span>{" "}
                    албан тушаалын дор үйлчилгээ үзүүлэгч тал болж оролцоно.
                    /anand@metacog.mn/{" "}
                  </li>
                </ul>
              </div>
              <div className="flex gap-2 items-start ">
                <p>2.</p>
                <p>
                  9.2 Энэхүү гэрээний 10.2-т заасан талуудын ажилтны мэдээлэл
                  өөрчлөгдсөн тохиолдолд нэн даруй уг мэдээллийг нөгөө талдаа
                  гэрээнд заасан нөхцөлөөр хүргүүлэх ба ийнхүү хүргүүлээгүйгээс
                  аль нэг талд хохирол учирсан тохиолдолд мэдээлэл аваагүй тал
                  ямар нэгэн байдлаар хариуцлага хүлээхгүй.
                </p>
              </div>
            </div>{" "}
            {/* 10r hesg*/}
            <div
              ref={div10Ref}
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p
                className={`font-bold mb-5 ${
                  submitted ? "text-center text-xl" : "text-lg"
                }`}
              >
                Арав. Гэрээнд нэмэлт өөрчлөлт оруулах, гэрээний хавсралт
              </p>
              <div className="flex gap-2 items-start">
                <p>1.</p>
                <p>
                  10.1 Гэрээнд нэмэлт, өөрчлөлтийг талууд харилцан тохиролцож
                  зөвхөн бичгээр оруулах бөгөөд тус бүр 2 хувь үйлдэн, талуудын
                  эрх бүхий этгээд гарын үсэг зурж, тэмдэг дарж баталгаажуулна.
                  Гэрээний нэмэлт, өөрчлөлтийг гэрээнд хавсаргах ба энэ нь
                  энэхүү гэрээний салшгүй хэсэг байна.
                </p>
              </div>
            </div>
            {/* busad hesg*/}
            <div
              ref={div11Ref}
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p
                className={`font-bold mb-5 ${
                  submitted ? "text-center text-xl" : "text-lg"
                }`}
              >
                Бусад
              </p>
              <div className="flex gap-2 items-start mb-4">
                <p>1.</p>
                <p>
                  11.1 Гэрээнд талуудын эрх бүхий этгээд гарын үсэг зурж, тэмдэг
                  дарснаар хүчин төгөлдөр болно.
                </p>
              </div>
              <div className="flex gap-2 items-start mb-4">
                <p>2.</p>
                <p>
                  11.2 Энэхүү гэрээг 2 эх хувь үйлдэж тал тус бүр нэг хувийг
                  хадгална.
                </p>
              </div>
              <div className="flex gap-2 items-start mb-4">
                <p>3.</p>
                <p>
                  11.3 Талууд гэрээнд аливаа нэмэлт өөрчлөлт оруулах тохиолдолд
                  харилцан тохиролцож нэмэлт гэрээ байгуулж гарын үсэг, тамга
                  тэмдэг дарснаар хүчин төгөлдөр болно.
                </p>
              </div>
              <div className="flex gap-2 items-start mb-4">
                <p>4.</p>
                <p>
                  11.4 Гэрээгээр зохицуулаагүй бусад асуудлыг Монгол Улсын
                  холбогдох хууль тогтоомжийн дагуу зохицуулна.
                </p>
              </div>
              <div className="flex gap-2 items-start">
                <p>5.</p>
                <p>
                  11.5 Гэрээний харилцаатай холбоотой гарсан маргааныг талууд
                  зөвшилцсөний үндсэн дээр харилцан тохиролцож шийдвэрлэх бөгөөд
                  хэрэв тодорхой зөвшилцөлд хүрээгүй тохиолдолд маргааныг Монгол
                  Улсын Шүүхээр шийдвэрлүүлнэ.
                </p>
              </div>
            </div>{" "}
            {/* geree baiguulah hesg*/}
            <div
              className={`${
                submitted
                  ? "shadow-none mt-6"
                  : "shadow-lg rounded-xl px-8 py-10 mt-6"
              }`}
            >
              <p className="text-lg font-bold mb-5 text-center">
                ГЭРЭЭ БАЙГУУЛСАН
              </p>
              <div className="flex items-start justify-between">
                <div>
                  <p className="text-lg font-bold">
                    Үйлчилгээ үзүүлэгч талыг төлөөлөн
                  </p>
                  <p className="text-lg">
                    <span className="font-bold">“МЕТАКОГ МЕТРИКС” ХХК-ИЙН</span>{" "}
                    <br /> ГҮЙЦЭТГЭХ ЗАХИРАЛ{" "}
                  </p>
                  <div className="mt-6">
                    <p className="text-lg">………………………..  /Б.Хүрэлбаатар/</p>
                    <p className="text-lg">Утас: 80019922</p>
                  </div>
                </div>
                <div>
                  <p className="text-lg font-bold">Хэрэглэгч талыг төлөөлөн</p>
                  <p className="text-lg font-bold">
                    {formData.companyName === ""
                      ? "_________"
                      : `${formData.companyName} ХХК-ИЙН`}{" "}
                    <br />
                    ХҮНИЙ НӨӨЦИЙН АЛБАНЫ ДАРГА
                  </p>
                  <div className="mt-6">
                    <p className="text-lg">
                      ………………………..  /
                      {lastNameFisrt === "" ? "__" : `${lastNameFisrt}.`}
                      {formData.firstname === ""
                        ? "________"
                        : formData.firstname}
                      /
                    </p>
                    <p className="text-lg">
                      Утас:{" "}
                      {formData.phoneNumber === null ||
                      formData.phoneNumber === NaN
                        ? "________"
                        : formData.phoneNumber}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isSticky && <div className="w-[30%]" />}
          <div
            className={`w-[30%] ${
              submitted
                ? "hidden"
                : `${isSticky ? "fixed top-5 right-[7.3rem] w-[337px]" : ""}`
            }`}
          >
            {displayForm ? (
              <form onSubmit={onSubmit}>
                <div className="w-full px-4 py-8 max-h-[670px] overflow-y-auto shadow-xl rounded-xl">
                  <p className="text-lg">Ерөнхий мэдээлэл</p>
                  <div className="mt-3">
                    <div>
                      <label className="block text-sm">
                        Гүйцэтгэх захиралын овог*
                      </label>
                      <input
                        className="rounded-lg w-full border-opacity-40"
                        type="text"
                        onClick={() => {
                          scrollToDiv(div0Ref);
                        }}
                        value={formData.ceoLastName}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            ceoLastName: e.target.value,
                          });
                        }}
                      />
                    </div>{" "}
                    <div className="mt-2">
                      <label className="block text-sm">
                        Гүйцэтгэх захиралын нэр*
                      </label>
                      <input
                        className="rounded-lg w-full border-opacity-40"
                        type="text"
                        onClick={() => {
                          scrollToDiv(div0Ref);
                        }}
                        value={formData.ceoFirstName}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            ceoFirstName: e.target.value,
                          });
                        }}
                      />
                    </div>{" "}
                    <div className="mt-2">
                      <label className="block text-sm">Хаяг (тоот)*</label>
                      <input
                        className="rounded-lg w-full border-opacity-40"
                        type="text"
                        onClick={() => {
                          scrollToDiv(div0Ref);
                        }}
                        value={formData.address}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            address: e.target.value,
                          });
                        }}
                      />
                    </div>{" "}
                    <div className="mt-2">
                      <label className="block text-sm">
                        Байгууллагын регистрийн дугаар*
                      </label>
                      <input
                        className="rounded-lg w-full border-opacity-40"
                        type="number"
                        onClick={() => {
                          scrollToDiv(div0Ref);
                        }}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            register: parseInt(e.target.value, 10),
                          });
                        }}
                      />
                    </div>
                    <div className="mt-2">
                      <label className="block text-sm">Компаны нэр*</label>
                      <input
                        className="rounded-lg w-full border-opacity-40"
                        type="text"
                        onClick={() => {
                          scrollToDiv(div0Ref);
                        }}
                        value={formData.companyName}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            companyName: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mt-2">
                      <label className="block text-sm">
                        Гэрээ байгуулж байгаа хүний овог*
                      </label>
                      <input
                        className="rounded-lg w-full border-opacity-40"
                        type="text"
                        onClick={() => {
                          scrollToDiv(div0Ref);
                        }}
                        value={formData.lastname}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            lastname: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mt-2">
                      <label className="block text-sm">
                        Гэрээ байгуулж байгаа хүний нэр*
                      </label>
                      <input
                        className="rounded-lg w-full border-opacity-40"
                        type="text"
                        onClick={() => {
                          scrollToDiv(div0Ref);
                        }}
                        value={formData.firstname}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            firstname: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mt-2">
                      <label className="block text-sm">Утасны дугаар*</label>
                      <input
                        className="rounded-lg w-full border-opacity-40"
                        type="number"
                        onClick={() => {
                          scrollToDiv(div0Ref);
                        }}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            phoneNumber: parseInt(e.target.value, 10),
                          });
                        }}
                      />
                    </div>
                    <div>
                      {formData.representatives.length === 1 ? (
                        <div>
                          <div className="flex items-center gap-4 mt-4">
                            <p className="text-lg font-bold">
                              Талуудын эрх бүхий төлөөлөгчид
                            </p>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                handleAddRepresentative();
                              }}
                              className="h-8 w-8 rounded-full bg-[#000] flex items-center justify-center"
                            >
                              <GoPlus className="h-6 w-6 text-white" />
                            </button>
                          </div>
                          <div>
                            <div className="mt-2">
                              <label className="block text-sm">Овог*</label>
                              <input
                                className="rounded-lg w-full border-opacity-40"
                                type="text"
                                onClick={() => {
                                  scrollToDiv(div9Ref);
                                }}
                                value={
                                  formData.representatives[0]?.lastname || ""
                                }
                                onChange={(e) => {
                                  const updatedRepresentatives = [
                                    ...formData.representatives,
                                  ];
                                  updatedRepresentatives[0].lastname =
                                    e.target.value;
                                  setFormData({
                                    ...formData,
                                    representatives: updatedRepresentatives,
                                  });
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <label className="block text-sm">Нэр*</label>
                              <input
                                className="rounded-lg w-full border-opacity-40"
                                type="text"
                                onClick={() => {
                                  scrollToDiv(div9Ref);
                                }}
                                value={
                                  formData.representatives[0]?.firstname || ""
                                }
                                onChange={(e) => {
                                  const updatedRepresentatives = [
                                    ...formData.representatives,
                                  ];
                                  updatedRepresentatives[0].firstname =
                                    e.target.value;
                                  setFormData({
                                    ...formData,
                                    representatives: updatedRepresentatives,
                                  });
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <label className="block text-sm">
                                Албан тушаал*
                              </label>
                              <input
                                className="rounded-lg w-full border-opacity-40"
                                type="text"
                                onClick={() => {
                                  scrollToDiv(div9Ref);
                                }}
                                value={
                                  formData.representatives[0]?.position || ""
                                }
                                onChange={(e) => {
                                  const updatedRepresentatives = [
                                    ...formData.representatives,
                                  ];
                                  updatedRepresentatives[0].position =
                                    e.target.value;
                                  setFormData({
                                    ...formData,
                                    representatives: updatedRepresentatives,
                                  });
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <label className="block text-sm">
                                И-Мейл хаяг*
                              </label>
                              <input
                                className="rounded-lg w-full border-opacity-40"
                                type="text"
                                onClick={() => {
                                  scrollToDiv(div9Ref);
                                }}
                                value={formData.representatives[0]?.email || ""}
                                onChange={(e) => {
                                  const updatedRepresentatives = [
                                    ...formData.representatives,
                                  ];
                                  updatedRepresentatives[0].email =
                                    e.target.value;
                                  setFormData({
                                    ...formData,
                                    representatives: updatedRepresentatives,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Accordion
                            data={formData.representatives[0]}
                            onChange={handleChange}
                          />

                          <div className="flex items-center justify-between mt-4">
                            <p className=" text-lg">2. Эрх бүхий төлөөлөгч</p>
                            <button className="w-8 h-8 bg-[#000] rounded-full flex items-center justify-center">
                              <FiMinus
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDeleteRepresentative();
                                }}
                                className="h-6 w-6 text-white"
                              />
                            </button>
                          </div>
                          <div>
                            <div className="mt-2">
                              <label className="block text-sm">Овог*</label>
                              <input
                                className="rounded-lg w-full border-opacity-40"
                                type="text"
                                onClick={() => {
                                  scrollToDiv(div9Ref);
                                }}
                                value={formData.representatives[1].lastname}
                                onChange={(e) => {
                                  const updatedRepresentatives = [
                                    ...formData.representatives,
                                  ];
                                  updatedRepresentatives[1].lastname =
                                    e.target.value;
                                  setFormData({
                                    ...formData,
                                    representatives: updatedRepresentatives,
                                  });
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <label className="block text-sm">Нэр*</label>
                              <input
                                className="rounded-lg w-full border-opacity-40"
                                type="text"
                                onClick={() => {
                                  scrollToDiv(div9Ref);
                                }}
                                value={formData.representatives[1].firstname}
                                onChange={(e) => {
                                  const updatedRepresentatives = [
                                    ...formData.representatives,
                                  ];
                                  updatedRepresentatives[1].firstname =
                                    e.target.value;
                                  setFormData({
                                    ...formData,
                                    representatives: updatedRepresentatives,
                                  });
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <label className="block text-sm">
                                Албан тушаал*
                              </label>
                              <input
                                className="rounded-lg w-full border-opacity-40"
                                type="text"
                                onClick={() => {
                                  scrollToDiv(div9Ref);
                                }}
                                value={formData.representatives[1].position}
                                onChange={(e) => {
                                  const updatedRepresentatives = [
                                    ...formData.representatives,
                                  ];
                                  updatedRepresentatives[1].position =
                                    e.target.value;
                                  setFormData({
                                    ...formData,
                                    representatives: updatedRepresentatives,
                                  });
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <label className="block text-sm">
                                И-Мейл хаяг*
                              </label>
                              <input
                                className="rounded-lg w-full border-opacity-40"
                                type="text"
                                onClick={() => {
                                  scrollToDiv(div9Ref);
                                }}
                                value={formData.representatives[1].email}
                                onChange={(e) => {
                                  const updatedRepresentatives = [
                                    ...formData.representatives,
                                  ];
                                  updatedRepresentatives[1].email =
                                    e.target.value;
                                  setFormData({
                                    ...formData,
                                    representatives: updatedRepresentatives,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>{" "}
                <div className="flex items-center gap-4 mt-4 ">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setDisplayForm(false);
                    }}
                    className="rounded-xl p-4 shadow-lg w-[20%] bg-white flex items-center justify-center"
                  >
                    <IoMdArrowBack className="w-6 h-6" />
                  </button>
                  <button
                    type="submit"
                    className="rounded-xl p-4 shadow-lg w-[80%] bg-white"
                  >
                    Үргэлжлүүлэх
                  </button>
                </div>
              </form>
            ) : (
              <div>
                <div className="shadow-xl px-4 py-1 rounded-xl">
                  <div
                    onClick={() => scrollToDiv(div1Ref)}
                    className="font-bold bg-[#E3E9EC] w-full px-4 py-2 rounded-lg mb-2 cursor-pointer"
                  >
                    <p>Нэг. Гэрээний зорилго</p>
                  </div>
                  <div
                    onClick={() => scrollToDiv(div2Ref)}
                    className="font-bold bg-[#E3E9EC] w-full px-4 py-2 rounded-lg mb-2 cursor-pointer"
                  >
                    <p>Хоёр. Гэрээний хугацаа</p>
                  </div>
                  <div
                    onClick={() => scrollToDiv(div3Ref)}
                    className="font-bold bg-[#E3E9EC] w-full px-4 py-2 rounded-lg mb-2 cursor-pointer"
                  >
                    <p>Гурав. Үйлчилгээний төлбөр, төлөх нөхцөл</p>
                  </div>
                  <div
                    onClick={() => scrollToDiv(div4Ref)}
                    className="font-bold bg-[#E3E9EC] w-full px-4 py-2 rounded-lg mb-2 cursor-pointer"
                  >
                    <p>Дөрөв. Хэрэглэгчийн эрх үүрэг</p>
                  </div>
                  <div
                    onClick={() => scrollToDiv(div5Ref)}
                    className="font-bold bg-[#E3E9EC] w-full px-4 py-2 rounded-lg mb-2 cursor-pointer"
                  >
                    <p>Тав. Үйлчилгээ үзүүлэгчийн эрх үүрэг</p>
                  </div>
                  <div
                    onClick={() => scrollToDiv(div6Ref)}
                    className="font-bold bg-[#E3E9EC] w-full px-4 py-2 rounded-lg mb-2 cursor-pointer"
                  >
                    <p>Зургаа. Гэрээг дуусгавар болгох, цуцлах</p>
                  </div>
                  <div
                    onClick={() => scrollToDiv(div7Ref)}
                    className="font-bold bg-[#E3E9EC] w-full px-4 py-2 rounded-lg mb-2 cursor-pointer"
                  >
                    <p>Долоо. Талуудын хариуцлага</p>
                  </div>
                  <div
                    onClick={() => scrollToDiv(div8Ref)}
                    className="font-bold bg-[#E3E9EC] w-full px-4 py-2 rounded-lg mb-2 cursor-pointer"
                  >
                    <p>Найм. Үйлчилгээ үзүүлэх нөхцөл</p>
                  </div>
                  <div
                    onClick={() => scrollToDiv(div9Ref)}
                    className="font-bold bg-[#E3E9EC] w-full px-4 py-2 rounded-lg mb-2 cursor-pointer"
                  >
                    <p>Ес. Талуудын эрх бүхий төлөөлөгчид</p>
                  </div>
                  <div
                    onClick={() => scrollToDiv(div10Ref)}
                    className="font-bold bg-[#E3E9EC] w-full px-4 py-2 rounded-lg mb-2 cursor-pointer"
                  >
                    <p>Арав. Гэрээнд нэмэлт өөрчлөлт оруулах, хавсралт</p>
                  </div>
                  <div
                    onClick={() => scrollToDiv(div11Ref)}
                    className="font-bold bg-[#E3E9EC] w-full px-4 py-2 rounded-lg mb-2 cursor-pointer"
                  >
                    <p>Бусад</p>
                  </div>
                </div>
                <div>
                  <div className="flex justify-center">
                    <button
                      onClick={() => {
                        if (!displayForm) {
                          setDisplayForm(true);
                        }
                      }}
                      className="w-12 h-12 border border-[#EDEDED] bg-white mt-2 shadow-lg rounded-full flex items-center justify-center"
                    >
                      <MdOutlineModeEdit className="w-6 h-6" />
                    </button>
                  </div>
                  <p className="text-lg mt-2 font-bold text-center">
                    Өөрийн мэдээлэл <br /> оруулах
                  </p>
                </div>
              </div>
            )}
          </div>
          {submitted && (
            <div className="fixed bottom-10 right-10 ">
              <div className="flex items-center gap-10">
                <div className="flex flex-col items-center">
                  <button
                    onClick={handleDownload}
                    className="border border-[#EDEDED] bg-white h-12 w-12 flex items-center justify-center rounded-full hover:bg-[#EDEDED]"
                  >
                    <GoDownload className="w-6 h-6" />
                  </button>
                  <p className="text-center mt-3">Татаж авах</p>
                </div>
                <div className="flex flex-col items-center">
                  <button
                    onClick={handleSubmit}
                    className="border border-[#EDEDED] bg-white h-12 w-12 flex items-center justify-center rounded-full hover:bg-[#EDEDED]"
                  >
                    <GrLinkNext className="w-6 h-6" />
                  </button>
                  <p className="text-center mt-3">Төлбөр төлөх</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contract;
