import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { FaRegEyeSlash } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";

const SignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [position, setPosition] = useState("");
  const [photo, setPhoto] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    if (value.length < 8) {
      setPhoneNumberError("Phone number must have at least 8 digits");
    } else {
      setPhoneNumberError("");
    }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];

    if (file.size > 25 * 1024 * 1024) {
      toast.error(t("toast.imgError"));
    } else {
      setPhoto(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phoneNumber.length < 8) {
      setPhoneNumberError("Phone number must have at least 8 digits");
      return;
    }
    const formData = {
      lastName,
      firstName,
      phoneNumber,
      email,
      password,
      position,
      photo,
    };

    console.log(formData);
    setLastName("");
    setFirstName("");
    setPhoneNumber("");
    setEmail("");
    setPassword("");
    setPosition("");
    setPhoto(null);
    navigate("company", {
      state: {
        user: formData,
      },
    });
  };

  return (
    <>
      <div className="flex justify-center">
        <div>
          <div className="mt-10 sm:ms-0 ms-4">
            <img className=" h-[27px] " src="/oneplacehrLogo.png" alt="logo" />
          </div>
          <div className="sm:mt-20 mt-6 flex">
            <div className="border-r-2 sm:pe-7 border-black m-auto">
              <div className="flex w-full justify-between items-center px-4 sm:me-4">
                <p className="font-bold sm:text-lg text-xs border-l-2 border-[#F18878] ps-2">
                  Бүртгэл
                </p>
                <p className="sm:text-lg text-xs text-[#209653]">
                  Эхний 3 хоног үнэгүй ашиглах боломжтой
                </p>
              </div>

              <div className="mt-10 sm:ms-0 ms-4 flex items-center">
                <div className="flex items-center">
                  <p className="sm:py-2 sm:px-4 px-2.5 py-0.5 sm:text-base text-sm bg-black rounded-full text-white font-bold">
                    1
                  </p>
                  <p className="sm:text-lg text-xs font-bold sm:ms-4 ms-2">
                    Таны бүртгэл
                  </p>
                </div>
                <div className="flex items-center ms-3 sm:ms-6 opacity-50">
                  <p className="sm:py-2 sm:px-4 px-2.5 py-0.5 sm:text-base text-sm bg-black rounded-full text-white font-bold">
                    2
                  </p>
                  <p className="sm:text-lg text-xs font-bold sm:ms-4 ms-2">
                    Байгууллагын бүртгэл
                  </p>
                </div>
                <div className="flex items-center ms-3 sm:ms-6 opacity-50">
                  <p className="sm:py-2 sm:px-4 px-2.5 py-0.5 sm:text-base text-sm bg-black rounded-full text-white font-bold">
                    3
                  </p>
                  <p className="sm:text-lg text-xs font-bold sm:ms-4 ms-2">
                    Үйлчилгээний нөхцөл
                  </p>
                </div>
              </div>
              <form className="ms-3 sm:ms-0" onSubmit={handleSubmit}>
                <div className="mt-8">
                  <p className="text-2xl font-bold">Ажилтны бүртгэл</p>
                  <div className="flex items-center mt-5">
                    <div className="h-[100px] w-[100px] border border-black relative">
                      {!photo && (
                        <img
                          className="mt-4 ms-4"
                          src="/signup/icon.svg"
                          alt="icon"
                        />
                      )}

                      {photo && (
                        <img
                          src={URL.createObjectURL(photo)}
                          alt="Photo"
                          className="h-full w-full object-cover"
                        />
                      )}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handlePhotoChange}
                      id="photo"
                      className="hidden"
                    />
                    <label
                      htmlFor="photo"
                      className="cursor-pointer border rounded-lg px-3 py-1 ms-4 hover:opacity-50"
                    >
                      Зураг оруулах <span className="text-[#ff0000]">*</span>
                    </label>
                  </div>
                </div>
                <div className="mt-10">
                  <div className="sm:flex block justify-between mb-4">
                    <div className="sm:w-1/2 w-4/5 sm:me-10">
                      <label htmlFor="photo">
                        Овог <span className="text-[#ff0000]">*</span>
                      </label>
                      <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="w-full border rounded-lg px-4 py-3"
                        required
                      />
                    </div>
                    <div className="sm:w-1/2 w-4/5 sm:mt-0 mt-4 sm:me-4">
                      <label htmlFor="photo">
                        Нэр <span className="text-[#ff0000]">*</span>
                      </label>
                      <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="w-full border rounded-lg px-4 py-3"
                        required
                      />
                    </div>
                  </div>
                  <div className="sm:flex block justify-between mb-4">
                    <div className="sm:w-1/2 w-4/5 sm:me-10">
                      <label htmlFor="photo">
                        И-мэйл хаяг <span className="text-[#ff0000]">*</span>
                      </label>
                      <input
                        type="email"
                        value={email}
                        placeholder="example@mail.com"
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full border rounded-lg px-4 py-3"
                        required
                      />
                    </div>
                    <div className="sm:w-1/2 w-4/5 sm:mt-0 mt-4 sm:me-4">
                      <label htmlFor="photo">
                        Утасны дугаар <span className="text-[#ff0000]">*</span>
                      </label>
                      <input
                        type="number"
                        value={phoneNumber}
                        placeholder="Холбогдох утасны дугаар"
                        onChange={handlePhoneNumberChange}
                        className="w-full border rounded-lg px-4 py-3"
                        required
                      />
                      {phoneNumberError && (
                        <p className="text-red-500">{phoneNumberError}</p>
                      )}
                    </div>
                  </div>
                  <div className="block sm:flex justify-between mb-4">
                    <div className="sm:w-1/2 w-4/5 sm:me-10">
                      <label htmlFor="photo">
                        Албан тушаал <span className="text-[#ff0000]">*</span>
                      </label>
                      <input
                        type="text"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        className="w-full border rounded-lg px-4 py-3"
                        required
                      />
                    </div>
                    <div className="sm:w-1/2 w-4/5 sm:mt-0 mt-4 sm:me-4">
                      <label htmlFor="photo">
                        Нууц үг <span className="text-red">*</span>
                      </label>
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          value={password}
                          placeholder="******"
                          onChange={(e) => setPassword(e.target.value)}
                          className="w-full border rounded-lg px-4 py-3"
                          required
                        />
                        <button
                          className="absolute top-1/2 transform -translate-y-1/2 right-3"
                          onClick={handleTogglePassword}
                        >
                          {showPassword ? <IoEyeOutline /> : <FaRegEyeSlash />}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w-full justify-end mt-10 mb-10">
                  <button
                    onClick={() => {}}
                    type="submit"
                    className="px-4 py-2 border border-black rounded-lg hover:opacity-50 me-4"
                  >
                    Дараагийнх
                  </button>
                </div>
              </form>
            </div>
            <div className="sm:block hidden ms-10">
              <div className="border border-[#F18878] shadow-xl rounded-[40px] h-[700px]">
                <div className="h-[168px] w-[401px] bg-gradient-to-t from-[#F18878] to-white rounded-[40px]  relative">
                  {photo !== null ? (
                    <img
                      src={URL.createObjectURL(photo)}
                      alt="Photo"
                      className="absolute w-[110px] h-[110px] bottom-[-20px] left-36 rounded-full"
                    />
                  ) : (
                    <img
                      className="absolute bottom-[-20px] left-36 "
                      src="/signup/usercircle.png"
                      alt="user"
                    />
                  )}
                </div>
                <div className="p-10">
                  <div>
                    <label className="text-[#666874]">Нэр:</label>
                    <p className="text-xl mt-1">
                      {lastName === "" ? "Нэр" : `${lastName} ,`}{" "}
                      {firstName === "" ? "" : firstName}
                    </p>
                  </div>
                  <div className="mt-6">
                    <label className="text-[#666874]">Утасны дугаар:</label>
                    <p className="text-xl mt-1">
                      {phoneNumber === "" ? "0000-0000" : `${phoneNumber} `}{" "}
                    </p>
                  </div>
                  <div className="mt-6">
                    <label className="text-[#666874]">И-мэйл хаяг:</label>
                    <p className="text-xl mt-1">
                      {email === "" ? "example@mail.com" : `${email} `}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default SignUp;
