import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Refer = () => {
  const [inviteLink, setInviteLink] = useState(
    "https://gusto.com/r/khurelbaatar1bbf485"
  );
  const { t, i18n } = useTranslation();
  const [copyMessage, setCopyMessage] = useState("");

  const copyLink = () => {
    if (inviteLink) {
      navigator.clipboard
        .writeText(inviteLink)
        .then(function () {
          setCopyMessage(t("toast.LinkCopied"));
          setTimeout(() => {
            setCopyMessage("");
          }, 2000);
        })
        .catch(function (err) {
          setCopyMessage(t("toast.copyFailed"));
        });
    }
  };
  return (
    <div className="w-10/12 m-auto pt-11">
      <p className="text-2xl font-bold">Уриад 10% хөнгөлөлт эдлээрэй</p>
      <p className="text-lg text-[#777985] mt-2">Lorem ipsum dolor sit amet,</p>
      <div className="mt-10 flex gap-10">
        <div className="w-3/5 border-[1px] shadow-xl p-10 rounded-xl">
          <div>
            <p className="text-xl font-bol">Майлээр урилга илгээх</p>
            <input
              className="w-full rounded-lg border-[#666874] mt-5"
              type="text"
              placeholder="Майл хаягаа энд бичнэ үү"
            />
            <div className="flex justify-end mt-4">
              <button className="text-white bg-[#324d72] px-6 py-2 rounded-lg text-base hover:opacity-50 font-bold">
                Илгээх
              </button>
            </div>
          </div>
          <div className="mt-10 relative">
            <p className="text-xl font-bol">Copy and share your link</p>
            {copyMessage && (
              <div className="bg-green-200 text-green-800 p-1 rounded-lg text-xs mt-2 absolute top-[-10px] right-[-10px] text-center w-[120px]">
                {copyMessage}
              </div>
            )}
            <div className="flex items-center justify-between mt-5">
              <input
                className="rounded-lg border-[#666874] w-4/6 flex items-center"
                type="text"
                value={inviteLink}
                disabled
                placeholder="https://gusto.com/r/khurelbaatar1bbf485"
              />
              <button
                onClick={copyLink}
                className="text-white bg-[#324d72] px-4 py-2 rounded-lg text-base hover:opacity-50 font-bold"
              >
                Copy Link
              </button>
            </div>
          </div>
        </div>
        <div className="w-1.5/5">
          <p className="text-xl font-bold">Хэрхэн хөнгөлөлт эдлэх вэ?</p>
          <div className="mt-4">
            <div className="flex items-center gap-5">
              <div className="w-1/4">
                <img src="./icons/hearth.png" alt="icon" />
              </div>
              <div className="flex items-start w-3/4">
                <p className="me-1">1.</p>
                <p>
                  Бусад бизнес эрхлэгч нарт
                  <br /> өөрийнхөө линкийг хуваалцаарай
                </p>
              </div>
            </div>
            <div className="flex items-center gap-5 my-10">
              <div className="w-1/4">
                <img src="./icons/handshake.png" alt="icon" />
              </div>
              <div className="flex items-start w-3/4">
                <p className="me-1">2.</p>
                <p>
                  Тэд бүртгүүлэн эхний <br /> худалдан авалт хийгээд
                </p>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <div className="w-1/4">
                <img src="./icons/giftbox.png" alt="icon" />
              </div>
              <div className="flex items-start w-3/4">
                <p className="me-1">3.</p>
                <p>
                  Тэгээд таньд хөнгөлөлтийн <br /> урамшуулалын мэдээлэл <br />
                  очиж та хөнгөлөлтөө эдэлнэ
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ms-1 mt-10">
        <p className="text-xl font-bold">
          The standard Lorem Ipsum passage, used since the 1500s
        </p>
        <p className="text-lg text-[#777985]">
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor
        </p>
      </div>
    </div>
  );
};

export default Refer;
