import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../components/uncommon/Loading";
import { getSurveys } from "../../services/employee.service";
import { deleteSurvey } from "../../services/survey.service";
import { MdOutlineEdit } from "react-icons/md";
import AgCellMenu from "../../components/menu/AgCellMenu";
import MainTable from "../../components/table/MainTable";
import { useTranslation } from "react-i18next";

const NewSurvey = () => {
  const [surveys, setSurveys] = useState(null);
  const [savedSurvey, setSavedSurvey] = useState(null);
  const [endedSurvey, setEndedSurvey] = useState(null);
  const [popUp, setPopUp] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [surveyToDeleteId, setSurveyToDeleteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [surveyToDeleteName, setSurveyToDeleteName] = useState("");
  const [table, setTable] = useState("ongoing");

  useEffect(() => {
    getSurveys()
      .then((data) => {
        setSurveys(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("aldaaa ", err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const handleDelete = (surveyId, surveyName) => {
    setSurveyToDeleteId(surveyId);
    setSurveyToDeleteName(surveyName);

    setPopUp(true);
  };

  const handleConfirmDelete = () => {
    if (surveyToDeleteId) {
      deleteSurvey(surveyToDeleteId)
        .then(() => {
          setSurveys((prevSurveys) =>
            prevSurveys.filter((survey) => survey.surveyId !== surveyToDeleteId)
          );
          toast.success(t("toast.deleteSuccess"));
          setPopUp(false);
        })
        .catch((error) => {
          console.error("Error deleting survey:", error);
          toast.error(t("toast.deleteError"));
          setPopUp(false);
        });
    }
  };

  const clickOnCell = (e) => {
    if (e.event.button === 1) {
      window.open(`/company/survey/report/${e.data.action.surveyId}`, "_blank");
    } else {
      navigate(`/company/survey/report/${e.data.action.surveyId}`);
    }
  };

  return (
    <div className="pt-11 w-10/12 m-auto">
      <div className="flex justify-between items-center mb-4">
        <p className="text-[#222] text-2xl font-bold">{t("Survey.survey")}</p>
        <button
          onClick={() => {
            navigate("/company/create/survey");
          }}
          className=" p-2 text-left rounded-[10px] flex bg-main-blue hover:bg-[#283d5b] gap-2 items-center text-white"
        >
          {t("Survey.createSurvey")} <FaPlus />
        </button>
      </div>
      <div className="my-3 flex items-center gap-4">
        <button
          onClick={() => {
            setTable("ongoing");
          }}
          className={`pb-2 ${
            table === "ongoing"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          {t("Survey.surveyInProgress")}
        </button>

        <button
          onClick={() => {
            setTable("saved");
          }}
          className={`pb-2 ${
            table === "saved"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          {t("Survey.savedSurveys")}
        </button>
        <button
          onClick={() => {
            setTable("ended");
          }}
          className={`pb-2 ${
            table === "ended"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          {t("Survey.completedSurveys")}
        </button>
      </div>
      <div className="w-full mb-2 mt-6">
        <MainTable
          columnDefs={[
            {
              headerName: "Тестүүд",
              field: "text",
              sortable: true,
              sortable: true,
              onCellClicked: clickOnCell,
              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/text.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/house.png" },
            },
            {
              headerName: "Үүсгэсэн огноо",
              field: "createdDate",
              sortable: true,
              sortable: true,
              onCellClicked: clickOnCell,
              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/created.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/calendar.png" },
            },
            {
              headerName: t("Survey.question"),
              field: "questionCount",
              sortable: true,
              sortable: true,
              onCellClicked: clickOnCell,
              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/question.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/chart.png" },
            },
            {
              headerName: t("Survey.participant"),
              field: "employeeCount",
              sortable: true,
              sortable: true,
              flex: 2,
              onCellClicked: clickOnCell,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/employee.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/location.png" },
            },
            {
              headerName: t("Survey.performance"),
              field: "process",
              sortable: true,
              sortable: true,
              flex: 2,
              onCellClicked: clickOnCell,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/percentage.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/document.png" },
            },
            {
              headerName: "",
              field: "action",

              sortable: false,
              cellRenderer: AgCellMenu,
              flex: 1,
              cellRendererParams: (params) => {
                let menuItems = [
                  {
                    label: (
                      <svg
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.66732 4V10.6667H2.33398V4H7.66732ZM6.66732 0H3.33398L2.66732 0.666667H0.333984V2H9.66732V0.666667H7.33398L6.66732 0ZM9.00065 2.66667H1.00065V10.6667C1.00065 11.4 1.60065 12 2.33398 12H7.66732C8.40065 12 9.00065 11.4 9.00065 10.6667V2.66667Z"
                          fill="#636466"
                        />
                      </svg>
                    ),
                    action: "handleDelete",
                  },
                  {
                    label: <MdOutlineEdit className="text-[#636466]" />,
                    action: "handleEdit",
                  },
                ];

                return {
                  menuItems: menuItems,
                  menuActions: {
                    handleDelete: () => {
                      handleDelete(
                        params.data.action.surveyId,
                        params.data.text
                      );
                    },
                    handleEdit: () => {
                      navigate(
                        `/company/edit/survey/${params.data.action.surveyId}`
                      );
                    },
                  },
                };
              },
            },
          ]}
          rowData={
            table === "ongoing"
              ? surveys.map((elm) => {
                  return {
                    text: elm.text,
                    createdDate: elm.createdDate.substring(0, 16),
                    questionCount: elm.questionCount,
                    employeeCount: elm.employeeCount,
                    process:
                      isNaN(elm.finishedCount) ||
                      elm.finishedCount === null ||
                      elm.employeeCount === 0
                        ? "0%"
                        : `${Math.round(
                            (elm.finishedCount / elm.employeeCount) * 100
                          )}%`,
                    action: elm,
                  };
                })
              : savedSurvey
          }
        />
      </div>

      {/* {surveys === null && endedSurvey === null && savedSurvey === null ? (
        <div className="h-screen flex items-center justify-center">
          <div className="flex flex-col items-center gap-4 pb-60">
            <img src="/ona/empty.png" alt="empty" />
            <p className="text-center text-[24px] text-[#222] font-semibold">
              Одоогоор үүсгэсэн Асуумж байхгүй байна.
            </p>
            <button
              onClick={() => {
                navigate("/company/create/survey");
              }}
              className="bg-[#324d72] rounded-xl px-4 py-2 font-semibold text-white flex items-center gap-2"
            >
              {t("Dashboard.createSurvey")} <FaPlus />
            </button>
          </div>
        </div>
      ) : (
        <div className="w-10/12 m-auto pt-11">
          <div className="flex justify-between items-center">
            <p className="text-[#222] text-2xl font-bold">
              {t("Survey.survey")}
            </p>
            <button
              onClick={() => {
                navigate("/company/create/survey");
              }}
              className=" p-2 text-left rounded-[10px] flex bg-main-blue hover:bg-[#283d5b] gap-2 items-center text-white"
            >
              {t("Survey.createSurvey")} <FaPlus />
            </button>
          </div>
          <div className={`flex items-start w-full mt-5 gap-10`}>
            <div className={`w-1/2`}>
              <p className="font-semibold text-lg text-[#222] mb-5">
                {t("Survey.surveyInProgress")}
              </p>
              <div
                className={`shadow-xl ${
                  surveys.length !== 0 ? "rounded-none" : "rounded-xl"
                }`}
                style={{ height: `${height}px`, overflowY: "auto" }}
              >
                {surveys.length !== 0 ? (
                  <div>
                    {surveys.slice().map((items) => {
                      const formattedDate = formatDateString(items.createdDate);
                      return (
                        <div
                          key={items.surveyId}
                          onMouseEnter={() => {
                            setOnGoingSurvey(items.surveyId);
                          }}
                          onMouseLeave={() => {
                            setOnGoingSurvey(0);
                          }}
                          className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg "
                        >
                          <div className="w-full">
                            <div className="flex justify-between items-center mb-3">
                              <p
                                onClick={() => {
                                  navigate(
                                    `/company/survey/report/${items.surveyId}`
                                  );
                                }}
                                className="text-[#636466] font-semibold cursor-pointer"
                              >
                                {items.text}
                              </p>
                              <p className="text-xs text-[#636466]">
                                {formattedDate}
                              </p>
                            </div>
                            <div className="flex items-center justify-between">
                              <div>
                                <p className="text-[#636466] text-xs">
                                  {t("Survey.question")}:
                                </p>
                                <p className="text-[#222] text-xs">
                                  {items.questionCount}
                                </p>
                              </div>
                              <div>
                                <p className="text-[#636466] text-xs">
                                  {t("Survey.performance")}:
                                </p>
                                <p className="text-[#222] text-xs text-center">
                                  {isNaN(
                                    (items.finishedCount /
                                      items.employeeCount) *
                                      100
                                  )
                                    ? 0
                                    : (
                                        (items.finishedCount /
                                          items.employeeCount) *
                                        100
                                      ).toFixed(0)}{" "}
                                  %
                                </p>
                              </div>
                              <div>
                                <p className="text-[#636466] text-xs">
                                  {t("Survey.participant")}:
                                </p>
                                <p className="text-[#222] text-xs ">
                                  {items.employeeCount}
                                </p>
                              </div>
                            </div>
                            <div
                              className={`overflow-hidden ${
                                onGoingSurvey === items.surveyId
                                  ? "max-h-[40px]"
                                  : "max-h-0"
                              } transition-max-height duration-300 ease-in-out`}
                            >
                              <div className="flex items-center justify-between mt-4">
                                <p
                                  onClick={() => {
                                    navigate(
                                      `/company/survey/report/${items.surveyId}`
                                    );
                                  }}
                                  className="text-[#636466] text-xs flex items-center gap-2  cursor-pointer"
                                >
                                  Дэлгэрэнгүй
                                  <IoIosArrowForward />
                                </p>
                                <div className="flex items-center gap-2">
                                  <div
                                    onClick={() =>
                                      navigate(
                                        `/company/edit/survey/${items.surveyId}`
                                      )
                                    }
                                    className="p-1 bg-[#F3F4F8] rounded-full cursor-pointer"
                                  >
                                    <MdOutlineEdit className="text-[#636466]" />
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleDelete(items.surveyId, items.text)
                                    }
                                    className="p-1 bg-[#F3F4F8] rounded-full cursor-pointer"
                                  >
                                    <svg
                                      width="10"
                                      height="12"
                                      viewBox="0 0 10 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.66732 4V10.6667H2.33398V4H7.66732ZM6.66732 0H3.33398L2.66732 0.666667H0.333984V2H9.66732V0.666667H7.33398L6.66732 0ZM9.00065 2.66667H1.00065V10.6667C1.00065 11.4 1.60065 12 2.33398 12H7.66732C8.40065 12 9.00065 11.4 9.00065 10.6667V2.66667Z"
                                        fill="#636466"
                                      />
                                    </svg>
                                  </div>

                                  {/* <div
                                    onClick={() => {
                                      navigate(
                                        `/company/survey/report/${items.surveyId}/edit`
                                      );
                                    }}
                                    className="p-1 bg-[#F3F4F8] rounded-full cursor-pointer "
                                  >
                                    <MdOutlineEdit className="text-[#636466] h-[12px] w-[12px]" />
                                  </div> 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="h-full flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img src="/ona/empty.png" alt="empty" />
                      <p className="text-[#1E293B] mt-2">
                        {t("Survey.noSurveys")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/2">
              <p className="font-semibold text-lg text-[#222] mb-5 ">
                {t("Survey.savedSurveys")}
              </p>
              <div
                className={`shadow-xl ${
                  savedSurvey !== null ? "rounded-none" : "rounded-xl"
                }`}
                style={{ height: `${height2}px`, overflowY: "auto" }}
              >
                {savedSurvey !== null ? (
                  <div>
                    <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                      <div className="w-full">
                        <div className="flex justify-between items-center mb-3">
                          <p className="text-[#636466] font-semibold">
                            Survey name
                          </p>
                          <p className="text-xs text-[#636466]">2023/12/21</p>
                        </div>
                        <div className="flex itesm-center justify-between">
                          <div>
                            <p className="text-[#636466] text-xs">Асуулт:</p>
                            <p className="text-[#222] text-xs">25</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Гүйцэтгэл:</p>
                            <p className="text-[#222] text-xs">-</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Оролцогч:</p>
                            <p className="text-[#222] text-xs">Бүх ажилтан</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                      <div className="w-full">
                        <div className="flex justify-between items-center mb-3">
                          <p className="text-[#636466] font-semibold">
                            Survey name
                          </p>
                          <p className="text-xs text-[#636466]">2023/12/21</p>
                        </div>
                        <div className="flex itesm-center justify-between">
                          <div>
                            <p className="text-[#636466] text-xs">Асуулт:</p>
                            <p className="text-[#222] text-xs">25</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Гүйцэтгэл:</p>
                            <p className="text-[#222] text-xs">-</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Оролцогч:</p>
                            <p className="text-[#222] text-xs">Бүх ажилтан</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                      <div className="w-full">
                        <div className="flex justify-between items-center mb-3">
                          <p className="text-[#636466] font-semibold">
                            Survey name
                          </p>
                          <p className="text-xs text-[#636466]">2023/12/21</p>
                        </div>
                        <div className="flex itesm-center justify-between">
                          <div>
                            <p className="text-[#636466] text-xs">Асуулт:</p>
                            <p className="text-[#222] text-xs">25</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Гүйцэтгэл:</p>
                            <p className="text-[#222] text-xs">-</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Оролцогч:</p>
                            <p className="text-[#222] text-xs">Бүх ажилтан</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img src="/ona/empty.png" alt="empty" />
                      <p className="text-[#1E293B] mt-2">
                        {t("Survey.noSurveys")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <p className="text-lg text-[#222] font-semibold mt-5 mb-3">
                  {t("Survey.completedSurveys")}
                </p>
                <div
                  className={`shadow-xl ${
                    endedSurvey !== null ? "rounded-none" : "rounded-xl"
                  }`}
                  style={{ height: `${height2}px`, overflowY: "auto" }}
                >
                  {endedSurvey !== null ? (
                    <div>
                      <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                        <div className="w-full">
                          <div className="flex justify-between items-center mb-3">
                            <p className="text-[#636466] font-semibold">
                              Survey name
                            </p>
                            <p className="text-xs text-[#636466]">2023/12/21</p>
                          </div>
                          <div className="flex itesm-center justify-between">
                            <div>
                              <p className="text-[#636466] text-xs">Асуулт:</p>
                              <p className="text-[#222] text-xs">25</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Гүйцэтгэл:
                              </p>
                              <p className="text-[#222] text-xs">-</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Оролцогч:
                              </p>
                              <p className="text-[#222] text-xs">Бүх ажилтан</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                        <div className="w-full">
                          <div className="flex justify-between items-center mb-3">
                            <p className="text-[#636466] font-semibold">
                              Survey name
                            </p>
                            <p className="text-xs text-[#636466]">2023/12/21</p>
                          </div>
                          <div className="flex itesm-center justify-between">
                            <div>
                              <p className="text-[#636466] text-xs">Асуулт:</p>
                              <p className="text-[#222] text-xs">25</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Гүйцэтгэл:
                              </p>
                              <p className="text-[#222] text-xs">-</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Оролцогч:
                              </p>
                              <p className="text-[#222] text-xs">Бүх ажилтан</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                        <div className="w-full">
                          <div className="flex justify-between items-center mb-3">
                            <p className="text-[#636466] font-semibold">
                              Survey name
                            </p>
                            <p className="text-xs text-[#636466]">2023/12/21</p>
                          </div>
                          <div className="flex itesm-center justify-between">
                            <div>
                              <p className="text-[#636466] text-xs">Асуулт:</p>
                              <p className="text-[#222] text-xs">25</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Гүйцэтгэл:
                              </p>
                              <p className="text-[#222] text-xs">-</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Оролцогч:
                              </p>
                              <p className="text-[#222] text-xs">Бүх ажилтан</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" flex items-center justify-center">
                      <div className="flex flex-col items-center">
                        <img src="/ona/empty.png" alt="empty" />
                        <p className="text-[#1E293B] mt-2">
                          {t("Survey.noSurveys")}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}

      {popUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-xl px-8  w-[391px] h-[222px] flex flex-col justify-center">
            <p className="text-lg text-[#444] font-semibold text-center mb-6 px-8">
              Та “{surveyToDeleteName}”-г устгахдаа итгэлтэй байна уу?
            </p>
            <button
              onClick={handleConfirmDelete}
              className="w-full rounded-xl py-2 bg-[#2D2D2D] text-white text-sm mb-2"
            >
              Устгах
            </button>
            <button
              onClick={() => {
                setPopUp(false);
              }}
              className="w-full rounded-xl py-2 bg-[#E5E7EB] text-[#6B7280] text-sm mb-2"
            >
              Болих
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default NewSurvey;
