import { createContext, useState, useContext } from "react";

// Creating the UserContext
export const UserContext = createContext();

// Creating a UserContextProvider component
export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null); // You can set the initial user data here

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Creating a custom hook to use the UserContext
export const useUserContext = () => {
  return useContext(UserContext);
};
