import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { emailConfirmation } from '../services/user.service';

const CompanyConfirmationEmail = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const [isSuccess, setIsSuccess] = useState(false);


    useEffect(() => {
        emailConfirmation(id).then(val => {
            console.log("val = ", val)
            setIsSuccess(true)
        })
    }, [])

    return (
        <div>
            {
                <div className="flex items-center justify-center min-h-screen bg-gray-100">
                    <div className="bg-white p-8 rounded shadow-md w-80">
                        {isSuccess ? (
                            <div className="text-center">
                                {/* <svg className="w-16 h-16 mx-auto text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2l4 -4m2 5a9 9 0 11-6.472 -3.486" />
                                </svg> */}
                                <h1 className="text-2xl font-semibold mt-4">Таны Email амжилттай баталгаажлаа</h1>
                                <p className="mt-2 text-gray-600">Та өөрийн бүртгүүлсэн Email хаяг, нууц үгээр орно уу. Баярлалаа</p>
                                <div className="flex justify-center mt-10">
                                    <Link
                                        to="/"
                                        className="bg-[#324d76] text-center w-full rounded rounded-lg py-3 text-white hover:bg-[#324d90] hover:text-gray-300"
                                    >
                                        Нэвтрэх
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className="text-center">
                                <svg className="w-16 h-16 mx-auto text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                <h1 className="text-2xl font-semibold mt-4">Email Confirmation Failed</h1>
                                <p className="mt-2 text-gray-600">Oops! There was a problem confirming your email. Please try again.</p>
                            </div>
                        )}
                    </div>
                </div>
            }
        </div>)
}

export default CompanyConfirmationEmail;