import {
  LinearProgress,
  linearProgressClasses,
  styled
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Unity, { UnityContext } from "react-unity-webgl";
import "./../../App.css";
import { createCreditFullScore, createCreditScore, startCreditUser } from "../../services/game.service";

const unityContext = new UnityContext({
  productName: "Test",
  companyName: "Metacog",

  loaderUrl: "../../../unity/mobileGames/mobilegames.loader.js",
  dataUrl: "../../../unity/mobileGames/mobilegames.data",
  frameworkUrl: "../../../unity/mobileGames/mobilegames.framework.js",
  codeUrl: "../../../unity/mobileGames/mobilegames.wasm",
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#30e83c" : "#30e880",
  },
}));

const games = [
  "SWITCHC",
  "BOTTLEC",
  "NEIGHBORSC"
];

function MobileGame() {
  const [start, setStart] = useState(false);
  const [progress, setProgress] = useState(0);
  const [gameOrder, setGameOrder] = useState(0);
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  useEffect(function () {
    // Dynamically load your script here
    // const script = document.createElement('script');
    // script.src = 'public/pocket.js';
    // script.type = 'text/javascript';
    // document.body.appendChild(script);
    // console.log("id  = ", id)
    let isMounted = true; // Flag to track whether the component is mounted
    // Function to start the credit user and handle the game completion
    const startCreditAndCompleteGame = async () => {
      try {
        const val = await startCreditUser(id);

        if (isMounted) {
          setStart(true);
          completeGame(val.data.playedGame);
        }
      } catch (err) {
        console.log("err = ", err);
      }
    };

    // Call the function only on the initial render
    startCreditAndCompleteGame();


    function adjustCanvas() {
      const container = document.getElementById('unity-container');
      const width = window.innerWidth;
      const height = window.innerHeight;


      container.style.width = height + 'px';
      container.style.height = width + 'px';
    }

    // function handleOrientationChange() {
    //   const isPortrait = window.innerHeight > window.innerWidth;
    //   const container = document.getElementById('unity-container');

    //   if (isPortrait) {
    //     // Logic for portrait mode
    //     container.style.transform = 'rotate(90deg)';
    //     // Other adjustments
    //   } else {
    //     // Logic for landscape mode
    //     container.style.transform = 'rotate(0deg)';
    //     // Other adjustments
    //   }

    //   adjustCanvas();
    // }

    // window.addEventListener('orientationchange', handleOrientationChange);


    // // Adjust canvas on initial load and window resize
    // window.addEventListener('load', adjustCanvas);
    // window.addEventListener('resize', adjustCanvas);


    window.alert = function () { };


    setTimeout(() => setStart(true), 5000);

    return () => {
      isMounted = false;

    }
  }, [id]);

  const completeGame = async (playedGame) => {
    console.log("playedGame = ", playedGame)

    unityContext.on("progress", (progression) => {
      console.log("progress = ", progression)
      if (progression === 1) {
        setTimeout(() => {
          setProgress(progression * 100);
        }, 3000);
        unityContext.send("MetaCog", "GameLang", `${playedGame},0`);
      } else {
        setProgress(progression * 100);
      }
    });
    let st = playedGame;
    let gameScores = [];

    unityContext.on("GameOver1", async function (result) {
      gameScores = [...gameScores,
      {
        creditPlayerId: parseInt(id),
        gameCode: games[st],
        values: result,
      }];
      if (st < 2) {
        createCreditScore(
          {
            creditPlayerId: parseInt(id),
            gameCode: games[st],
            values: result,
          }
        );
        st++;
        unityContext.send("MetaCog", "GameLang", `${st},0`);
      }
      else {
        await createCreditFullScore(gameScores).then(a => {
        })
        window.Pocket.onComplete({ userId: id, success: true });
      }
    });
  }

  return (
    <Fragment>
      <div style={{ width: '100vw', height: '100vh' }}>
        <div className="absolute z-50	p-3" onClick={() => {
          window.Pocket.goBack();
        }}>
          <div className="border text-white px-2.5 rounded text-[24px] bg-border-grey">
            x
          </div>
        </div>
        {progress < 100 ? (
          <div
            className={
              " top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-screen h-screen bg-white  z-20 fixed"
            }
          >
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
              <div className=" text-center text-lg">
                {t('GameFlow.loading')}
                <div className="font-bold">{progress.toFixed(2)}%</div>{" "}
              </div>
              <div style={{ width: 350 }} className="mt-4">
                <BorderLinearProgress variant="determinate" value={progress} />
              </div>
            </div>
          </div>
        ) : null}
        {
          start ?
            <Unity style={{ width: '100%', height: '100%' }} unityContext={unityContext} />
            : null
        }
      </div>
    </Fragment>
  )
}

export default MobileGame;
