import React, { useEffect, useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import {
  sendedFeedback,
  takeFeedback,
} from "../../../services/feedback.service";
import Loading from "../../../components/uncommon/Loading";

const SendedFeedback = ({ id }) => {
  const height = window.innerHeight - 360;
  const [table, setTable] = useState("sended");
  const [loading, setLoading] = useState(false);

  const [sendNumber, setSendNumber] = useState(0);
  const [startSend, setStartSend] = useState(null);
  const [conSend, setConSend] = useState(null);
  const [stopSend, setStopSend] = useState(null);

  const [inboxNumber, setInboxNumber] = useState(0);
  const [startInbox, setStartInbox] = useState(null);
  const [conInbox, setConInbox] = useState(null);
  const [stopInbox, setStopInbox] = useState(null);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (id !== null) {
      setLoading(true);
      sendedFeedback(id)
        .then((data) => {
          setSendNumber(data.data.length);
          const starts = data.data
            .filter((data) => data.type === "START")
            .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          setStartSend(starts);

          const cons = data.data
            .filter((data) => data.type === "CONTINUE")
            .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          setConSend(cons);

          const stops = data.data
            .filter((data) => data.type === "STOP")
            .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          setStopSend(stops);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });

      takeFeedback(id)
        .then((data) => {
          setInboxNumber(data.data.length);
          const starts = data.data
            .filter((data) => data.type === "START")
            .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          setStartInbox(starts);

          const cons = data.data
            .filter((data) => data.type === "CONTINUE")
            .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          setConInbox(cons);

          const stops = data.data
            .filter((data) => data.type === "STOP")
            .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          setStopInbox(stops);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [id]);

  const startGradiant = {
    background: "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)",
  };

  const conGradiant = {
    background: "linear-gradient(90deg, #48D7B4, #46DF9F, #01CF60)",
  };

  const stopGradiant = {
    background: "linear-gradient(90deg, #FFA354, #FF9840, #FF5C00)",
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `/${year}/${month}/${day}`;
  }

  const isLatest = (dateString) => {
    const createdDate = new Date(dateString);
    const currentDate = new Date();

    const timeDifference = currentDate - createdDate;
    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;

    return timeDifference <= threeDaysInMs;
  };

  const filterDataByText = (data) => {
    if (!data) return [];
    return data.filter((item) =>
      item.text.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  if (id === null) {
    return <div className="ms-4 mt-4 text-lg">There is no Employee</div>;
  }

  if (
    startInbox === null ||
    conInbox === null ||
    stopInbox === null ||
    startSend === null ||
    conSend === null ||
    stopSend === null
  ) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-10 mt-6">
          <div
            onClick={() => {
              setTable("sended");
            }}
            className={`w-[150px] h-[84px] flex items-center justify-center shadow-xl rounded-xl cursor-pointer ${
              table === "sended" ? "opacity-100" : "opacity-50"
            }`}
          >
            <div>
              <p className="text-sm text-[#666874]">Илгээсэн</p>
              <p className="text-[#324D72] text-[32px]">{sendNumber}</p>
            </div>
          </div>
          <div
            onClick={() => {
              setTable("inbox");
            }}
            className={`w-[150px] h-[84px] flex items-center justify-center shadow-xl rounded-xl cursor-pointer ${
              table === "inbox" ? "opacity-100" : "opacity-50"
            }`}
          >
            <div>
              <p className="text-sm text-[#666874]">Хүлээн авсан</p>
              <p className="text-[#324D72] text-[32px]">{inboxNumber}</p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex relative">
            <div className="absolute top-1/2 left-3 transform  -translate-y-1/2 z-10">
              <FaMagnifyingGlass className="text-[#64748B] text-xs opacity-100" />
            </div>
            <input
              className="text-xs rounded-xl border border-[#B3B3B3] ps-6"
              placeholder="Search for responses"
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)} // Update search text
            />
          </div>
        </div>
      </div>
      {table === "sended" ? (
        <div className="flex items-center gap-4 justify-between mt-6">
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%] pb-4">
            <div className="flex items-center gap-2">
              <div
                style={startGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Start</p>
              <div className="px-2 py-1 bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {startSend.length}
              </div>
            </div>
            <div style={startGradiant} className="w-full h-[4px] my-4" />
            {startSend.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Илгээсэн feedback байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(startSend).map((items) => {
                  return (
                    <div className="w-full p-4 bg-white rounded-xl mb-4">
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              latest
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div>
                        <p className="text-[11px] text-end  text-[#222] text-semibold">
                          Илгээсэн:{" "}
                          {items.hide === true
                            ? "Anonymous"
                            : `${items.lastName} ${items.firstName}`}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%] pb-4">
            <div className="flex items-center gap-2">
              <div
                style={conGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Continue</p>
              <div className="px-2 py-1 bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {conSend.length}
              </div>
            </div>
            <div style={conGradiant} className="w-full h-[4px] my-4" />
            {conSend.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Илгээсэн feedback байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(conSend).map((items) => {
                  return (
                    <div className="w-full p-4 bg-white rounded-xl mb-4">
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              latest
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div>
                        <p className="text-[11px] text-end  text-[#222] text-semibold">
                          Илгээсэн:{" "}
                          {items.hide === true
                            ? "Anonymous"
                            : `${items.lastName} ${items.firstName}`}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%]  pb-4">
            <div className="flex items-center gap-2">
              <div
                style={stopGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Stop</p>
              <div className="px-2 py-1 bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {stopSend.length}
              </div>
            </div>
            <div style={stopGradiant} className="w-full h-[4px] my-4" />
            {stopSend.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Илгээсэн feedback байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(stopSend).map((items) => {
                  return (
                    <div className="w-full p-4 bg-white rounded-xl mb-4">
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              latest
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div>
                        <p className="text-[11px] text-end  text-[#222] text-semibold">
                          Илгээсэн:{" "}
                          {items.hide === true
                            ? "Anonymous"
                            : `${items.lastName} ${items.firstName}`}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-4 justify-between mt-6">
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%]  pb-4">
            <div className="flex items-center gap-2">
              <div
                style={startGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Start</p>
              <div className="px-2 py-1 bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {startInbox.length}
              </div>
            </div>
            <div style={startGradiant} className="w-full h-[4px] my-4" />
            {startInbox.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Хүлээн авсан feedback байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(startInbox).map((items) => {
                  return (
                    <div className="w-full p-4 bg-white rounded-xl mb-4">
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              latest
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div>
                        <p className="text-[11px] text-end  text-[#222] text-semibold">
                          Илгээсэн:{" "}
                          {items.hide === true
                            ? "Anonymous"
                            : `${items.lastName} ${items.firstName}`}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%] pb-4">
            <div className="flex items-center gap-2">
              <div
                style={conGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Continue</p>
              <div className="px-2 py-1 bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {conInbox.length}
              </div>
            </div>
            <div style={conGradiant} className="w-full h-[4px] my-4" />
            {conInbox.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Хүлээн авсан feedback байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(conInbox).map((items) => {
                  return (
                    <div className="w-full p-4 bg-white rounded-xl mb-4">
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              latest
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div>
                        <p className="text-[11px] text-end  text-[#222] text-semibold">
                          Илгээсэн:{" "}
                          {items.hide === true
                            ? "Anonymous"
                            : `${items.lastName} ${items.firstName}`}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="rounded-xl bg-[#F5F5F5] px-4 pt-6 w-[33%] pb-4">
            <div className="flex items-center gap-2">
              <div
                style={stopGradiant}
                className="rounded-full w-[8px] h-[8px]"
              ></div>
              <p className="text-[#0D062D]">Stop</p>
              <div className="px-2 py-1 bg-[#E0E0E0] rounded-full  text-[#625F6D] text-xs ms-2">
                {stopInbox.length}
              </div>
            </div>
            <div style={stopGradiant} className="w-full h-[4px] my-4" />
            {stopInbox.length === 0 ? (
              <div className="flex  flex-col items-center justify-center ">
                <img src="/feedback.svg" alt="nothing" />
                <p className="text-[#1E293B] text-sm">
                  Хүлээн авсан feedback байхгүй
                </p>
              </div>
            ) : (
              <div
                className="overflow-y-scroll"
                style={{ height: `${height}px` }}
              >
                {filterDataByText(stopInbox).map((items) => {
                  return (
                    <div className="w-full p-4 bg-white rounded-xl mb-4">
                      <div className="flex items-center justify-between">
                        <p className="text-[#64748B] text-xs py-0.5">
                          {formatDate(items.createdDate)}
                        </p>
                        {isLatest(items.createdDate) && (
                          <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                            <p className="text-[#FF9D72] font-semibold text-sm">
                              latest
                            </p>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-xs text-[#222] py-2">{items.text}</p>
                      </div>
                      <div>
                        <p className="text-[11px] text-end  text-[#222] text-semibold">
                          Илгээсэн:{" "}
                          {items.hide === true
                            ? "Anonymous"
                            : `${items.lastName} ${items.firstName}`}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SendedFeedback;
