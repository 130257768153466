import React from "react";
import { useLocation } from "react-router-dom";

const BurnOutDepDemo = () => {
  const location = useLocation();
  const receivedData = location.state?.data;

  return <div>{console.log(receivedData)}BurnOutDepDemo</div>;
};

export default BurnOutDepDemo;
