import React from "react";

const TestAnswers = () => {
  const data = [
    { label: "Хариулт 1", percentage: 50, count: 35, color: "bg-green-400" },
    { label: "Хариулт 2", percentage: 7, count: 9, color: "bg-gray-200" },
    { label: "Хариулт 3", percentage: 20, count: 29, color: "bg-gray-200" },
    { label: "Хариулт 4", percentage: 9, count: 13, color: "bg-gray-200" },
  ];

  return (
    <div className="m-auto w-10/12 flex items-center justify-center">
      <div>
        <div className="w-[514px] rounded-xl py-6 flex items-center justify-center gap-10 bg-[#fff] mb-6">
          <div>
            <p className="text-sm text-[#666874] mb-2">Тест илгээсэн</p>
            <p className="text-[24px] text-[#1E293B] font-semibold">331</p>
          </div>
          <div>
            <p className="text-sm text-[#666874] mb-2">Тэнцээгүй</p>
            <p className="text-[24px] text-[#1E293B] font-semibold">331</p>
          </div>
          <div>
            <p className="text-sm text-[#666874] mb-2">Тэнцсэн</p>
            <p className="text-[24px] text-[#1E293B] font-semibold">331</p>
          </div>
        </div>
        <div className="w-[514px] rounded-xl px-2 py-2 bg-[#fff] mb-6">
          <div>
            <div>
              <div className="flex items-center justify-between w-full">
                <p className="text-sm text-[#1E293B]">
                  Where does it come from?
                </p>
                <p className="text-[#1E293B] text-xs">
                  75-с <span className="font-bold text-sm">35</span> зөв
                  хариулсан
                </p>
              </div>
            </div>
            <hr className="h-[4px] my-2" />
            <div className="w-full max-w-md mx-auto">
              {data.map((item, index) => (
                <div key={index} className="flex items-center mb-4">
                  <div className="w-24 text-right pr-4 text-gray-700">
                    {item.label}
                  </div>
                  <div className="flex-1">
                    <div className="relative h-8 rounded-md overflow-hidden">
                      <div
                        className={`absolute h-full ${item.color}`}
                        style={{ width: `${item.percentage}%` }}
                      ></div>
                      <div className="absolute inset-0 flex items-center justify-center text-gray-700 font-semibold">
                        {item.percentage}%
                      </div>
                    </div>
                  </div>
                  <div className="w-12 pl-4 text-gray-700">{item.count}</div>
                </div>
              ))}
              <div className="flex justify-between text-gray-500 text-sm mt-2 ms-24">
                <p className="text-xs">0</p>
                <p className="text-xs">10</p>
                <p className="text-xs">20</p>
                <p className="text-xs">30</p>
                <p className="text-xs">40</p>
                <p className="text-xs">50</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestAnswers;
