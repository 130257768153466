import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MainButton from "../../components/buttons/MainButton";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#23293D',
    color: theme.palette.common.black,
    fontSize: 16,
    borderBottom: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: "none",
    boxShadow: "none",
    color: theme.palette.common.black,
  },
}));

function createData(name, description, score, label, img) {
  return { name, description, score, label, img };
}

const NewAssessmentRequirement = ({
  savedSkillScore,
  changePage,
  skillScoreInputs,
  setSkillScoreInputs,
  createAssessment,
  gameLanguage,
  setGameLanguage,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const { t, i18n } = useTranslation();
  const rows = [
    createData(
      `${t("NewAssessment.inductiveName")}`,
      `${t("NewAssessment.inductiveDescription")}`,
      3,
      "inductive",
      "inductive.png"
    ),
    createData(
      `${t("NewAssessment.deductiveName")}`,
      `${t("NewAssessment.deductiveDescription")}`,
      3,
      "deductive",
      "deductive.png"
    ),
    createData(
      `${t("NewAssessment.quantitativeName")}`,
      `${t("NewAssessment.quantitativeDescription")}`,
      3,
      "quantitativeReasoing",
      "quantitative.png"
    ),
    createData(
      `${t("NewAssessment.attentionName")}`,
      `${t("NewAssessment.attentionDescription")}`,
      3,
      "attention",
      "attention.png"
    ),
    createData(
      `${t("NewAssessment.workingMemoryName")}`,
      `${t("NewAssessment.workingMemoryDescription")}`,
      3,
      "workingMemory",
      "memory.png"
    ),
    createData(
      `${t("NewAssessment.processingSpeedName")}`,
      `${t("NewAssessment.processingSpeedDescription")}`,
      3,
      "processingSpeed",
      "processingSpeed.png"
    ),
    createData(
      `${t("NewAssessment.patternRecognitionName")}`,
      `${t("NewAssessment.patternRecognitionDescription")}`,
      3,
      "patternRecognition",
      "pattern.png"
    ),
    createData(
      `${t("NewAssessment.criticalThinkingName")}`,
      `${t("NewAssessment.criticalThinkingDescription")}`,
      3,
      "criticalThinking",
      "critical-thinking.png"
    ),
    createData(
      `${t("NewAssessment.decisionMakingName")}`,
      `${t("NewAssessment.decisionMakingDescription")}`,
      3,
      "decisionMaking",
      "decision-making.png"
    ),
    createData(
      `${t("NewAssessment.planningName")}`,
      `${t("NewAssessment.planningDescription")}`,
      3,
      "planning",
      "planning.png"
    ),
    createData(
      `${t("NewAssessment.learningSpeedName")}`,
      `${t("NewAssessment.learningSpeedDescription")}`,
      3,
      "learningRate",
      "learning.png"
    ),
    createData(
      `${t("NewAssessment.curiosityName")}`,
      `${t("NewAssessment.curiosityDescription")}`,
      3,
      "curiosity",
      "curiosity.png"
    ),
    createData(
      `${t("NewAssessment.riskTakingName")}`,
      `${t("NewAssessment.riskTakingDescription")}`,
      3,
      "riskTaking",
      "risk-taking.png"
    ),
    createData(
      `${t("NewAssessment.adaptability")}`,
      `${t("NewAssessment.adaptabilityDescription")}`,
      3,
      "adaptability",
      "adaptability.png"
    ),
    createData(
      `${t("NewAssessment.consistence")}`,
      `${t("NewAssessment.consistenceDescription")}`,
      3,
      "consistence",
      "consistency.png"
    ),
    createData(
      `${t("NewAssessment.goalSetting")}`,
      `${t("NewAssessment.goalSettingDescription")}`,
      3,
      "goalSetting",
      "goal-setting.png"
    ),
    createData(
      `${t("NewAssessment.cooperative")}`,
      `${t("NewAssessment.cooperativeDescription")}`,
      3,
      "cooperative",
      "cooperative.png"
    ),
    createData(
      `${t("NewAssessment.trusting")}`,
      `${t("NewAssessment.trustingDescription")}`,
      3,
      "trusting",
      "trust.png"
    ),
    createData(
      `${t("NewAssessment.assertiveness")}`,
      `${t("NewAssessment.assertivenessDescription")}`,
      3,
      "assertiveness",
      "assertive.png"
    ),
  ];

  const skillScore = [
    {
      value: 5,
      label: `${t("NewAssessment.important")}`,
    },
    {
      value: 4,
      label: `${t("NewAssessment.relevant")}`,
    },
    {
      value: 3,
      label: `${t("NewAssessment.average")}`,
    },
    {
      value: 2,
      label: `${t("NewAssessment.unimportant")}`,
    },
    {
      value: 1,
      label: `${t("NewAssessment.notRelevant")}`,
    },
  ];
  return (
    <div className="flex justify-center pl-11">
      <div>
        <p className="font-bold mt-[30px] text-[20px] pb-[30px] ">
          {t("NewAssessmentGeneral.gameReq")}
        </p>
        <p>{t("NewAssessmentGeneral.gameLang")}*</p>
        <div>
          <div className="flex justify-between">
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              size="small"
              className="languageSelection bg-gray-50 rounded-[10px]"
            >
              <Select
                defaultValue={gameLanguage}
                inputProps={{
                  name: "language",
                  id: "uncontrolled-native",
                }}
                onChange={(e) => {
                  setGameLanguage(e.target.value);
                }}
              >
                <MenuItem value={0}>
                  <span>Монгол</span>
                </MenuItem>
                <MenuItem value={2}>
                  <Box display="flex" alignItems="center">
                    <span>English</span>
                  </Box>
                </MenuItem>
                <MenuItem value={1}>
                  <Box display="flex" alignItems="center">
                    <span>Русский</span>
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
            <div>
              <MainButton
                color={"bg-main-blue"}
                labelColor={"text-white"}
                label={t("NewAssessmentGeneral.createNewAsessment")}
                onClick={createAssessment}
              />
            </div>
          </div>
        </div>
        <div className="mt-11">
          {/* <p>Оюун ухааны үзүүлэлтүүд</p> */}
          <hr />
        </div>
        <div className="mt-4">
          <div className="m-4">
            <div style={{ width: "100%" }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 700, border: "none" }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>{`${t(
                        "NewAssessment.skillName"
                      )}`}</StyledTableCell>
                      <StyledTableCell align="center">{`${t(
                        "NewAssessment.description"
                      )}`}</StyledTableCell>
                      <StyledTableCell width={200} align="center">{`${t(
                        "NewAssessment.requiredLevel"
                      )}`}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          <div className="flex items-center">
                            <img
                              src={`/assets/report/${row.img}`}
                              className="w-[30px] object-cover"
                              alt="Logo"
                            />
                            <div className="flex flex-col justify-center pl-2">
                              {row.name}
                            </div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.description}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <FormControl
                            sx={{ m: 1, minWidth: 120 }}
                            size="small"
                            className="requirementSelection rounded-[10px]"
                          >
                            <Select
                              value={skillScoreInputs[row.label]}
                              sx={{ color: "black" }}
                              onChange={(e) => {
                                setSkillScoreInputs({
                                  ...skillScoreInputs,
                                  [row.label]: parseInt(e.target.value),
                                });
                              }}
                            >
                              {skillScore.map((option) => (
                                <MenuItem
                                  value={option.value}
                                  key={option.value}
                                >
                                  <Box display="flex" alignItems="center">
                                    <span>{option.label}</span>
                                  </Box>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <div className="flex justify-start mt-[30px]">
          <div className="w-[217px]">
            <MainButton
              onClick={() => {
                changePage("general");
              }}
              rIcon={<ArrowLeft className="ml-1" />}
              label={t("NewAssessmentGeneral.back")}
              customClass="border-solid border-2 border-black-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAssessmentRequirement;
