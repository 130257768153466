import api from "../helper/request";

const getSurveyById = (id) => api.get(`/survey/${id}`);
const getSurveys = () => api.get("/survey");
const addSurveys = (obj) => api.post("/survey", obj);
const addSurveyQuestion = (obj) => api.post("survey-question", obj);
const deleteSurvey = (id) => api.delete(`survey/${id}`);
const updateSurvey = (obj) => api.post("/update-question", obj);
const updateSurveyName = (obj) => api.post("updateSurvey", obj);
const deleteQuestion = (id) => api.delete(`survey-question/delete/${id}`);
const surveyInvite = (obj) => api.post("survey-employee", obj);
const newSurvayInvite = (obj) => api.post("survey", obj);
const survayAnswerData = (id) => api.get(`survey-answer/${id}`);
const invitedEmployee = (id) => api.get(`invited-survey-employee/${id}`);
const getQuestionsData = (id) => api.get(`question-answer/${id}`);
const getSurveySummery = (id) => api.get(`/survey/report/${id}`);
const getTopics = () => api.get(`/survey/topic`);
const getEmailText = () => api.get(`/email-template/survey`);
const createSurveyAiTxt = (obj) => api.post("survey/create/ai", obj);
const getSurveyListById = (id) => api.get(`/survey/detail?surveyId=${id}`);
const updateEditedSurvey = (obj) => api.post("/survey/edit", obj);

export {
  getSurveyById,
  getSurveys,
  addSurveys,
  addSurveyQuestion,
  deleteSurvey,
  updateSurvey,
  updateSurveyName,
  deleteQuestion,
  surveyInvite,
  newSurvayInvite,
  survayAnswerData,
  invitedEmployee,
  getQuestionsData,
  getTopics,
  getSurveySummery,
  getEmailText,
  createSurveyAiTxt,
  getSurveyListById,
  updateEditedSurvey,
};
