import React from 'react';
import './ChatBubble.css'; // import your CSS file for styling

const ChatBubble = ({ message, sender }) => {
    return (
        <div>
            <div className="sender-label pl-[14px] font-bold">{sender === 'user' ? 'Та' : 'HR туслах'}</div>
            <div className={`chat-bubble ${sender}`}>
                <p dangerouslySetInnerHTML={{ __html: message }}></p>
            </div>
        </div>
    );
};
export default ChatBubble;
