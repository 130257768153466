import React, { useRef, useState } from "react";
import { IoFilter } from "react-icons/io5";
import { LuCalendarDays } from "react-icons/lu";
import { IoIosArrowDown } from "react-icons/io";
import { FaMagnifyingGlass } from "react-icons/fa6";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { IoIosClose } from "react-icons/io";

const SurveyTable = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const modalRef = useRef(null);

  const toggleCalendar = () => setShowCalendar(!showCalendar);

  const handleModalClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowFilter(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const data = [
    // Sample data
    { id: 1, name: "John Doe", age: 28 },
    { id: 2, name: "Jane Smith", age: 34 },
    { id: 3, name: "Alice Johnson", age: 23 },
    { id: 4, name: "Chris Lee", age: 45 },
    { id: 4, name: "Chris Lee", age: 45 },
    { id: 4, name: "Chris Lee", age: 45 },
    { id: 4, name: "Chris Lee", age: 45 },
    { id: 4, name: "Chris Lee", age: 45 },
    { id: 4, name: "Chris Lee", age: 45 },
    { id: 4, name: "Chris Lee", age: 45 },
    // Add more data as needed
  ];

  return (
    <div className="bg-[#fff] px-5 pb-3">
      <div className="flex items-center gap-8">
        <button
          onClick={() => {
            setShowFilter(true);
          }}
          className="flex items-center gap-2"
        >
          <IoFilter />
          <p className="text-xs text-[#222[">Шүүлтүүр</p>
        </button>
        <div className="relative">
          <div
            className={`flex items-center gap-2 px-2 py-0.5 rounded ${
              selectedDate !== null
                ? "bg-[#F3F4F8] border border-[#ADADAD]"
                : ""
            }`}
          >
            <button className="flex items-center" onClick={toggleCalendar}>
              <LuCalendarDays className="h-4 w-4 me-1" />
              {selectedDate ? (
                <p className="text-[#222] text-xs">
                  {selectedDate.toLocaleDateString()}{" "}
                </p>
              ) : (
                <p className="text-[#222] text-xs flex items-center gap-2">
                  {" "}
                  Бүх цаг үеийн <IoIosArrowDown className="h-3 w-3" />
                </p>
              )}
            </button>
            {selectedDate !== null ? (
              <button
                onClick={() => {
                  setSelectedDate(null);
                }}
                className="text-[#222] text-xs flex items-center gap-2"
              >
                <IoIosClose className="h-5 w-5" />
              </button>
            ) : (
              ""
            )}
          </div>

          {showCalendar && (
            <div className="absolute bg-white border rounded mt-2 shadow-lg p-4">
              <div className="flex items-start gap-6">
                <div className="border-r-2 borer-[#EAEAEA] h-full w-[150px]">
                  <button
                    // onClick={() => {
                    //   setSelectedDate("Өнөөдөр");
                    // }}
                    className={`hover:bg-[#E5E7EB] px-2 rounded-xl mb-1 text-xs ${
                      selectedDate === "Өнөөдөр" ? "bg-[#324d72]" : ""
                    }`}
                  >
                    Өнөөдөр
                  </button>
                  <button
                    // onClick={() => {
                    //   setSelectedDate("Өнгөрсөн 7 хоног");
                    // }}
                    className={`hover:bg-[#E5E7EB] px-2 rounded-xl mb-1 text-xs ${
                      selectedDate === "Өнгөрсөн 7 хоног" ? "bg-[#324d72]" : ""
                    }`}
                  >
                    Өнгөрсөн 7 хоног
                  </button>
                  <button
                    // onClick={() => {
                    //   setSelectedDate("Өнгөрсөн сар");
                    // }}
                    className={`hover:bg-[#E5E7EB] px-2 rounded-xl mb-1 text-xs ${
                      selectedDate === "Өнгөрсөн сар" ? "bg-[#324d72]" : ""
                    }`}
                  >
                    Өнгөрсөн сар
                  </button>
                  <button
                    // onClick={() => {
                    //   setSelectedDate("Өнгөрсөн жил");
                    // }}
                    className={`hover:bg-[#E5E7EB] px-2 rounded-xl mb-1 text-xs ${
                      selectedDate === "Өнгөрсөн жил" ? "bg-[#324d72]" : ""
                    }`}
                  >
                    Өнгөрсөн жил
                  </button>
                </div>
                <div>
                  <Calendar
                    selectRange={true}
                    className="border border-[#E5E7EB] rounded-xl"
                    onChange={handleDateChange}
                    value={selectedDate}
                  />
                  <div className="flex justify-end mt-4">
                    <button
                      className="bg-[#E5E7EB] px-5 py-1 rounded-lg mr-2 text-xs text-[#6B7280]"
                      onClick={toggleCalendar}
                    >
                      Болих
                    </button>
                    <button
                      onClick={() => {
                        setShowCalendar(false);
                      }}
                      className={`text-white px-5 py-1 rounded-lg text-xs ${
                        selectedDate !== null ? "bg-[#222]" : "bg-[#E5E7EB]"
                      }`}
                    >
                      Шүүх
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="relative">
          <FaMagnifyingGlass className="absolute w-3 h-3 text-[#EAEAEA] left-2 inset-y-1/3" />
          <input
            className="rounded-xl border border-[#EAEAEA] text-[10px] ps-6 py-1 "
            placeholder="Search for response"
            type="text"
          />
        </div>
      </div>
      <div className="container mx-auto p-4">
        <div className="overflow-auto max-h-[500px] max-w-full border-2">
          <table className="table-auto min-w-full">
            <thead>
              <tr>
                <th className="border border-[#EAEAEA] text-[#222] text-xs px-3">
                  <div className="w-[170px] h-[66px] text-center "></div>
                </th>
                <th className="border border-[#EAEAEA] text-[#222] text-xs px-3">
                  <div className="w-[150px] h-[66px] text-center overflow-hidden">
                    <div className="truncate whitespace-nowrap">
                      <div className="whitespace-normal break-words">
                        Манай багийн гишүүд өөрийн ажил үүрэгтээ хариуцлагатай
                        ханддаг. Манай багийн гишүүд өөрийн ажил үүрэгтээ
                        хариуцлагатай ханддаг. Манай багийн гишүүд өөрийн ажил
                        үүрэгтээ хариуцлагатай ханддаг.
                      </div>
                    </div>
                  </div>
                </th>

                <th className="border border-[#EAEAEA] text-[#222] text-xs px-3">
                  <div className="w-[150px] h-[66px] text-center">
                    Манай багийн гишүүд өөрийн ажил үүрэгтээ хариуцлагатай
                    ханддаг.
                  </div>
                </th>
                <th className="border border-[#EAEAEA] text-[#222] text-xs px-3">
                  <div className="w-[150px] h-[66px] text-center">
                    Манай багийн гишүүд өөрийн ажил үүрэгтээ хариуцлагатай
                    ханддаг.
                  </div>
                </th>
                <th className="border border-[#EAEAEA] text-[#222] text-xs px-3">
                  <div className="w-[150px] h-[66px] text-center">
                    Манай багийн гишүүд өөрийн ажил үүрэгтээ хариуцлагатай
                    ханддаг.
                  </div>
                </th>
                <th className="border border-[#EAEAEA] text-[#222] text-xs px-3">
                  <div className="w-[150px] h-[66px] text-center">
                    Манай багийн гишүүд өөрийн ажил үүрэгтээ хариуцлагатай
                    ханддаг.
                  </div>
                </th>
                <th className="border border-[#EAEAEA] text-[#222] text-xs px-3">
                  <div className="w-[150px] h-[66px] text-center">
                    Манай багийн гишүүд өөрийн ажил үүрэгтээ хариуцлагатай
                    ханддаг.
                  </div>
                </th>
                <th className="border border-[#EAEAEA] text-[#222] text-xs px-3">
                  <div className="w-[150px] h-[66px] text-center">
                    Манай багийн гишүүд өөрийн ажил үүрэгтээ хариуцлагатай
                    ханддаг.
                  </div>
                </th>
                <th className="border border-[#EAEAEA] text-[#222] text-xs px-3">
                  <div className="w-[150px] h-[66px] text-center">
                    Манай багийн гишүүд өөрийн ажил үүрэгтээ хариуцлагатай
                    ханддаг.
                  </div>
                </th>
                <th className="border border-[#EAEAEA] text-[#222] text-xs px-3">
                  <div className="w-[150px] h-[66px] text-center">
                    Манай багийн гишүүд өөрийн ажил үүрэгтээ хариуцлагатай
                    ханддаг.
                  </div>
                </th>
                <th className="border border-[#EAEAEA] text-[#222] text-xs px-3">
                  <div className="w-[150px] h-[66px] text-center">
                    Манай багийн гишүүд өөрийн ажил үүрэгтээ хариуцлагатай
                    ханддаг.
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className="hover:bg-gray-100">
                  <td className="h-[66px] text-center border border-[#EAEAEA] text-[#222] text-xs bg-[#FFFBF3]">
                    {item.id}
                  </td>
                  <td className="h-[66px] text-center border border-[#EAEAEA] text-[#222] text-xs bg-[#FFFBF3]">
                    {item.name}
                  </td>
                  <td className="h-[66px] text-center border border-[#EAEAEA] text-[#222] text-xs bg-[#FFFBF3]">
                    {item.age}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showFilter && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div
            ref={modalRef}
            className="modal  bg-white rounded-lg overflow-y-auto w-[300px] h-[300px]"
          >
            <div className="flex items-center justify-between border-b pb-2 mt-4 px-3">
              <p className="text-sm font-semibold">Асуултнаас шүүх</p>
              <IoIosClose
                onClick={() => {
                  setShowFilter(false);
                }}
                className="w-7 h-7"
              />
            </div>
            <div className="px-3 mt-3 ">
              <p className="text-sm text-[#222]">Асуумж сонгох</p>
              <div className="mt-2 relative">
                <select
                  className="w-full py-2 ps-8 rounded-xl border-[#EDEDED]"
                  name=""
                >
                  <option disabled className="text-[#636466] text-xs">
                    Эндээс сонгоно уу
                  </option>
                </select>
                <FaMagnifyingGlass className="absolute left-2 inset-y-1/4 text-[#636466]" />
              </div>
              <div className="mt-3">
                <p className="text-[#222] text-sm opacity-40">Хариулт сонгох</p>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="rounded-full border-[#D9D9D9] border-2"
                  />
                  <p className="text-[#C1C1C1] text-sm">1</p>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="rounded-full border-[#D9D9D9] border-2"
                  />
                  <p className="text-[#C1C1C1] text-sm">2</p>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="rounded-full border-[#D9D9D9] border-2"
                  />
                  <p className="text-[#C1C1C1] text-sm">3</p>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="rounded-full border-[#D9D9D9] border-2"
                  />
                  <p className="text-[#C1C1C1] text-sm">4</p>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="rounded-full border-[#D9D9D9] border-2"
                  />
                  <p className="text-[#C1C1C1] text-sm">5</p>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      )}
    </div>
  );
};

export default SurveyTable;
