import React, { useState } from "react";
import { BsPlus } from "react-icons/bs";
import { Tree, TreeNode } from "react-organizational-chart";
import { Link } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import { AiOutlineMinus } from "react-icons/ai";

const treeStyle = {
  padding: "5px",
  borderRadius: "8px",
  display: "inline-block",
  border: "1px solid red",
};
const containerStyle = {
  overflow: "auto",
  width: "100%",
  height: "80vh",
  display: "flex",
  maxWidth: "100vw",
};

const testData = {
  id: 1,
  name: "Нэр",
  position: "Менежер",
  imgSrc: process.env.PUBLIC_URL + "/user1.svg",
  children: [
    {
      id: 2,
      name: "Child 1",
      position: "Position 1",
      imgSrc: process.env.PUBLIC_URL + "/user2.svg",
      children: [
        {
          id: 3,
          name: "Grand Child",
          position: "Position 2",
          imgSrc: process.env.PUBLIC_URL + "/user3.svg",
        },
      ],
    },

    {
      id: 8,
      name: "Child 3",
      position: "Position 7",
      imgSrc: process.env.PUBLIC_URL + "/user1.svg",
      children: [
        {
          id: 9,
          name: "Grand Child 1",
          position: "Position 8",
          imgSrc: process.env.PUBLIC_URL + "/user2.svg",
        },
        {
          id: 10,
          name: "Grand Child 2",
          position: "Position 9",
          imgSrc: process.env.PUBLIC_URL + "/user3.svg",
        },
      ],
    },
    {
      id: 8,
      name: "Child 3",
      position: "Position 7",
      imgSrc: process.env.PUBLIC_URL + "/user1.svg",
      children: [
        {
          id: 9,
          name: "Grand Child 1",
          position: "Position 8",
          imgSrc: process.env.PUBLIC_URL + "/user2.svg",
        },
        {
          id: 10,
          name: "Grand Child 2",
          position: "Position 9",
          imgSrc: process.env.PUBLIC_URL + "/user3.svg",
        },
      ],
    },

    {
      id: 8,
      name: "Child 3",
      position: "Position 7",
      imgSrc: process.env.PUBLIC_URL + "/user1.svg",
      children: [
        {
          id: 9,
          name: "Grand Child 1",
          position: "Position 8",
          imgSrc: process.env.PUBLIC_URL + "/user2.svg",
        },
        {
          id: 10,
          name: "Grand Child 2",
          position: "Position 9",
          imgSrc: process.env.PUBLIC_URL + "/user3.svg",
        },
      ],
    },
    {
      id: 8,
      name: "Child 3",
      position: "Position 7",
      imgSrc: process.env.PUBLIC_URL + "/user1.svg",
      children: [
        {
          id: 9,
          name: "Grand Child 1",
          position: "Position 8",
          imgSrc: process.env.PUBLIC_URL + "/user2.svg",
        },
        {
          id: 10,
          name: "Grand Child 2",
          position: "Position 9",
          imgSrc: process.env.PUBLIC_URL + "/user3.svg",
        },
      ],
    },
    {
      id: 8,
      name: "Child 3",
      position: "Position 7",
      imgSrc: process.env.PUBLIC_URL + "/user1.svg",
      children: [
        {
          id: 9,
          name: "Grand Child 1",
          position: "Position 8",
          imgSrc: process.env.PUBLIC_URL + "/user2.svg",
        },
        {
          id: 10,
          name: "Grand Child 2",
          position: "Position 9",
          imgSrc: process.env.PUBLIC_URL + "/user3.svg",
        },
        {
          id: 10,
          name: "Grand Child 2",
          position: "Position 9",
          imgSrc: process.env.PUBLIC_URL + "/user3.svg",
        },
        {
          id: 10,
          name: "Grand Child 2",
          position: "Position 9",
          imgSrc: process.env.PUBLIC_URL + "/user3.svg",
        },
        {
          id: 10,
          name: "Grand Child 2",
          position: "Position 9",
          imgSrc: process.env.PUBLIC_URL + "/user3.svg",
        },
      ],
    },
    {
      id: 8,
      name: "Child 3",
      position: "Position 7",
      imgSrc: process.env.PUBLIC_URL + "/user1.svg",
      children: [
        {
          id: 9,
          name: "Grand Child 1",
          position: "Position 8",
          imgSrc: process.env.PUBLIC_URL + "/user2.svg",
        },
        {
          id: 10,
          name: "Grand Child 2",
          position: "Position 9",
          imgSrc: process.env.PUBLIC_URL + "/user3.svg",
        },
      ],
    },
  ],
};

const renderTreeNode = (data) => (
  <TreeNode key={data.id} label={renderNode(data)}>
    {data.children &&
      data.children.map((child) => <>{renderTreeNode(child)}</>)}
  </TreeNode>
);

const renderNode = (node) => (
  <button
    className="py-2 px-4 flex items-center justify-center"
    style={{
      ...treeStyle,
      border: `1px solid ${node.id % 2 === 0 ? "red" : "blue"}`,
    }}
    onClick={() => {
      console.log("aaaaa");
    }}
  >
    <div>
      <div className="flex item-center justify-center">
        <img width={29} height={29} src={node.imgSrc} alt="user" />
      </div>
      <div>
        <p>{node.name}</p>
        <p className="text-gray-400 text-xs">{node.position}</p>
      </div>
    </div>
  </button>
);

const DepartmentDetail = () => {
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(0.1, prevZoom - 0.1));
  };
  return (
    <>
      <div className="m-auto w-10/12 pt-11">
        <div className="flex justify-between">
          <div>
            <p className="text-2xl font-bold">Хэлтэсийн нэр</p>
            <p className="text-gray-400 text-base ">10 ажилтан</p>
          </div>
        </div>
        <div className="mt-4">
          <button
            className="px-2 py-2 mx-2 rounded-full border border-3"
            onClick={handleZoomIn}
          >
            <BiPlus className="text-xl" />
          </button>
          <button
            className="px-2 py-2 mx-2 rounded-full border border-3"
            onClick={handleZoomOut}
          >
            <AiOutlineMinus className="text-xl" />
          </button>
        </div>
        <div className="mt-6 p-4 bg-[#F5F5F6]" style={containerStyle}>
          <div
            style={{
              borderTop: "none",
              transformOrigin: "top left",
              transform: `scale(${zoomLevel})`,
            }}
          >
            <Tree
              lineWidth={"2px"}
              lineColor={"#3D608C"}
              lineBorderRadius={"10px"}
            >
              {renderTreeNode(testData)}
            </Tree>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentDetail;
