import React from "react";
import { useNavigate } from "react-router-dom";
const Done = () => {
  const navigate = useNavigate();
  return (
    <div className="w-10/12 m-auto h-screen flex justify-center items-center">
      <div className="pb-40">
        <p className="text-2xl font-bold">
          Таны төлбөр баталгаажиж{" "}
          <span className="text-green-600">Premium</span> багц идэвхижлээ.
          Баярлалаа.
        </p>
        <div className="flex mt-5 justify-center">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="text-xl font-bold px-6 py-2 border border-black rounded-xl "
          >
            Үндсэн хуудас руу үсрэх
          </button>
        </div>
      </div>
    </div>
  );
};

export default Done;
