import React, { useState, useEffect } from "react";
import ChatBubble from "./Chatbubble";
import "./ChatApp.css"; // import your CSS file for styling
import { askQuestionApi } from "../../services/ai.service";
import { Adjust } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ChatApp = () => {
  const { t, i18n } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [firstMessage, setFirstMessage] = useState(
    "Сайн байна уу! Та юу мэдэхийг хүсч байна?"
  );

  useEffect(() => {
    if (i18n.language === "en") {
      setFirstMessage("Hello! What do you want to know?");
    } else if (i18n.language === "mn") {
      setFirstMessage("Сайн байна уу! Та юу мэдэхийг хүсч байна?");
    }
  }, [i18n]);

  // Dummy previous chat history
  const previousChatHistory = [
    { message: firstMessage, sender: "ai" },
    // Add more previous messages as needed
  ];

  useEffect(() => {
    // Set the initial chat history when the component mounts
    setMessages(previousChatHistory);
  }, []);

  const handleMessageSend = async () => {
    if (inputValue.trim() === "") return;
    const newMessage = { message: inputValue, sender: "user" };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputValue("");
    setWaiting(true);
    // Simulate AI response after a short delay
    await askQuestionApi(inputValue).then((a) => {
      let text = a.data;
      text = text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>"); // Replace **bold** with <b>bold</b>

      // Format line breaks
      text = text.replace(/\n/g, "<br>");
      const aiResponse = { message: text, sender: "ai" };

      setMessages((prevMessages) => [...prevMessages, aiResponse]);
      setWaiting(false);
    });
  };

  return (
    <div className="chat-container">
      {console.log(i18n)}
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <ChatBubble key={index} message={msg.message} sender={msg.sender} />
        ))}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={t("assisstant.write")}
          style={{ fontStyle: "italic" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleMessageSend();
            }
          }}
        />
        {waiting ? (
          <Adjust style={{ width: "40px", height: "40px", color: "#324D72" }} />
        ) : (
          <button
            style={{ backgroundColor: "#324D72" }}
            onClick={handleMessageSend}
          >
            {t("assisstant.send")}
          </button>
        )}
      </div>
    </div>
  );
};

export default ChatApp;
