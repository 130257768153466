import React from "react";
import { MdArrowForwardIos, MdOutlineEdit } from "react-icons/md";
import MainTable from "../../../components/table/MainTable";
import AgCellMenu from "../../../components/menu/AgCellMenu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const OnBoardingList = ({ list }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const clickOnCell = (e) => {
    if (e.event.button === 1) {
      window.open(`/company/onBoarding/create/${e.data.action.id}`, "_blank");
    } else {
      navigate(`/company/onBoarding/create/${e.data.action.id}`);
    }
  };

  if (!Array.isArray(list)) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full mb-2 mt-6">
      <MainTable
        columnDefs={[
          {
            headerName: "Тестүүд",
            field: "text",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  <img src="/survey/icon/text.svg" alt="icon" />
                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/house.png" },
          },
          {
            headerName: "Үүсгэсэн огноо",
            field: "createdDate",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  <img src="/survey/icon/created.svg" alt="icon" />
                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/calendar.png" },
          },
          {
            headerName: t("Survey.question"),
            field: "questionCount",
            sortable: true,
            onCellClicked: clickOnCell,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  <img src="/survey/icon/question.svg" alt="icon" />
                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/chart.png" },
          },
          {
            headerName: t("Survey.participant"),
            field: "employeeCount",
            sortable: true,
            flex: 2,
            onCellClicked: clickOnCell,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center gap-1">
                  <img src="/survey/icon/employee.svg" alt="icon" />
                  <p className="text-[##1E293B] text-sm font-normal">
                    {hprops.displayName}
                  </p>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/location.png" },
          },

          // {
          //   headerName: "",
          //   field: "action",
          //   sortable: false,
          //   cellRenderer: AgCellMenu,
          //   flex: 1,
          //   cellRendererParams: (params) => {
          //     let menuItems = [
          //       {
          //         label: (
          //           <svg
          //             width="10"
          //             height="12"
          //             viewBox="0 0 10 12"
          //             fill="none"
          //             xmlns="http://www.w3.org/2000/svg"
          //           >
          //             <path
          //               d="M7.66732 4V10.6667H2.33398V4H7.66732ZM6.66732 0H3.33398L2.66732 0.666667H0.333984V2H9.66732V0.666667H7.33398L6.66732 0ZM9.00065 2.66667H1.00065V10.6667C1.00065 11.4 1.60065 12 2.33398 12H7.66732C8.40065 12 9.00065 11.4 9.00065 10.6667V2.66667Z"
          //               fill="#636466"
          //             />
          //           </svg>
          //         ),
          //         action: "handleDelete",
          //       },
          //       {
          //         label: <MdOutlineEdit className="text-[#636466]" />,
          //         action: "handleEdit",
          //       },
          //     ];

          //     return {
          //       menuItems: menuItems,
          //       menuActions: {
          //         handleDelete: () => {
          //           handleDelete(params.data.action.id, params.data.text);
          //         },
          //         handleEdit: () => {
          //           navigate(
          //             `/company/edit/survey/${params.data.action.id}`
          //           );
          //         },
          //       },
          //     };
          //   },
          // },
        ]}
        rowData={[...list].reverse().map((elm) => {
          return {
            text: elm.name,
            createdDate: elm.createdDate?.substring(0, 16),
            questionCount: elm.questionCount,
            employeeCount: elm.employeeCount,
            action: elm,
          };
        })}
      />
    </div>
  );
};

export default OnBoardingList;
