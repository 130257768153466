import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/uncommon/loader";
import AgCellMenu from "../../components/menu/AgCellMenu";
import Maintable from "../../components/table/MainTable";
import { sentPlayerReportUrl } from "../../services/assessment.service";

export default function AssessmentTable({ players, deletePlayer }) {
  const { t, i18n } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const clickOnCell = (e) => {
    // if (e && e.data.action.completeType === "completed") {
    //   window.open(`/player/report/${e.data.action.id}`, "_blank");
    // }
    if (e && e.data.action.completeType == "started") {
      return false;
    }
    window.open(`/player/${e.data.action.id}`, "_blank");
  };

  const copyLink = (rowData) => {
    toast.success(t("toast.copied"));
    navigator.clipboard.writeText(
      `www.app.metacog.mn/${rowData.action.urlText}`
    );
  };

  const sendReport = async (rowData) => {
    setShowModal(true);
    await sentPlayerReportUrl(rowData.action.id)
      .then((res) => {
        toast.success(t("toast.Success"));
      })
      .catch((e) => {
        toast.error(t("toast.unSuccess "));
      });
    setShowModal(false);
  };

  const inActivePlayer = (rowData) => {
    deletePlayer(rowData.action);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <ToastContainer />
      <Loader showModal={showModal} />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Dialog
          open={false}
          onClose={() => {
            setShowModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Та оролцогч устгах гэж байна."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Та зөвшөөрч байна уу ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {}}>Болих</Button>
            <Button onClick={() => {}} autoFocus>
              Зөвшөөрөх
            </Button>
          </DialogActions>
        </Dialog>
        <div>
          <Maintable
            columnDefs={[
              {
                headerName: t("Assessment.lastAndFirsltName"),
                field: "name",
                sortable: true,
                onCellClicked: clickOnCell,
                flex: 2,
              },
              {
                headerName: t("NewAssessmentGeneral.email"),
                field: "email",
                sortable: true,
                flex: 2,
                onCellClicked: clickOnCell,
              },
              {
                headerName: t("Assessment.sendAnket"),
                field: "createdDate",
                sortable: true,
                onCellClicked: clickOnCell,
              },
              {
                headerName: "Статус",
                field: "status",
                sortable: true,
                onCellClicked: clickOnCell,
                flex: 3,
                cellRenderer: (params) => {
                  switch (params.data.action.completeType) {
                    case "completed":
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/greenEllipse.png" alt="" />
                          </div>
                          <p>{t("Assessment.completed")}</p>
                        </div>
                      );
                    case "started":
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/blueDot.png" alt="" />
                          </div>
                          <p>{t("Assessment.started")}</p>
                        </div>
                      );
                    case "applied":
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/orangeDot.png" alt="" />
                          </div>
                          <p>{t("Assessment.applied")}</p>
                        </div>
                      );
                    case "declined":
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/redDot.png" alt="" />
                          </div>
                          <p>{t("Assessment.declined")}</p>
                        </div>
                      );
                    case "interview":
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/purpleDot.png" alt="" />
                          </div>
                          <p>{t("Assessment.interview")}</p>
                        </div>
                      );
                    default:
                      return (
                        <div className="flex items-center">
                          <div className="w-2 h-2 flex items-center justify-center mr-1">
                            <img src="/icons/grayDot.png" alt="" />
                          </div>
                          <p>{t("Assessment.sendInvitation")}</p>
                        </div>
                      );
                  }
                },
              },
              {
                headerName: t("Report.score"),
                field: "mpi",
                sortable: true,
                onCellClicked: clickOnCell,
                flex: 1,
              },
              {
                headerName: t("Assessment.gameResult"),
                field: "finish",
                sortable: true,
                flex: 2,
                onCellClicked: clickOnCell,
                cellRenderer: (params) => {
                  switch (params.data.action.completeType) {
                    case "completed":
                      return <img src="/icons/circle-check.png" alt="" />;
                    default:
                      return <img src="/icons/circle-alert.png" alt="" />;
                  }
                },
              },
              {
                headerName: "",
                field: "actions",
                flex: 1,
                cellStyle: { textAlign: "center" },
                cellRenderer: AgCellMenu,
                cellRendererParams: {
                  menuItems: [
                    { label: t("Assessment.copyPlayerLink"), action: "copy" },
                    { label: t("Assessment.sendPlayerReport"), action: "send" },
                    { label: t("Assessment.deletePlayer"), action: "delete" },
                  ],
                  menuActions: {
                    copy: copyLink,
                    send: sendReport,
                    delete: inActivePlayer,
                  },
                },
              },
            ]}
            rowData={players.map((el) => {
              const mpiScore =
                parseFloat(el.mpi) > 0
                  ? `${parseFloat(el.mpi).toFixed(2)} MPI`
                  : "0 MPI";
              return {
                name: el.player.name,
                email: el.player.email,
                createdDate: el.createdDate,
                status: el.completeType,
                finish: mpiScore,
                mpi: mpiScore,
                action: el,
              };
            })}
          />
        </div>
      </Paper>
    </Box>
  );
}
