import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { completeLink, findLink } from "../../services/link.service";
import { createPlayer } from "../../services/user.service";
import { useTranslation } from "react-i18next";

const StudentForm = () => {
  const [name, setName] = useState(null);
  const [age, setAge] = useState(null);
  const [email, setEmail] = useState(null);
  const [gender, setGender] = useState(null);
  const [workWith, setWorkWith] = useState(null);
  const [studyLevel, setStudyLevel] = useState(null);
  const [major, setMajor] = useState(null);
  const [majorLevel, setMajorLevel] = useState(null);
  const [majorExperienceYear, setMajorExperienceYear] = useState(null);
  const [district, setDistrict] = useState(null);
  const [userId, setUserId] = useState(null);
  const { uniqId } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [term, setTerm] = useState(false);
  const { t } = useTranslation();

  const startGame = () => {
    if (
      name == null ||
      age == null ||
      gender == null ||
      workWith == null ||
      studyLevel == null
    ) {
      toast.error(t("toast.fillAllTheField"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      createPlayer({
        name: name,
        email: email,
        assessmentId: 1,
        district: district,
        age: age,
        gender: gender,
        workWith: workWith,
        major: major,
        majorLevel: majorLevel,
        majorExperienceYear: majorExperienceYear,
        company: company,
        studyLevel: studyLevel,
      })
        .then((res) => {
          navigate(`/game/${res.data.id}`, {
            state: {
              userId: `${res.data.id}`,
              universty: company,
            },
          });
        })
        .catch((e) => {
          toast.error(t("toast.Error"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  useEffect(function () {
    console.log("student ");
    if (uniqId === "test") {
    } else if (uniqId === "invescore") {
      setCompany("INVESCORE");
    } else if (uniqId === "otgontenger") {
      setCompany("OTGONTENGER");
    } else if (uniqId === "num") {
      setCompany("NUM");
    } else if ("erdenet_shutis") {
      setCompany("ERDENET_SHUTIS");
    } else {
      findLink({ link: uniqId })
        .then((val) => {
          completeLink({ link: uniqId });
        })
        .catch((err) => {
          navigate("/notfound");
        });
    }
  }, []);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (showModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [showModal]);

  return userId == null ? (
    <div className="px-8 pt-8 pb-8 min-h-screen bg-gradient-to-r from-sky-500 to-indigo-500">
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Үйлчилгээний нөхцөл</DialogTitle>
        <DialogContent dividers={"paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <p className="font-semibold">1. Ерөнхий зүйл </p>{" "}
            <p>
              Та Метаког Метрикс ХХК - ийн ("Metacog Metrics") онлайн вэб
              сайтад, платформд (хамтдаа "платформ") хандах буюу ашиглах бүртээ
              та: (a) Metacog Metrics-ийн Нууцлалын бодлогыг уншиж, ойлгож,
              хүлээн зөвшөөрч; (б) Metacog Metrics-тэй энэхүү үйлчилгээний
              нөхцөл, болзлыг зөвшөөрч буйгаа илэрхийлж байгаа юм. Цаашид "бид"
              эсвэл "бидний" гэсэн аливаа ишлэл нь Метаког Метрикс-ийг
              илэрхийлнэ.
            </p>
            <p className="font-semibold">2. Метаког Метриксийн үйлчилгээ</p>
            <p>
              Сайтын тодорхой үйлчилгээ, платформд хандах, ашиглахын тулд та
              бидэнтэй нэг бол гэрээ байгуулан сайт дээр хэрэглэгчийн бүртгэл
              ("Бүртгэл") үүсгэж бүртгүүлэх шаардлагатай эсвэл бидний урилгаар
              нэг удаагийн зөвхөн танд зориулагдсан холбоосоор хандах боломжтой.
              Бүртгэлээ үүсгэж, симуляци-г эхлүүлэхийн өмнө нэр, цахим
              шуудангийн хаяг, боловсролын түвшин, зэрэг, ажиллаж буй салбар гэх
              мэт хувийн болон мэргэжлийн зарим мэдээллийг оруулах буюу
              баталгаажуулахыг танаас шаардах болно.{" "}
            </p>
            <p className="font-semibold">3. Метаког Метриксийн тоглоомууд</p>
            <p>
              Бүртгэлээ үүсгэн бүртгүүлсний дараа та сайт дээр сэтгэлзүй болон
              тархи судлалын шинжлэх ухаанд суурилсан онлайн тоглоомуудыг
              ("Тоглоом") тоглох боломжтой болно. Та тоглоом бүрийг тоглох үед
              Метаког нь таны Тоглоомын явцын мэдээллийг цуглуулж, хадгалах
              эрхтэй гэдгийг та зөвшөөрч байна. "Тоглоомын өгөгдөл" гэдэг нь
              тоглоом тоглох явцад таны зан байдал, үйлдлээс цуглуулсан өгөгдөл,
              таны хэрэглэгчийн тоглоомтой бодит цагийн харилцан үйлчлэлийн
              талаарх мета өгөгдөл багтана. Та дараахыг ойлгож хүлээн зөвшөөрч
              байна, үүнд:{" "}
              <p className="pl-4">
                {" "}
                • Сайтын бүртгэлийн маягтын дагуу өөрийнхөө тухай үнэн зөв,
                бодит, одоогийн, бүрэн мэдээллийг өгөх;
              </p>{" "}
              <p className="pl-4">
                • Профайл мэдээллээ оруулахдаа жинхэнэ нэр, имэйл хаягаа
                ашиглана уу;{" "}
              </p>
              <p className="pl-4">
                • Сайтыг ашиглах, ашиглах эрхээ гуравдагч этгээдэд шилжүүлэхгүй,
                дахин худалдахгүй байх;
              </p>
              <p className="pl-4">
                • Нэгээс олон бүртгэл үүсгэж болохгүй. Үүний дагуу та дараах
                зүйлийг хийхийг зөвшөөрч байна.
              </p>
              <p className="pl-4">
                • Тоглоомыг нухацтай авч, тоглоомыг анхааралтай тоглох;
              </p>
              <p className="pl-4">
                • Тоглоом бүрийг эхлүүлэхийн өмнө зааврыг анхааралтай уншина;
              </p>
              <p className="pl-4">
                • Тоглоомыг хагаст нь зогсоож, эсвэл тодорхой нэг Тоглоомыг
                давтахын тулд тоглоомыг зориудаар дуусгахгүй байх;
              </p>
              <p className="pl-4">
                • Анхны аккаунтаараа эсвэл 12 сарын хугацаанд нэгээс олон удаа
                ямар нэгэн Тоглоом тоглохгүй байх; болон
              </p>
              <p className="pl-4">
                • Тоглоом дууссанаас хойш 12 сарын хугацаанд тоглоомын явцын
                мэдээлэл эсвэл шинж чанарын профайлаа устгахыг бүү оролдоорой.
                Хувийн мэдээлэл хүсэх үед таны өгсөн хариулт үнэн зөв байх болно
                гэдгийг хүлээн зөвшөөрч байна.
              </p>
            </p>
            <p className="font-semibold">4. Мэдээлэл хадгалах</p>
            <p>
              Метаког Метрикс нь Монгол Улсын Хувь Хүний Нууцын Тухай Хууль
              тогтоомжид заасан болон манай Нууцлалын бодлогод зааснаар таны
              хувь хүний мэдээлэл хамгаалагдах болно
            </p>
            <p className="font-semibold">5. Бидэнтэй холбогдох</p>
            Хэрэв танд сайттай холбоотой асуулт, гомдол байвал бидэнтэй
            hello@metacog.mn цахим хаягаарvхолбогдоно уу.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
          >
            Гарах
          </Button>
          <Button
            onClick={() => {
              setTerm(true);
              setShowModal(false);
            }}
          >
            Зөвшөөрөх
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <FormControl className="w-full flex justify-center">
        <div className="flex justify-center">
          <div className="p-10 bg-white rounded-xl">
            <div className="flex justify-center ">
              <img
                className="pb-4 bg-transparent	"
                src="/assets/longLogo.png"
                // height={80}
                width={180}
                alt="Italian Trulli"
              />
            </div>

            <div className="flex justify-center text-xl w-96 text-xs	bg-slate-200">
              <Box component="span" sx={{ p: 2, border: "1px dashed grey" }}>
                <span className="font-semibold">Метаког</span> нь Сэтгэл зүйн
                болон тархи судлал дээр суурилсан танин мэдэхүй болон зөөлөн ур
                чадварууд илрүүлэх симуляци юм. Нийт 8 өөр төрлийн тоглоом
                тоглох бөгөөд бүх тоглоомуудыг дуусгахад 15-20 минут
                зарцуулагдана. Бид танд чимээгүй орчинд, суурин эсвэл зөөврийн
                компьютертой mouse буюу хулгана ашиглахыг зөвлөж байна.
              </Box>
            </div>
            <p className="flex justify-center text-sm font-semibold">
              {" "}
              Танд амжилт хүсье.
            </p>
            <div className="w-96">
              <TextField
                onChange={(e) => {
                  setName(e.target.value);
                }}
                margin="normal"
                required
                fullWidth
                label="Нэр"
                autoFocus
              />
            </div>
            <div>
              <TextField
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="col-span-3"
                margin="normal"
                required
                fullWidth
                label="Хариу илгээх e-mail хаяг"
              />
            </div>
            <div>
              <TextField
                onChange={(e) => setAge(e.target.value)}
                select // tell TextField to render select
                fullWidth
                margin="normal"
                label="Нас"
                required
              >
                <MenuItem key={"15-19"} value={"15-19 "}>
                  15-19
                </MenuItem>
                <MenuItem key={"20-24"} value={"20-24"}>
                  20-24
                </MenuItem>
                <MenuItem key={"25-29"} value={"25-29"}>
                  25-29
                </MenuItem>
                <MenuItem key={"30-34"} value={"30-34"}>
                  30-34
                </MenuItem>
                <MenuItem key={"35-39"} value={"35-39"}>
                  35-39
                </MenuItem>
              </TextField>
            </div>
            <div>
              <TextField
                onChange={(e) => setGender(e.target.value)}
                select // tell TextField to render select
                className={
                  company !== "INVESCORE" ? "col-span-3" : "col-span-4"
                }
                margin="normal"
                label="Хүйс"
                required
                fullWidth
              >
                <MenuItem key={"gender"} value={"MALE"}>
                  Эрэгтэй
                </MenuItem>
                <MenuItem key={"Wgender"} value={"WOMEN"}>
                  Эмэгтэй
                </MenuItem>
                <MenuItem key={"Ogender"} value={"OTHER"}>
                  Бусад
                </MenuItem>
              </TextField>
            </div>
            <div>
              <TextField
                onChange={(e) => {
                  setWorkWith(e.target.value);
                }}
                className="col-span-6"
                margin="normal"
                required
                fullWidth
                select
                label="Суралцаж буй мэргэжил"
              >
                <MenuItem key={"h1"} value={"Инженер"}>
                  Инженер
                </MenuItem>
                <MenuItem key={"h2"} value={"Технологи"}>
                  Мэдээлэл технологи
                </MenuItem>
                <MenuItem key={"h2"} value={"Эдийн засаг"}>
                  Эдийн засаг
                </MenuItem>
                <MenuItem key={"h3"} value={"Санхүү"}>
                  Санхүү
                </MenuItem>
                <MenuItem key={"h5"} value={"Борлуулалт, маркетинг "}>
                  Борлуулалт, маркетинг
                </MenuItem>
                <MenuItem key={"h4"} value={"Эрх зүй"}>
                  Эрх зүй
                </MenuItem>
                <MenuItem key={"h6"} value={"Менежмент"}>
                  Менежмент
                </MenuItem>
                <MenuItem key={"h7"} value={"Эрүүл мэнд, нийгмийн хамгаалал"}>
                  Эрүүл мэнд, нийгмийн хамгаалал
                </MenuItem>
                <MenuItem key={"h8"} value={"Боловсрол, шинжлэх ухаан"}>
                  Боловсрол, шинжлэх ухаан
                </MenuItem>
                <MenuItem key={"h9"} value={"Урлаг, дизайн "}>
                  Урлаг, дизайн
                </MenuItem>
                <MenuItem key={"h10"} value={"Судалгаа, хөгжил"}>
                  Судалгаа, хөгжил
                </MenuItem>
                <MenuItem key={"h1№"} value={"Сэтгүүл зүй"}>
                  Сэтгүүл зүй
                </MenuItem>
                <MenuItem key={"h20"} value={"Нийгэм, хүмүүнлэг"}>
                  Нийгэм, хүмүүнлэг
                </MenuItem>
                <MenuItem key={"h21"} value={"Хэл соёл"}>
                  Хэл соёл
                </MenuItem>
              </TextField>
            </div>
            <div>
              <TextField
                //   value={value}
                onChange={(e) => setStudyLevel(e.target.value)}
                select // tell TextField to render select
                className="col-span-6"
                margin="normal"
                required
                label="Суралцаж буй түвшин"
                fullWidth
              >
                <MenuItem
                  key={"Бакалавр 1-р түвшин"}
                  value={"Бакалавр 1-р түвшин"}
                >
                  Бакалавр 1-р түвшин
                </MenuItem>
                <MenuItem
                  key={"Бакалавр 2-р түвшин"}
                  value={"Бакалавр 2-р түвшин"}
                >
                  Бакалавр 2-р түвшин
                </MenuItem>
                <MenuItem
                  key={"Бакалавр 3-р түвшин"}
                  value={"Бакалавр 3-р түвшин"}
                >
                  Бакалавр 3-р түвшин
                </MenuItem>
                <MenuItem
                  key={"Бакалавр 4-р түвшин"}
                  value={"Бакалавр 4-р түвшин"}
                >
                  Бакалавр 4-р түвшин
                </MenuItem>
                <MenuItem
                  key={"Магистр 1-р түвшин"}
                  value={"Магистр 2-р түвшин"}
                >
                  Магистр 1-р түвшин
                </MenuItem>
                <MenuItem
                  key={"Магистр 2-р түвшин"}
                  value={"Магистр 2-р түвшин"}
                >
                  Магистр 2-р түвшин
                </MenuItem>
                <MenuItem key={"Багш"} value={"Багш"}>
                  Багш
                </MenuItem>
              </TextField>
            </div>
            <div>
              <Checkbox checked={term} onChange={() => setTerm(!term)} />
              <Button onClick={() => setShowModal(true)}>
                Үйлчилгээний нөхцөл
              </Button>
            </div>

            <div className="mt-6 w-full">
              <Button
                onClick={startGame}
                className="w-full"
                variant="contained"
                disabled={term ? false : true}
              >
                Эхлэх
              </Button>
            </div>
          </div>
        </div>

        {/* <div className="grid grid-cols-12 gap-4"></div> */}
      </FormControl>
    </div>
  ) : (
    <Navigate to={`/game/${userId}/switch`} />
  );
};

export default StudentForm;
