import React, { useState } from "react";
import { CgArrowTopLeft } from "react-icons/cg";
import { IoCheckmarkCircle } from "react-icons/io5";
import { GoCircle } from "react-icons/go";

const SubDetail = ({ selected, func, scrollToDiv, div1Ref }) => {
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const handleMouseEnter = (columnIndex) => {
    setHoveredColumn(columnIndex);
  };

  const handleMouseLeave = () => {
    setHoveredColumn(null);
  };

  return (
    <table className="w-full shadow-xl bg-white rounded-xl">
      <thead>
        <tr>
          <th className="w-[288px]">
            <div className="flex items-end justify-center pt-20">
              <a
                target="_blank"
                rel="noreffer"
                href="https://oneplace.hr/pricing"
                className="flex items-center border border-[#DEDEDE]  font-semibold rounded-xl px-2 py-2 hover:border-[#000] hover:bg-[#000] hover:text-white"
              >
                <CgArrowTopLeft className="h-8 w-8" />
                Багцуудын дэлгэрэнгүй <br /> мэдээлэл харах
              </a>
            </div>
          </th>
          <th
            className={` rounded-t-xl  ${
              selected === "starter"
                ? "bg-[#000] text-white"
                : ` ${
                    selected === "starter"
                      ? "bg-[#000] text-white"
                      : `${
                          hoveredColumn === 1
                            ? "bg-opacity-40 bg-[#a0a0a0] border-b border-[#000]"
                            : ""
                        }`
                  }`
            }`}
            onClick={() => {
              func("starter");
              scrollToDiv(div1Ref)
            }}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className={`text-center py-4 `}>
              <p className="text-xl font-semibold mb-2">Starter</p>
              <p className="text-2xl font-semibold">₮ 1,750,000</p>
              <p className="text-sm mb-3">Сар Бүр</p>
              <button className="border bg-white text-black border-[#000] rounded-2xl px-6 py-1 ">
                Худалдан авах
              </button>
            </div>
          </th>
          <th
            onClick={() => {
              func("advanced");
               scrollToDiv(div1Ref);
            }}
            className={` rounded-t-xl ${
              selected === "advanced"
                ? "bg-black text-white"
                : `${
                    hoveredColumn === 2
                      ? "bg-opacity-40 bg-[#FFEFD5] border-b border-[#000]"
                      : ""
                  }`
            }`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="text-center py-4">
              <p
                className={`text-xl font-semibold mb-2 ${
                  selected === "advanced"
                    ? "text-white"
                    : `${hoveredColumn === 2 ? "text-[#FFBE59]" : "text-black"}`
                }`}
              >
                Advenced
              </p>
              <p className="text-2xl font-semibold">₮ 2,150,000</p>
              <p className="text-sm mb-3">Сар Бүр</p>
              <button className="border bg-white text-black border-[#000] rounded-2xl px-6 py-1 ">
                Худалдан авах
              </button>
            </div>
          </th>
          <th
            onClick={() => {
              func("premium");
               scrollToDiv(div1Ref);
            }}
            className={` rounded-t-xl relative ${
              selected === "premium"
                ? "bg-black text-white"
                : `${
                    hoveredColumn === 3
                      ? "bg-opacity-40 bg-[#EBFFED] border-b border-[#000]"
                      : ""
                  }`
            }`}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="absolute top-0 right-0 p-1 bg-[#F48D79] rounded-tr-xl rounded-bl-xl">
              <p className="text-white text-xs">Best Value</p>
            </div>
            <div className="text-center py-4">
              <p
                className={`text-xl font-semibold mb-2 ${
                  selected === "premium"
                    ? "text-white"
                    : `${hoveredColumn === 3 ? "text-[#00750C]" : "text-black"}`
                }`}
              >
                Premium
              </p>
              <p className="text-2xl font-semibold">₮ 3,150,000</p>
              <p className="text-sm mb-3">Сар Бүр</p>
              <button className="border bg-white text-black border-[#000] rounded-2xl px-6 py-1 ">
                Худалдан авах
              </button>
            </div>
          </th>
          <th
            onClick={() => {
              func("payasyougo");
               scrollToDiv(div1Ref);
            }}
            className={` rounded-t-xl ${
              selected === "payasyougo"
                ? "bg-black text-white"
                : `${
                    hoveredColumn === 4
                      ? "bg-opacity-40 bg-[#FFEAEA] border-b border-[#000]"
                      : ""
                  }`
            }`}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="text-center py-4">
              <p
                className={`text-xl font-semibold mb-2 ${
                  selected === "payasyougo"
                    ? "text-white"
                    : `${hoveredColumn === 4 ? "text-[#F18B8B]" : "text-black"}`
                }`}
              >
                Pay As You Go
              </p>
              <p className="text-2xl font-semibold">---</p>
              <p className="text-sm mb-3">Хүн тус бүрээр</p>
              <button className="bg-white text-black border border-[#000] rounded-2xl px-6 py-1 ">
                Худалдан авах
              </button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="h-[76px]">
          <td className={`text-lg font-semibold px-4 w-[288px]`}>
            Хязгааргүй тоглох эрх
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "starter"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 1 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "advanced"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 2 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "premium"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 3 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "payasyougo"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 4 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <p className="text-center">
                нэг хүн тоглоход <br /> 55,000₮
              </p>
            </div>
          </td>
        </tr>
        <tr className="h-[76px]">
          <td className={`text-lg font-semibold px-4 w-[288px]`}>
            Сонгон шалгаруулах тоглоом
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "starter"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 1 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "advanced"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 2 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "premium"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 3 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "payasyougo"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 4 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
        </tr>
        <tr className="h-[76px]">
          <td className={`text-lg font-semibold px-4 w-[288px]`}>
            Burnout тоглоом{" "}
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "starter"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 1 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "advanced"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 2 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "premium"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 3 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "payasyougo"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 4 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
        </tr>
        <tr className="h-[76px]">
          <td className={`text-lg font-semibold px-4 w-[288px]`}>
            Судалгаа авах тоглоом{" "}
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "starter"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 1 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "advanced"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 2 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "premium"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 3 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "payasyougo"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 4 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
        </tr>
        <tr className="h-[76px]">
          <td className={`text-lg font-semibold px-4 w-[288px]`}>
            Ажил горилогчид илгээх тайлан
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "starter"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 1 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "advanced"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 2 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "premium"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 3 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "payasyougo"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 4 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
        </tr>
        <tr className="h-[76px]">
          <td className={`text-lg font-semibold px-4 w-[288px]`}>
            Чадварын шаардлага тохируулах
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "starter"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 1 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "advanced"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 2 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "premium"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 3 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "payasyougo"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 4 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
        </tr>
        <tr className="h-[76px]">
          <td className={`text-lg font-semibold px-4 w-[288px]`}>
            Xянах самбар
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "starter"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 1 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "advanced"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 2 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">Энгийн</div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "premium"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 3 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">Энгийн</div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "payasyougo"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 4 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
        </tr>{" "}
        <tr className="h-[76px]">
          <td className={`text-lg font-semibold px-4 w-[288px]`}>
            Aккаунт нээх эрх
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "starter"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 1 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">2 аккаунт</div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "advanced"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 2 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">3 аккаунт</div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "premium"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 3 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">5 аккаунт</div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "payasyougo"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 4 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">3 аккаунт</div>
          </td>
        </tr>
        <tr className="h-[76px]">
          <td className={`text-lg font-semibold px-4 w-[288px]`}>
            Тоглоомын хэсэгт лого оруулах
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "starter"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 1 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "advanced"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 2 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "premium"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 3 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <GoCircle className="w-8 h-8" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "payasyougo"
                ? "bg-[#F6F6F6] border-x border-black"
                : `${hoveredColumn === 4 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <p className="text-center px-4 w-[150px]">
                {" "}
                нэг удаагийн хураамж 700,000₮
              </p>
            </div>
          </td>
        </tr>
        <tr className="h-[76px]">
          <td className={`text-lg font-semibold px-4 w-[288px]`}>
            Техникийн дэмжлэг
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "starter"
                ? "bg-[#F6F6F6] border-x border-b border-black"
                : `${hoveredColumn === 1 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "advanced"
                ? "bg-[#F6F6F6] border-x border-b border-black"
                : `${hoveredColumn === 2 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "premium"
                ? "bg-[#F6F6F6] border-x border-b border-black"
                : `${hoveredColumn === 3 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
          <td
            className={`font-semibold px-4 ${
              selected === "payasyougo"
                ? "bg-[#F6F6F6] border-x border-b border-black"
                : `${hoveredColumn === 4 ? "bg-[#F6F6F6]" : ""}`
            }`}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center items-center">
              <IoCheckmarkCircle className="h-8 w-8 text-green-400" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SubDetail;
