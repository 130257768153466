import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IoIosClose } from "react-icons/io";
import { FaRegCopy } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { GoDownload } from "react-icons/go";
import { BsPrinter } from "react-icons/bs";
import { MdOutlineCreditCard } from "react-icons/md";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { MdOutlineQuestionMark } from "react-icons/md";
import { LuPhoneCall } from "react-icons/lu";
import { IoIosMail } from "react-icons/io";
import { FaChevronRight } from "react-icons/fa6";

const Payment = () => {
  const location = useLocation();
  const [total, setTotal] = useState(6450000);
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const [popUp, setPopUp] = useState("");
  const [selected, setSelected] = useState("card");
  const formattedTotal = total.toLocaleString("en-MN").replace(/,/g, "'");
  const [copied, setCopied] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const [copyText, setCopyText] = useState("");
  const [copyText2, setCopyText2] = useState("");
  const [help, setHelp] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  const copyToClipboard2 = () => {
    navigator.clipboard
      .writeText(copyText2)
      .then(() => {
        setCopied2(true);
        setTimeout(() => setCopied2(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  const handleHelpClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      setHelp(false);
      setIsExiting(false);
    }, 300); // Match this duration to the animation duration
  };

  return (
    <div className="relative">
      {!help && !isExiting && (
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setHelp(true)}
          className="flex items-center fixed right-0 top-[200px] justify-center w-[48px] h-[80px] bg-white border-y border-l rounded-l-lg border-black"
        >
          <div className="relative">
            <MdOutlineQuestionMark className="h-[40px]" />
            {hover && (
              <div className="absolute -left-[115px] top-1">
                <div className="relative flex items-center justify-center w-[100px]">
                  <img
                    className="absolute"
                    src="/helpBg.svg"
                    alt="background"
                  />
                  <p className="pe-4 ps-2 py-2 text-white z-20 font-semibold text-sm">
                    Тусламж
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {(help || isExiting) && (
        <div
          className={`flex fixed right-0 top-[200px] bg-white rounded-bl-lg border-[#B0B0B0] border z-20 ${
            help ? "animate-slide-in-left" : "animate-slide-out-left"
          }`}
        >
          <div className="relative">
            <div
              onClick={handleHelpClose}
              className="flex items-center absolute left-[-48px] -top-[1px] justify-center w-[48px] h-[80px] bg-white border-y border-l rounded-l-lg border-[#B0B0B0]"
            >
              <FaChevronRight className="w-8" />
            </div>
            <div className="py-4 ps-4 pe-10">
              <p className="text-[#909090]">Холбоо барих</p>
              <p className="flex items-center gap-2 mt-2">
                <LuPhoneCall className="text-[#5F9A61] w-5 h-5" />
                +976 7777-4001
              </p>
              <p className="flex items-center gap-2 mt-2">
                <IoIosMail className="text-[#4F7293] h-6 w-6" />
                Support@oneplace.hr
              </p>
              <p className="text-[#909090] mt-4">Видео заавар үзэх</p>
              <img className="mt-2" src="/ytThumb.png" alt="thumbnail" />
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="bg-[#F2F2F2] pt-10 px-[100px]">
          <div className="flex items-center justify-between ">
            <div>
              <button
                onClick={() => {
                  navigate("/subscription/contract");
                }}
                className="flex items-center gap-2 font-bold text-xl px-4 py-2 hover:border-2 hover:rounded-xl"
              >
                <IoArrowBackOutline />
                Буцах
              </button>
            </div>
          </div>
          <div className="flex items-start justify-center mt-5 gap-3">
            <div>
              <div className="flex">
                <img src="/active.svg" alt="icon" />

                <img src="/activeLine.svg" alt="icon" />
              </div>
              <p className="text-[#eb8875]">БАГЦ СОНГОХ</p>
            </div>
            <div>
              <div className="flex">
                <img src="/active.svg" alt="icon" />
                <img src="/activeLine.svg" alt="icon" />
              </div>
              <p className="text-[#eb8875]">ХУГАЦАА СОНГОХ</p>
            </div>
            <div>
              <div className="flex">
                <img src="/active.svg" alt="icon" />
                <img src="/grayLine.svg" alt="icon" />
              </div>
              <p className="text-[#eb8875]">ТӨЛБӨР ТӨЛӨХ</p>
            </div>
            <img src="/inactive.svg" alt="icon" />
          </div>
          <p className="pt-3 pb-5 text-3xl font-bold ms-4">Нэхэмжлэл</p>
        </div>

        <div className="w-10/12 m-auto gap-10 pt-10 flex items-start justify-center">
          <div className="w-[594px]">
            <div className="w-full shadow-xl px-4 py-10">
              <p className="text-end font-bold text-xs">
                Сангийн сайдын 2017 оны 347 дугаар тушаалын хавсралт
              </p>

              <div className="mt-4 flex justify-between items-start">
                <div className="w-[237px]">
                  <p className="text-[#5E6470] font-semibold mb-2">
                    Нэхэмжлэгч
                  </p>
                  <p className="text-sm text-[#1A1C21]">Metacog Metrics</p>
                  <p className="text-xs text-[#5E6470]">
                    <span className="font-bold">ТТД:</span> 6817629
                  </p>
                  <p className="text-xs text-[#5E6470]">
                    <span className="font-nold">Хаяг:</span> Баянзүрх дүүрэг
                    26-р хороо, Хүннү гудамж-215, 803 тоот “Метаког Метрикс” ХХК
                  </p>
                  <p className="text-xs text-[#5E6470]">
                    <span className="font-bold">Утас:</span> 80019922
                  </p>
                  <p className="text-[#5E6470] text-xs">
                    <span className="font-bold">Э-Шуудан:</span>{" "}
                    khurelbaatar14@gmail.com
                  </p>
                </div>
                <div className="w-[237px]">
                  <p className="text-[#5E6470] font-semibold mb-2">
                    Хариуцагч:
                  </p>
                  <p className="text-sm text-[#1A1C21]">Номинконстракшн</p>
                  <p className="text-xs text-[#5E6470]">
                    <span className="font-bold">ТТД:</span> 6817629
                  </p>
                  <p className="text-xs text-[#5E6470]">
                    <span className="font-nold">Хаяг:</span> Баянзүрх дүүрэг
                    26-р хороо, Хүннү гудамж-215, 803 тоот “Метаког Метрикс” ХХК
                  </p>
                  <p className="text-xs text-[#5E6470]">
                    <span className="font-bold">Утас:</span> 99798876, 80909668
                  </p>
                  <p className="text-[#5E6470] text-xs">
                    <span className="font-bold">Гэрээний №: </span>{" "}
                  </p>
                  <p className="text-[#5E6470] text-xs">
                    <span className="font-bold">Огноо: </span> 2023-11-07
                  </p>
                  <p className="text-[#5E6470] text-xs">
                    <span className="font-bold">
                      Төлбөр хийх хугацаа (хоног):
                    </span>{" "}
                  </p>
                </div>
              </div>
              <div className="mt-4 flex justify-between items-start">
                <div>
                  <p className="text-[#5E6470] text-sm mb-2">Багцын нэр</p>
                  <p className="text-[#1A1C21] text-sm">Started багц</p>
                </div>
                <div>
                  <p className="text-[#5E6470] text-center text-sm mt-2 mb-2">
                    Эхлэх огноо
                  </p>
                  <p className="text-[#1A1C21] text-center text-sm">
                    01, Aug, 2023
                  </p>
                </div>
                <div>
                  <p className="text-[#5E6470] text-end text-sm mb-2">
                    Дуусах огноо
                  </p>
                  <p className="text-[#1A1C21] text-end text-sm">
                    15, Aug, 2023
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <table className="w-full">
                  <thead>
                    <tr className="border-y border-[#E1E3E7]">
                      <th className="text-[#5E6470] text-xs py-3 uppercase text-start">
                        Багцын нэр
                      </th>
                      <th className="text-[#5E6470] text-xs py-3 uppercase text-start">
                        Нэгжийн үнэ
                      </th>
                      <th className="text-[#5E6470] text-xs py-3 uppercase text-start">
                        Хугацаа
                      </th>
                      <th className="text-[#5E6470] text-xs py-3 uppercase text-end">
                        Нийт үнэ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-[#1A1C21] text-sm font-semibold text-start py-4">
                        Starter Багц <br />
                        <span className="text-[#5E6470] font-none">
                          3 сарын starter багц
                        </span>
                      </td>
                      <td className="text-[#1A1C21] text-sm text-start">
                        ₮ 2’150’000
                      </td>
                      <td className="text-[#1A1C21] text-sm text-start">
                        3 сар
                      </td>
                      <td className="text-[#1A1C21] text-sm text-end">
                        ₮{formattedTotal}
                      </td>
                    </tr>
                    <tr className="py-3 border-b border-[#D7DAE0]">
                      <td className="text-[#1A1C21] text-sm font-semibold text-start pb-4">
                        Багц-2 <br />
                        <span className="text-[#5E6470] font-none">
                          Багцын тайлбар
                        </span>
                      </td>
                      <td className="text-[#1A1C21] text-sm text-start">0%</td>
                      <td className="text-[#1A1C21] text-sm text-start">---</td>
                      <td className="text-[#1A1C21] text-sm text-end">
                        ₮ {formattedTotal}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mt-4 flex items-start w-full">
                <div className="w-[30%] flex justify-center">
                  <img className="w-[120px]" src="/stamp.svg" alt="stamp" />
                </div>
                <div className="w-[70%] ps-10">
                  <div className="flex items-center justify-between mb-4">
                    <p className="text-sm font-semibold">Хөнгөлөлт (0%)</p>
                    <p className="text-sm font-semibold text-end">0₮</p>
                  </div>
                  <div className="flex items-center justify-between mb-4">
                    <p className="text-sm font-semibold">НӨАТ дүн (10%)</p>
                    <p className="text-sm font-semibold text-end">₮ 6’45’000</p>
                  </div>
                  <div className="flex items-center justify-between pt-4 border-t border-[#D7DAE0] mb-4">
                    <p className="text-sm font-bold">Нийт дүн</p>
                    <p className="text-sm font-bold text-end">
                      ₮ {formattedTotal}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4 pt-4 flex justify-end mb-10">
                <div>
                  <p className="text-[#1A1C21] text-end">
                    <span className="font-semibold">Захирал:</span>{" "}
                    ............................./ ............./
                  </p>
                  <p className="text-[#1A1C21] text-end">
                    <span className="font-semibold">Нягтлан бодогч:</span>{" "}
                    ............................./ ............./
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-10 pt-8 pb-20">
              <button className="flex items-center gap-2 py-2 px-8 rounded-xl border border-[#DEDEDE] bg-white hover:bg-[#DEDEDE]">
                <GoDownload />
                Татаж авах
              </button>
              <button className="flex items-center gap-2 py-2 px-8 rounded-xl border border-[#DEDEDE] bg-white hover:bg-[#DEDEDE]">
                <BsPrinter />
                Хэвлэх
              </button>
            </div>
          </div>
          <div>
            <div className="flex items-start gap-10">
              <div className="flex flex-col justify-center">
                <div
                  onClick={() => {
                    setSelected("card");
                  }}
                  className={`relative w-[180px] h-[110px] flex items-center justify-center rounded-lg shadow-lg ${
                    selected === "card"
                      ? "border-2 border-[#828282] border-opacity-20"
                      : "text-[#828282] "
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={selected === "card" ? true : false}
                    className="w-4 h-4 absolute top-4 left-4"
                  />
                  <MdOutlineCreditCard
                    className={`h-10 w-10 ${
                      selected === "card" ? "opacity-100" : "opacity-20"
                    }`}
                  />
                </div>
                <p className="text-center text-sm mt-2">Дансаар төлөх</p>
              </div>
              <div className="flex flex-col justify-center">
                <div
                  onClick={() => {
                    setSelected("qr");
                  }}
                  className={`relative w-[180px] h-[110px] flex items-center justify-center rounded-lg shadow-lg ${
                    selected === "qr"
                      ? "border-2 border-[#828282] border-opacity-20"
                      : "text-[#828282]"
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={selected === "qr" ? true : false}
                    className="w-4 h-4 absolute top-4 left-4"
                  />
                  <MdOutlineQrCodeScanner
                    className={`h-10 w-10 ${
                      selected === "qr" ? "opacity-100" : "opacity-20"
                    }`}
                  />
                </div>
                <p className="text-center text-sm mt-2">QR Pay</p>
              </div>
            </div>
            {selected === "card" ? (
              <div className="flex justify-cente mt-8">
                <div className="w-[381px] py-8 px-6 shadow-xl bg-white rounded-xl">
                  <div className="flex justify-center">
                    <img src="/golomt.svg" alt="golomt" />
                  </div>
                  <p className="text-center font-bold text-lg mt-3 mb-5">
                    Голомт банк{" "}
                  </p>
                  <div className="pt-4 border-t border-[#CECFD3]">
                    <div className="flex justify-between">
                      <p className="text-sm font-semibold">
                        Хүлээн авагчийн нэр
                      </p>
                      <div className="flex items-center gap-4 relative">
                        {copied && copyText === "Метаког Метрикс" ? (
                          <div className="bg-green-400 text-white rounded-lg px-2 py-1 absolute text-[8px] -top-5 right-0">
                            Амжилттай хуулагдлаа
                          </div>
                        ) : (
                          ""
                        )}
                        <p className="text-sm font-semibold">Метаког Метрикс</p>
                        <button
                          onClick={() => {
                            setCopyText("Метаког Метрикс");
                            copyToClipboard();
                          }}
                        >
                          <FaRegCopy />
                        </button>
                      </div>
                    </div>
                    {console.log(copyText2)}
                    <div className="flex justify-between my-4">
                      <p className="text-sm font-semibold">Дансны дугаар:</p>
                      <div className="flex items-center gap-4 relative">
                        {copied2 && copyText2 === "3215122950" ? (
                          <div className="bg-green-400 text-white rounded-lg px-2 py-1 absolute text-[8px] -top-5 right-0 z-50">
                            Амжилттай хуулагдлаа
                          </div>
                        ) : (
                          ""
                        )}
                        <p className="text-sm font-semibold text-start">
                          3 215 122 950
                        </p>
                        <button
                          onClick={() => {
                            setCopyText2("3215122950");
                            copyToClipboard2();
                          }}
                        >
                          <FaRegCopy />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-sm font-semibold">Гүйлгээний утга:</p>
                      <p className="text-sm font-semibold">----</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-center mt-8">
                <div className="w-[381px] py-8 px-6 shadow-xl bg-white rounded-xl">
                  <div className="flex justify-center">
                    <img src="/qr.png" alt="qr" />
                  </div>
                  <div className="flex justify-center">
                    <button className="text-sm font-semibold px-6 py-2 border border-[#DEDEDE] rounded-lg mt-6">
                      Төлбөр шалгах
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
