import React, { useEffect, useState } from "react";
import { FaRegMap } from "react-icons/fa";
import { CiGlass, CiLocationOn } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import { getDetail, onboardReport } from "../../../services/onboard.service";
import { GoArrowRight } from "react-icons/go";
import Loading from "../../../components/uncommon/Loading";

// const TaskItem = ({ task }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <div className="border-l-[10px] border-[#EEEFF1] border-rounded-md  pl-4 pb-4  ">
//       <div className="flex items-center justify-between relative">
//         <div className="h-4 w-4 bg-[#4F78A8] rounded-full absolute -left-7 top-0" />
//         <div className="flex items-center space-x-2">
//           <input
//             className="rounded-sm"
//             type="checkbox"
//             checked={task.completed}
//             readOnly
//           />
//           <div>
//             <span className={`font-semibold text-sm`}>
//               {task.name}{" "}
//               <span className="text-xs text-green-500">{task.points}pts</span>
//             </span>
//             <p className="text-[#1E293B] text-xs opacity-80">
//               Дуусгасан /06.12/
//             </p>
//           </div>
//         </div>
//         <button onClick={() => setIsOpen(!isOpen)}>{isOpen ? "▲" : "▼"}</button>
//       </div>
//       {isOpen && (
//         <div className="mt-2 space-y-1">
//           {task.subtasks.map((subtask, index) => (
//             <div key={index} className="flex items-center space-x-2">
//               <div className="flex items-center justify-center w-[16px] h-[16px] border rounded-full  border-[#00CC99] bg-[#E6FAF5]">
//                 <GiCheckMark className="w-[10px] text-[#00CC99]" />
//               </div>
//               <p className="text-[#2C4360] text-xs">{subtask}</p>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

const Modal = ({ task, onClose }) => {
  if (!task) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30">
      <div className="bg-white p-8 rounded-md shadow-lg max-w-lg w-full">
        <div className="flex items-start justify-between">
          <h2 className="text-lg font-semibold mb-4 text-[#3E80FF] ">
            {task.name}
            <span className="ms-2 text-sm text-[#3E80FF] opacity-80">
              /{task.score} pts /
            </span>
          </h2>
          <button className="text-4xl" onClick={onClose}>
            <IoIosClose />
          </button>
        </div>
        <div>
          {task.questions.map((items) => {
            return (
              <div
                key={items.orderId}
                className="flex items-center justify-start mb-2 gap-4"
              >
                <div className="w-[5%]">
                  {items.isFinish ? (
                    <div className=" bg-[#E6FAF5] w-[16px] h-[16px] border border-[#00CC99] rounded-md flex items-center justify-center">
                      <img src="/onboard/done.svg" alt="icon" />
                    </div>
                  ) : (
                    <div className=" bg-[#EBEBEB] w-[16px] h-[16px] border border-[#BCBCBC] rounded-md flex items-center justify-center"></div>
                  )}
                </div>
                <p className="text-[#2C4360] text-xs w-[90%]"> {items.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const chunkArray = (array, size) => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
};

const TaskRows = ({ tasks }) => {
  const taskChunks = chunkArray(tasks, 3);
  const [isHover, setIsHover] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [ended, setEnded] = useState(false);
  let uncolor = 4;

  const getCurrentTaskInProgress = (tasks) => {
    const index = tasks.findIndex((task) => {
      const totalSubtasks = task.questions.length;
      const completedSubtasks = task.questions.filter(
        (subtask) => subtask.isFinish
      ).length;

      return (
        (completedSubtasks > 0 && completedSubtasks < totalSubtasks) ||
        completedSubtasks === 0
      );
    });

    return index !== -1 ? index + 1 : -1;
  };

  const currentTaskInProgress = getCurrentTaskInProgress(tasks);

  const currentRow =
    currentTaskInProgress < 4 ? 1 : Math.ceil(currentTaskInProgress / 3);

  const rows = Math.ceil(tasks.length / 3);
  useEffect(() => {
    const allTasksFinished = tasks.every((task) => {
      const completedSubtasks = task.questions.filter(
        (subtask) => subtask.isFinish
      ).length;
      return completedSubtasks === task.questions.length;
    });
    setEnded(allTasksFinished);
  }, [tasks]);
  return (
    <div className="relative">
      {taskChunks.map((taskChunk, rowIndex) => {
        return (
          <div className="flex justify-center " key={rowIndex}>
            <div
              className={`w-[800px] mb-20 relative ${
                rowIndex % 2 === 0 ? "" : ""
              }`}
            >
              <div className={`flex justify-between mb-4 `}>
                {(rowIndex % 2 !== 0
                  ? [...taskChunk].reverse()
                  : taskChunk
                ).map((task, index) => {
                  const unfinishedCount = task.questions.filter(
                    (subtask) => !subtask.isFinish
                  ).length;

                  return (
                    <div
                      onMouseEnter={() => {
                        setIsHover(task.id);
                      }}
                      onMouseLeave={() => {
                        setIsHover(0);
                      }}
                      key={index}
                      className="px-4 h-[40px] overflow-auto"
                    >
                      <div className="flex items-center gap-2">
                        {unfinishedCount !== 0 &&
                        unfinishedCount !== task?.questions.length ? (
                          <div className="bg-[#FFF9D8] w-[20px] h-[20px] border border-[#FFDE2F] rounded-md flex items-center justify-center">
                            <img src="/onboard/ongoing.svg" alt="icon" />
                          </div>
                        ) : unfinishedCount === 0 ? (
                          <div className="bg-[#E6FAF5] w-[20px] h-[20px] border border-[#00CC99] rounded-md flex items-center justify-center">
                            <img src="/onboard/done.svg" alt="icon" />
                          </div>
                        ) : (
                          <span className="text-xl text-[#7196BF]">
                            +{task.score}pts
                          </span>
                        )}

                        {isHover === task.id ? (
                          <button
                            onClick={() => setModalData(task)}
                            className="flex items-center justify-center gap-2 text-xl"
                          >
                            {task.name} <GoArrowRight />
                          </button>
                        ) : (
                          <div>
                            <p className="text-sm font-semibold text-[#222]">
                              {unfinishedCount === 0 ? "Дуусгасан" : task.name}
                              {unfinishedCount === task?.questions.length ? (
                                ""
                              ) : unfinishedCount === 0 ? (
                                <span className={`text-xs text-[#27AE60] ps-1`}>
                                  +{task.score}pts
                                </span>
                              ) : (
                                ""
                              )}
                            </p>
                            <p className="text-xs text-[#222] opacity-70">
                              {unfinishedCount !== 0 &&
                              unfinishedCount !== task?.questions.length
                                ? `${unfinishedCount} task дутуу`
                                : unfinishedCount === 0
                                ? task.date
                                : `${task?.questions.length} tasks`}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="w-full h-[12px] bg-[#EEEFF1] rounded-xl mt-2 relative z-10">
                {ended && (
                  <div
                    className={`absolute w-[99.24%] top-0.5 h-[8px] bg-[#4F78A8] rounded-xl z-10 ${
                      rowIndex % 2 === 0 ? "left-[3px]" : "right-[3px]"
                    }`}
                  />
                )}
                {currentRow > rowIndex && (
                  <div
                    style={{
                      width:
                        currentRow - 1 > rowIndex
                          ? "99.24%"
                          : currentRow - 1 === rowIndex
                          ? currentTaskInProgress - 1 > 0 &&
                            taskChunk.length === 1
                            ? (rowIndex + 1) % 2 === 0
                              ? "96%"
                              : "40px"
                            : taskChunk.length === 2 && (rowIndex + 1) % 2 === 0
                            ? (currentTaskInProgress - 1) % 3 === 0
                              ? "96%"
                              : "40px"
                            : (currentTaskInProgress - 1) % 3 === 0
                            ? "40px"
                            : currentTaskInProgress % 3 === 0
                            ? "96%"
                            : "49.6%"
                          : "",
                    }}
                    className={`absolute top-0.5 h-[8px] bg-[#4F78A8] rounded-xl z-10 ${
                      rowIndex % 2 === 0 ? "left-[3px]" : "right-[3px]"
                    }`}
                  />
                )}
                {rows > rowIndex + 1 && (
                  <div
                    className={`absolute  h-[153px] w-[12px] top-[3px] bg-[#EEEFF1] ${
                      rowIndex % 2 === 0 ? "right-0" : "left-0 "
                    } `}
                  />
                )}
                {ended && rows > rowIndex + 1 && (
                  <div
                    className={`${
                      rowIndex === rows ? "hidden" : "absolute"
                    } h-[153px] w-[6px] top-[3px] bg-[#4F78A8] z-20 rounded-xl ${
                      rowIndex % 2 === 0 ? "right-[3px]" : "left-[3px] "
                    } `}
                  />
                )}

                {currentRow > rowIndex + 1 && (
                  <div
                    className={`${
                      rowIndex === currentRow ? "hidden" : "absolute"
                    } h-[153px] w-[6px] top-[3px] bg-[#4F78A8] z-20 rounded-xl ${
                      rowIndex % 2 === 0 ? "right-[3px]" : "left-[3px] "
                    } `}
                  />
                )}
                <div className="absolute top-1/2 -translate-y-1/2 w-full flex justify-between px-6 z-20">
                  {(rowIndex + 1) % 2 === 0
                    ? taskChunk
                        .slice()
                        .reverse()
                        .map((task, index) => {
                          const actualIndex = taskChunk.length - 1 - index;
                          const inRow = Math.ceil(currentTaskInProgress / 3);
                          let inIndex =
                            currentTaskInProgress % taskChunk.length;
                          if (inIndex === 0) {
                            inIndex = taskChunk.length === 1 ? 1 : 3;
                          }

                          const isCurrentTask =
                            inRow === rowIndex + 1 &&
                            inIndex === actualIndex + 1;

                          if (taskChunk.length === 1) {
                            uncolor = 3;
                          } else {
                            uncolor--;
                          }

                          return (
                            <div
                              key={actualIndex}
                              className="relative flex items-center justify-center "
                            >
                              <div
                                className={`rounded-full ${
                                  ended
                                    ? "bg-[#4F78A8] w-[20px] h-[20px]"
                                    : inRow > rowIndex + 1
                                    ? "bg-[#4F78A8] w-[20px] h-[20px]"
                                    : inRow === rowIndex + 1
                                    ? inIndex > uncolor
                                      ? "bg-[#4F78A8] w-[20px] h-[20px]"
                                      : inIndex === actualIndex + 1
                                      ? "border border-[#324D72] w-[20px] h-[16px] bg-[#EEEFF1]"
                                      : "bg-[#CECFD3] w-[20px] h-[20px]"
                                    : "bg-[#CECFD3] w-[20px] h-[20px]"
                                }`}
                              />

                              {isCurrentTask && (
                                <div className="absolute top-[-30px]">
                                  <img
                                    className="h-[50px] text-[#324d72]"
                                    src="/onboard/location.svg"
                                    alt="location"
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })
                    : taskChunk.map((task, index) => {
                        const inRow = Math.ceil(currentTaskInProgress / 3);
                        let inIndex = currentTaskInProgress % taskChunk.length;
                        if (inIndex === 0) {
                          inIndex = 3;
                        }

                        const isCurrentTask =
                          inRow === rowIndex + 1 && inIndex === index + 1;

                        return (
                          <div
                            key={index}
                            className="relative flex items-center justify-center "
                          >
                            <div
                              className={`rounded-full ${
                                ended
                                  ? "bg-[#4F78A8] w-[20px] h-[20px]"
                                  : inRow > rowIndex + 1
                                  ? "bg-[#4F78A8] w-[20px] h-[20px]"
                                  : inRow === rowIndex + 1
                                  ? inIndex > index + 1
                                    ? "bg-[#4F78A8] w-[20px] h-[20px]"
                                    : inIndex === index + 1
                                    ? "border border-[#324D72] w-[20px] h-[16px] bg-[#EEEFF1]"
                                    : "bg-[#CECFD3] w-[20px] h-[20px]"
                                  : "bg-[#CECFD3] w-[20px] h-[20px]"
                              }`}
                            />

                            {isCurrentTask && (
                              <div className="absolute top-[-30px]">
                                <img
                                  className="h-[50px] text-[#324d72]"
                                  src="/onboard/location.svg"
                                  alt="location"
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {modalData && (
        <Modal task={modalData} onClose={() => setModalData(null)} />
      )}
    </div>
  );
};

const OnboardEmployee = ({ id }) => {
  const [map, setMap] = useState(true);
  const [list, setList] = useState(null);
  const [selected, setSelected] = useState(null);
  const [selectedList, setSelectedList] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onboardReport(id)
      .then((data) => {

        setList(data.data);
        if (data.data.length > 0) {
          setSelected(data.data[0].onboardId);
          setSelectedList(data.data[0]);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [id]);

  useEffect(() => {
    if (id && selected) {
      setLoading(true);
      getDetail(id, selected)
        .then((response) => {
          setTasks(response.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Error fetching details:", err);
          setLoading(false);
        });
    }
  }, [id, selected]);

  if (list === null || tasks === null || loading) {
    return <Loading />;
  }

  if (list.length === 0 || tasks.length === 0) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <div>
          <div>
            <img src="/onboard/empty.svg" alt="empty" />
          </div>
          <p>Onboard doesn't apply</p>
        </div>
      </div>
    );
  }

  const finishedTopics = tasks.onboardTopics.filter((topic) =>
    topic.questions.every((question) => question.isFinish)
  );

  const finishedTopicsCount = finishedTopics.length;

  const totalScore = finishedTopics.reduce(
    (acc, topic) => acc + topic.score,
    0
  );

  return (
    <div className="w-10/12 m-auto pt-11">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-8">
          <div className="w-[180px] h-[90px] rounded-xl shadow-xl flex items-center justify-center px-4 py-2">
            <div>
              <p className="text-sm text-[#666874]">Гүйцэтгэлийн явц</p>
              <p className="text-[32px] text-[#324d72] font-semibold">
                {Math.round(selectedList.performancePercentage)}%
              </p>
            </div>
          </div>
          <div className="w-[180px] h-[90px] rounded-xl shadow-xl flex items-center justify-center px-4 py-2">
            <div>
              <p className="text-sm text-[#666874]">Дуусгасан task</p>
              <p className="text-[32px] text-[#324d72] font-semibold">
                {finishedTopicsCount}
                <span className="text-[24px] opacity-70">
                  /{tasks.onboardTopics.length}
                </span>
              </p>
            </div>
          </div>
          <div className="w-[180px] h-[90px] rounded-xl shadow-xl flex items-center justify-center px-4 py-2">
            <div>
              <p className="text-sm text-[#666874]">Таны оноо</p>
              <p className="text-[32px] text-[#324d72] font-semibold">
                {totalScore}
                <span className="text-[24px] opacity-70">points</span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <select
            className="border border-[#CECFD3] text-xs text-[#222] rounded-lg "
            value={selected}
            onChange={(e) => {
              setSelected(Number(e.target.value));
              const lists = list.find(
                (items) => items.onboardId === Number(e.target.value)
              );
              setSelectedList(lists);
              setLoading(true);

              getDetail(id, Number(e.target.value))
                .then((response) => {
                  setTasks(response.data);
                  setLoading(false);
                })
                .catch((err) => {
                  console.log("Error fetching details:", err);
                  setLoading(false);
                });
            }}
          >
            {list.map((item) => (
              <option key={item.onboardId} value={item.onboardId}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mt-10">
        <div className="relative ">
          <TaskRows tasks={tasks.onboardTopics} />
        </div>
        <div>
          {/* {tasks.map((task, index) => (
              <TaskItem key={index} task={task} />
            ))} */}
        </div>
      </div>
    </div>
  );
};

export default OnboardEmployee;
