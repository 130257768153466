import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdArrowBack } from "react-icons/io";

const CompanySign = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [companyName, setCompanyName] = useState("");
  const [direction, setDirection] = useState("");
  const [register, setRegister] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [photo, setPhoto] = useState(null);
  const directions = [
    { name: "Автомашин засвар үйлчилгээ", id: 1 },
    { name: "Аялал жуулчлал", id: 2 },
    { name: "Банк санхүү", id: 3 },
    { name: "Барилга, үл хөдлөх хөрөнгө", id: 4 },
    { name: "Боловсрол, шинжлэх ухаан", id: 5 },
    { name: "Даатгал", id: 6 },
    { name: "Дизайн, Энтертайнмент, Соёл урлаг", id: 7 },
    { name: "Маркетинг, PR", id: 8 },
    { name: "Мэдээлэл технологи", id: 9 },
    { name: "Ресторан, хоол үйлдвэрлэл", id: 10 },
    { name: "Спорт, гоо сайхан, фитнесс", id: 11 },
    { name: "Тээвэр, гааль, агуулах", id: 12 },
    { name: "Төрийн болон ТББ", id: 13 },
    { name: "Уул уурхайн", id: 14 },
    { name: "Үйлдвэрлэл болон дэд бүтэц", id: 15 },
    { name: "Үйлчилгээ", id: 16 },
    { name: "Байгаль орчин", id: 17 },
    { name: "Худалдаа, Борлуулалт", id: 18 },
    { name: "Хэвлэл мэдээлэл, ТВ", id: 19 },
    { name: "Эмнэлэг, Эм хангамж", id: 20 },
    { name: "Бусад", id: 21 },
  ];

  useEffect(() => {
    console.log("location = ", location);
    if (location == null || location.state === null) {
      navigate("/signup");
    }
  }, []);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 25 * 1024 * 1024) {
      toast.error(t("toast.imgError"));
    } else {
      setPhoto(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (photo === null || photo.size < 1) {
      toast.error(t("toast.imgEnter"));
    }
    const formData = {
      companyName,
      direction,
      register,
      employeeNumber,
      photo,
    };

    setCompanyName("");
    setDirection("");
    setRegister("");
    setEmployeeNumber("");
    setPhoto(null);
    navigate("/signup/thermofservice", {
      state: {
        user: location.state.user,
        company: formData,
      },
    });
  };

  return (
    <>
      <div className="m-auto my-10">
        <img
          className="h-[27px] ms-3 sm:ms-20 "
          src="/oneplacehrLogo.png"
          alt="logo"
        />
        <div className="sm:mt-10 mt-4 mb-4">
          <button
            onClick={() => {
              navigate("/signup");
            }}
            className="flex items-center gap-2 ms-3 sm:ms-20 hover:bg-[#f0f0f0] p-2 rounded-xl"
          >
            <IoMdArrowBack />
            Буцах
          </button>
        </div>
        <div className="flex justify-end">
          <div className="sm:border-r-2  sm:pe-7 border-black ms-3 sm:ms-20">
            <div className="flex w-full justify-between items-center sm:px-0 px-4 sm:me-4">
              <p className="font-bold sm:text-lg text-xs border-l-2 border-[#F18878] ps-2">
                Бүртгэл
              </p>
              <p className="sm:text-lg text-xs text-[#209653]">
                Эхний 3 хоног үнэгүй ашиглах боломжтой
              </p>
            </div>

            <div className="mt-10 flex items-center">
              <div className="flex items-center">
                <p className="sm:py-2 sm:px-4 px-2.5 py-0.5 sm:text-base text-sm bg-black rounded-full text-white font-bold">
                  1
                </p>
                <p className="sm:text-lg text-xs font-bold sm:ms-4 ms-2">
                  Таны бүртгэл
                </p>
              </div>
              <div className="flex items-center ms-3 sm:ms-6 ">
                <p className="sm:py-2 sm:px-4 px-2.5 py-0.5 sm:text-base text-sm bg-black rounded-full text-white font-bold">
                  2
                </p>
                <p className="sm:text-lg text-xs font-bold sm:ms-4 ms-2">
                  Байгууллагын бүртгэл
                </p>
              </div>
              <div className="flex items-center ms-3 sm:ms-6 opacity-50">
                <p className="sm:py-2 sm:px-4 px-2.5 py-0.5 sm:text-base text-sm bg-black rounded-full text-white font-bold">
                  3
                </p>
                <p className="sm:text-lg text-xs font-bold sm:ms-4 ms-2">
                  Үйлчилгээний нөхцөл
                </p>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mt-8">
                <p className="text-2xl font-bold">Байгууллагын бүртгэл</p>
                <div className="flex items-center mt-5">
                  <div className="h-[100px] w-[100px] border border-">
                    {!photo && (
                      <img
                        className="mt-4 ms-4"
                        src="/signup/icon.svg"
                        alt="icon"
                      />
                    )}
                    {photo && (
                      <img
                        src={URL.createObjectURL(photo)}
                        alt="Photo"
                        className="h-full w-full object-cover"
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handlePhotoChange}
                    id="photo"
                    className="hidden"
                  />
                  <label
                    htmlFor="photo"
                    className="cursor-pointer border rounded-lg px-3 py-1 ms-4 hover:opacity-50"
                  >
                    Лого оруулах <span className="text-[#ff0000]">*</span>
                  </label>
                </div>
              </div>
              <div className="mt-10">
                <div className="sm:flex block justify-between mb-4">
                  <div className="w-4/5 sm:w-1/2 me-2 sm:me-10">
                    <label htmlFor="photo">
                      Байгууллагын нэр
                      <span className="text-[#ff0000]">*</span>
                    </label>
                    <input
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="w-full border rounded-lg px-4 py-3"
                      required
                    />
                  </div>
                  <div className="w-4/5 sm:w-1/2 me-2 sm:me-4 sm:mt-0 mt-4">
                    <label htmlFor="photo">
                      Үйл ажиллагааны чиглэл{" "}
                      <span className="text-[#ff0000]">*</span>
                    </label>
                    <select
                      id="directionSelect"
                      value={direction}
                      onChange={(e) => setDirection(e.target.value)}
                      className="w-full border rounded-lg px-4 py-3"
                      required
                    >
                      <option disabled value="">
                        Сонгох...
                      </option>
                      {directions.map((dir) => (
                        <option key={dir.id} value={dir.name}>
                          {dir.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="sm:flex block justify-between mb-4">
                  <div className="w-4/5 sm:w-1/2 me-2 sm:me-10">
                    <label htmlFor="photo">
                      Байгууллагын регистрийн дугаар{" "}
                      <span className="text-[#ff0000]">*</span>
                    </label>
                    <input
                      type="text"
                      value={register}
                      onChange={(e) => setRegister(e.target.value)}
                      className="w-full border rounded-lg px-4 py-3"
                      required
                    />
                  </div>
                  <div className="w-4/5 sm:w-1/2 me-2 sm:me-4 sm:mt-0 mt-4">
                    <label htmlFor="photo">
                      Ажилтны тоо <span className="text-[#ff0000]">*</span>
                    </label>

                    <select
                      value={employeeNumber}
                      onChange={(e) => setEmployeeNumber(e.target.value)}
                      className="w-full border rounded-lg px-4 py-3"
                    >
                      <option value="1-9">1-9</option>
                      <option value="10-49">10-49</option>
                      <option value="50-99">50-99</option>
                      <option value="100-499">100-499</option>
                      <option value="500-1000">500-1000</option>
                      <option value="1000+">1000+</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-end mt-10">
                <button
                  type="submit"
                  className="px-4 py-2 border border-black rounded-lg hover:opacity-50 me-4"
                >
                  Дараагийнх
                </button>
              </div>
            </form>
          </div>
          <div className="sm:block hidden ms-10">
            <div className="relative">
              <img src="/signup/platformpic.svg" alt="example" />
              {photo !== null ? (
                <img
                  src={URL.createObjectURL(photo)}
                  alt="Photo"
                  className="absolute w-[160px] h-[40px] bottom-[-20px] top-0 left-0 rounded-lg"
                />
              ) : (
                <div className="h-[40px] w-[160px] flex items-center justify-center bg-[#F2F2F2] text-[#ABADB5] font-bold absolute text-3xl top-0 left-0">
                  ЛОГО
                </div>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default CompanySign;
