import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa6";
import AgCellMenu from "../../components/menu/AgCellMenu";
import MainTable from "../../components/table/MainTable";
import { useNavigate } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";

const Test = () => {
  const [table, setTable] = useState("ongoing");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data = [
    {
      name: "Мэдлэг шалгах сорил",
      createdDate: "2024-08-28 16:45",
      questionCount: "4",
      employeeCount: "40",
      finishedCount: "18",
    },
    {
      name: "Test 4",
      createdDate: "2024-08-28 16:45",
      questionCount: "24",
      employeeCount: "4",
      finishedCount: "3",
    },
    {
      name: "ХАБ-н тест",
      createdDate: "2024-08-28 16:45",
      questionCount: "12",
      employeeCount: "1",
      finishedCount: "1",
    },
    {
      name: "Хүний нөөцийн тест",
      createdDate: "2024-08-28 16:45",
      questionCount: "47",
      employeeCount: "12",
      finishedCount: "13",
    },

    {
      name: "Сорил-2",
      createdDate: "2024-08-28 16:45",
      questionCount: "27",
      employeeCount: "36",
      finishedCount: "12",
    },
  ];

  return (
    <div className="w-10/12 m-auto pt-11">
      <div className="flex items-center justify-between mb-10">
        <p className="text-[24px] text-[#222]">Тестүүд</p>
        <button
          onClick={() => {
            navigate("create");
          }}
          className="text-[#fff] bg-[#324d72] px-5 py-2 rounded-lg flex items-center gap-2 text-sm "
        >
          Тест үүсгэх <FaPlus />
        </button>
      </div>
      <div className="flex items-center gap-6 mb-4">
        <button
          onClick={() => {
            setTable("ongoing");
          }}
          className={`pb-1 font-semibold ${
            table === "ongoing"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          Идэвхтэй
        </button>
        <button
          onClick={() => {
            setTable("closed");
          }}
          className={`pb-1 font-semibold ${
            table === "closed"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          Хаагдсан
        </button>
        <button
          onClick={() => {
            setTable("saved");
          }}
          className={`pb-1 font-semibold ${
            table === "saved"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          Хадгалсан
        </button>
      </div>
      <div className="">
        <MainTable
          columnDefs={[
            {
              headerName: "Тестүүд",
              field: "text",
              sortable: true,
              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/text.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/house.png" },
            },
            {
              headerName: "Үүсгэсэн огноо",
              field: "createdDate",
              sortable: true,
              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/created.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/calendar.png" },
            },
            {
              headerName: t("Survey.question"),
              field: "questionCount",
              sortable: true,

              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/question.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/chart.png" },
            },
            {
              headerName: t("Survey.participant"),
              field: "employeeCount",
              sortable: true,

              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/employee.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/location.png" },
            },
            {
              headerName: t("Survey.performance"),
              field: "process",
              sortable: true,

              flex: 2,
              headerComponent: (hprops) => {
                return (
                  <div className="flex justify-center items-center gap-1">
                    <img src="/survey/icon/percentage.svg" alt="icon" />
                    <p className="text-[##1E293B] text-sm font-normal">
                      {hprops.displayName}
                    </p>
                  </div>
                );
              },
              headerComponentParams: { menuIcon: "/icons/document.png" },
            },
          ]}
          rowData={
            table === "ongoing"
              ? data.map((elm) => {
                  return {
                    text: elm.name,
                    createdDate: elm.createdDate.substring(0, 16),
                    questionCount: elm.questionCount,
                    employeeCount: elm.employeeCount,
                    process:
                      isNaN(elm.finishedCount) ||
                      elm.finishedCount === null ||
                      elm.employeeCount === 0
                        ? "0%"
                        : `${Math.round(
                            (elm.finishedCount / elm.employeeCount) * 100
                          )}%`,
                    action: elm,
                  };
                })
              : ""
          }
        />
      </div>
    </div>
  );
};

export default Test;
