import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateBurnoutPlayerForRD } from "../../../../services/burnout.service";

const PregameQuestion = () => {
  const [selectedNumber, setSelectedNumber] = useState(1);
  const [checkedValues, setCheckedValues] = useState("");
  const [isColorBlind, setIsColorBlind] = useState(false);
  const [isUsedDrug, setIsUsedDrug] = useState(false);
  const [condition, setCondition] = useState("");
  const navigate = useNavigate();
  const location = useLocation();


  const handleChangeAge = (e) => {
    setSelectedNumber(parseInt(e.target.value));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      age: selectedNumber,
      gender: checkedValues,
      isColorBlind: isColorBlind,
      isUsedDrug: isUsedDrug,
      condition: condition,
      id: location.state.id
    };


    try {

      const response = updateBurnoutPlayerForRD(data).then(a => {
        navigate(location.state.backUrl)
      });
      console.log("response = ", response)

      if (response.ok) {
        // navigate(location.state);

      } else {
        console.log("Error submitting data to the backend");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="w-10/12 m-auto">
      {
        console.log("location = ", location.state.backUrl)
      }
      <form className="mt-20" onSubmit={handleSubmit}>
        <div>
          <div className="flex items-center">
            <p className="sm:text-xl text-sm font-bold me-5">1. Нас</p>
            <select
              value={selectedNumber}
              onChange={handleChangeAge}
              className="block w-32 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
            >
              {[...Array(100).keys()].map((num) => (
                <option key={num + 1} value={num + 1}>
                  {num + 1}
                </option>
              ))}
            </select>
          </div>
          <div className="sm:flex block items-center mt-10">
            <p className="sm:text-xl text-sm font-bold me-5">2. Хүйс</p>
            <div className="sm:mt-0 mt-5 flex items-center gap-5 sm:gap-20">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="Эрэгтэй"
                  checked={checkedValues === "male"}
                  onChange={() => {
                    setCheckedValues("male");
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Эрэгтэй</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="Эмэгтэй"
                  checked={checkedValues === "female"}
                  onChange={() => {
                    setCheckedValues("female");
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Эмэгтэй</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="other"
                  checked={checkedValues === "other"}
                  onChange={() => {
                    setCheckedValues("other");
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Бусад</span>
              </label>
            </div>
          </div>
          <div className="mt-10">
            <p className="sm:text-xl text-sm font-bold me-5">
              3. Та ямар нэг өнгө ялгах харааны бэрхшээлтэй юу?
            </p>
            <div className="flex items-center gap-20 ms-0 sm:ms-20 mt-5">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="эр"
                  checked={isColorBlind === true}
                  onChange={() => {
                    setIsColorBlind(true);
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Тийм</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="эр"
                  checked={isColorBlind === false}
                  onChange={() => {
                    setIsColorBlind(false);
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Үгүй</span>
              </label>
            </div>
          </div>
          <div className="mt-10">
            <p className="sm:text-xl text-sm font-bold me-5">
              4. Та сүүлийн 24 цагийн дотор сэтгэцэд нөлөөлөх эм, бодис
              хэрэглэсэн үү?
            </p>
            <div className="flex items-center gap-20 ms-0 sm:ms-20 mt-5">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="эр"
                  checked={isUsedDrug === true}
                  onChange={() => {
                    setIsUsedDrug(true);
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Тийм</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="эр"
                  checked={isUsedDrug === false}
                  onChange={() => {
                    setIsUsedDrug(false);
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Үгүй</span>
              </label>
            </div>
          </div>
          <div className="mt-10">
            <p className="sm:text-xl text-sm font-bold me-5">
              5.Одоогийн биеийн байдлаа дүгнэвэл:
            </p>
            <div className="flex items-center gap-5 sm:gap-20 ms-0 sm:ms-20 mt-5">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="good"
                  checked={condition === "good"}
                  onChange={() => {
                    setCondition("good");
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Сайн</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="normal"
                  checked={condition === "normal"}
                  onChange={() => {
                    setCondition("normal");
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Хэвийн</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="bad"
                  checked={condition === "bad"}
                  onChange={() => {
                    setCondition("bad");
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Муу</span>
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-10">
          <button
            type="submit"
            className="px-6 py-2 border shadow-lg font-bold border-black rounded-lg text-lg hover:bg-black hover:text-white"
          >
            Дараагийх
          </button>
        </div>
      </form>
    </div>
  );
};

export default PregameQuestion;
