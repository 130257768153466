import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Scatter } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { getBurnOutPlayerData } from "../../../services/burnout.service";
import { useTranslation } from "react-i18next";

const BurnOutReport = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const { employeeData } = location.state;
  const [datas, setDatas] = useState(null);

  useEffect(() => {
    getBurnOutPlayerData(id)
      .then((data) => {
        setDatas(data.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  console.log("data", datas);
  console.log(employeeData);

  if (employeeData === undefined && datas === null) {
    return (
      <>
        <div className="m-auto w-10/12 pt-11">
          <div className="min-h-full flex items-center justify-center">
            <div>
              <div className="relative">
                <img
                  src="/comingSoonImg/dog.gif"
                  className="w-[300px] h-[300px] mt-20"
                  alt="coming soon img"
                />
                <div className="absolute w-[304px] h-[224px] top-[-120px] right-[-220px]">
                  <img
                    src="/comingSoonImg/CloudShapeLoading.svg"
                    alt="coming soon text"
                  />
                </div>
                <div className="absolute bottom-[60px] w-[150px] right-1/4">
                  <div className="relative">
                    <img src="/comingSoonImg/place.svg" alt="place" />
                    <div className="bottom-1.5 left-px absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="15"
                        viewBox="0 0 13 15"
                        fill="none"
                      >
                        <path
                          d="M5.90576 14.1407C7.16593 9.76172 10.1021 1.11716 11.7654 1.57089C13.4287 2.02463 8.55201 10.1398 5.90576 14.1407Z"
                          fill="#32A675"
                        />
                        <path
                          d="M6.69471 14.8527C5.65631 10.4159 3.99777 1.43824 5.67086 1.02207C7.34395 0.605905 7.05055 10.0691 6.69471 14.8527Z"
                          fill="#32A675"
                        />
                        <path
                          d="M6.94727 14.1407C5.6871 9.76172 2.75094 1.11716 1.08765 1.57089C-0.575648 2.02463 4.30102 10.1398 6.94727 14.1407Z"
                          fill="#32A675"
                        />
                      </svg>
                    </div>
                    <div className="absolute right-1 bottom-1.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="35"
                        viewBox="0 0 18 35"
                        fill="none"
                      >
                        <path
                          d="M5.90576 34.1407C7.16593 29.7617 10.1021 21.1172 11.7654 21.5709C13.4287 22.0246 8.55201 30.1398 5.90576 34.1407Z"
                          fill="#32A675"
                        />
                        <path
                          d="M6.69471 34.8527C5.65631 30.4159 3.99777 21.4382 5.67086 21.0221C7.34395 20.6059 7.05055 30.0691 6.69471 34.8527Z"
                          fill="#32A675"
                        />
                        <path
                          d="M6.94727 34.1407C5.6871 29.7617 2.75094 21.1172 1.08765 21.5709C-0.575648 22.0246 4.30102 30.1398 6.94727 34.1407Z"
                          fill="#32A675"
                        />
                        <path
                          d="M6.37012 29.9741C6.40595 23.4119 6.91866 19.7716 8.97119 13.3682"
                          stroke="#32A675"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M16.2922 7.21658C16.9248 3.23134 12.8461 -0.831332 8.98672 3.9935C6.05271 -0.261686 1.19169 1.57596 1.61088 6.78833C1.68796 7.74675 2.17797 8.61271 2.81063 9.33676L8.98672 16.4049L15.3345 9.03343C15.7896 8.50498 16.1829 7.90535 16.2922 7.21658Z"
                          fill="#FFC5A8"
                          stroke="#FFC5A8"
                          strokeOpacity="0.1"
                          strokeWidth="2.46201"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const renderStatus = () => {
    switch (employeeData?.burnoutLevel) {
      case null:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/grayDot.png" alt="" />
            </div>
            <p>No result</p>
          </div>
        );
      case 1:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/redDot.png" alt="" />
            </div>
            <p>Burnout</p>
          </div>
        );
      case 2:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/orangeDot.png" alt="" />
            </div>
            <p>Disengaged</p>
          </div>
        );
      case 3:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/purpleDot.png" alt="" />
            </div>
            <p>Overextended</p>
          </div>
        );
      case 4:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/blueDot.png" alt="" />
            </div>
            <p>Ineffective</p>
          </div>
        );
      case 5:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/greenEllipse.png" alt="" />
            </div>
            <p>Engaged</p>
          </div>
        );
      default:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/grayDot.png" alt="" />
            </div>
            <p>Хариу гараагүй</p>
          </div>
        );
    }
  };

  const renderBurnoutText = () => {
    switch (employeeData?.burnoutLevel) {
      case null:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/grayDot.png" alt="" />
            </div>
            <p>No result</p>
          </div>
        );
      case 1:
        return (
          <div className="text-[#324D72]">
            <div className="flex">
              <img className="" src="/icons/burnoutEmoji1.png" alt="emoji5" />
              <div className="font-bold mt-1 ml-2">Burned-Out</div>
            </div>
            <div className="mt-7 font-bold">Сэтгэл зүйн тусламж:</div>
            <div className="mt-4 ml-4">
              Сэтгэл зүйн тусламж авах боломжийг санал болгох. Үүнд зөвлөгөө
              өгөх үйлчилгээ, стрессийн амралт эсвэл АХХШ-н мэргэжилтнүүд рүү
              шилжүүлэх зэрэг багтаж болно.{" "}
            </div>
            <div className="mt-7 font-bold">Байгууллагын хяналт, өөрчлөлт:</div>
            <div className="mt-4 ml-4">
              Ядаргаанд хүргэж буй системийн асуудлуудыг тодорхойлж,
              шийдвэрлэхийн тулд өдөр тутмын ажил, соёлыг хянана. Үүнд
              удирдлагын арга барилд өөрчлөлт турших, ажлын ачааллыг хуваарилах
              эсвэл ажлын байрны эргономикийг сайжруулах зэрэг орно.
            </div>
            <div className="mt-7 font-bold">Ротаци:</div>
            <div className="mt-4 ml-4">
              Хэрвээ компани дотроо Rotation буюу өөр хэлтэс баг руу шилжин
              ажиллах боломжтой бол тухайн хүнтэй ганцаарчилсан уулзалт хийж энэ
              талаар ярилцах
            </div>
            <div className="mt-7 font-bold">Тогтмол санал асуулга авах:</div>
            <div className="mt-4 ml-4">
              Ажилчдын сайн сайхан байдал, сэтгэл ханамжийг хянахын тулд тогтмол
              судалгаа эсвэл албан бус шалгалт авч байх
            </div>
          </div>
        );
      case 2:
        return (
          <div className="text-[#324D72]">
            <div className="flex">
              <img className="" src="/icons/burnoutEmoji2.png" alt="emoji5" />
              <div className="font-bold mt-1 ml-2">Disengaged</div>
            </div>
            <div className="mt-7 font-bold">
              Оролцоотой байдлыг нэмэгдүүлэх:
            </div>
            <div className="mt-4 ml-4">
              Эдгээр ажилчдыг юу идэвхтэй болгодгийг тодорхойл. Энэ нь илүү
              сорилт шаардсан ажил, ахих боломж, эсвэл урамшуулал гэх мэт байж
              болно.
            </div>
            <div className="mt-7 font-bold">Тогтмол ярилцах:</div>
            <div className="mt-4 ml-4">
              Шууд удирдлагатай ганцаарчилсан уулзалтууд хийснээр тэдний
              асуудал, ахиц дэвшил, ажил мэргэжлийн хүсэл тэмүүллийн талаар
              ярилцах нь маш үр дүнтэй.
            </div>
            <div className="mt-7 font-bold">Баг:</div>
            <div className="mt-4 ml-4">
              Хамгийн сүүлд багаараа хэзээ гарсан талаар асуугаарай. Тэднийг
              багийнхантайгаа илүү ойр болоход нь туслах хэрэгтэй .
            </div>
            <div className="mt-7 font-bold">Шалтгааныг олох:</div>
            <div className="mt-4 ml-4">
              Хэрэв бусдаас өөрийгөө тусгаарласан байвал үндсэн шалтгааныг
              ойлгохын тулд ганцаарчилсан ярилцлага хийх хэрэгтэй болдог.
            </div>
          </div>
        );
      case 3:
        return (
          <div className="text-[#324D72]">
            <div className="flex">
              <img className="" src="/icons/burnoutEmoji3.png" alt="emoji5" />
              <div className="font-bold mt-1 ml-2">Overextended</div>
            </div>
            <div className="mt-7 font-bold">Ачааллын удирдлага:</div>
            <div className="mt-4 ml-4">
              Тэдний ажлын ачааллыг хянаж, тохируулах. Даалгавруудыг дахин
              хуваарилах арга зам хайх эсвэл нэмэлтээр юу хэрэгтэй байгааг
              үнэлээрэй
            </div>
            <div className="mt-7 font-bold">Ээлжийн амралт:</div>
            <div className="mt-4 ml-4">
              Хэзээ хамгийн сүүлд ээлжийн амралт авсаныг нь асууж үзээрэй.
            </div>
            <div className="mt-7 font-bold">Цагийн менежмент:</div>
            <div className="mt-4 ml-4">
              Тэднийг даалгавраа эрэмбэлэх, цагийг үр дүнтэй удирдахад сурга.
              цагийн менежментийг зөв хийснээр хэт их ачаалалтай байгаа
              мэдрэмжийг бууруулж болдог.
            </div>
            <div className="mt-7 font-bold">
              Тогтмол завсарлага ба амрах арга:
            </div>
            <div className="mt-4 ml-4">
              Ажлын цагаар тогтмол завсарлага авч байхыг дэмжээрэй. Ажил дээрээ
              анхаарал төвлөрүүлэх, бясалгах гэх мэт тайвшруулах арга техникийг
              нэвтрүүлэх.
            </div>
          </div>
        );
      case 4:
        return (
          <div className="text-[#324D72]">
            <div className="flex">
              <img className="" src="/icons/burnoutEmoji4.png" alt="emoji5" />
              <div className="font-bold mt-1 ml-2">Ineffective</div>
            </div>
            <div className="mt-7 font-bold">Ур чадварыг дээшлүүлэх:</div>
            <div className="mt-4 ml-4">
              Түүнд илүү үр дүнтэй ажиллахын тулд нэмэлт сургалт эсвэл нөөц
              шаардлагатай эсэхийн үнэлгээ хийх.
            </div>
            <div className="mt-7 font-bold">Зорилго тавих:</div>
            <div className="mt-4 ml-4">
              Илүү зорилготой, түүндээ төвлөрч, дараа дараагийн ахиц дэвшлийг
              хэмжихэд, хүрч болох зорилтуудыг тавихад нь туслах,
            </div>
            <div className="mt-7 font-bold">
              Ажлын байрны даалгаврыг тохируулах:
            </div>
            <div className="mt-4 ml-4">
              Хийж буй даалгаврууд нь түүний давуу тал болон сонирхолд нь нийцэж
              байгаа эсэхийг шалгахын тулд тэдний үүргийг дахин үнэл.
            </div>
            <div className="mt-7 font-bold">Хамтран ажиллахыг дэмж:</div>
            <div className="mt-4 ml-4">
              Өөрийн хувь нэмрээ оруулж чадах өөр төслүүд санал болгох замаар
              тухайн хүний хамтарч ажиллах ур чадварыг нэмэгдүүлэх боломжтой.
            </div>
          </div>
        );
      case 5:
        return (
          <div className="text-[#324D72]">
            <div className="flex">
              <img className="" src="/icons/burnoutEmoji5.png" alt="emoji5" />
              <div className="font-bold mt-1 ml-2">Engaged</div>
            </div>
            <div className="mt-7 font-bold">
              Оролцоотай байдлыг нь хадгалах:
            </div>
            <div className="mt-4 ml-4">
              Тэдний хүчин чармайлт, оруулсан хувь нэмрийг хүлээн зөвшөөрч
              урашмуулах нь маш чухал юм.
            </div>
            <div className="mt-7 font-bold">Мэргэжлийн өсөлтийн боломжууд:</div>
            <div className="mt-4 ml-4">
              Ахисан түвшний сургалт, манлайллын хөгжлийн хөтөлбөр эсвэл өндөр
              түвшний төслүүд дээр ажиллах боломжийг санал болгоорой. Мөн аливаа
              мэргэжлийн хөтөлбөр эсвэл нийгэмлэгт орохыг нь дэмжээрэй.
            </div>
            <div className="mt-7 font-bold">
              Ажил амьдралын тэнцвэрт байдал:
            </div>
            <div className="mt-4 ml-4">
              Ирээдүйд ядрахаас урьдчилан сэргийлэхийн тулд тэнцвэрийг бий
              болгох. Үүнд ажлын уян хатан цаг, алсаас ажиллах сонголт эсвэл
              өмнө нь нэмэлт цагаар ажилласан бол амралт багтаж болно.
            </div>
            <div className="mt-7 font-bold">
              Эрх мэдэл ба бие даасан байдал:
            </div>
            <div className="mt-4 ml-4">
              Тэдний ажилд шийдвэр гаргах эрх мэдэл, бие даасан байдлыг
              нэмэгдүүлэх. Энэ нь өөриймсөг сэтгэлгээ, илүү сэтгэл ханамжийг бий
              болгодог.
            </div>
          </div>
        );
      default:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/grayDot.png" alt="" />
            </div>
            <p>Хариу гараагүй</p>
          </div>
        );
    }
  };

  return (
    <div className="m-auto w-10/12 pt-11">
      <div className="flex items-center">
        <div className="gap-8 flex items-center">
          <p className="text-[24px] ms-5  font-bold">
            {employeeData.name}{" "}
            {employeeData.lastName ? employeeData.lastName : ""}
          </p>

          <div className="flex gap-2 items-center">{renderStatus()}</div>
        </div>
      </div>
      <div className="mb-11">
        <div className="flex items-center justify-center mt-12">
          <div className="w-10/12 flex ">
            <Scatter
              data={{
                datasets: [
                  {
                    label: "",
                    backgroundColor: "rgba(75,192,192,1)",
                    pointBorderColor: "rgba(75,192,192,1)",
                    pointBackgroundColor: "rgba(75,192,192,1)",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "rgba(75,192,192,1)",
                    pointHoverBorderColor: "rgba(220,220,220,1)",
                    pointHoverBorderWidth: 2,
                    pointRadius: 10,
                    pointHitRadius: 10,
                    data: datas
                      ? [
                          {
                            x: (datas.mental || 0) + (datas.physical || 0),
                            y: datas.cognitive || 0,
                          },
                        ]
                      : [],
                  },
                ],
              }}
              options={{
                responsive: true,
                scales: {
                  x: {
                    type: "linear",
                    position: "bottom",
                    max: 10,
                    min: 0,
                  },
                  y: {
                    type: "linear",
                    position: "left",
                    max: 5,
                    min: 0,
                  },
                },
              }}
            />
          </div>
        </div>
        <div className="flex justify-between items-center my-20">
          <div className="p-7 w-[282px] h-[164px] flex flex-col justify-between text-lg text-[#324d72] font-bold text-center shadow-xl rounded-lg">
            <p>{t("burnout.generalReport.fatigue")} </p>
            <div>
              <img className="mt-7" src="/burnoutRe.png" alt="emote" />
              <input
                type="range"
                className="w-full"
                value={datas?.physical}
                min={1}
                max={5}
                step={0.1}
                disabled
              />
            </div>
          </div>
          <div className="p-7 w-[282px] h-[164px] flex flex-col justify-between text-lg text-[#324d72] font-bold text-center shadow-xl rounded-lg">
            <p>{t("burnout.generalReport.generalMood")}</p>
            <div>
              <img src="/burnoutRe.png" alt="emote" />
              <input
                type="range"
                className="w-full"
                value={datas?.mental}
                min={1}
                max={5}
                step={0.1}
                disabled
              />
            </div>
          </div>
          <div className="p-7 w-[282px] h-[164px] flex flex-col justify-between text-lg text-[#324d72] font-bold text-center shadow-xl rounded-lg">
            <p>{t("burnout.generalReport.intellectualAbility")}</p>
            <div>
              <img className="mt-7" src="/burnoutRe.png" alt="emote" />
              <input
                type="range"
                className="w-full"
                value={datas?.cognitive}
                min={1}
                max={5}
                step={0.1}
                disabled
              />
            </div>
          </div>
        </div>
        {renderBurnoutText()}
      </div>
    </div>
  );
};

export default BurnOutReport;