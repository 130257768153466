import { Box, Divider, Modal, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changeUserPassword, getUserInfo } from "../../services/user.service";
import UserInformation from "../../pages/profileDetail/UserInformation";
import UserSettings from "../../pages/profileDetail/UserSettings";

const modalStyle = {
  borderRadius: "15px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 12,
  p: 4,
};

const MyProfile = () => {
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [activePage, setActivePage] = useState("information");
  const navigate = useNavigate();

  const handleChangePage = (page) => {
    setActivePage(page);
    navigate(`/settings/profile/${page}`);
  };

  const renderActivePage = () => {
    switch (activePage) {
      case "information":
        return <UserInformation handlePasswordChange={handlePasswordChange} />;
      case "settings":
        return <UserSettings />;
      default:
        return null;
    }
  };

  const changePassword = () => {
    if (password.length < 3 || oldPassword.length < 3 || cPassword.length < 3) {
      toast.error(t("toast.usernameMustBe4"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    if (cPassword !== password) {
      toast.error(t("toast.newAndRepeatPasswordError"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    if (oldPassword === password) {
      toast.error(t("toast.oldAndNewPasswordError"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    changeUserPassword({
      password: password,
      oldPassword: oldPassword,
    })
      .then((res) => {
        toast.success(t("toast.editSuccess"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setPassword("");
        setCPassword("");
        setOldPassword("");
      })
      .catch((e) => {
        console.log("error = ", e.response);
        if (e?.response?.data?.message != null) {
          toast.error(e?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(t("toast.Error"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  const handlePasswordChange = () => {
    setViewModal(true);
  };

  return (
    <div className="m-11">
      <ToastContainer />
      <Modal
        open={viewModal}
        onClose={() => {
          setViewModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography sx={{ fontSize: 18 }} gutterBottom>
            {t("Profile.changePassword")}
          </Typography>{" "}
          <TextField
            type={"password"}
            value={oldPassword}
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
            className="mb-30"
            margin="normal"
            required
            fullWidth
            label={t("Profile.currentPassword")}
            autoFocus
          />
          <Divider variant="middle" />
          <TextField
            type={"password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="mt-30"
            margin="normal"
            required
            fullWidth
            label={t("Profile.newPassword")}
          />
          <TextField
            type={"password"}
            value={cPassword}
            onChange={(e) => {
              setCPassword(e.target.value);
            }}
            className="mt-30"
            margin="normal"
            required
            fullWidth
            label={t("Profile.newPasswordRepeat")}
          />
          <div className="grid grid-cols-4 gap-4"></div>
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 pr-4 pt-4 pb-4">
            <button
              onClick={() => {
                // setViewModal(false);
                // submitNewUser();
                changePassword();
              }}
              className="btn bg-[#23293D] hover:bg-slate-900 text-white"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.875 8.91667H7.125V6.58334H9.625V5.41667H7.125V3.08334H5.875V5.41667H3.375V6.58334H5.875V8.91667ZM6.5 11.8333C5.63542 11.8333 4.82292 11.6801 4.0625 11.3737C3.30208 11.0676 2.64062 10.6521 2.07812 10.1271C1.51562 9.60209 1.07042 8.98473 0.7425 8.275C0.414167 7.56528 0.25 6.80695 0.25 6C0.25 5.19306 0.414167 4.43473 0.7425 3.72501C1.07042 3.01528 1.51562 2.39792 2.07812 1.87292C2.64062 1.34792 3.30208 0.932199 4.0625 0.625755C4.82292 0.319699 5.63542 0.166672 6.5 0.166672C7.36458 0.166672 8.17708 0.319699 8.9375 0.625755C9.69792 0.932199 10.3594 1.34792 10.9219 1.87292C11.4844 2.39792 11.9296 3.01528 12.2575 3.72501C12.5858 4.43473 12.75 5.19306 12.75 6C12.75 6.80695 12.5858 7.56528 12.2575 8.275C11.9296 8.98473 11.4844 9.60209 10.9219 10.1271C10.3594 10.6521 9.69792 11.0676 8.9375 11.3737C8.17708 11.6801 7.36458 11.8333 6.5 11.8333Z"
                  fill="white"
                />
              </svg>
              <span className="hidden xs:block ml-2">{t("Settings.add")}</span>
            </button>
          </div>
        </Box>
      </Modal>

      <div className="bg-white p-4 mb-4">
        <div>
          <p className="text-2xl font-extrabold">{t("Profile.title")}</p>
        </div>
        <div className="mt-10">
          <button
            onClick={() => {
              handleChangePage("information");
            }}
            className={`w-1/4 text-center py-2 text-lg font-bold ${
              activePage === "information"
                ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
                : ""
            }`}
          >
            {t("Profile.MyInformation.title")}
          </button>
          <button
            onClick={() => {
              handleChangePage("settings");
            }}
            className={`w-1/4 text-center py-2 text-lg font-bold ${
              activePage === "settings"
                ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
                : ""
            }`}
          >
            {t("Profile.Settings.title")}
          </button>
        </div>
        <div>{renderActivePage()}</div>
      </div>
    </div>
  );
};

export default MyProfile;
