import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BurnoutReportTable from "./burnout/BurnOutReportTable";
import ContractEmployeeTable from "./burnout/ContractEmployeeTable";
import MainEmployeeTable from "./burnout/MainEmployeeTable";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa6";

const BurnOut = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState("report");
  const navigate = useNavigate();
  const handleChangePage = (page) => {
    setActivePage(page);
    navigate(`${page}`);
  };
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const pathParts = currentPath.split("/");
    const lastPart = pathParts[pathParts.length - 1];
    setActivePage(lastPart);

    console.log("currentPath = ", lastPart);
  }, [location]);

  const renderActivePage = () => {
    switch (activePage) {
      case "contract":
        return <ContractEmployeeTable />;
      case "main":
        return <MainEmployeeTable />;
      case "report":
        return <BurnoutReportTable />;
      default:
        return null;
    }
  };

  return (
    <div className="m-auto w-10/12 pt-11">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-2xl font-bold">{t("burnout.title")}</p>
          <p className="text-gray-400 text-sm mt-2">
            {t("burnout.companyEmp")}
          </p>
        </div>
        <button
          onClick={() => {
            navigate("create");
          }}
          className="bg-[#324d72] text-sm rounded-xl px-8 py-2 font-semibold text-white flex items-center gap-2"
        >
          {" "}
          {t("burnout.researches.createBtn")} <FaPlus />
        </button>
      </div>

      <div>
        <div className="mt-5">
          <button
            onClick={() => {
              handleChangePage("report");
            }}
            className={`w-1/4 text-center py-2 text-lg font-bold ${
              activePage === "report"
                ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
                : ""
            }`}
          >
            {t("burnout.generalRep")}
          </button>
          <button
            onClick={() => {
              handleChangePage("contract");
            }}
            className={`w-1/4 text-center py-2 text-lg font-bold ${
              activePage === "contract"
                ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
                : ""
            }`}
          >
            {t("burnout.eachEmp")}
          </button>
          <button
            onClick={() => {
              handleChangePage("main");
            }}
            className={`w-1/4 text-center py-2 text-lg font-bold ${
              activePage === "main"
                ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
                : ""
            }`}
          >
            {t("burnout.research")}
          </button>
        </div>
      </div>
      {""}
      <div>{renderActivePage()}</div>
    </div>
  );
};

export default BurnOut;
