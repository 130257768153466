import api from "../helper/request";

const getCompletedPlayers = () => api.get("/player1/completed");

const getPlayerDetail = (playerId) =>
  api.post("/lvl3", null, {
    params: {
      playerId: playerId,
    },
  });

const getAllWorkWithData = () => api.post("/lvl3/allMajors");

const getMpi = () => api.get("/mpi");

const createResultUrl = (id, url) =>
  api.post("/player/setResultUrl", null, {
    params: {
      id: id,
      url: url,
    },
  });

const getCompanyInfo = () => {
  return api.get("/company");
};

const createCompanyAndUser = (obj) => {
  return api.post("/account/new", obj);
}

export {
  getCompletedPlayers,
  getPlayerDetail,
  getMpi,
  createResultUrl,
  getAllWorkWithData,
  getCompanyInfo,
  createCompanyAndUser
};
