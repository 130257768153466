import React, { useEffect, useState } from "react";
import { getAnyBurnOutPlayers } from "../../../services/burnout.service";
import Loading from "../../../components/uncommon/Loading";
import { Pie } from "react-chartjs-2";
import MainTable from "../../../components/table/MainTable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const BurnOutTableDemo = () => {
  const [datas, setDatas] = useState(null);
  const { t } = useTranslation();
  const [departmentsData, setDepartmentsData] = useState([]);
  const [lowestDep, setLowestDep] = useState(null);
  const [highestDep, setHighestDep] = useState(null);
  const [burnoutAvg, setBurnoutAvg] = useState(0);
  const [playedCount, setPlayedCount] = useState(0);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getAnyBurnOutPlayers()
      .then((data) => {
        setDatas(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (datas) {
      const completedGames = datas.filter(
        (data) => data.completeGameType === "completed"
      );

      setPlayedCount(completedGames.length);
      setTotalPlayers(datas.length);

      // Calculate burnout average
      setBurnoutAvg(
        completedGames.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue.level || 0),
          0
        ) / completedGames.length
      );

      // Calculate department-wise player counts and average level
      const departmentCounts = datas.reduce((acc, curr) => {
        const department = curr.departmentName || "Хэлтэст харьяалагдахгүй";
        acc[department] = acc[department] || {
          total: 0,
          played: 0,
          level1Count: 0,
          totalLevel: 0,
        };
        acc[department].total++;
        if (curr.completeGameType === "completed") {
          acc[department].played++;
          acc[department].totalLevel += curr.level || 0;
          if (curr.level === 1) {
            acc[department].level1Count++;
          }
        }
        return acc;
      }, {});

      const departmentsArray = Object.entries(departmentCounts).map(
        ([department, data]) => {
          return {
            department,
            total: data.total,
            played: data.played,
            level1Count: data.level1Count,
            averageLevel: data.played > 0 ? data.totalLevel / data.played : 0,
          };
        }
      );

      setDepartmentsData(departmentsArray);
    }
  }, [datas]);

  useEffect(() => {
    // Find lowest and highest departments by burnout level
    if (departmentsData.length > 0) {
      const sortedDepartments = [...departmentsData].sort(
        (a, b) => a.averageLevel - b.averageLevel
      );
      setLowestDep(sortedDepartments[0]);
      setHighestDep(sortedDepartments[sortedDepartments.length - 1]);
    }
  }, [departmentsData]);

  if (datas === null) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  const playedPercentage = totalPlayers
    ? ((playedCount / totalPlayers) * 100).toFixed(0)
    : 0;
  const pieData = {
    labels: ["нийцэл", ""],
    datasets: [
      {
        data: [playedPercentage, totalPlayers],
        backgroundColor: ["transparent", "transparent"],
        borderColor: ["#06B496", "transparent"],
        borderWidth: 2,
        borderAlign: "center",
        cutout: "99%",
      },
    ],
  };
  const pieOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const OnClickCell = (data) => {
    navigate("dep");
  };

  console.log(datas);
  return (
    <div className="w-10/12 m-auto pt-11">
      <div className="flex items-center justify-between me-20">
        <select className="rounded-lg">
          <option>Тайлангийн хугацаа</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
        <p className="text-bold text-xl">
          Хамгийн сүүлийн судалгааг .. сарын өмнө авсан байна.
        </p>
      </div>
      <div className="flex justify-between items-center mx-20 mt-10">
        <div className="relative" style={{ height: "100px", width: "100px" }}>
          <div className="absolute top-[30%] left-[20%]">
            <p className="text-4xl font-bold">{playedPercentage}%</p>
          </div>
          <Pie data={pieData} options={pieOptions} />
          <p className="text-center text-lg">Оролцоо</p>
        </div>
        <div className="border w-[187px] h-[140px] border-[#F18B8B] rounded-xl p-4 text-center shadow-xl">
          <p className="mb-2 text-lg">
            Хамгийн <span className="font-bold">Burnout</span> <br /> өндөртэй
            хэлтэс
          </p>
          <p>{lowestDep?.department}</p>
        </div>
        <div className="border w-[187px] h-[140px] border-[#6CB7A1] rounded-xl p-4 text-center shadow-xl">
          <p className="mb-2 text-lg">
            Сэтгэл ханамж <br />
            өндөртэй хэлтэс
          </p>
          <p>{highestDep?.department}</p>
        </div>
        {Number(burnoutAvg.toFixed(0)) === 1 ? (
          <div className="text-center">
            <p>Burnout</p>
            <div className="flex justify-center">
              <img
                className="h-[43px] w-[83px]"
                src="/burnout/1.svg"
                alt="burnout"
              />
            </div>
            <p className="text-[#324d72] mt-4">Дунджаар</p>
          </div>
        ) : Number(burnoutAvg.toFixed(0)) === 2 ? (
          <div className="text-center">
            <p>Анхаарах хэрэгтэй</p>
            <div className="flex justify-center">
              <img
                className="h-[43px] w-[83px]"
                src="/burnout/2.svg"
                alt="burnout"
              />
            </div>
            <p className="text-[#324d72] mt-4">Дунджаар</p>
          </div>
        ) : Number(burnoutAvg.toFixed(0)) === 3 ? (
          <div className="text-center">
            <p>Хэт ачаалалтай</p>
            <div className="flex justify-center">
              <img
                className="h-[43px] w-[83px]"
                src="/burnout/3.svg"
                alt="burnout"
              />
            </div>
            <p className="text-[#324d72] mt-4">Дунджаар</p>
          </div>
        ) : Number(burnoutAvg.toFixed(0)) === 4 ? (
          <div className="text-center">
            <p>Бүтээмж буурсан</p>
            <div className="flex justify-center">
              <img
                className="h-[43px] w-[83px]"
                src="/burnout/4.svg"
                alt="burnout"
              />
            </div>
            <p className="text-[#324d72] mt-4">Дунджаар</p>
          </div>
        ) : Number(burnoutAvg.toFixed(0)) === 5 ? (
          <div className="text-center">
            <p>Идэвхитэй</p>
            <div className="flex justify-center">
              <img
                className="h-[43px] w-[83px]"
                src="/burnout/5.svg"
                alt="burnout"
              />
            </div>
            <p className="text-[#324d72] mt-4">Дунджаар</p>
          </div>
        ) : (
          <div className="text-center">
            <p>Хариу байхгүй</p>
          </div>
        )}
      </div>
      <div className="mt-20">
        <MainTable
          rowData={departmentsData}
          columnDefs={[
            {
              headerName: t("burnout.generalReport.department"),
              field: `department`,
              sortable: true,
              flex: 4,
              cellClass: "cursor-pointer",
              onCellClicked: OnClickCell,
            },
            {
              headerName: "Сүүлийн огноо",
              field: `aa`,
              sortable: true,
              flex: 2,
              cellClass: "cursor-pointer",
            },
            {
              headerName: "Нийт тоглосон",
              sortable: true,
              flex: 2,
              cellClass: "cursor-pointer",
              cellRenderer: (params) => {
                return (
                  <div>
                    <p className="font-bold text-center">
                      {params.data.played} / {params.data.total}
                    </p>
                  </div>
                );
              },
            },
            {
              headerName: "Дундаж",
              field: `averageLevel`,
              sortable: true,
              flex: 2,
              cellClass: "cursor-pointer",
              cellRenderer: (params) => {
                switch (Number(params.data.averageLevel.toFixed(0))) {
                  case 1:
                    return (
                      <div className="flex flex-col items-center">
                        <img
                          className="h-[23px] w-[48px]"
                          src="/burnout/1.svg"
                          alt="burnout"
                        />
                        <p className="text-center text-xs">Burnout</p>
                      </div>
                    );
                  case 2:
                    return (
                      <div className="flex flex-col items-center">
                        <img
                          className="h-[23px] w-[48px]"
                          src="/burnout/2.svg"
                          alt="burnout"
                        />
                        <p className="text-center text-xs">Анхаарах хэрэгтэй</p>
                      </div>
                    );
                  case 3:
                    return (
                      <div className="flex flex-col items-center">
                        <img
                          className="h-[23px] w-[48px]"
                          src="/burnout/3.svg"
                          alt="burnout"
                        />
                        <p className="text-center text-xs">Хэт ачаалалтай</p>
                      </div>
                    );
                  case 4:
                    return (
                      <div className="flex flex-col items-center">
                        <img
                          className="h-[23px] w-[48px]"
                          src="/burnout/4.svg"
                          alt="burnout"
                        />
                        <p className="text-center text-xs">Бүтээмж буурсан</p>
                      </div>
                    );
                  case 5:
                    return (
                      <div className="flex flex-col items-center">
                        <img
                          className="h-[23px] w-[48px]"
                          src="/burnout/5.svg"
                          alt="burnout"
                        />
                        <p className="text-center text-xs">Идэвхитэй</p>
                      </div>
                    );
                  default:
                    return null;
                }
              },
            },
            {
              headerName: "Анхаарах хэрэгтэй ажилтны тоо",
              field: `level1Count`,
              sortable: true,
              flex: 2,
              cellClass: "cursor-pointer",
              cellRenderer: (params) => {
                return (
                  <p className="text-center text-[#F18B8B]">
                    {params.data.level1Count}
                  </p>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default BurnOutTableDemo;
