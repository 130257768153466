import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import i18n (needs to be bundled ;)) 
import './i18n';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { UserContextProvider } from './context/UserContext';
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'GIP-Regular',
      fontWeight: 'GIP-Bold',
    },
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </UserContextProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
