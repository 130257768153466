import React, { useEffect, useState } from "react";
import { FaChevronRight, FaChevronUp, FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { MdOutlineGroup } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";

const SoftSkillResearch = () => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(window.innerHeight - 280);
  const [height2, setHeight2] = useState(window.innerHeight / 2 - 160);
  const [softSkill, setSoftSkill] = useState([
    {
      id: 1,
      title: "a",
      date: "2024/12/12",
      data: [
        {
          name: "Name Surname",
          email: "example@gmail.com",
          status: "invited",
          mpi: "41.2",
        },
        {
          name: "Enkhjin Otgonjargal",
          email: "example@gmail.com",
          status: "completed",
          mpi: "41.2",
        },
        {
          name: "Name Surname",
          email: "example@gmail.com",
          status: "started",
          mpi: "41.2",
        },
        {
          name: "Enkhjin Otgonjargal",
          email: "example@gmail.com",
          status: "invited",
          mpi: "41.2",
        },
      ],
    },
    {
      id: 2,
      title: "asdasd",
      date: "2024/12/12",
      data: [
        {
          name: "Enkhjin Otgonjargal",
          email: "example@gmail.com",
          status: "invited",
          mpi: "41.2",
        },
        {
          name: "Name Surname",
          email: "example@gmail.com",
          status: "completed",
          mpi: "41.2",
        },
        {
          name: "Enkhjin Otgonjargal",
          email: "example@gmail.com",
          status: "invited",
          mpi: "41.2",
        },
      ],
    },
    {
      id: 3,
      title: "123123",
      date: "2024/12/12",
      data: [
        {
          name: "Name Surname",
          email: "example@gmail.com",
          status: "started",
          mpi: "41.2",
        },
        {
          name: "Enkhjin Otgonjargal",
          email: "example@gmail.com",
          status: "invited",
          mpi: "41.2",
        },
        {
          name: "Name Surname",
          email: "example@gmail.com",
          status: "invited",
          mpi: "41.2",
        },
        {
          name: "Enkhjin Otgonjargal",
          email: "example@gmail.com",
          status: "completed",
          mpi: "41.2",
        },
        {
          name: "Name Surname",
          email: "example@gmail.com",
          status: "invited",
          mpi: "41.2",
        },
      ],
    },
    {
      id: 4,
      title: "4424",
      date: "2024/12/12",
      data: [
        {
          name: "Enkhjin Otgonjargal",
          email: "example@gmail.com",
          status: "completed",
          mpi: "41.2",
        },
        {
          name: "Name Surname",
          email: "example@gmail.com",
          status: "invited",
          mpi: "41.2",
        },
        {
          name: "Enkhjin Otgonjargal",
          email: "example@gmail.com",
          status: "invited",
          mpi: "41.2",
        },
      ],
    },
    {
      id: 5,
      title: "llalsl",
      date: "2024/12/12",
      data: [
        {
          name: "Name Surname",
          email: "example@gmail.com",
          status: "invited",
          mpi: "41.2",
        },
      ],
    },
  ]);
  const [endedSoftSkill, setEndedSoftSkill] = useState(null);
  const [savedSoftSkill, setSavedSoftSkill] = useState(null);
  const coming = false;
  const [height3, setHeight3] = useState(window.innerHeight - 100);
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(0);
  const [chosen, setChosen] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 200);
      setHeight2(window.innerHeight / 2 - 100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const found = softSkill.find((e) => e.id === clicked);
    setChosen(found);
  }, [clicked]);

  if (coming) {
    return (
      <div className="relative" style={{ height: `${height3}px` }}>
        <div className="ms-10 mt-10">
          <p className="text-[#2C4360] font-semibold text-[36px]">
            {t("comingSoon.title")}
          </p>
        </div>
        <img
          className="absolute right-0 bottom-0"
          src="/comingSoonImg/ONA.png"
          alt="coming soon"
        />
      </div>
    );
  }

  const getStatusIndicator = (status) => {
    switch (status) {
      case "completed":
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/greenEllipse.png" alt="" />
            </div>
            <p>{t("Assessment.completed")}</p>
          </div>
        );
      case "started":
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/grayDot.png" alt="" />
            </div>
            <p>{t("Assessment.started")}</p>
          </div>
        );

      default:
        return (
          <div className="flex items-center">
            <div className="w-2 h-2 flex items-center justify-center mr-1">
              <img src="/icons/orangeDot.png" alt="" />
            </div>
            <p>{t("Assessment.sendInvitation")}</p>
          </div>
        );
    }
  };

  if (clicked !== 0) {
    return (
      <div>
        {console.log(chosen)}
        <div>
          <div className="w-[400px] rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white mt-10">
            <div className="flex justify-between">
              <p className="text-[#1E293B] font-semibold">{chosen?.title}</p>
              <p className="text-xs text-[#636466]">{chosen?.date}</p>
            </div>

            <div className="flex items-end justify-between">
              <div>
                <p className="text-[#636466] text-xs">Оролцогч:</p>
                <p className="text-xs text-[#1E293B] flex items-center">
                  <MdOutlineGroup className="h-4 w-4" />
                  <span className="font-semibold ps-0.5 pe-1">25</span>
                  Хэлтэс нэр
                </p>
              </div>
              <button
                onClick={() => {
                  setClicked(0);
                }}
                className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100"
              >
                Хураангүй <FaChevronUp />
              </button>
            </div>
          </div>
          <table className="min-w-full bg-white">
            <thead className="sticky top-0 bg-white">
              <tr>
                <th className="py-2 border-b text-start ps-4">Овог Нэр</th>
                <th className="py-2 border-b text-start">Цахим хаяг</th>
                <th className="py-2 border-b text-start">Статус</th>
                <th className="py-2 border-b text-start">Оноо</th>
                <th className="py-2 border-b text-start">Тоглоомын үр дүн</th>
                <th className="py-2 border-b"></th>
              </tr>
            </thead>
            <tbody>
              {chosen?.data.map((row, index) => (
                <tr
                  className={`${index % 2 === 0 ? "bg-[#F5F5F6]" : "bg-white"}`}
                  key={index}
                >
                  <td className="py-2 border-b ps-4 text-[#324D72] font-bold">
                    {row.name}
                  </td>
                  <td className="py-2 border-b text-[#324D72] font-semibold">
                    {row.email}
                  </td>
                  <td className="py-2 border-b text-[#324D72]">
                    {" "}
                    {getStatusIndicator(row.status)}
                  </td>
                  <td className="py-2 border-b text-[#324D72]">
                    {row.mpi} MPI
                  </td>
                  <td className="py-2 border-b text-[#324D72]">
                    <button
                      onClick={() => {}}
                      className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100 "
                    >
                      Дэлгэрэнгүй <FaChevronRight />
                    </button>
                  </td>
                  <td className="py-2 border-b">
                    <BsThreeDotsVertical className="text-[#222]" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex w-full gap-10 items-start justify-between mt-10">
        <div className="w-1/2">
          <p className="font-semibold text-lg text-[#222] mb-6">
            Явагдаж байгаа асуумжууд
          </p>
          <div style={{ height: `${height}px`, overflowY: "auto" }}>
            {softSkill === null ? (
              <div className="h-full flex items-center justify-center">
                <div className="flex flex-col items-center">
                  <img src="/ona/empty.png" alt="empty" />
                  <p className="text-[#1E293B] mt-2">
                    Одоогоор асуумж байхгүй байна.
                  </p>
                </div>
              </div>
            ) : (
              <div>
                {softSkill.map((e) => {
                  return (
                    <div
                      key={e.id}
                      className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white"
                    >
                      <div className="flex justify-between">
                        <p className="text-[#1E293B] font-semibold">
                          {e.title}
                        </p>
                        <p className="text-xs text-[#636466]">{e.date}</p>
                      </div>
                      <div className="flex items-end justify-between">
                        <div>
                          <p className="text-[#636466] text-xs">Оролцогч:</p>
                          <p className="text-xs text-[#1E293B] flex items-center">
                            <MdOutlineGroup className="h-4 w-4" />
                            <span className="font-semibold ps-0.5 pe-1">
                              25
                            </span>
                            Хэлтэс нэр
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            setClicked(e.id);
                          }}
                          className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100"
                        >
                          Дэлгэрэнгүй <FaChevronDown />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2">
          <div className="mb-4">
            <p className="font-semibold text-lg text-[#222] mb-6">
              Хадгалсан асуумжууд
            </p>
            <div
              className={`shadow-xl ${
                savedSoftSkill === null ? "rounded-xl" : "rounded-none"
              }`}
              style={{ height: `${height2}px`, overflowY: "auto" }}
            >
              {savedSoftSkill === null ? (
                <div className=" flex items-center justify-center">
                  <div className="flex flex-col items-center">
                    <img src="/ona/empty.png" alt="empty" />
                    <p className="text-[#1E293B] mt-2">
                      Одоогоор асуумж байхгүй байна.
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                    <div className="flex justify-between">
                      <p className="text-[#1E293B] font-semibold">
                        Soft Skill game
                      </p>
                      <p className="text-xs text-[#636466]">2024/06/12</p>
                    </div>
                    <div className="flex items-end justify-between">
                      <div>
                        <p className="text-[#636466] text-xs">Оролцогч:</p>
                        <p className="text-xs text-[#1E293B] flex items-center">
                          <MdOutlineGroup className="h-4 w-4" />
                          <span className="font-semibold ps-0.5 pe-1">25</span>
                          Хэлтэс нэр
                        </p>
                      </div>
                      <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                        Дэлгэрэнгүй <FaChevronDown />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mb-4">
            <p className="font-semibold text-lg text-[#222] mb-6">
              Бүрэн дууссан асуумжууд
            </p>
            <div
              className={`shadow-xl ${
                endedSoftSkill === null ? "rounded-xl" : "rounded-none"
              }`}
              style={{ height: `${height2}px`, overflowY: "auto" }}
            >
              {endedSoftSkill === null ? (
                <div className=" flex items-center justify-center">
                  <div className="flex flex-col items-center">
                    <img src="/ona/empty.png" alt="empty" />
                    <p className="text-[#1E293B] mt-2">
                      Одоогоор асуумж байхгүй байна.
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                    <div className="flex justify-between">
                      <p className="text-[#1E293B] font-semibold">
                        Soft Skill game
                      </p>
                      <p className="text-xs text-[#636466]">2024/06/12</p>
                    </div>
                    <div className="flex items-end justify-between">
                      <div>
                        <p className="text-[#636466] text-xs">Оролцогч:</p>
                        <p className="text-xs text-[#1E293B] flex items-center">
                          <MdOutlineGroup className="h-4 w-4" />
                          <span className="font-semibold ps-0.5 pe-1">25</span>
                          Хэлтэс нэр
                        </p>
                      </div>
                      <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                        Дэлгэрэнгүй <FaChevronDown />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftSkillResearch;
