import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getTime } from "../services/assessment.service";

const NotStarted = () => {
  //   const { assessmentId } = route.params;
  const { state } = useLocation();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    if (state != null && state.assessmentId != null) {
      console.log("Start = ", state);
      getTime({ assessmentId: parseInt(state.assessmentId) }).then((res) => {
        console.log("res = ", res);
        setStart(moment(res.data.start).format("YYYY-MM-DD hh:mm"));
        setEnd(moment(res.data.end).format("YYYY-MM-DD hh:mm"));
      });
    }
  }, []);
  return (
    <div className="flex h-screen">
      <div className="m-auto text-center shadow-2xl	 p-5 rounded-2xl">
        {/* <h1 style={{ color: "red", fontSize: 100 }}>404</h1> */}
        <h1 className="text-[22px]">
          Таны тэстийн эхлэх хугацаа болоогүй байна.{" "}
        </h1>
        <h2 className="font-bold text-[20px] pt-4">{start}</h2>
        <h2 className="font-bold text-[20px] pb-4">{end}</h2>
        <h3 className="text-[22px]">Цагийн хооронд орно уу</h3>
        <h3 className="text-[22px]">Баярлалаа</h3>
      </div>
    </div>
  );
};

export default NotStarted;
