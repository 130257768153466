import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { MdOutlineGroup } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa6";

const MainEmployeeTable = () => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(window.innerHeight - 280);
  const [height2, setHeight2] = useState(window.innerHeight / 2 - 160);
  const [burnout, setBurnout] = useState([]);
  const [endedBurnout, setEndedBurnout] = useState(null);
  const [savedBurnOut, setSavedBurnOut] = useState(null);
  const [height3, setHeight3] = useState(window.innerHeight - 100);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 200);
      setHeight2(window.innerHeight / 2 - 100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className=" pt-11">
      {burnout === null && endedBurnout === null && savedBurnOut === null ? (
        <div className="h-screen flex items-center justify-center">
          <div className="flex flex-col items-center gap-4 pb-60">
            <img src="/ona/ona2.png" alt="ona" />
            <p className="text-center text-[24px] text-[#222] font-semibold">
              {t("burnout.researches.noResearch")}
            </p>
            <button
              onClick={() => {
                navigate("create");
              }}
              className="bg-[#324d72] rounded-xl px-4 py-2 font-semibold text-white flex items-center gap-2"
            >
              {t("burnout.researches.createBtn")} <FaPlus />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex w-full gap-10 items-start justify-between mt-10">
          <div className="w-1/2">
            <p className="font-semibold text-lg text-[#222] mb-6">
              {t("burnout.researches.ongoingBurnout")}
            </p>
            <div style={{ height: `${height}px`, overflowY: "auto" }}>
              {burnout !== null ? (
                <div className="h-full flex items-center justify-center">
                  <div className="flex flex-col items-center">
                    <img src="/ona/empty.png" alt="empty" />
                    <p className="text-[#1E293B] mt-2">
                      {t("burnout.researches.noResearchCreated")}
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                    <div className="flex justify-between">
                      <p className="text-[#1E293B] font-semibold">
                        BurnOut test
                      </p>
                      <p className="text-xs text-[#636466]">2024/06/12</p>
                    </div>
                    <div className="flex items-end justify-between">
                      <div>
                        <p className="text-[#636466] text-xs">
                          {t("burnout.researches.participants")}:
                        </p>
                        <p className="text-xs text-[#1E293B] flex items-center">
                          <MdOutlineGroup className="h-4 w-4" />
                          <span className="font-semibold ps-0.5 pe-1">25</span>
                          Хэлтэс нэр
                        </p>
                      </div>
                      <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                        {t("burnout.researches.detail")} <FaChevronRight />
                      </button>
                    </div>
                  </div>
                  <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                    <div className="flex justify-between">
                      <p className="text-[#1E293B] font-semibold">
                        BurnOut test
                      </p>
                      <p className="text-xs text-[#636466]">2024/06/12</p>
                    </div>
                    <div className="flex items-end justify-between">
                      <div>
                        <p className="text-[#636466] text-xs">
                          {t("burnout.researches.participants")}:
                        </p>
                        <p className="text-xs text-[#1E293B] flex items-center">
                          <MdOutlineGroup className="h-4 w-4" />
                          <span className="font-semibold ps-0.5 pe-1">25</span>
                          Хэлтэс нэр
                        </p>
                      </div>
                      <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                        {t("burnout.researches.detail")} <FaChevronRight />
                      </button>
                    </div>
                  </div>
                  <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                    <div className="flex justify-between">
                      <p className="text-[#1E293B] font-semibold">
                        BurnOut test
                      </p>
                      <p className="text-xs text-[#636466]">2024/06/12</p>
                    </div>
                    <div className="flex items-end justify-between">
                      <div>
                        <p className="text-[#636466] text-xs">
                          {t("burnout.researches.participants")}:
                        </p>
                        <p className="text-xs text-[#1E293B] flex items-center">
                          <MdOutlineGroup className="h-4 w-4" />
                          <span className="font-semibold ps-0.5 pe-1">25</span>
                          Хэлтэс нэр
                        </p>
                      </div>
                      <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                        {t("burnout.researches.detail")} <FaChevronRight />
                      </button>
                    </div>
                  </div>{" "}
                  <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                    <div className="flex justify-between">
                      <p className="text-[#1E293B] font-semibold">
                        BurnOut test
                      </p>
                      <p className="text-xs text-[#636466]">2024/06/12</p>
                    </div>
                    <div className="flex items-end justify-between">
                      <div>
                        <p className="text-[#636466] text-xs">
                          {t("burnout.researches.participants")}:
                        </p>
                        <p className="text-xs text-[#1E293B] flex items-center">
                          <MdOutlineGroup className="h-4 w-4" />
                          <span className="font-semibold ps-0.5 pe-1">25</span>
                          Хэлтэс нэр
                        </p>
                      </div>
                      <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                        {t("burnout.researches.detail")} <FaChevronRight />
                      </button>
                    </div>
                  </div>{" "}
                  <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                    <div className="flex justify-between">
                      <p className="text-[#1E293B] font-semibold">
                        BurnOut test
                      </p>
                      <p className="text-xs text-[#636466]">2024/06/12</p>
                    </div>
                    <div className="flex items-end justify-between">
                      <div>
                        <p className="text-[#636466] text-xs">
                          {t("burnout.researches.participants")}:
                        </p>
                        <p className="text-xs text-[#1E293B] flex items-center">
                          <MdOutlineGroup className="h-4 w-4" />
                          <span className="font-semibold ps-0.5 pe-1">25</span>
                          Хэлтэс нэр
                        </p>
                      </div>
                      <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                        {t("burnout.researches.detail")} <FaChevronRight />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-1/2">
            <div className="mb-4">
              <p className="font-semibold text-lg text-[#222] mb-6">
                {t("burnout.researches.saveBurnout")}
              </p>
              <div
                className={`shadow-xl ${
                  savedBurnOut !== null ? "rounded-xl" : "rounded-none"
                }`}
                style={{ height: `${height2}px`, overflowY: "auto" }}
              >
                {savedBurnOut === null ? (
                  <div className=" flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img src="/ona/empty.png" alt="empty" />
                      <p className="text-[#1E293B] mt-2">
                        {t("burnout.researches.noResearchCreated")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                      <div className="flex justify-between">
                        <p className="text-[#1E293B] font-semibold">
                          BurnOut test
                        </p>
                        <p className="text-xs text-[#636466]">2024/06/12</p>
                      </div>
                      <div className="flex items-end justify-between">
                        <div>
                          <p className="text-[#636466] text-xs">
                            {t("burnout.researches.participants")}:
                          </p>
                          <p className="text-xs text-[#1E293B] flex items-center">
                            <MdOutlineGroup className="h-4 w-4" />
                            <span className="font-semibold ps-0.5 pe-1">
                              25
                            </span>
                            Хэлтэс нэр
                          </p>
                        </div>
                        <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                          {t("burnout.researches.detail")} <FaChevronRight />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4">
              <p className="font-semibold text-lg text-[#222] mb-6">
                {t("burnout.researches.endedBurnout")}
              </p>
              <div
                className={`shadow-xl ${
                  endedBurnout === null ? "rounded-xl" : "rounded-none"
                }`}
                style={{ height: `${height2}px`, overflowY: "auto" }}
              >
                {endedBurnout === null ? (
                  <div className=" flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img src="/ona/empty.png" alt="empty" />
                      <p className="text-[#1E293B] mt-2">
                        {t("burnout.researches.noResearchCreated")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainEmployeeTable;
