import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
const ComingSoonResearch = () => {
  return (
    <>
      <div className="m-auto w-10/12 pt-11">
        <div>
          <div>
            <p className="text-2xl font-bold">
              2023 оны 9-р сарын урьдчилсан судалгаа
            </p>
            <hr className="my-5" />
          </div>
          <div>
            <p className="text-lg my-5">
              Энэхүү судалгааг 9-р сарын 6-ны Лхагва гарагт илгээнэ. Сэдэв нь
              Менежмент
            </p>
          </div>
        </div>
        <div className="bg-[#f5f5f6] w-full flex justify-center items-center py-20 px-10">
          <div>
            {/* garchig */}
            <div className="flex justify-center">
              <div>
                <div>
                  <div className="flex justify-center mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="42"
                      height="41"
                      viewBox="0 0 42 41"
                      fill="none"
                    >
                      <path
                        d="M28.8926 2.7334C35.6412 2.7334 40.1758 9.40993 40.1758 15.6384C40.1758 28.2521 21.3562 38.5805 21.0156 38.5805C20.675 38.5805 1.85547 28.2521 1.85547 15.6384C1.85547 9.40993 6.39004 2.7334 13.1387 2.7334C17.0133 2.7334 19.5467 4.7722 21.0156 6.56456C22.4846 4.7722 25.018 2.7334 28.8926 2.7334Z"
                        stroke="#4F78A8"
                        strokeWidth="3.62869"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p className="text-2xl font-bold mb-5">
                    Метаког Метрикс - н сар болгоны судалгаа
                  </p>
                  <p className="mb-5">
                    Та энэ судалгааг
                    <span className="font-bold">2023 оны 9-р сарын 12</span>
                    өмнө бөглөнө үү.
                  </p>
                  <p className="flex items-center justify-center text-sm mb-5">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                      >
                        <path
                          d="M2.80469 3.0083L19.3047 19.5083M18.0442 13.1131C18.2624 12.5255 18.388 11.906 18.388 11.2583V7.7911C18.388 7.05822 18.388 6.69177 18.2682 6.37678C18.1623 6.09851 17.9902 5.85022 17.7668 5.65337C17.514 5.43054 17.1709 5.30187 16.4847 5.04454L11.5697 3.20142C11.3791 3.12995 11.2838 3.09422 11.1858 3.08006C11.0988 3.06749 11.0105 3.06749 10.9236 3.08006C10.8256 3.09422 10.7303 3.12995 10.5397 3.20142L8.78731 3.85857M15.954 16.1576C14.4258 17.6659 12.5742 18.7321 11.6458 19.216C11.4556 19.3152 11.3605 19.3648 11.2299 19.3902C11.1282 19.41 10.9814 19.41 10.8797 19.3902C10.7491 19.3649 10.655 19.3158 10.4667 19.2177C8.72632 18.3109 3.72135 15.3477 3.72135 11.2583V7.7911C3.72135 7.05822 3.72135 6.69177 3.84122 6.37678C3.94711 6.09851 4.11917 5.85022 4.34254 5.65337C4.51381 5.50243 4.7265 5.39469 5.05919 5.26091"
                          stroke="#777985"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    Судалгааны хариултууд үргэлж нэр нууцалсан байх болно
                  </p>
                  <div className="flex justify-center mb-5">
                    <Link
                      to={{}}
                      className="flex font-bold text-[#324d72] hover:text-[#000] underline items-center"
                    >
                      <BiEdit className="text-[#FF9D72] hover:text-[#FF9D60] " />
                      асуултыг засах
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* first questions */}
            <div>
              <div className="mb-20">
                <div className="my-5">
                  <p className="text-xl font-bold text-center">
                    Менежмент надад бизнесийн зорилго, зорилтоо тодорхой
                    илэрхийлдэг.
                  </p>
                </div>
                {/* buttons  */}
                <div className="grid grid-cols-5 gap-4 ">
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал огт нийлэхгүй
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал нийлэхгүй
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Төвийг сахисан
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал нийлж байна
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Эрс санал нийлж байна
                  </div>
                </div>
              </div>
              {/* second questions */}
              <div className="my-20">
                <div className="my-5">
                  <p className="text-xl font-bold text-center">
                    Би менежертэй холбоотой гарч ирж буй асуудлын талаар ярихад
                    тухтай байдаг.
                  </p>
                </div>
                {/* buttons  */}
                <div className="grid grid-cols-5 gap-4 ">
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал огт нийлэхгүй
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал нийлэхгүй
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Төвийг сахисан
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал нийлж байна
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Эрс санал нийлж байна
                  </div>
                </div>
              </div>
              {/* third questions */}
              <div className="my-20">
                <div className="my-5">
                  <p className="text-xl font-bold text-center">
                    Ажил дээрээ надаас юу хүлээж байгааг би мэднэ.
                  </p>
                </div>
                {/* buttons  */}
                <div className="grid grid-cols-5 gap-4 ">
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал огт нийлэхгүй
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал нийлэхгүй
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Төвийг сахисан
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал нийлж байна
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Эрс санал нийлж байна
                  </div>
                </div>
              </div>
              {/* fourth questions */}
              <div className="my-20">
                <div className="my-5">
                  <p className="text-xl font-bold text-center">
                    Би өөрийгөө сайн удирдаж байгаа юм шиг санагддаг.
                  </p>
                </div>
                {/* buttons  */}
                <div className="grid grid-cols-5 gap-4 ">
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал огт нийлэхгүй
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал нийлэхгүй
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Төвийг сахисан
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Санал нийлж байна
                  </div>
                  <div
                    onClick={{}}
                    className="bg-[#E5E6E8] flex items-center text-center rounded-lg font-bold  p-4"
                  >
                    Эрс санал нийлж байна
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoonResearch;
