import { Add } from "@mui/icons-material";
import MainButton from "../../components/buttons/MainButton";
import MainInput from "../../components/input/MainInput";
import { useState } from "react";
import { createAdminCompany } from "../../services/user.service";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Admin = () => {
  const [companyName, setCompanyName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  return (
    <div className="p-4">
      <ToastContainer />

      <p>Create Company</p>

      <div className="p-11">
        <div>
          <p>Company name:</p>
          <div className="w-[400px]">
            <MainInput
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
              placeholder={`Name`}
              type="text"
              id="name"
              name="name"
            />
          </div>
        </div>
        <div className="pt-4">
          <p>Username:</p>
          <div className="w-[400px]">
            <MainInput
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              placeholder={`Username`}
              type="text"
              id="username"
              name="username"
            />
          </div>
        </div>
        <div className="pt-4">
          <p>Email:</p>
          <div className="w-[400px]">
            <MainInput
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder={`Email`}
              type="text"
              id="email"
              name="email"
            />
          </div>
        </div>
        <div className="w-[300px] pt-4">
          <MainButton
            color={"bg-main-blue"}
            labelColor={"text-white"}
            customClass="w-[150px]"
            label={"Компани үүсгэх"}
            rIcon={<Add className="ml-1" />}
            onClick={() => {
              if (companyName === "" || username === "" || email === "") {
                toast.error(t("toast.fillTheAllFieldCorrect"));
              } else {
                createAdminCompany({
                  username: username,
                  email: email,
                  companyName: companyName,
                })
                  .then((res) => {
                    setCompanyName("");
                    setUsername("");
                    setEmail("");
                    toast.success(t("toast.newUserAddedSuccessfully"));
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message);
                  });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Admin;
