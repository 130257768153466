import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, linearProgressClasses } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from 'react-player/vimeo';
import { useLocation, useNavigate } from "react-router-dom";
import Unity, { UnityContext } from "react-unity-webgl";
import { createApuMissingScores, createApuScore } from "../../services/game.service";
import "./../../App.css";


const steps = [
    "Тоглоом 1",
    "Тоглоом 2",
    "Тоглоом 3",
];


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? "#30e83c" : "#30e880",
    },
}));

const metacogContext = new UnityContext({
    productName: "Games",
    companyName: "Metacog",

    // loaderUrl: "../../../unity/MetaCog/MetaCog.loader.js",
    // dataUrl: "../../../unity/MetaCog/MetaCog.data",
    // frameworkUrl: "../../../unity/MetaCog/MetaCog.framework.js",
    // codeUrl: "../../../unity/MetaCog/MetaCog.wasm",

    loaderUrl: "../../../unity/test/unityTest.loader.js",
    dataUrl: "../../../unity/test/unityTest.data",
    frameworkUrl: "../../../unity/test/unityTest.framework.js",
    codeUrl: "../../../unity/test/unityTest.wasm",
});

const games = [
    "ARROWS",
    "SWITCH",
    "DELIVERY",
    "NOMADLIFE",
    "TEAM",
    "CARDS",
    "WATERWELL",
    "NEIGHBORS",
];

// arrow, cards, delivery, nomad, neoghbors, water, team, switch
const order = [0, 1, 2];

const ApuFlow = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [playCards, setPlayCards] = useState(true);

    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState(0);
    const [show, setShow] = useState(true);
    const [gameOrder, setGameOrder] = useState(0);
    const [video, setVideo] = useState(true);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [showModal, setShowModal] = useState(false);
    const [isRotate, setIsRotate] = useState(false)
    const [orientation, setOrientation] = useState('portrait');
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);


    const updateOrientation = () => {
        setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
        setViewportHeight(window.innerHeight);
        if (window.innerWidth > window.innerHeight) {
            setIsRotate(true)
        }
    };

    useEffect(() => {
        window.alert = function () { };
        window.addEventListener('resize', updateOrientation);
        updateOrientation();

        completeGame(0);

        // if (location && location.state && location.state.userId != null) {
        //     completeGame(location?.state?.playedGame || 0);
        // } else {
        //     // navigate("/NotFound");
        // }
    }, []);

    useEffect(() => {
        if (progress === 100) {
            setTimeout(() => {
                setShow(false);
            }, 2000);
        }
    }, [progress]);

    const completeGame = async (playedGame) => {
        let st = playedGame;
        if (playedGame != 0) {
            setVideo(false);
            setShowModal(true);
        }

        metacogContext.on("progress", (progression) => {
            // Now we can use the progression to for example
            // display it on our React app.
            setProgress(progression * 100);
            if (progression === 1) {
                metacogContext.send("MetaCog", "GameLang", `${order[playedGame]},0`);
                setStep(st);
            }
        });

        let gameScores = [];

        metacogContext.on("GameOver1", async function (result) {
            // gameScores = [...gameScores,
            // {
            //     assessmentPlayerId: parseInt(location.state.userId),
            //     gameCode: games[order[st]],
            //     values: result,
            // }];

            if (st < 2) {
                // createApuScore({
                //     assessmentPlayerId: location.state.userId,
                //     gameCode: games[order[st]],
                //     values: result,
                // })
                //     .then((res) => {
                //     })
                //     .catch((error) => {
                //         console.log("Error on creating scores")
                //     });

                st = st + 1;

                metacogContext.send("MetaCog", "GameLang", `${order[st]},0`);
                setStep(st);

            } else {
                // await createApuScore({
                //     assessmentPlayerId: location.state.userId,
                //     gameCode: games[order[st]],
                //     values: result,
                // })
                //     .then((res) => {

                //     })
                //     .catch((error) => {
                //         console.log("Error on creating scores")
                //     });
                // await createApuMissingScores(gameScores);
                await metacogContext.removeAllEventListeners();
                navigate(`/congratz`);
            }
        });
    }


    return (
        <div>
            {
                orientation === 'portrait' && isRotate === false
                    ? <div className="flex flex-col items-center justify-center h-full w-full">
                        <img src="/assets/rotate.webp" alt="" />
                        <p>Сайн байна уу</p>
                        <p>Та утсаа эргүүлнэ үү!</p>
                        <p>Баярлалаа</p>
                    </div>
                    :
                    <div className="wrapper flex h-screen grid grid-rows-8 bg-[#f6f7f8]">
                        <Dialog
                            open={showModal}
                            onClose={() => {
                                setShowModal(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {t('GameFlow.doYouContinue')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t('GameFlow.continueText')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    setShowModal(false);
                                }} autoFocus>
                                    {t('GameFlow.continue')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <div className="row-span-1 flex justify-center items-center z-30">
                            <Box sx={{ width: "100%" }}>
                                <Stepper activeStep={step} alternativeLabel>
                                    {
                                        steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))
                                    }

                                </Stepper>
                            </Box>
                        </div>
                        {/* <div className={"grid"}> */}
                        <div className="row-span-7 m-auto">
                            <div
                                className={`pb-14 pl-8 pr-8  ${show === true
                                    ? "bg-[#f6f7f8] bg-clip-padding pt-20"
                                    : "bg-white pt-8 shadow-xl bg-clip-padding"
                                    }  `}
                            >
                                {playCards ? (
                                    <div className="unity-wrapper flex justify-center">
                                        <Unity className="unity-canvas" unityContext={metacogContext} />
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        {show === true ? (
                            <div
                                className={
                                    " top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/3 w-screen h-screen bg-[#f6f7f8] z-20 fixed"
                                }
                            >
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                                    <div className=" text-center text-lg">
                                        {t('GameFlow.loading')}
                                        <div className="font-bold">{progress.toFixed(2)}%</div>{" "}
                                    </div>
                                    <div style={{ width: 350 }} className="mt-4">
                                        <BorderLinearProgress variant="determinate" value={progress} />
                                    </div>
                                </div>
                            </div>
                        ) : null}
{/* 
                        {video === true ? (
                            <div
                                className={
                                    " top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/3 w-screen h-screen bg-[#f6f7f8] z-30 fixed"
                                }
                            >
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                                    <div className="pb-4 text-2xl">
                                        {
                                            <>
                                                Та видео зааврыг үзэж дууссан бол{" "}
                                                <button
                                                    className="font-semibold"
                                                    style={{ color: "#FFC5A8" }}
                                                    onClick={() => {
                                                        setVideo(false);
                                                    }}
                                                >
                                                    ЭНД{" "}
                                                </button>
                                                {" "} дарна уу.
                                            </>
                                        }

                                    </div>

                                    <ReactPlayer url='https://vimeo.com/836379695' playing width='1154px'
                                        height='649px' onEnded={() => {
                                            setVideo(false);
                                        }} />
                                </div>
                            </div>
                        ) : null} */}
                    </div>
            }
        </div>
    );
}

export default ApuFlow;