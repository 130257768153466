import React, { useEffect, useState } from "react";
import data from "../test.json";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import EmployeeDetail from "./EmployeeDetail";
import EmployeePersonalDetail from "./EmployeePersonalDetail";

const WorkerInfo = () => {
  const { id } = useParams();
  const [worker, setWorker] = useState([]);
  const [activePage, setActivePage] = useState("employee_detail");
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const parsedId = parseInt(id);
      const foundWorker = data.find((worker) => worker.id === parsedId);
      setWorker(foundWorker);
    } catch (error) {
      console.error("Error loading worker data:", error);
    }
  }, [id]);

  const handleActivePage = (page) => {
    console.log(`Navigating to ${page}`);
    setActivePage(page);
    navigate(`/company/employee/employee_info/${id}/${page}`);
  };

  const renderActivePage = () => {
    switch (activePage) {
      case "employee_detail":
        return <EmployeeDetail />;
      case "personal_detail":
        return <EmployeePersonalDetail />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="m-auto w-11/12 pt-11">
        <div>
          <p className="flex items-center text-gray-500">
            Байгууллагын бүтэц <MdOutlineKeyboardArrowRight />
            {worker ? <span> {worker.firstName}</span> : <span> ...</span>}
          </p>
        </div>
        <div className="pt-10">
          <div className="flex items-center  text-[#324D72] text-xl gap-5">
            <img src={worker ? worker.profileImg : ".."} alt="user img" />
            <p className="font-extrabold">{worker ? worker.firstName : ".."}</p>
          </div>
        </div>
        <div className="pt-10">
          <div className="flex-grow mb-5 border-b">
            <div className="flex ">
              <button
                className={`text-gray-70 pe-5 font-bold pb-2 text-lg  ${
                  activePage === "employee_detail"
                    ? "text-[#324D72] border-b-2 border-[#FF9D72]"
                    : ""
                }`}
                onClick={() => handleActivePage("employee_detail")}
              >
                Ажлын дэлгэрэнгүй
              </button>
              <button
                className={`text-gray-70 ps-5 font-bold pb-2 text-lg  ${
                  activePage === "personal_detail"
                    ? "text-[#324D72] border-b-2 border-[#FF9D72]"
                    : ""
                }`}
                onClick={() => handleActivePage("personal_detail")}
              >
                Хувийн мэдээлэл
              </button>
            </div>
          </div>
        </div>
        {renderActivePage()}
      </div>
    </>
  );
};

export default WorkerInfo;
