import React, { useEffect, useState } from "react";
import { IoMdKey, IoMdClose } from "react-icons/io";
import { FaCheck, FaMagnifyingGlass, FaPlus } from "react-icons/fa6";
import { GoPlusCircle } from "react-icons/go";
import { MdDeleteOutline } from "react-icons/md";
import "../../../css/additional-styles/costum-checkbox.scss";

const TestQuestions = ({ test, setTest, setPoints, setRequiredPoints }) => {
  const [topic, setTopic] = useState("");
  const [questions, setQuestions] = useState([]);

  const [adding, setAdding] = useState(false);
  const [checking, setChecking] = useState(null);

  useEffect(() => {
    const totalPoints = calculateTotalPoints();
    setPoints(totalPoints);
    setRequiredPoints(Math.round(totalPoints / 2));
  }, [test.topic]);

  const handleAddTopic = () => {
    if (topic.trim()) {
      const newTopic = { name: topic, point: 0, questions: [] };

      setTest((prevTest) => ({
        ...prevTest,
        topic: [...prevTest.topic, newTopic],
      }));

      setTopic("");
      setAdding(false);
    }
  };

  const handleAddQuestion = (topicIndex) => {
    if (questions[topicIndex]?.trim()) {
      const newQuestion = { text: questions[topicIndex], isAnswer: false };
      const updatedTest = { ...test };
      updatedTest.topic[topicIndex].questions.push(newQuestion);
      setTest(updatedTest);

      const updatedQuestions = [...questions];
      updatedQuestions[topicIndex] = "";
      setQuestions(updatedQuestions);
    }
  };

  const toggleQuestionCheck = (topicIndex, questionIndex) => {
    setTest((prevTest) => {
      const updatedTestTopics = [...prevTest.topic];

      updatedTestTopics[topicIndex].questions = updatedTestTopics[
        topicIndex
      ].questions.map((question, index) => ({
        ...question,
        isAnswer: index === questionIndex,
      }));

      return { ...prevTest, topic: updatedTestTopics };
    });
  };

  const handleDeleteQuestion = (topicIndex, questionIndex) => {
    setTest((prevTest) => {
      const updatedTestTopics = prevTest.topic.map((topic, idx) => {
        if (idx === topicIndex) {
          const updatedQuestions = topic.questions.filter(
            (_, qIdx) => qIdx !== questionIndex
          );
          return { ...topic, questions: updatedQuestions };
        }
        return topic;
      });

      return { ...prevTest, topic: updatedTestTopics };
    });
  };

  const handleDeleteTopic = (topicIndex) => {
    setTest((prevTest) => ({
      ...prevTest,
      topic: prevTest.topic.filter((_, index) => index !== topicIndex),
    }));
  };

  const handlePointChange = (topicIndex, newPoint) => {
    setTest((prevTest) => ({
      ...prevTest,
      topic: prevTest.topic.map((item, index) =>
        index === topicIndex ? { ...item, point: newPoint } : item
      ),
    }));
  };

  const calculateTotalPoints = () => {
    return test.topic.reduce((sum, topic) => sum + topic.point, 0);
  };

  return (
    <div>
      <div className="m-auto w-10/12  flex justify-center items-center">
        <div>
          <div className="w-[482px] bg-[#fff] rounded-xl p-4 mb-4">
            <input
              className="text-[#1E293B] text-[20px] w-full border-none rounded-lg"
              onChange={(e) => setTest({ ...test, name: e.target.value })}
              placeholder="Нэр өгнө үү."
              type="text"
            />
            <hr className="bg-[#E5E6E8] h-[2px] my-2" />
            <input
              className="text-xs text-[#575763] w-full border-none rounded-lg"
              onChange={(e) =>
                setTest({ ...test, description: e.target.value })
              }
              placeholder="Тайлбар бичнэ үү"
              type="text"
            />
          </div>

          {test.topic.map((item, topicIndex) => (
            <div
              key={topicIndex}
              className="w-[482px] bg-[#fff] rounded-xl p-4 mb-4 relative overflow-hidden"
            >
              {checking === topicIndex && (
                <div className="h-[7px] w-[482px] rounded-t-xl bg-[#324d72] absolute top-0 left-0" />
              )}
              <p className="text-sm text-[#1E293B]">{item.name}</p>
              <hr className="h-[2px] bg-[#E5E6E8] my-2" />
              {checking === topicIndex && (
                <div className="flex justify-end items-center gap-2">
                  <input
                    className="border-none rounded-sm p-0 w-[40px] text-xs text-[#424348]"
                    value={item.point}
                    onChange={(e) =>
                      handlePointChange(topicIndex, Number(e.target.value))
                    }
                    type="number"
                  />
                  <p className="text-[#424348] text-xs">оноо</p>
                </div>
              )}
              {item.questions.map((question, questionIndex) => (
                <div key={questionIndex}>
                  {checking === topicIndex ? (
                    <div>
                      <div
                        onClick={() => {
                          toggleQuestionCheck(topicIndex, questionIndex);
                        }}
                        className={`flex items-center  justify-between px-3 py-1.5 rounded-md mt-2 ${
                          question.isAnswer ? "bg-[#00D97D] bg-opacity-20" : ""
                        }`}
                      >
                        <div className="flex items-center gap-2 ">
                          <label className="custom-checkbox flex items-center justify-center">
                            <input
                              type="checkbox"
                              checked={question.isAnswer}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <p className="text-xs text-wrap text-[#1E293B]">
                            {question.text}
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          {question.isAnswer && (
                            <FaCheck className="text-[#00D97D]" />
                          )}
                          <button
                            onClick={() =>
                              handleDeleteQuestion(topicIndex, questionIndex)
                            }
                            className="text-[#575763] text-xl"
                          >
                            <IoMdClose />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={questionIndex}
                      className={`flex items-center  justify-between px-3 py-1.5 rounded-md mt-2 ${
                        question.isAnswer ? "bg-[#00D97D] bg-opacity-20" : ""
                      }`}
                    >
                      <p className="text-xs text-wrap text-[#1E293B]">
                        {question.text}
                      </p>
                      <div className="flex items-center gap-2">
                        {question.isAnswer && (
                          <FaCheck className="text-[#00D97D]" />
                        )}
                        <button
                          onClick={() =>
                            handleDeleteQuestion(topicIndex, questionIndex)
                          }
                          className="text-[#575763] text-xl"
                        >
                          <IoMdClose />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="flex items-center gap-2 px-3 py-1.5 rounded-md">
                <input
                  className="w-full text-xs ps-2 py-1 text-[#1E293B] rounded-lg border-none mt-2"
                  onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[topicIndex] = e.target.value;
                    setQuestions(updatedQuestions);
                  }}
                  placeholder="Хариулт нэмэх"
                  value={questions[topicIndex] || ""}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddQuestion(topicIndex);
                    }
                  }}
                  type="text"
                />

                <button onClick={() => handleAddQuestion(topicIndex)}>
                  <FaPlus />
                </button>
              </div>
              <div className="flex items-center justify-between mt-4 px-3 rounded-md">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => {
                      if (topicIndex === checking) {
                        setChecking(null);
                      } else {
                        setChecking(topicIndex);
                      }
                    }}
                    className="text-[#324D72] flex items-center gap-1 "
                  >
                    <span className="text-xl">
                      <IoMdKey />
                    </span>
                    <span className="text-[14px]">Зөв хариулт</span>
                  </button>
                  <p className="text-[#424348] text-[13px]">
                    ({item.point} оноо)
                  </p>
                </div>
                <div>
                  {topicIndex === checking && (
                    <button
                      onClick={() => {
                        setChecking(null);
                      }}
                      className="px-3 py-1 border border-[#CECFD3] rounded-lg text-[#324d72] text-sm"
                    >
                      Болсон
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteTopic(topicIndex)}
                    className="text-[#707070] text-xl"
                  >
                    <MdDeleteOutline />
                  </button>
                </div>
              </div>
            </div>
          ))}

          {adding ? (
            <div className="w-[482px] bg-[#fff] rounded-xl p-4 mb-4 flex items-center">
              <input
                className="w-full text-sm text-[#1E293B] rounded-lg border-none"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleAddTopic();
                  }
                }}
                placeholder="Асуулт нэмэх"
                type="text"
              />
              <button onClick={handleAddTopic} className=" ml-2">
                <FaPlus />
              </button>
            </div>
          ) : (
            <div className="w-[482px] flex justify-end">
              <button
                onClick={() => {
                  setAdding(true);
                }}
                className="text-[#324D72] text-sm flex items-center gap-2 py-1 px-3 rounded-lg bg-[#fff] font-semibold"
              >
                <GoPlusCircle />
                Асуулт нэмэх
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestQuestions;
