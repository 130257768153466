const Level = ({ lvl }) => {
    return (
        <div className="flex justify-center w-[300px] ">
            {
                lvl === 1 ? <img src="/assets/level/recA.svg" alt="1" width={55} className="h-[25px] mt-[-3px] ml-[-4px]" /> :
                    <img src="/assets/level/rec.svg" alt="rec" width={55} className="pr-1 h-[20px]" />
            }
            {
                lvl === 2 ? <img src="/assets/level/recB.svg" alt="2" width={55} className="h-[25px] mt-[-3px] ml-[-4px]" /> :
                    <img src="/assets/level/rec.svg" alt="rec" width={55} className="pr-1 h-[20px]" />
            }
            {
                lvl === 3 ? <img src="/assets/level/recC.svg" alt="3" width={55} className="h-[25px] mt-[-3px] ml-[-4px]" /> :
                    <img src="/assets/level/rec.svg" alt="rec" width={55} className="pr-1 h-[20px]" />
            }
            {
                lvl === 4 ? <img src="/assets/level/recD.svg" alt="4" width={55} className="h-[25px] mt-[-3px] ml-[-4px]" /> :
                    <img src="/assets/level/rec.svg" alt="rec" width={55} className="pr-1 h-[20px]" />
            }
            {
                lvl === 5 ? <img src="/assets/level/recE.svg" alt="5" width={55} className="h-[25px] mt-[-3px] ml-[-4px]" /> :
                    <img src="/assets/level/rec.svg" alt="rec" width={55} className="pr-1 h-[20px]" />
            }

        </div>
    );
}

export default Level;