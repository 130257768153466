import { CircularProgress, Modal } from "@mui/material";
import { Box } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "white",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "25px",
};

const Loader = ({ showModal }) => {
  const { t, i18n } = useTranslation();

  return (
    <Modal
      open={showModal}
      // onClose={showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="flex  justify-center content-center	">
          <div className="">
            <CircularProgress className="ml-11" />
            <p className="text-center text-lg"> {t('GameFlow.loading')} ...</p>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default Loader;
