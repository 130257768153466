import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import MainButton from "../../components/buttons/MainButton";

function createData(name, description, score, label, img) {
  return { name, description, score, label, img };
}
const NewAssRequirementDemo = ({
  savedSkillScore,
  changePage,
  skillScoreInputs,
  setSkillScoreInputs,
  createAssessment,
  gameLanguage,
  setGameLanguage,
  jobName,
}) => {
  const [usePervalue, setUsePervalue] = useState(true);

  const { t, i18n } = useTranslation();
  const rows = [
    createData(
      `${t("NewAssessment.inductiveName")}`,
      `${t("NewAssessment.inductiveDescription")}`,
      3,
      "inductive",
      "inductive.png"
    ),
    createData(
      `${t("NewAssessment.deductiveName")}`,
      `${t("NewAssessment.deductiveDescription")}`,
      3,
      "deductive",
      "deductive.png"
    ),
    createData(
      `${t("NewAssessment.quantitativeName")}`,
      `${t("NewAssessment.quantitativeDescription")}`,
      3,
      "quantitativeReasoing",
      "quantitative.png"
    ),
    createData(
      `${t("NewAssessment.attentionName")}`,
      `${t("NewAssessment.attentionDescription")}`,
      3,
      "attention",
      "attention.png"
    ),
    createData(
      `${t("NewAssessment.workingMemoryName")}`,
      `${t("NewAssessment.workingMemoryDescription")}`,
      3,
      "workingMemory",
      "memory.png"
    ),
    createData(
      `${t("NewAssessment.processingSpeedName")}`,
      `${t("NewAssessment.processingSpeedDescription")}`,
      3,
      "processingSpeed",
      "processingSpeed.png"
    ),
    createData(
      `${t("NewAssessment.patternRecognitionName")}`,
      `${t("NewAssessment.patternRecognitionDescription")}`,
      3,
      "patternRecognition",
      "pattern.png"
    ),
    createData(
      `${t("NewAssessment.criticalThinkingName")}`,
      `${t("NewAssessment.criticalThinkingDescription")}`,
      3,
      "criticalThinking",
      "critical-thinking.png"
    ),
    createData(
      `${t("NewAssessment.decisionMakingName")}`,
      `${t("NewAssessment.decisionMakingDescription")}`,
      3,
      "decisionMaking",
      "decision-making.png"
    ),
    createData(
      `${t("NewAssessment.planningName")}`,
      `${t("NewAssessment.planningDescription")}`,
      3,
      "planning",
      "planning.png"
    ),
    createData(
      `${t("NewAssessment.learningSpeedName")}`,
      `${t("NewAssessment.learningSpeedDescription")}`,
      3,
      "learningRate",
      "learning.png"
    ),
    createData(
      `${t("NewAssessment.curiosityName")}`,
      `${t("NewAssessment.curiosityDescription")}`,
      3,
      "curiosity",
      "curiosity.png"
    ),
    createData(
      `${t("NewAssessment.riskTakingName")}`,
      `${t("NewAssessment.riskTakingDescription")}`,
      3,
      "riskTaking",
      "risk-taking.png"
    ),
    createData(
      `${t("NewAssessment.adaptability")}`,
      `${t("NewAssessment.adaptabilityDescription")}`,
      3,
      "adaptability",
      "adaptability.png"
    ),
    createData(
      `${t("NewAssessment.consistence")}`,
      `${t("NewAssessment.consistenceDescription")}`,
      3,
      "consistence",
      "consistency.png"
    ),
    createData(
      `${t("NewAssessment.goalSetting")}`,
      `${t("NewAssessment.goalSettingDescription")}`,
      3,
      "goalSetting",
      "goal-setting.png"
    ),
    createData(
      `${t("NewAssessment.cooperative")}`,
      `${t("NewAssessment.cooperativeDescription")}`,
      3,
      "cooperative",
      "cooperative.png"
    ),
    createData(
      `${t("NewAssessment.trusting")}`,
      `${t("NewAssessment.trustingDescription")}`,
      3,
      "trusting",
      "trust.png"
    ),
    createData(
      `${t("NewAssessment.assertiveness")}`,
      `${t("NewAssessment.assertivenessDescription")}`,
      3,
      "assertiveness",
      "assertive.png"
    ),
  ];

  const handleChange = (setter) => (e) => {
    setter(parseInt(e.target.value));
  };

  const getGradientColor = (value) => {
    const percentage = (value - 1) * 25;
    return `linear-gradient(to left, #D9D9D9 ${
      100 - percentage
    }%, #66BCCE ${0}%)`;
  };

  return (
    <div className="w-10/12 m-auto pt-11">
      <div>
        <div className="mt-20">
          <div>
            {/* {jobName === "" ? ( */}
            <p className="text-2xl ps-3 pb-2">
              {t("NewAssessment.requirementTitle")}
            </p>
            {/* ) : (
              <p className="text-2xl ps-3 pb-2">
                <span className="font-bold text-2xl">{jobName}</span> хариуцсан
                ажилтанд байвал зохих чадварууд :
              </p>
            )} */}
            <svg
              width="713"
              height="1"
              viewBox="0 0 713 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="0.5" x2="713" y2="0.5" stroke="#ABADB5" />
            </svg>
          </div>
          <div>
            {/* <p className="text-center mt-20  text-2xl">Зөөлөн ур чадварууд</p> */}
            <div className="me-20">
              {/* <table className="w-full mt-5">
                <tbody>
                  <tr>
                    <td className="bg-[#D9D9D9] bg-opacity-[49%] ps-5 py-2">
                      <p className="text-xl font-bold ">Нягтлан бодох чадвар</p>
                    </td>
                    <td className="bg-[#D9D9D9] bg-opacity-[49%] flex py-2 items-center gap-2">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={temperature1}
                            onChange={handleChange(setTemperature1)}
                            className="appearance-none w-full relative  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(temperature1),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => {
                              const number = value + 1;
                              return (
                                <span
                                  key={value}
                                  onClick={() => setTemperature1(number)}
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                    temperature1 >= value + 1
                                      ? "bg-[#66BCCE]"
                                      : "bg-[#D9D9D9]"
                                  }`}
                                  style={{ left: `${value * 24 + 2}%` }}
                                ></span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99958 19.2858C15.128 19.2858 19.2853 15.1285 19.2853 10.0001C19.2853 4.87171 15.128 0.714355 9.99958 0.714355C4.87122 0.714355 0.713867 4.87171 0.713867 10.0001C0.713867 15.1285 4.87122 19.2858 9.99958 19.2858Z"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.85645 14.2859H12.1422"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99986 14.2859V9.28589H8.57129"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99972 6.07146C9.80248 6.07146 9.64258 5.91156 9.64258 5.71432C9.64258 5.51708 9.80248 5.35718 9.99972 5.35718"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 6.07146C10.1972 6.07146 10.3571 5.91156 10.3571 5.71432C10.3571 5.51708 10.1972 5.35718 10 5.35718"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="ps-5 py-2">
                      <p className="text-xl font-bold ">Давтамж таних</p>
                    </td>
                    <td className="flex py-2 items-center gap-2">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={temperature2}
                            onChange={handleChange(setTemperature2)}
                            className="appearance-none w-full relative  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(temperature2),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => {
                              const number = value + 1;
                              return (
                                <span
                                  key={value}
                                  onClick={() => setTemperature2(number)}
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                    temperature2 >= value + 1
                                      ? "bg-[#66BCCE]"
                                      : "bg-[#D9D9D9]"
                                  }`}
                                  style={{ left: `${value * 24 + 2}%` }}
                                ></span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99958 19.2858C15.128 19.2858 19.2853 15.1285 19.2853 10.0001C19.2853 4.87171 15.128 0.714355 9.99958 0.714355C4.87122 0.714355 0.713867 4.87171 0.713867 10.0001C0.713867 15.1285 4.87122 19.2858 9.99958 19.2858Z"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.85645 14.2859H12.1422"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99986 14.2859V9.28589H8.57129"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99972 6.07146C9.80248 6.07146 9.64258 5.91156 9.64258 5.71432C9.64258 5.51708 9.80248 5.35718 9.99972 5.35718"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 6.07146C10.1972 6.07146 10.3571 5.91156 10.3571 5.71432C10.3571 5.51708 10.1972 5.35718 10 5.35718"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-[#D9D9D9] bg-opacity-[49%] ps-5 py-2">
                      <p className="text-xl font-bold ">
                        Шийдвэр гаргах чадвар
                      </p>
                    </td>
                    <td className="bg-[#D9D9D9] bg-opacity-[49%] flex py-2 items-center gap-2">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={temperature3}
                            onChange={handleChange(setTemperature3)}
                            className="appearance-none w-full relative  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(temperature3),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => {
                              const number = value + 1;
                              return (
                                <span
                                  key={value}
                                  onClick={() => setTemperature3(number)}
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                    temperature3 >= value + 1
                                      ? "bg-[#66BCCE]"
                                      : "bg-[#D9D9D9]"
                                  }`}
                                  style={{ left: `${value * 24 + 2}%` }}
                                ></span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99958 19.2858C15.128 19.2858 19.2853 15.1285 19.2853 10.0001C19.2853 4.87171 15.128 0.714355 9.99958 0.714355C4.87122 0.714355 0.713867 4.87171 0.713867 10.0001C0.713867 15.1285 4.87122 19.2858 9.99958 19.2858Z"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.85645 14.2859H12.1422"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99986 14.2859V9.28589H8.57129"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99972 6.07146C9.80248 6.07146 9.64258 5.91156 9.64258 5.71432C9.64258 5.51708 9.80248 5.35718 9.99972 5.35718"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 6.07146C10.1972 6.07146 10.3571 5.91156 10.3571 5.71432C10.3571 5.51708 10.1972 5.35718 10 5.35718"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="ps-5 py-2">
                      <p className="text-xl font-bold ">Суралцах хурд</p>
                    </td>
                    <td className="flex py-2 items-center gap-2">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <input
                            type="range"
                            max="4"
                            value={temperature4}
                            onChange={handleChange(setTemperature4)}
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(temperature4),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  temperature4 >= value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99958 19.2858C15.128 19.2858 19.2853 15.1285 19.2853 10.0001C19.2853 4.87171 15.128 0.714355 9.99958 0.714355C4.87122 0.714355 0.713867 4.87171 0.713867 10.0001C0.713867 15.1285 4.87122 19.2858 9.99958 19.2858Z"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.85645 14.2859H12.1422"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99986 14.2859V9.28589H8.57129"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99972 6.07146C9.80248 6.07146 9.64258 5.91156 9.64258 5.71432C9.64258 5.51708 9.80248 5.35718 9.99972 5.35718"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 6.07146C10.1972 6.07146 10.3571 5.91156 10.3571 5.71432C10.3571 5.51708 10.1972 5.35718 10 5.35718"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-[#D9D9D9] bg-opacity-[49%] ps-5 py-2">
                      <p className="text-xl font-bold ">Төлөвлөлт</p>
                    </td>
                    <td className="bg-[#D9D9D9] bg-opacity-[49%] flex py-2 items-center gap-2">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <input
                            type="range"
                            max="4"
                            value={temperature5}
                            onChange={handleChange(setTemperature5)}
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(temperature5),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  temperature5 >= value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99958 19.2858C15.128 19.2858 19.2853 15.1285 19.2853 10.0001C19.2853 4.87171 15.128 0.714355 9.99958 0.714355C4.87122 0.714355 0.713867 4.87171 0.713867 10.0001C0.713867 15.1285 4.87122 19.2858 9.99958 19.2858Z"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.85645 14.2859H12.1422"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99986 14.2859V9.28589H8.57129"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99972 6.07146C9.80248 6.07146 9.64258 5.91156 9.64258 5.71432C9.64258 5.51708 9.80248 5.35718 9.99972 5.35718"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 6.07146C10.1972 6.07146 10.3571 5.91156 10.3571 5.71432C10.3571 5.51708 10.1972 5.35718 10 5.35718"
                          stroke="#666874"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table> */}
            </div>
          </div>
          <div className="mt-10">
            {/* <p className="text-center text-2xl">Зан төлөв</p> */}
            <div className="mt-10">
              {rows.map((item) => {
                let value;
                if (usePervalue) {
                  let Pervalue;
                  const evalue = skillScoreInputs[item.label];
                  if (evalue) {
                    if (evalue === 1) {
                      Pervalue = 0;
                    } else if (evalue === 2) {
                      Pervalue = 25;
                    } else if (evalue === 3) {
                      Pervalue = 50;
                    } else if (evalue === 4) {
                      Pervalue = 75;
                    } else {
                      Pervalue = 100;
                    }
                  } else {
                    Pervalue = 50;
                  }
                  value = Pervalue;
                }
                return (
                  <div className="mb-10" key={item.label}>
                    <div className="flex justify-between me-20 pe-20  items-start">
                      <p className="text-left text-xl">{item.name}</p>
                      {/* <p className="text-lg text-center">
                        Тогтвортой орчин нөхцөлд <br /> тууштай ажиллах талтай
                      </p> */}
                      <div className="relative">
                        <p className="absolute -bottom-5 right-0 text-base">
                          {t("NewAssessment.requirementHigh")}
                        </p>
                        <p className="absolute -bottom-5 left-0 text-base">
                          {t("NewAssessment.requirementLow")}
                        </p>
                        <input
                          type="range"
                          className="w-[400px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                          value={value}
                          onChange={(e) => {
                            const newValue = parseInt(e.target.value);
                            let skillValue;
                            if (newValue >= 0 && newValue <= 19) {
                              skillValue = 1;
                            } else if (newValue >= 20 && newValue <= 39) {
                              skillValue = 2;
                            } else if (newValue >= 40 && newValue <= 59) {
                              skillValue = 3;
                            } else if (newValue >= 60 && newValue <= 79) {
                              skillValue = 4;
                            } else {
                              skillValue = 5;
                            }
                            setSkillScoreInputs({
                              ...skillScoreInputs,
                              [item.label]: skillValue,
                            });
                            setUsePervalue(false);
                          }}
                          style={{
                            backgroundImage:
                              "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                          }}
                        />
                      </div>
                      {/* <p className="text-lg text-center">
                        Таамаглаагүй өөрчлөлтөд <br /> нээлттэй ханддаг
                      </p> */}
                    </div>
                  </div>
                );
              })}

              {/* <div className="flex justify-between items-center">
                <p className="text-lg text-center">
                  Тогтвортой орчин нөхцөлд <br /> тууштай ажиллах талтай
                </p>
                <input
                  type="range"
                  className="w-[400px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />

                <p className="text-lg text-center">
                  Таамаглаагүй өөрчлөлтөд <br /> нээлттэй ханддаг
                </p>
              </div>
              <div className="flex justify-between items-center mt-8">
                <p className="text-lg text-center">
                  Тогтвортой орчин нөхцөлд <br /> тууштай ажиллах талтай
                </p>
                <input
                  type="range"
                  className="w-[400px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />

                <p className="text-lg text-center">
                  Таамаглаагүй өөрчлөлтөд <br /> нээлттэй ханддаг
                </p>
              </div>
              <div className="flex justify-between items-center mt-8">
                <p className="text-lg text-center">
                  Тогтвортой орчин нөхцөлд <br /> тууштай ажиллах талтай
                </p>
                <input
                  type="range"
                  className="w-[400px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />

                <p className="text-lg text-center">
                  Таамаглаагүй өөрчлөлтөд <br /> нээлттэй ханддаг
                </p>
              </div>
              <div className="flex justify-between items-center mt-8">
                <p className="text-lg text-center">
                  Тогтвортой орчин нөхцөлд <br /> тууштай ажиллах талтай
                </p>
                <input
                  type="range"
                  className="w-[400px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />

                <p className="text-lg text-center">
                  Таамаглаагүй өөрчлөлтөд <br /> нээлттэй ханддаг
                </p>
              </div>
              <div className="flex justify-between items-center mt-8">
                <p className="text-lg text-center">
                  Тогтвортой орчин нөхцөлд <br /> тууштай ажиллах талтай
                </p>
                <input
                  type="range"
                  className="w-[400px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />

                <p className="text-lg text-center">
                  Таамаглаагүй өөрчлөлтөд <br /> нээлттэй ханддаг
                </p>
              </div>
              <div className="flex justify-between items-center mt-8">
                <p className="text-lg text-center">
                  Тогтвортой орчин нөхцөлд <br /> тууштай ажиллах талтай
                </p>
                <input
                  type="range"
                  className="w-[400px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />

                <p className="text-lg text-center">
                  Таамаглаагүй өөрчлөлтөд <br /> нээлттэй ханддаг
                </p>
              </div>
              <div className="flex justify-between items-center mt-8">
                <p className="text-lg text-center">
                  Тогтвортой орчин нөхцөлд <br /> тууштай ажиллах талтай
                </p>
                <input
                  type="range"
                  className="w-[400px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />

                <p className="text-lg text-center">
                  Таамаглаагүй өөрчлөлтөд <br /> нээлттэй ханддаг
                </p>
              </div>
              <div className="flex justify-between items-center mt-8">
                <p className="text-lg text-center">
                  Тогтвортой орчин нөхцөлд <br /> тууштай ажиллах талтай
                </p>
                <input
                  type="range"
                  className="w-[400px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />

                <p className="text-lg text-center">
                  Таамаглаагүй өөрчлөлтөд <br /> нээлттэй ханддаг
                </p>
              </div> */}
            </div>
          </div>
          <div className="mt-10">
            {/* <p className="text-2xl font-bold text-center">Оюуны чадамж</p> */}
            <div className="mt-10">
              <div>
                {/* <table>
                  <tbody>
                    <tr>
                      <td className="py-2 pe-20">
                        <p className="text-2xl font-bold">Eрөнхийлөн дүгнэх</p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <input
                                type="range"
                                max="4"
                                value={temperature1}
                                onChange={handleChange(setTemperature1)}
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage:
                                    getGradientColor(temperature1),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      temperature1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99958 19.2858C15.128 19.2858 19.2853 15.1285 19.2853 10.0001C19.2853 4.87171 15.128 0.714355 9.99958 0.714355C4.87122 0.714355 0.713867 4.87171 0.713867 10.0001C0.713867 15.1285 4.87122 19.2858 9.99958 19.2858Z"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.85645 14.2859H12.1422"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99986 14.2859V9.28589H8.57129"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99972 6.07146C9.80248 6.07146 9.64258 5.91156 9.64258 5.71432C9.64258 5.51708 9.80248 5.35718 9.99972 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 6.07146C10.1972 6.07146 10.3571 5.91156 10.3571 5.71432C10.3571 5.51708 10.1972 5.35718 10 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">
                        <p className="text-2xl font-bold">Боловсруулах хурд</p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <input
                                type="range"
                                max="4"
                                value={temperature1}
                                onChange={handleChange(setTemperature1)}
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage:
                                    getGradientColor(temperature1),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      temperature1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99958 19.2858C15.128 19.2858 19.2853 15.1285 19.2853 10.0001C19.2853 4.87171 15.128 0.714355 9.99958 0.714355C4.87122 0.714355 0.713867 4.87171 0.713867 10.0001C0.713867 15.1285 4.87122 19.2858 9.99958 19.2858Z"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.85645 14.2859H12.1422"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99986 14.2859V9.28589H8.57129"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99972 6.07146C9.80248 6.07146 9.64258 5.91156 9.64258 5.71432C9.64258 5.51708 9.80248 5.35718 9.99972 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 6.07146C10.1972 6.07146 10.3571 5.91156 10.3571 5.71432C10.3571 5.51708 10.1972 5.35718 10 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">
                        <p className="text-2xl font-bold">Анхаарал төвлөрөлт</p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <input
                                type="range"
                                max="4"
                                value={temperature1}
                                onChange={handleChange(setTemperature1)}
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage:
                                    getGradientColor(temperature1),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      temperature1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99958 19.2858C15.128 19.2858 19.2853 15.1285 19.2853 10.0001C19.2853 4.87171 15.128 0.714355 9.99958 0.714355C4.87122 0.714355 0.713867 4.87171 0.713867 10.0001C0.713867 15.1285 4.87122 19.2858 9.99958 19.2858Z"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.85645 14.2859H12.1422"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99986 14.2859V9.28589H8.57129"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99972 6.07146C9.80248 6.07146 9.64258 5.91156 9.64258 5.71432C9.64258 5.51708 9.80248 5.35718 9.99972 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 6.07146C10.1972 6.07146 10.3571 5.91156 10.3571 5.71432C10.3571 5.51708 10.1972 5.35718 10 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">
                        <p className="text-2xl font-bold">Тоон сэтгэлгээ</p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <input
                                type="range"
                                max="4"
                                value={temperature1}
                                onChange={handleChange(setTemperature1)}
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage:
                                    getGradientColor(temperature1),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      temperature1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99958 19.2858C15.128 19.2858 19.2853 15.1285 19.2853 10.0001C19.2853 4.87171 15.128 0.714355 9.99958 0.714355C4.87122 0.714355 0.713867 4.87171 0.713867 10.0001C0.713867 15.1285 4.87122 19.2858 9.99958 19.2858Z"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.85645 14.2859H12.1422"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99986 14.2859V9.28589H8.57129"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99972 6.07146C9.80248 6.07146 9.64258 5.91156 9.64258 5.71432C9.64258 5.51708 9.80248 5.35718 9.99972 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 6.07146C10.1972 6.07146 10.3571 5.91156 10.3571 5.71432C10.3571 5.51708 10.1972 5.35718 10 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">
                        <p className="text-2xl font-bold">Нарийвчлан дүгнэх</p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <input
                                type="range"
                                max="4"
                                value={temperature1}
                                onChange={handleChange(setTemperature1)}
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage:
                                    getGradientColor(temperature1),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      temperature1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99958 19.2858C15.128 19.2858 19.2853 15.1285 19.2853 10.0001C19.2853 4.87171 15.128 0.714355 9.99958 0.714355C4.87122 0.714355 0.713867 4.87171 0.713867 10.0001C0.713867 15.1285 4.87122 19.2858 9.99958 19.2858Z"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.85645 14.2859H12.1422"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99986 14.2859V9.28589H8.57129"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99972 6.07146C9.80248 6.07146 9.64258 5.91156 9.64258 5.71432C9.64258 5.51708 9.80248 5.35718 9.99972 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 6.07146C10.1972 6.07146 10.3571 5.91156 10.3571 5.71432C10.3571 5.51708 10.1972 5.35718 10 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">
                        <p className="text-2xl font-bold">Идэвхитэй санах ой</p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <input
                                type="range"
                                max="4"
                                value={temperature1}
                                onChange={handleChange(setTemperature1)}
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage:
                                    getGradientColor(temperature1),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      temperature1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99958 19.2858C15.128 19.2858 19.2853 15.1285 19.2853 10.0001C19.2853 4.87171 15.128 0.714355 9.99958 0.714355C4.87122 0.714355 0.713867 4.87171 0.713867 10.0001C0.713867 15.1285 4.87122 19.2858 9.99958 19.2858Z"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.85645 14.2859H12.1422"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99986 14.2859V9.28589H8.57129"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99972 6.07146C9.80248 6.07146 9.64258 5.91156 9.64258 5.71432C9.64258 5.51708 9.80248 5.35718 9.99972 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 6.07146C10.1972 6.07146 10.3571 5.91156 10.3571 5.71432C10.3571 5.51708 10.1972 5.35718 10 5.35718"
                              stroke="#666874"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </div>
            </div>
            <div className="mt-10 flex justify-end items-center gap-5 me-20">
              <p className="text-2xl font-bold">
                {t("NewAssessment.language")}
                <span className="text-[#ff0000]">*</span>:
              </p>

              <select
                className="rounded-lg ps-4 pe-8 py-2 ms-4"
                defaultValue={gameLanguage}
                inputProps={{
                  name: "language",
                  id: "uncontrolled-native",
                }}
                onChange={(e) => {
                  setGameLanguage(e.target.value);
                }}
              >
                <option value={0}>
                  <span>Монгол</span>
                </option>
                <option value={2}>
                  <div display="flex" alignItems="center">
                    <span>English</span>
                  </div>
                </option>
                <option value={1}>
                  <div display="flex" alignItems="center">
                    <span>Русский</span>
                  </div>
                </option>
              </select>
            </div>
            <div className="flex items-center justify-between mx-10 my-10">
              <button
                onClick={() => {
                  changePage("general");
                }}
                className="flex items-center py-2 px-4 gap-4 text-xl font-bold border border-black rounded-lg bg-[#E8EDF2]"
              >
                <FaChevronLeft className="w-4 h-4" />
                {t("NewAssessment.backBtn")}
              </button>

              <div>
                <MainButton
                  color={"bg-main-blue"}
                  labelColor={"text-white"}
                  label={t("NewAssessmentGeneral.createNewAsessment")}
                  onClick={createAssessment}
                  customClass="flex items-center py-2 px-4 gap-4 text-xl font-bold text-white rounded-lg bg-[#324D72]"
                  rIcon={<FaChevronRight className="w-4 h-4" />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAssRequirementDemo;
