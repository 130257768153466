import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createCompanyAndUser } from "../../services/player.service";
import { data } from "autoprefixer";
import { ToastContainer, toast } from "react-toastify";
import { FaArrowDownLong } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const TermsOfService = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const div1Ref = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (location === null || location.state === null) {
      navigate("/signup");
    }
  }, []);

  const scrollToDiv = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const onSubmit = async (datas) => {
    setLoading(true);
    const user = datas.user;
    const company = datas.company;
    const formData = new FormData();

    formData.append("email", user.email);
    formData.append("fName", user.firstName);
    formData.append("lName", user.lastName);
    formData.append("phoneNumber", user.position);
    formData.append("password", user.password);
    formData.append("userPhoto", user.photo);
    formData.append("position", user.position);
    formData.append("companyName", company.companyName);
    formData.append("companyPhoto", company.photo);
    formData.append("companyRegister", company.register);
    formData.append("companyDirection", company.direction);
    formData.append("employeeNumber", company.employeeNumber);

    for (const pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    await createCompanyAndUser(formData)
      .then((e) => {
        navigate("/company/successful");
      })
      .catch(() => {
        toast.error(t("toast.registeredUser"));
      });
    setLoading(false);
  };

  return (
    <div className="sm:w-8/12 w-10/12 m-auto mt-20 relative">
      <ToastContainer />

      <div>
        <div className="flex w-full justify-between items-center me-4 sm:px-20">
          <p className="font-bold sm:text-lg text-xs border-l-2 border-[#F18878] ps-2">
            Байгууллагын бүртгэл
          </p>
          <p className="sm:text-lg text-end text-xs text-[#209653]">
            Эхний 3 хоног үнэгүй ашиглах боломжтой
          </p>
        </div>

        <div className="border rounded-lg my-10 sm:px-10 px-0">
          <div className="sm:mt-10 mt-5 flex items-center justify-center sm:px-0 px-2">
            <div className="flex items-center">
              <p className="sm:py-2 sm:px-4 py-[3px] px-[9px] bg-black rounded-full text-white font-bold sm:text-base text-[12px]">
                1
              </p>
              <p className="sm:text-lg text-[10px] font-bold ms-1 sm:ms-4">
                Таны бүртгэл
              </p>
            </div>
            <div className="flex items-center ms-2 sm:ms-6 ">
              <p className="sm:py-2 sm:px-4 py-[3px] px-[9px] bg-black rounded-full text-white font-bold sm:text-base text-[12px]">
                2
              </p>
              <p className="sm:text-lg text-[10px] font-bold ms-1 sm:ms-4">
                Байгууллагын бүртгэл
              </p>
            </div>
            <div className="flex items-center ms-2 sm:ms-6 ">
              <p className="sm:py-2 sm:px-4 py-[3px] px-[9px] bg-black rounded-full text-white font-bold sm:text-base text-[12px]">
                3
              </p>
              <p className="sm:text-lg text-[10px] font-bold ms-1 sm:ms-4">
                Үйлчилгээний нөхцөл
              </p>
            </div>
          </div>
          <div className="sm:px-10 sm:mx-20 mx-4 sm:mt-20 mt-10">
            <p>
              <p className="font-bold mb-4 sm:text-base text-sm">
                Oneplace.hr ашиглах үйлчилгээний нөхцөл
              </p>
              <p className="mb-2 sm:text-base text-sm">
                Үйлчилгээний нөхцөлд дараах зүйлс багтана:
              </p>
              <p className="sm:text-base text-sm">
                Oneplace.hr вэбсайтад бүртгүүлж, ашигласнаар та эдгээр нөхцөл
                болон Oneplace.hr Нууцлалын бодлогыг дагаж мөрдөхийг зөвшөөрч
                байна гэж үзнэ. Хэрэв та эдгээр бүх нөхцөлийг хүлээн
                зөвшөөрөхгүй бол уг үйлчилгээг ашиглахгүй байж болно. Энэхүү
                үйлчилгээний нөхцөлд "бид", "бидний", "манай" болон
                “Oneplace.hr” нь Oneplace.hr-ийг илтгэнэ, "та" болон "таны" нь
                Oneplace.hr хэрэглэгч буюу таныг илтгэнэ. Дараах нөхцөлүүд
                Oneplace.hr-ийн санал болгож буй вэбсайт, програм, үйлчилгээнд
                хамаарна. Үүнд гар утас, таблет хувилбарууд болон бусад
                төхөөрөмжөөр дамжуулан ашиглах боломжтой Oneplace.hr-ийн бусад
                хувилбарууд - компьютер, гар утас, таблет, сошиал медиа багтана.
                “Oneplace.hr” ВЭБСАЙТААС АЛИВАА БҮТЭЭГДЭХҮҮН, МЭДЭЭЛЭЛ,
                ҮЙЛЧИЛГЭЭГ ТАТАХ, АШИГЛАХ, АВАХЫН ӨМНӨ ЭДГЭЭР ҮЙЛЧИЛГЭЭНИЙ
                НӨХЦӨЛТЭЙ САЙТАР ТАНИЛЦАНА УУ.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                НӨХЦӨЛИЙГ ХҮЛЭЭН ЗӨВШӨӨРӨХ
              </p>
              <p className="sm:text-base text-sm">
                Энэхүү гэрээ нь таны Oneplace.hr-ийн үйлчилгээг ашиглахтай
                холбоотой хууль ёсны, хүчинтэй нөхцөлүүдийг тодорхойлсон. Энэхүү
                вэб сайтад бүртгүүлж, ашигласнаар та энэхүү гэрээг дагаж
                мөрдөхийг зөвшөөрч байна гэж үзнэ. Бид энэ гэрээг үе үе өөрчилж
                болох бөгөөд энэхүү өөрчлөлт нь Oneplace.hr дээр нийтлэгдсэнээс
                хойш хүчин төгөлдөр болно. Гэрээнд өөрчлөлт оруулсны дараа
                Oneplace.hr-ийн үргэлжилсэн ашиглалт нь энэхүү үйлчилгээний
                нөхцөлд орсон өөрчлөлтийн хүлээн зөвшөөрсөн гэж үзнэ; Тиймээс та
                энэхүү гэрээг тогтмол хянаж байх нь чухал юм. Таны оруулсан
                бүртгэлийн мэдээлэл алдаагүй, үнэн зөв гэдгийг мөн таны тус
                платформын ашиглалт холбогдох хууль тогтоомж, дүрмийг зөрчөөгүйг
                та баталгаажуулж, мэдээллийнхээ төлөөлөл болно. Компани нь
                өөрийн үзэмжээр аливаа байгууллагад үйлчилгээг санал болгохоос
                татгалзаж, зохих шалгуурыг хэдийд ч өөрчлөх эрхтэй. Хуулиар
                хориглосон тохиолдолд энэ заалт хүчингүй болох бөгөөд ийм шүүх
                эрх мэдэл хэрэгжих бүс, харъя газарт үйлчилгээ, платформд хандах
                эрх хүчингүй болно.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">ШААРДЛАГА</p>
              <p className="sm:text-base text-sm">
                Та Oneplace.hr-тай гэрээ байгуулснаар мөн эдгээр нөхцөл болон
                орон нутгийн, улсын, үндэсний болон олон улсын хууль тогтоомж,
                дүрэм, журмын дагуу Oneplace.hr-ийн санал болгож буй үйлчилгээг
                ашиглах эрх үүснэ. Вэбсайтад бүртгүүлж, ашигласнаар та энэхүү
                гэрээг байгуулах эрх бүхий гэдгээ баталж, баталгаажуулна.
                Түүнчлэн, та энэхүү гэрээг байгуулахаас сэргийлэх хөгжлийн
                бэрхшээл, өөр гэрээний хязгаарлалт байхгүй гэдгээ илэрхийлж,
                баталгаажуулж байна гэж үзнэ.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">ХЭРЭГЛЭГЧИД</p>
              <p className="sm:text-base text-sm">
                Хэрэв та Oneplace.hr-д бүртгүүлэхийг хүсвэл энэхүү гэрээг уншиж,
                бүртгэлийн явцад хүлээн зөвшөөрснөө мэдэгдэх шаардлагатай.
                Oneplace.hr-ийн үйлчилгээг ашиглахтай холбогдуулан та заавал
                гэрээ байгуулах боломжтой, насанд хүрсэн байх бөгөөд холбогдох
                хууль тогтоомж, харьяаллын дагуу үйлчилгээ авахыг хориглосон хүн
                биш гэдгээ илэрхийлнэ. Мөн та: (а) Oneplace.hr-ийн бүртгэлийн
                маягтын дагуу өөрийнхөө тухай үнэн зөв, бүрэн бүтэн, одоогийн
                мэдээллийг өгөх, (б) бүртгэлийн мэдээлэл өөрчлөгдөх тохиолдолд
                үнэн зөв, бүрэн бүтэн, одоогийн мэдээллийг хангахын тулд нэн
                даруй мэдээллээ шинэчлэхийг зөвшөөрч байна. Хэрэв бид таны
                оруулсан мэдээлэл үнэн биш, одоогийн биш эсвэл бүрэн бус гэж
                сэжиглэх үндэслэл үүсвэл Oneplace.hr таны бүртгэлийг хэзээд ч,
                ямар нэгэн мэдэгдэлгүйгээр түдгэлзүүлэх, хаах, үйлчилгээг (эсвэл
                түүний аль нэг хэсгийг) одоо болон ирээдүйд ашиглахаас татгалзах
                эрхтэй.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                ХЭРЭГЛЭГЧИЙН БҮРТГЭЛ БА ТҮҮНИЙ АЮУЛГҮЙ БАЙДАЛ
              </p>
              <p className="sm:text-base text-sm">
                Oneplace.hr-д бүртгүүлж хэрэглэгчийн бүртгэл үүсгэх үед нууц үг
                сонгох шаардлагатай бөгөөд тус бүртгэлийн талаар нэмэлт мэдээлэл
                авах шаардлага гарч болзошгүй. Та өөрийн нууц үг болон
                бүртгэлийн мэдээллээ нууцлах үүрэгтэй бөгөөд таны нууц үг,
                бүртгэлийн хүрээнд хийгдсэн бүх үйлдэлд та бүрэн хариуцлага
                үүрнэ. Та (a) таны нууц үг, бүртгэлийг өөр хүн зөвшөөрөлгүй
                ашигласан эсвэл аюулгүй байдал зөрчигдсөн тохиолдолд
                Oneplace.hr-д нэн даруй мэдэгдэх, (б) холболт бүрийн төгсгөлд
                бүртгэлээсээ гарах тохиргоог (sign-out) баталгаажуулж байхыг
                зөвшөөрч байна. Та Oneplace.hr-ээс зөвшөөрөл авалгүйгээр өөр
                хэрэглэгчийн бүртгэлийг хэзээ ч ашиглаж болохгүй. Энэхүү
                нөхцөлийг биелүүлээгүйгээс үүдэн гарах аливаа хохирол, алдагдлыг
                Oneplace.hr хариуцахгүй.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                БҮРТГЭЛИЙГ УСТГАХ, ХААХ
              </p>
              <p className="sm:text-base text-sm">
                Oneplace.hr дээрх зааврын дагуу хэрэглэгчид ямар ч шалтгаанаар
                бүртгэлээ хааж болно. Ингэснээр тухайн бүртгэл устаж,
                Oneplace.hr-д таны оруулсан бүх хувийн мэдээлэл устана. Хэрэв та
                эдгээр нөхцөлийн аль нэгийг зөрчсөн, Oneplace.hr-ийг худал,
                төөрөгдүүлсэн мэдээлэл өгсөн, эсвэл ашиглалтад саад учруулсан
                гэж үзвэл Oneplace.hr танд мэдэгдэлгүйгээр мөн таны өмнө
                хариуцлага хүлээхгүйгээр таны бүртгэлийг эсвэл таны вэбсайт руу
                хандах эрхийг нэн даруй цуцлах эрхтэй.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">ЗАХИАЛГА</p>
              <p className="sm:text-base text-sm">
                Oneplace.hr нь хэрэглэгчдэд вэбсайт болон програмаар дамжуулан
                худалдан авах боломжтой захиалгат subscription-оор (subscription
                - ямарваа нэгэн бүтээгдэхүүн, үйлчилгээг авахын тулд сард нэг
                удаа эсвэл жилээр нь төлбөрөө хийдэг систем) үйлчилгээгээ санал
                болгодог. Та subscription захиалга өгөхдөө Oneplace.hr вэбсайт
                дээр байршуулсан үнийн мэдээллийн дагуу захиалга хийнэ. Захиалга
                хийхээсээ өмнө subscription багц бүрийн үнэ болон боломжит
                функцуудын мэдээллийг сайтар шалгана уу. Вэбсайтын хэрэглэгч
                онлайнаар subscription захиалга өгөх үед Oneplace.hr захиалгыг
                хүлээн авсан эсэхийг баталгаажуулахын тулд тухайн хэрэглэгч рүү
                и-мэйл илгээнэ. Энэхүү и-мэйл баталгаажуулалт автоматаар
                боловсруулдаг бөгөөд ингэснээр хэрэглэгч өөрийн захиалгын
                дэлгэрэнгүй мэдээллийг баталгаажуулах боломжтой болно.
                Oneplace.hr нь шаардлагатай тохиолдолд аливаа борлуулалтыг
                цуцалж, үйлчилгээ үзүүлэхгүй байх эрхтэй бөгөөд subscription
                захиалгын хүртээмжийг өөрчлөх эсвэл бүр мөсөн зогсоож болно.
                Үүнийг таны хууль зүйн эрхэд нөлөөлөхгүйгээр хийгдэнэ.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">ҮНЭ</p>
              <p className="sm:text-base text-sm">
                “Метаког Метрикс” ХХК – нь subscription захиалгын үнийг
                тодорхойлох эрхтэй. Oneplace.hr вэбсайт дээр үнийн мэдээллийг
                нийтлэх бөгөөд үнэд өөрчлөлт орсон тохиолдолд цаг тухайд нь
                шинэчлэн вэбсайт дээр нийтэлнэ. Иймд үнийн мэдээлэл өөрчлөгдсөн
                эсэхийг манай вэбсайтаас үе үе шалгаж байхыг зөвлөж байна.
                Oneplace.hr subscription захиалгын нэмэлт төлбөр, хураамж
                зэргийг өөрчлөх эрхтэй. Oneplace.hr нь өөрийн дураар,
                Oneplace.hr-ийн аль ч хэрэглэгчдэд өөр өөр онцлог, өөр үнэ бүхий
                урамшууллын санал гаргаж болно.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">ТӨЛБӨР</p>
              <p className="sm:text-base text-sm">
                Oneplace.hr руу хийгддэг бүх төлбөр найдвартай байдлаар
                хийгддэг. Subscription захиалгын төлбөрийг төлбөрийн платформаар
                дамжуулан төлнө. Subscription захиалга өгсний дараа төлбөрийн
                үнийн дүнг таны кредит карт эсвэл дебит картаас шууд хасна.
                Гүйлгээг амжилттай болж, төлбөр баталгаажсаны дараа бид таны
                и-мэйл рүү төлбөрийн баримт илгээнэ.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                ГУРАВДАГЧ БАЙГУУЛЛАГЫН МАТЕРИАЛ
              </p>
              <p className="sm:text-base text-sm">
                “Гуравдагч байгууллагын материал” гэж гуравдагч байгууллага,
                этгээдийн эзэмшлийн аливаа контент, зураг, видео, текстийг
                хэлнэ. Уг материалууд нь гуравдагч байгууллагын холбогдох
                нөхцөл, лицензийн дагуу Oneplace.hr дээр байрших бөгөөд зөвхөн
                тэдний нөхцөл, лицензээр зөвшөөрөгдсөн тохиолдолд л ашиглагдаж
                болно.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                ҮЙЛЧИЛГЭЭНИЙ ДАХИН БОРЛУУЛАХ
              </p>
              <p className="sm:text-base text-sm">
                Хэрэглэгчид Oneplace.hr-ээс урьдчилан бичгээр зөвшөөрөл
                авалгүйгээр үйлчилгээ, контентийн аль нэг хэсэг, платформын
                функцийг өөр аль ч талд худалдах эрхгүй.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                АЮУЛГҮЙ БАЙДЛЫН БҮРЭЛДЭХҮҮН
              </p>
              <p className="sm:text-base text-sm">
                Oneplace.hr болон Oneplace.hr доторх программ хангамж нь дижитал
                материалын хамгаалалтын бүрэлдэхүүн хэсгүүдийг агуулж болох ба
                эдгээр материалыг ашиглах нь Oneplace.hr болон/эсвэл Oneplace.hr
                платформыг контентоор хангадаг контент нийлүүлэгчдийн тогтоосон
                ашиглалтын дүрмийн дагуу явагдана. Та Oneplace.hr-д суурилсан
                аюулгүй байдлын бүрэлдэхүүн хэсэг болон ашиглалтын дүрмийг
                хүчингүй болгох, идэвхгүй болгох, эсвэл өөр аргаар хөндлөнгөөс
                оролцож болохгүй.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                ХУВИЙН МЭДЭЭЛЭЛ
              </p>
              <p className="sm:text-base text-sm">
                Oneplace.hr-ийн үйлчилгээ болон програмыг ашиглахтай
                холбогдуулан таны нийтэлсэн эсвэл өөр ямарваа нэгэн хэлбэрээр
                оруулсан хувийн мэдээллийг манай Нууцлалын бодлогын дагуу
                ашиглана. Та Oneplace.hr үйлчилгээг ашиглавал бид таны хувийн
                мэдээллийг цуглуулж, хадгалахыг зөвшөөрч байна гэж үзнэ. Тус
                цуглуулсан хувийн мэдээллийг тодорхойлолтын үнэн зөв эсэхийг
                шалгах, шаардлагатай хяналт шалгалт хийхэд ашигладаг. Өөрийн
                хувийн мэдээллийг бидэнд өгөхдөө тань руу зурвас, и-мэйл, эсвэл
                бусад аргаар харилцахыг зөвшөөрч байна гэж үзнэ. Биднээс тус
                зурвас, и-мэйл, эсвэл бусад аргаар харилцахыг хүсэхгүй байна гэж
                мэдэгдэхээс нааш үргэлжилнэ. Үүний талаар дэлгэрэнгүй мэдээллийг
                манай Нууцлалын бодлогоос харна уу.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                АРИЛЖААНЫ БУС ХЭРЭГЛЭЭ
              </p>
              <p className="sm:text-base text-sm">
                Oneplace.hr-ийн тусгайлан зөвшөөрснөөс бусад тохиолдолд
                үйлчилгээг арилжааны зорилгоор ашиглахыг хориглоно.
                Oneplace.hr-ийн аль ч платформуудыг зөвшөөрөлгүй хуулах, өөр
                програмтай холбохыг хориглоно. Арилжааны зар сурталчилгаа,
                түншлэлийн холбоосууд болон бусад хэлбэрийн контентуудийг бидэнд
                мэдэгдэлгүйгээр байршуулбал бүртгэлийг хаахад хүргэж болзошгүй.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                ХЭРЭГЛЭГЧИЙН БҮРТГЭЛИЙГ УСТГАХ
              </p>
              <p className="sm:text-base text-sm">
                Oneplace.hr дээрх зааврыг дагаж хэрэглэгчид ямар ч шалтгаанаар
                бүртгэлээ хаах бүрэн боломжтой. Энэ цуцлалт нь таны бүртгэлийг
                устгаж, Oneplace.hr-д олгосон бүх хувийн мэдээлэл устгагдана.
                Хэрэв Oneplace.hr таныг энэхүү гэрээний аль нэг нөхцөлийг
                зөрчсөн, бидэнд худал, төөрөгдүүлсэн мэдээлэл өгсөн, програм,
                вэбсайт, үйлчилгээг бусдад ашиглахад саад учруулсан гэж үзвэл
                Oneplace.hr таны бүртгэлийг эсвэл таны хандалтыг танд
                мэдэгдэлгүйгээр, таны өмнө хариуцлага хүлээхгүйгээр нэн даруй
                цуцлах эрхтэй.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                ХОРИГЛОСОН ҮЙЛ АЖИЛЛАГАА
              </p>
              <p className="sm:text-base text-sm">
                Вэбсайт дээр байгаа агуулга, мэдээлэл: (дараах зүйлсээр
                хязгаарлагдахгүй) өгөгдөл, мэдээлэл, текст, хөгжим, дуу чимээ,
                гэрэл зураг, график, видео, газрын зураг, дүрс болон бусад
                материал багтана. Мөн түүнчлэн тус контентийг хангахад ашигласан
                дэд бүтэц нь Oneplace.hr-ийн өмч юм уу эсвэл гуравдагч этгээдээс
                Oneplace.hr-д олгосон лицензтэй юм. Та өөрийн контентоос вебсайт
                дээрх бусад бүх контентийн аливаа мэдээлэл, программ хангамж,
                үйлчилгээг өөрчлөх, хуулах, түгээх, дамжуулах, үзүүлэх,
                гүйцэтгэх, хуулбарлах, нийтлэх, лицензлэх, дайвар бүтээгдэхүүн
                үүсгэх, шилжүүлэх, худалдах, дахин худалдах зэргийг
                зөвшөөрөхгүй. Нэмж дурдахад Oneplace.hr-ийн үйлчилгээг
                ашигласнаар та дараах зүйлсийг хийхгүй байхыг зөвшөөрч байна гэж
                үзнэ:
              </p>
              <p className="ml-7 sm:text-base text-sm mt-4 sm:mt-0 ">
                <ul>
                  <li>
                    ● Oneplace.hr-ээс гаргасан энэхүү журам болон холбогдох
                    удирдамжийн дагуу тодорхой зөвшөөрөгдсөн арилжааны зорилгоос
                    гадуур аливаа үйлчилгээ эсвэл контентийг арилжааны зорилгоор
                    ашиглахгүй.
                  </li>
                  <li>
                    ● Манай дэд бүтцэд үндэслэлгүй эсвэл хэт их ачаалал өгөхгүй
                    байх.
                  </li>
                  <li>
                    ● Oneplace.hr-ээс бичгээр зөвшөөрөл авалгүйгээр ямар ч
                    зорилгоор үйлчилгээний аль ч хэсэгт Deep-Link хийхгүй.
                  </li>
                  <li>
                    ● Oneplace.hr-ээс урьдчилан бичгээр зөвшөөрөл авалгүйгээр
                    үйлчилгээний аль нэг хэсгийг бусад вэбсайт эсвэл үйлчилгээ
                    рүү оруулахгүй.
                  </li>
                  <li>
                    ● Аливаа контентийг ашиглах, хуулбарлахаас урьдчилан
                    сэргийлэх, хязгаарлах үйлчилгээ, функцүүдийн аюулгүй
                    байдалтай холбоотой функцуудыг мэхлэх, идэвхгүй болгох эсвэл
                    өөр байдлаар саад учруулахгүй.Oneplace.hr өөрийн вэбсайт
                    дээр аливаа контентийг шууд татаж авах боломжтойгоор
                    тавиагүй тохиолдолд дурын контентийг татаж авахгүй байх.
                  </li>
                  <li>
                    ● Oneplace.hr-ийн үйлчилгээтэй холбоотой аливаа програм
                    хангамжийг өөрчлөх, орчуулах, хялбарчлан засах, засварлах,
                    задлах, хөрвүүлэх, урвуу инженерчлэл хийхийг оролдохгүй.
                  </li>
                </ul>
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                БАТАЛГААНЫ МЭДЭЭЛЭЛ
              </p>
              <p className="sm:text-base text-sm">
                Oneplace.hr нь үйлчилгээгээ өөрсдийн зүгээс сайн үзүүлэх боловч
                бусад хүний үйлчилгээний талаар ямар ч баталгаа өгөхгүй.
                Интернетийн мөн чанараас шалтгаалан Oneplace.hr нь вэбсайт болон
                програмыг "байгаагаар нь", "боломжтой" гэсэн зарчмаар хангаж,
                засвар үйлчилгээ хийдэг бөгөөд вэбсайт болон програмыг ашиглах
                нь тасалдалгүй эсвэл алдаагүй байх болно гэж амлалт өгдөггүй.
                Хэрэв бид өөрсдийнхөө хяналтаас гадуур ямар нэгэн шалтгаанаар
                интернетийн үйлчилгээгээ үзүүлэх боломжгүй бол бид таны өмнө
                хариуцлага хүлээхгүй. Манай вэбсайт болон аппликейшнинд үе үе
                гаднын вэбсайтын холбоосууд орж ирдэг бөгөөд энэ нь бидний
                хяналтад байдаггүй. Эдгээр холбоосууд нь зөвхөн таны тав тухыг
                хангах үүднээс өгөгдсөн байдаг бөгөөд бид эдгээр сайтуудын
                агуулгад хариуцлага хүлээхгүй. Дээр дурдсанаас бусад тохиолдолд
                бид тодорхой болон далд, хууль ёсны болон бусад ямар ч баталгаа
                өгөх боломжгүй бөгөөд эдгээр бүх нөхцөлийг хуулиар зөвшөөрөгдсөн
                дээд хэмжээгээр хассан болно. Та эдгээр нөхцөлийг зөрчсөн
                тохиолдолд хариуцлагаа хүлээх бөгөөд хэрэв та эдгээр нөхцөлийг
                зөрчиж аппликейшнийг ашиглавал үүний үр дүнд учирсан бүхий
                хохирлыг Oneplace.hr-д нөхөн төлөх болно. Oneplace.hr нь
                интернетийн тасалдал, харилцаа холбооны тасалдал, гал түймэр,
                үер, дайны болон бусад биднээс хяналтаас гадуурх урьдчилан
                таамаглашгүй аливаа үйл явдлаас болж энэхүү гэрээний дагуу
                хүлээсэн үүргээ биелүүлээгүй тохиолдолд ямар ч хэмжээгээр
                хариуцлага хүлээхгүй. Эдгээр нөхцөлүүд нь хэрэглэгч таны хуулиар
                олгогдсон эрхэд нөлөөлөхгүй. Дээр дурьдсанчлан, Oneplace.hr нь
                таны Oneplace.hr болон компанид мэдэгдсэн аливаа асуудлаас үүдэн
                гарсан, шууд ба шууд бус болон үр дагаварт учирч болзошгүй
                аливаа алдагдал, хохирлыг ямар ч хязгаарлалтгүйгээр, хуулиар
                зөвшөөрөгдсөн дээд хэмжээгээр хариуцахгүй. Oneplace.hr нь нөхөн
                олговрын хэлбэрээр ямар нэгэн мөнгө төлөх үүрэг хүлээхгүй бөгөөд
                үүнд дараах зүйлстэй холбоотой бүх хариуцлагыг хүлээхгүй:
                <ul className="ml-7">
                  <li>
                    {" "}
                    ● Oneplace.hr-ийн талаарх буруу эсвэл ташаа мэдээлэл.
                  </li>
                  <li>
                    {" "}
                    ● Oneplace.hr-ийг шинэчлэхэд ямар нэгэн саатал, тасалдал.
                  </li>
                  <li>
                    {" "}
                    ● Аппликейшн, эсвэл уг програмаар дамжуулан худалдан авсан
                    аливаа бүтээгдэхүүн, үйлчилгээг ашигласнаас үүдэн аливаа
                    гуравдагч этгээдийн оюуны өмчийн эрхийг аливаа этгээд
                    зөрчсөн.
                  </li>
                  <li>
                    {" "}
                    ● Бидний хяналтаас гадуурх нөхцөл байдалд таны мэдээллийн
                    дамжуулалт, өгөгдөлд зөвшөөрөлгүй хандсан, өөрчлөгдсөнөөс
                    үүдэн таны хэрэглээ, эсвэл програмыг ашиглах боломжгүйгээс
                    үүдэн гарсан аливаа алдагдал.
                  </li>
                  <li>
                    {" "}
                    ● Аливаа ашгийн алдагдал, шаардлагагүй зардал, авилга,
                    өгөгдлийг устгах, эсвэл бидний буруугүй үйлдлээс хамаарсан
                    шууд үр дагаваргүй бусад аливаа алдагдал.
                  </li>
                  <li>
                    {" "}
                    ● Вэбсайт болон програмын контент руу нэвтэрсэн, ашигласан
                    хүмүүс; хэрэглэгчийн хүлээн авсан цахим шуудан эсвэл тус
                    цахим шуудангийн хавсралтаас үүдсэн хэрэглэгчийн компьютерын
                    тоног төхөөрөмж, программ хангамж, өгөгдөл болон бусад эд
                    хөрөнгийг халдварлаж болзошгүй вирус болон бусад хортой
                    программ хангамжийн улмаас учирсан аливаа хохирол.
                  </li>
                  <li>
                    {" "}
                    ● Энэхүү мэдэгдлээс бусад тохиолдолд хүчин төгөлдөр болох
                    бүх мэдэгдэл, баталгаа болон бусад нөхцөлүүд.
                  </li>
                </ul>
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                ЦАХИМ ХОЛБОО
              </p>
              <p className="sm:text-base text-sm">
                Компьютер, утас, кабел, сүлжээ, цахим болон интернетийн техник
                хангамж, програм хангамжийн доголдол, холболт, хүртээмж,
                компьютерын дамжуулалт, интернетийн хүртээмж бүтэлгүйтсэн,
                хэсэгчлэн эсвэл эвдэрсэн тохиолдолд Oneplace.hr хариуцлага
                хүлээхгүй.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                НӨХӨН ТӨЛБӨР
              </p>
              <p className="">
                Та Oneplace.hr-ийг дараах тохиолдлуудаас хамааралтай тохиолдолд
                (зөвхөн үүгээр хязгаарлагдахгүй) гуравдагч байгууллагаас
                шаардсан аливаа нэхэмжлэл, гомдол, шаардлага, нөхөн төлбөр,
                алдагдал, хохирол, торгууль, хууль эрх зүйн болон нягтлан бодох
                бүртгэлийн боломжит хураамж зэрэг аливаа төрлийн болон уг шинж
                чанартай бусад зардал, зардлаас хамгаалах, нөхөн төлбөрийг
                олгохыг зөвшөөрч байна:
                <p className="ml-7 sm:text-base text-sm mt-4 sm:mt-0">
                  <ul>
                    <li>
                      ● Та энэхүү гэрээ эсвэл энд дурдсан баримт бичгийг
                      зөрчсөн.
                    </li>
                    <li>
                      ● Таны аливаа хууль тогтоомж эсвэл гуравдагч байгууллага,
                      этгээдийн эрхийг зөрчсөн үйлдлүүд.
                    </li>
                    <li>● Oneplace.hr програмыг татаж авах.</li>
                    <li>● Oneplace.hr програм болон үйлчилгээний хэрэглээ.</li>
                  </ul>
                </p>
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                ӨӨРЧЛӨЛТ БА ЦУЦЛАЛТ
              </p>
              <p className="sm:text-base text-sm">
                Бид өөрийн үзэмжээр, танд мэдэгдэлгүйгээр хүссэн үедээ програм,
                вэбсайт болон эдгээр нөхцөлийг өөрчлөх эрхтэй. Та эдгээр
                нөхцөлийн талаар тогтмол мэдээдэллийг авах үүрэгтэй. Та
                вэбсайтын үйлчилгээг үргэлжлүүлэн ашиглах нь эдгээр Нөхцөлд
                оруулсан аливаа өөрчлөлтийг хүлээн зөвшөөрч байгаа гэж үзэх
                бөгөөд нөхцөлийн аливаа өөрчлөлт нь өмнөх бүх хувилбарыг
                хүчингүй болгоно. Энд өөрөөр заагаагүй бол эдгээр нөхцлийн бүх
                өөрчлөлт бүх хэрэглэгчдэд хамаарна. Цаашилбал, бид танд бичгээр
                мэдэгдэх (и-мэйлээр гэх мэт) эсвэл ямар нэгэн анхааруулгагүйгээр
                хүссэн үедээ эдгээр нөхцөлийн дагуу тантай байгуулсан гэрээг
                цуцалж болно.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                ЭРХИЙН ШИЛЖҮҮЛЭГ
              </p>
              <p className="sm:text-base text-sm">
                Энэхүү гэрээ болон үүгээр олгогдсон аливаа эрх, лицензийг та
                бусдад шилжүүлж болохгүй, харин Oneplace.hr хязгаарлалтгүйгээр
                өөрийн үзэмжээр хэнд ч шилжүүлэх эрхтэй.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">
                ИНТЕГРАЦИЙН НӨХЦӨЛ
              </p>
              <p className="sm:text-base text-sm">
                Энэхүү гэрээ нь Нууцлалын бодлого болон Oneplace.hr-ээс
                нийтэлсэн аливаа хууль эрх зүйн мэдэгдлийн хамт та болон
                Oneplace.hr-ийн хооронд програмын вэбсайт болон түүний
                үйлчилгээг ашиглахтай холбоотой гэрээг бүхэлд нь бүрдүүлж,
                зохицуулна.
              </p>
              <p className="sm:text-base text-sm mt-4 font-bold">МАРГААН</p>
              <p className="sm:text-base text-sm">
                Эдгээр нөхцөлийг зөрчих, цуцлах, хэрэгжүүлэх, тайлбарлах,
                хүчинтэй байх, эсвэл Oneplace.hr вэбсайт болон програмыг
                ашиглахтай холбоотой аливаа маргаан, нэхэмжлэлийг арбитрын
                шүүхээр шийдвэрлэнэ гэдгийг хүлээн зөвшөөрч байна. Гэрээний тал
                бүр бага хэмжээний нэхэмжлэлийг шүүхэд бие даасан хэлбэрээр
                гаргах, талуудын зохиогчийн эрх, барааны тэмдэгт, худалдааны
                нууц, патент эсвэл бусад оюуны өмчийн эрхийг зүй бусаар ашиглах,
                ашиглахыг заналхийлэх, эрх зөрчихөөс урьдчилан сэргийлэхийн тулд
                эрх бүхий харьяаллын шүүхэд захирамж болон бусад эрх тэгш
                тусламж хүсэх эрхтэй. Эдгээр нөхцөлүүд Монгол Улсын хуулиар
                зохицуулагддаг. Бидний эдгээр Нөхцөлүүдийг хэрэгжүүлэх нь одоо
                байгаа хууль тогтоомж, хууль эрх зүйн үйл явцын дагуу явагдах
                бөгөөд эдгээр Нөхцөлд агуулагдах нөхцөлүүд нь бидний хууль
                сахиулах болон бусад төрийн болон хууль ёсны хүсэлт, манай
                платформыг ашиглахтай холбоотой шаардлагыг биелүүлэх эрхийг
                хязгаарладаггүй. Хэрэв эдгээр нөхцөлийн аль нэг хэсэг нь
                хүчингүй болох, хууль бус эсвэл хэрэгжих боломжгүй нь
                тогтоогдвол үлдсэн бусад заалтуудын хүчин төгөлдөр байдал, хууль
                ёсны байдал, хэрэгжилтэд ямар нэгэн байдлаар нөлөөлөхгүй.
              </p>
            </p>
          </div>
          <div
            ref={div1Ref}
            className="flex justify-end me-20 pe-10  mt-5 mb-10 "
          >
            <button
              className="border rounded-lg px-4 py-2 bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => onSubmit(location.state)}
              disabled={loading}
              aria-busy={loading}
              aria-live="polite"
            >
              {loading ? (
                <div className="animate-spin h-5 w-5 border-4 border-t-transparent border-black rounded-full"></div>
              ) : (
                "Зөвшөөрөx"
              )}
            </button>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          scrollToDiv(div1Ref);
        }}
        className="p-3 fixed sm:right-40 right-6 sm:bottom-10 bottom-6 rounded-full bg-[#000] "
      >
        <FaArrowDownLong className="text-white sm:text-2xl text-lg" />
      </div>
    </div>
  );
};

export default TermsOfService;
